import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import usePlacesService from "src/libs/ReactGooglePlacesAutocomplete/hooks/use-places-service"
import useFetchSuggestions from "src/libs/ReactGooglePlacesAutocomplete/hooks/use-fetch-suggestions"
import { MyUI, Popover } from "src/core/components"
import styled from "styled-components"
import classNames from "classnames"
import { HUtils, HCF } from "@macashipo/mlib"
import { Button} from "reactstrap"
import { BsClock } from "react-icons/bs"
import { IoClose } from "react-icons/io5"

function SelectList(props) {
  const {
    list,
    onSelect,
    stylePopover,
    noOptionsMessage,
    inputValue,
    innerRef,
    fnList,
    configMsg,
    moreConfig
  } = props
  const [data, setData] = React.useState(list)
  React.useEffect(() => {
    if (innerRef) {
      innerRef({
        updateList: a => {
          setData(a)
        },
      })
    }
  }, [])
  var sourceData = []
  if (data && data.length > 0) {
    sourceData = data.map((v, i) => {
      return {
        Text: v.label,
        Value: v.label,
        More: v.value,
      }
    })
  }
  // console.warn("sourceData:", sourceData)
  if (sourceData == null || sourceData == undefined) {
    return null
  }
  let _uiEmpty = null
  if (sourceData && sourceData.length === 0) {
    _uiEmpty = <div className="a-noitem">{configMsg?.emptyMsg || "No options"}</div>
    if (noOptionsMessage) {
      _uiEmpty = noOptionsMessage({
        inputValue,
      })
    } else if (inputValue == null || inputValue.length == 0) {
      _uiEmpty = <div className="a-noitem">{configMsg?.emptyValueMsg || "Search or enter an address"}</div>
    }
  }
  const renderOptions = () => {
    if (!moreConfig?.renderCustomOption) {
      return <MyUI
        type="select_box_search_v2"
        hideSearch={true}
        hideCheckbox={true}
        styleContainer={{ width: "100%" }}
        sourceData={sourceData}
        // valueSelected={ctc.valueCell}
        onChange={itemOption => {
          console.warn("onChange:", itemOption, itemOption.Value)
          if (onSelect) {
            onSelect(itemOption)
          }
          //hide popover
          if (props.hide) {
            props.hide()
          }
        }}
        moreConfig={moreConfig}
      />
    }
    else {
      return <>{
        sourceData?.map((v, i) => {
          return <div className="one-address-recently cursor-pointer" key={`one-address-${i + 1}`} onClick={() => {
            if (onSelect) {
              onSelect(v)
            }
          }}>
            <div className="wrap-ic">
              <i className="flaticon-pin"/>
            </div>
            <div className="address-content">{v.Text}</div>
          </div>
        })
      }</>
    }
  }
  return (
    <WrapPopover
      style={{
        width: "100%",
        minWidth: "200px",
        maxWidth: "90vw",
        minHeight: "40px",
        ...stylePopover,
      }}
      onMouseOver={() => {
        fnList?.setIsManual && fnList.setIsManual(false)
      }}
      onMouseLeave={() => {
        fnList?.setIsManual && fnList.setIsManual(true)
      }}
    >
      {sourceData && sourceData.length === 0 && _uiEmpty}
      {sourceData && sourceData.length > 0 && (
        <>{renderOptions()}</>
        // <MyUI
        //   type="select_box_search_v2"
        //   hideSearch={true}
        //   hideCheckbox={true}
        //   styleContainer={{ width: "100%" }}
        //   sourceData={sourceData}
        //   // valueSelected={ctc.valueCell}
        //   onChange={itemOption => {
        //     console.warn("onChange:", itemOption, itemOption.Value)
        //     if (onSelect) {
        //       onSelect(itemOption)
        //     }
        //     //hide popover
        //     if (props.hide) {
        //       props.hide()
        //     }
        //   }}
        //   moreConfig={moreConfig}
        // />
      )}
    </WrapPopover>
  )
}

const GooglePlacesAutocompleteCustom = (args, ref) => {
  const selectProps = args.selectProps || {}
  const [suggestList, setSuggestList] = React.useState(null)
  const [visible, setVisible] = React.useState(false)
  const rInput = React.useRef(null)
  const currentDefaultValue = React.useRef(selectProps.defaultValue)
  const isManual = React.useRef(null)
  const currentList = React.useRef(null)
  const currentText = React.useRef("")
  const currentValue = React.useRef(null)
  const disabled = HUtils.get(selectProps, "isDisabled")
  const [firstDropdown, setFirstDropdown] = React.useState(true)
  const [isFocus, setIsFocus] = React.useState(false)
  const { placesService, sessionToken, setSessionToken } = usePlacesService({
    apiKey: HCF.getCF("GooglePlaceApiKey"),
    apiOptions: args.apiOptions ?? {},
    onLoadFailed: args.onLoadFailed ?? console.error,
  })
  const fetchSuggestions = useFetchSuggestions({
    autocompletionRequest: args.autocompletionRequest ?? {},
    debounce: args.debounce ?? 300,
    minLengthAutocomplete: args.minLengthAutocomplete ?? 0,
    placesService,
    sessionToken,
    withSessionToken: args.withSessionToken ?? false,
  })
  useImperativeHandle(
    ref,
    () => {
      return {
      getSessionToken: () => {
        return sessionToken
      },
      refreshSessionToken: () => {
        setSessionToken(new google.maps.places.AutocompleteSessionToken())
      },
      focusSearchInput: () => {
        (rInput.current).click()
      },
      closeDropdownAddress:()=>{
        setVisible(false)
      },
      lostFocusInput: () => {
        (rInput.current).blur()
        if (rInput) {
          rInput.current.value = currentDefaultValue.current
        }
        setVisible(false)
      }
    }},
    [sessionToken]
  )

  //update new default value
  if (selectProps.defaultValue != currentDefaultValue.current) {
    currentDefaultValue.current = selectProps.defaultValue
    if (rInput) {
      rInput.current.value = currentDefaultValue.current
    }
  }
  const renderDropdownMenu = () => {
    if(!visible){
      if(isFocus){
        if (selectProps?.fnRenderRecentlyAddress) {
          return selectProps?.fnRenderRecentlyAddress()
        }
      }else{
        if (selectProps?.fnRenderPopularrLocation) {
          return selectProps?.fnRenderPopularrLocation()
        }
      }
    } else if (visible) {
      return <SelectList
        // data={suggestList}
        list={suggestList}
        onSelect={item => {
          if (item && rInput) {
            rInput.current.value = item.Text
          }
          currentValue.current = item.Value
          isManual.current = false
          HUtils.runFuntion(selectProps, "onChange", [
            {
              Text: item.Text,
              Value: item.Value,
              More: item.More,
              cbChange: () => {
                // setVisible(false)
                // selectProps?.onHanleDropdown && selectProps?.onHanleDropdown(false)
              },
            },
          ])
        }}
        noOptionsMessage={selectProps.noOptionsMessage}
        stylePopover={args.stylePopover}
        inputValue={currentText.current}
        innerRef={opts => {
          console.log("rs: innerRef", opts)
          currentList.current = opts
        }}
        configMsg={selectProps.configMsg}
        fnList={{
          setIsManual: val => {
            isManual.current = val
          },
        }}
        moreConfig={selectProps?.moreConfig}
      />
    }
    return <></>
  }

  if (selectProps?.hasFirstDropDown) {
    return (
      <Wrap
        className={classNames("", {
          readonly: disabled,
        })}
      >
        <i className="ic-search flaticon-search"/>
        <input
          ref={rInput}
          className={classNames("form-control input-enter-address", visible ? "active" : "")}
          placeholder={HUtils.get(selectProps, "placeholder")}
          disabled={HUtils.get(selectProps, "isDisabled")}
          autoFocus={HUtils.get(selectProps, "autoFocus")}
          spellCheck={false}
          defaultValue={currentDefaultValue.current}
          type="text"
          onBlur={ev => {
            let _text = ev.currentTarget.value
            if (!_text && !firstDropdown) {
              setFirstDropdown(true)
            }
          }}
          onClick={(e) => {
            rInput.current.focus()
          }}
          onChange={ev => {
            let _text = ev.currentTarget.value
            currentText.current = _text
            isManual.current = true
            if (args["defaultSuffix"]) {
              _text += " " + args["defaultSuffix"]
            }
            if (!visible) {
              setVisible(true)
            }
            if (!_text) {
              setSuggestList(null)
            }
            if (selectProps?.hasFirstDropDown) {
              setFirstDropdown(false)
            }
            fetchSuggestions(_text, rs => {
              console.log("rs:", rs)
              currentList.current?.updateList &&
                currentList.current.updateList(rs)
              setSuggestList(rs)
            })
          }}
          onFocus={(e) => {
            setSuggestList(null)
            e.stopPropagation()
            setIsFocus(true)
            selectProps?.setFocusing && selectProps?.setFocusing(true)
            const _onFocus = HUtils.get(selectProps, "onFocus")
            _onFocus && _onFocus(e)
          }}
        />
        {!disabled && rInput?.current?.value && (
          <div className={`clear ${visible ? "active" : ""}`}>
            <Button
              title="Clear"
              onClick={(e) => {
                rInput.current.focus()
                if (!firstDropdown) {
                  setFirstDropdown(true)
                }
                HUtils.runFuntion(selectProps, "onChange", [
                  {
                    Text: null,
                    Value: null,
                    cbChange: (value) => {
                      rInput.current.value = value
                      // setVisible(false)
                    },
                    cbSuccess: () => {
                      rInput.current.value = ""
                      currentText.current = ""
                      setSuggestList(null)
                    },
                    cbError: () => { },
                  },
                  {
                    isWaiting: false,
                  },
                ])
              }}
              color="clear-address"
            >
              <IoClose size={16} />
            </Button>
          </div>
        )}
        {renderDropdownMenu()}
      </Wrap>
    )
  }
  return <></>
}

const Wrap = styled.div`
  position: relative;
  & .clear {
    position: absolute;
    top: 6px;
    right: 0px;
    bottom: 0px;
    & > button {
      padding: 0 8px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 0.25rem 0.25rem 0;
      background: transparent;
      border: 0px;
      color: #cccccc;
      transition: color 150ms;
      font-size: 18px;
      &:hover {
        color: #999999;
      }
      &:focus,
      &:active {
        box-shadow: none;
        background-color: transparent !important;
        color: #999999 !important;
      }
    }
  }
  & .ic-search{
    position: absolute;
    top: 13px;
    left: 8px;
    padding: 3px 0;
    padding-right: 8px;
    bottom: 0px;
    display: flex;
    font-size: 18px;
    height: fit-content;
    border-right: 1px solid #ccc;
  }
  & .form-control {
    padding-right: 40px;
  }
  &.readonly {
    & .form-control {
      padding-right: 0.75rem;
    }
  }
  & .input-enter-address {
    padding-left: 43px;
    min-height: 50px;
    border-radius: 10px;
    &.active, 
    &:focus {
    }
  }
  & .option:hover {
    background-color: transparent !important;
  }
  & .one-address-recently {
    margin-top: -5px;
    transition: ease-in-out all 0.3s;
    display: flex;
    align-items: flex-start;
    padding: 8px;
    margin-left: -8px;
    cursor: pointer;
    border: 1px solid transparent;
    &:first-child {
      margin-top: 0;
    }
    & .wrap-ic {
      margin-top: 3px;
      width: 40px;
      height: 40px;
      min-width: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #dddddd;
      margin-right: 16px;
    }
    & i {
      font-size: 20px;
      color: #5F5F5F;
      font-weight: 400;
      display: flex;
    }
    & .address-content {
      width: calc(100% - 56px);
      color: #7b7878;
      & .title {
        font-weight: 600;
        color: var(--color-text-363636);
        margin: 0;
      }
      & .time {
        font-size: 13px;
      }
    }
    &.active {
      user-select: none;
      pointer-events: none;
      cursor: context-menu;
      background-color: #ebebeb;
      border-color: #ebebeb;
      border-radius: 8px;
    }
  }
`
const WrapPopover = styled.div`
  font-size: 0.88rem;
  & .a-noitem {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    min-height: 40px;
    color: hsl(0, 0%, 60%);
  }
`
export default forwardRef(GooglePlacesAutocompleteCustom)
