import React, { ReactNode, useEffect, useState } from "react"
import Header from "src/components/Header/Header"
import SideNav from "src/components/SideNav/SideNav"
import { HConfig } from "src/core/helpers"
import { HCF, MyUI } from "@macashipo/mlib"
import ResizeObserver from "resize-observer-polyfill"
import Bg from "src/modules/app_sigo/assets/imgs/bg-hotro.png"
import styled from "styled-components"
import classnames from "classnames"
interface Props {
  children: ReactNode
  isHideFooter?: boolean
  isHideHeader?: boolean
  isHideSideNav?: boolean
  isFull?: boolean
}

export default function MainLayout(props: Props) {
  const { children, isHideHeader, isHideFooter, isHideSideNav, isFull } = props
  const [needReload, setNeedReload] = useState(false)
  const configFooter = HConfig.getConfigFooter()
  useEffect(() => {
    const applyResponsiveWidth = 1010
    const ro = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        const { width } = entry.contentRect
        if (entry.target == document.body) {
          const isResponsive =
            document.getElementsByClassName("theme-sigo-responsive")?.length > 0
              ? true
              : false
          if (isResponsive) {
            if (width > applyResponsiveWidth) {
              if (needReload === false) {
                setNeedReload(true)
              }
            } else {
              if (needReload === true) {
                setNeedReload(false)
              }
            }
          } else {
            if (width <= applyResponsiveWidth) {
              if (needReload === false) {
                setNeedReload(true)
              }
            } else {
              if (needReload === true) {
                setNeedReload(false)
              }
            }
          }
        }
      }
    })
    ro.observe(document.body)
  })
  const renderReloadUI = () => {
    return (
      <WrapReload
        className="container"
        style={{
          background: `#fff url('${Bg}') no-repeat top center`,
          backgroundSize: "contain",
          margin: "0px",
          height: "100vh",
        }}
      >
        <img
          className="logo"
          src={`${HCF.getCF("baseUrl")}/logo/logo-sigo.png`}
          alt="logo"
        ></img>
        <div className="row">
          <div className="col-md-6">
            <h2>Bạn đang chuyển đổi thiết bị?</h2>
            <h4>Bạn vui lòng tải lại trang để có trãi nghiệm tốt nhất.</h4>
            <button
              className="btn btn-primary mt-4"
              onClick={() => window.location.reload()}
            >
              <i className="flaticon-refresh" /> Tải lại trang{" "}
            </button>
          </div>
          <div className="col-md-6 icon">
            <svg
              version="1.1"
              id="fi_668757"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              xmlSpace="preserve"
              width={200}
              height={200}
            >
              <g>
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M300.643,367.607h200.894v44.643c0,12.328-9.993,22.321-22.321,22.321h-156.25
		c-12.328,0-22.321-9.993-22.321-22.321v-44.643H300.643z"
                />
                <rect
                  x="177.875"
                  y="367.604"
                  style={{ fill: "#FFFFFF" }}
                  width="66.965"
                  height="89.283"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M300.643,311.803v55.804H32.784c-12.328,0-22.321-9.993-22.321-22.321v-33.482H300.643z"
                />
              </g>
              <path
                style={{ fill: "#60DEAE" }}
                d="M389.93,311.803H32.784c-12.328,0-22.321-9.993-22.321-22.321V77.427
	c0-12.328,9.993-22.321,22.321-22.321h357.144c12.329,0,22.322,9.993,22.322,22.321v212.055
	C412.251,301.81,402.258,311.803,389.93,311.803z"
              />
              <path
                style={{ fill: "#FFD259" }}
                d="M300.643,166.713c0-12.328,9.993-22.321,22.321-22.321h156.251c12.328,0,22.321,9.993,22.321,22.321
	v223.215H300.643V166.713z"
              />
              <g>
                <rect
                  x="245.54"
                  y="378.768"
                  style={{ fill: "#423E5E" }}
                  width="20.926"
                  height="78.129"
                />
                <rect
                  x="156.247"
                  y="378.768"
                  style={{ fill: "#423E5E" }}
                  width="20.926"
                  height="78.129"
                />
                <path
                  style={{ fill: "#423E5E" }}
                  d="M272.742,467.357H149.972c-5.779,0-10.463-4.685-10.463-10.463s4.684-10.463,10.463-10.463h122.768
		c5.778,0,10.463,4.685,10.463,10.463S278.519,467.357,272.742,467.357z"
                />
                <rect
                  x="10.463"
                  y="301.34"
                  style={{ fill: "#423E5E" }}
                  width="290.177"
                  height="20.926"
                />
                <path
                  style={{ fill: "#423E5E" }}
                  d="M300.643,389.231H32.784C14.707,389.231,0,374.524,0,356.447V77.427
		C0,59.35,14.707,44.643,32.784,44.643h357.144c18.078,0,32.785,14.707,32.785,32.784v66.965c0,5.779-4.685,10.463-10.463,10.463
		c-5.778,0-10.463-4.684-10.463-10.463V77.427c0-6.54-5.319-11.858-11.858-11.858H32.784c-6.538,0-11.858,5.318-11.858,11.858
		v279.02c0,6.538,5.32,11.858,11.858,11.858h267.858c5.778,0,10.463,4.685,10.463,10.463S306.421,389.231,300.643,389.231z"
                />
                <rect
                  x="378.769"
                  y="412.951"
                  style={{ fill: "#423E5E" }}
                  width="44.643"
                  height="20.926"
                />
                <path
                  style={{ fill: "#423E5E" }}
                  d="M233.671,266.463c-2.185,0-4.387-0.681-6.27-2.094l-44.643-33.482
		c-2.635-1.977-4.185-5.078-4.185-8.371s1.551-6.394,4.185-8.371l44.643-33.482c4.624-3.467,11.181-2.53,14.649,2.093
		c3.467,4.623,2.53,11.182-2.093,14.648l-33.482,25.112l33.482,25.112c4.623,3.466,5.56,10.026,2.093,14.648
		C239.994,265.018,236.852,266.463,233.671,266.463z"
                />
                <path
                  style={{ fill: "#423E5E" }}
                  d="M367.616,288.784c-3.182,0-6.323-1.446-8.379-4.186c-3.466-4.623-2.53-11.182,2.093-14.649
		l33.482-25.112l-33.482-25.112c-4.623-3.466-5.559-10.026-2.093-14.649c3.467-4.623,10.026-5.559,14.648-2.093l44.643,33.483
		c2.635,1.977,4.185,5.078,4.185,8.371c0,3.293-1.551,6.394-4.185,8.371l-44.643,33.482
		C372.003,288.102,369.801,288.784,367.616,288.784z"
                />
                <path
                  style={{ fill: "#423E5E" }}
                  d="M479.216,467.357h-156.25c-18.077,0-32.785-14.707-32.785-32.784V267.161
		c0-18.077,14.708-32.784,32.785-32.784h78.125c5.778,0,10.463,4.684,10.463,10.463c0,5.779-4.685,10.463-10.463,10.463h-78.125
		c-6.54,0-11.859,5.319-11.859,11.858v167.412c0,6.538,5.32,11.858,11.859,11.858h156.25c6.538,0,11.858-5.32,11.858-11.858V166.714
		c0-6.538-5.32-11.858-11.858-11.858h-156.25c-6.54,0-11.859,5.32-11.859,11.858v33.482c0,18.077-14.707,32.784-32.784,32.784
		h-66.965c-5.779,0-10.463-4.684-10.463-10.463c0-5.779,4.684-10.463,10.463-10.463h66.965c6.538,0,11.858-5.32,11.858-11.858
		v-33.482c0-18.077,14.708-32.784,32.785-32.784h156.25c18.077,0,32.784,14.707,32.784,32.784v267.858
		C512,452.65,497.293,467.357,479.216,467.357z"
                />
                <path
                  style={{ fill: "#423E5E" }}
                  d="M501.537,400.392H300.643c-5.778,0-10.463-4.685-10.463-10.463c0-5.778,4.685-10.463,10.463-10.463
		h200.894c5.778,0,10.463,4.685,10.463,10.463C512,395.707,507.315,400.392,501.537,400.392z"
                />
                <rect
                  x="200.193"
                  y="334.823"
                  style={{ fill: "#423E5E" }}
                  width="22.321"
                  height="20.926"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </div>
        </div>
      </WrapReload>
    )
  }
  return (
    <div className="c-app d-flex align-items-stretch">
      {needReload ? (
        renderReloadUI()
      ) : (
        <>
          {!isHideSideNav && <SideNav />}
          <main
            className={classnames(
              "c-wrapper flex-grow-1 mw-100 min-vh-100",
              isHideHeader ? "hide-header" : ""
            )}
          >
            {!isHideHeader && <Header />}
            {/* {isFull ? (
          <div className="c-body">{children}</div>
        ) : (
          <div className="c-body content p-3">{children}</div>
        )} */}
            <div className="c-body">{children}</div>
            {configFooter.show === true && !isHideFooter && (
              <MyUI type="sg_footer" />
              // <footer className="c-footer">
              //   <MyUI type="html" html={configFooter.footer} />
              // </footer>
            )}
          </main>
        </>
      )}
    </div>
  )
}
const WrapReload = styled.div`
  padding: 4rem;
  max-width: 1080px !important;
  margin: 0 auto !important;
  & img {
    max-height: 40px;
  }
  & h2 {
    margin-top: 48px;
    font-weight: 600;
    font-size: 32px;
  }
  & h4 {
    color: #767676 !important;
  }
  & button {
    font-weight: 600;
    display: flex;
    align-items: center;
    & i {
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
  }
  & .icon {
    padding: 0 1rem;
    text-align: center;
    margin-top: 40px;
  }
  @media (max-width: 767px) {
    padding: 2rem 1rem;
    & > .row {
      flex-direction: column-reverse;
    }
    & .icon {
      text-align: left;
    }
  }
`
