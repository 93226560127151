import React, { useEffect, useState } from "react"
import { HAuth } from "src/core/helpers"
import { MyModal } from "src/core/components"
import { logout } from "src/App/App.actions"
import { ConnectedProps, connect } from "react-redux"
import dfAvatar from "src/modules/app_sigo/images/user.png"
import styled from "styled-components"
import { HEE } from "@macashipo/mlib"
import { KAuth } from "src/helpers/Flows/Auth"
import { SGNewPaths } from "src/modules/app_sigo/constants/paths"

const mapStateToProps = state => ({
  isAuthenticated: state.app.isAuthenticated,
})

const mapDispatchToProps = {
  logout,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface Props extends ConnectedProps<typeof connector> {}

const MyUIAvatar = (props: Props) => {
  const { isAuthenticated, logout } = props
  const [avatar, setAvatar] = useState(HAuth.getUserAvatar() || dfAvatar)
  const [userName, setUsername] = useState(HAuth.getUserName())
  const _updateAuthInfo = () => {
    setUsername(HAuth.getUserName())
    setAvatar(HAuth.getUserAvatar())
  }
  useEffect(() => {
    HEE.on(KAuth.eeUpdateAuthInfo, _updateAuthInfo)
    return () => {
      HEE.off(KAuth.eeUpdateAuthInfo, _updateAuthInfo)
    }
    // eslint-disable-next-line
  }, [])
  return (
    <Wrap className="nav-item dropdown menumember">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className="nav-link dropdown-toggle pt-0px pb-0px navavatar hidden-mobile"
        data-toggle="dropdown"
      >
        <span className="wp-icobar">
          <span className="icon-bar"></span>
          <span className="icon-bar mid"></span>
          <span className="icon-bar"></span>
        </span>
        <span className="numalert">0</span>
        <img src={avatar} className="arvartimg" alt="" />
      </a>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
        {isAuthenticated ? (
          <>
            <li className="active __web-inspector-hide-shortcut__">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                }}
              >
                {userName}
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGNewPaths.taikhoancuatoi}>
                Tài khoản
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGNewPaths.thongbao}>
                Thông báo <span className="text-danger "> *</span>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGNewPaths.chuyendicuatoi}>
                Chuyến đi của tôi
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGNewPaths.tichluy}>
                Tích lũy
              </a>
            </li>
            <li className="divider"></li>
            <li>
              <a className="dropdown-item" href={SGNewPaths.donhanghientai}>
                Quản lý xe cho thuê <span className="text-danger ">*</span>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGNewPaths.thanhtoan}>
                Thanh toán
              </a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="dropdown-item signout bgxam"
                href="#"
                onClick={e => {
                  e.preventDefault()
                  logout({})
                  window.location.reload()
                }}
              >
                Đăng xuất
              </a>
            </li>
          </>
        ) : (
          <>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                data-toggle="modal"
                data-target="#signin"
                onClick={e => {
                  e.preventDefault()
                  MyModal.showFromMyUI(
                    {
                      type: "sg_login",
                      afterLogin: () => {
                        MyModal.hideModalMyUI()
                        window.location.reload()
                      },
                      actionType: "register",
                      field: "MobilePhone",
                    },
                    {
                      size: "top",
                      showHeader: false,
                      showHeaderClose: false,
                      backdrop: "static",
                      modalClassName: "modal-login",
                    }
                  )
                }}
              >
                Đăng ký
              </a>
            </li>
            <li className="divider"></li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                data-toggle="modal"
                data-target="#signin"
                onClick={e => {
                  e.preventDefault()
                  MyModal.showFromMyUI(
                    {
                      type: "sg_login",
                      afterLogin: () => {
                        MyModal.hideModalMyUI()
                        window.location.reload()
                      },
                      actionType: "login",
                      field: "MobilePhone",
                    },
                    {
                      size: "top",
                      showHeader: false,
                      showHeaderClose: false,
                      backdrop: "static",
                      modalClassName: "modal-login",
                    }
                  )
                }}
              >
                Đăng nhập
              </a>
            </li>
          </>
        )}
      </ul>
    </Wrap>
  )
}
const Wrap = styled.li`
  & .arvartimg {
    max-width: 40px;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
`
export default connector(MyUIAvatar)
