import React, { useRef, useState } from "react"
import { HLink, HUtils } from "src/core/helpers"
import { MyForm, MyModal, MyUI } from "src/core/components"
import { isNotEmpty } from "src/core/helpers/utils"
import styled from "styled-components"
import { showAlert } from "src/modules/app_sigo/helpers/ui"
import { mobileWidth } from "src/modules/app_sigo/constants"
import { SGNewPaths } from "src/modules/app_sigo/constants/paths"

const Search = props => {
  const [myFilter, setMyFilter] = useState({
    from: null as any,
    datetimefrom: new Date().setHours(21, 0, 0, 0),
    datetimeto: new Date(new Date().setHours(24, 0, 0, 0)).setHours(
      20,
      0,
      0,
      0
    ),
  })
  const refTopForm = useRef<MyForm>(null)
  const configFormFilter = {
    defaultValues: myFilter,
    controls: [
      {
        label: "Địa điểm",
        fieldName: "from",
        type: "input_address",
        more: {
          classNameGroup: "col-md-4 col-sm-12",
          morePropsInputAddress: {
            mappingFields: {
              fieldAddress: "from",
            },
            props4Search: {
              configSelect: {
                placeholder: "Bạn đặt xe ở … ",
              },
            },
          },
        },
      },
      {
        label: "Bắt đầu",
        fieldName: "datetimefrom",
        type: "input_datetime",
        more: {
          classNameGroup: "col-md-4 col-sm-6 wpinput mid",
          configDate: {
            minDate: new Date(),
            placeholderDate: "Chọn ngày",
          },
          configTime: {
            placeholderTime: "Chọn giờ",
          },
        },
      },
      {
        label: "Kết thúc",
        fieldName: "datetimeto",
        type: "input_datetime",
        more: {
          classNameGroup: "col-md-4 col-sm-6 wpinput",
          configDate: {
            minDate: new Date(),
            placeholderDate: "Chọn ngày",
          },
          configTime: {
            placeholderTime: "Chọn giờ",
          },
        },
      },
    ],
  }
  const getDisabledSearch = () => {
    let isDisabled = false
    Object.keys(myFilter).forEach(f => {
      if (!isNotEmpty(myFilter[f])) {
        isDisabled = true
        return
      }
    })
    return isDisabled
  }
  const getLinkSearch = () => {
    let url = `${SGNewPaths.searchxe}?`
    Object.keys(myFilter).forEach((f, i) => {
      if (isNotEmpty(myFilter[f])) {
        if (f === "from") {
          url = url + "&" + f + "=" + myFilter[f].replaceAll(" ", "+")
        } else {
          url = url + "&" + f + "=" + myFilter[f]
        }
      }
    })
    return url
  }
  return (
    <Wrap className="search-popup row">
      <div className="col-sm-12 item">
        <div className="wpsearchsl">
          <MyForm
            className="wpsearch filter-top"
            ref={refTopForm}
            configForm={configFormFilter}
            fnList={
              {
                afterUpdateValueControl: opts => {
                  const { fieldName, newValue } = opts
                  let newData: any = JSON.parse(JSON.stringify(myFilter))
                  newData[fieldName] = newValue
                  if (
                    fieldName === "datetimefrom" &&
                    newData.datetimefrom >= newData.datetimeto
                  ) {
                    const _newDateTimeTo = newValue + 86400000
                    newData.datetimeto = _newDateTimeTo
                    if (
                      refTopForm?.current?.fnListFromExtControl?.["datetimeto"]
                    ) {
                      refTopForm.current.fnListFromExtControl[
                        "datetimeto"
                      ].updateValue(_newDateTimeTo)
                    }
                    setMyFilter(newData)
                  } else if (
                    fieldName === "datetimeto" &&
                    newData.datetimeto <= newData.datetimefrom
                  ) {
                    if (
                      refTopForm?.current?.fnListFromExtControl?.[fieldName]
                    ) {
                      refTopForm.current.fnListFromExtControl[
                        fieldName
                      ].updateValue(myFilter[fieldName])
                    }
                    showAlert({
                      component: (
                        <MyUI
                          type="alert"
                          alertType="warning"
                          title="Thông báo"
                          msg="Thời gian thuê xe không hợp lệ, bạn hãy chọn lại nhé."
                        />
                      ),
                    })
                  } else {
                    setMyFilter(newData)
                  }
                },
                afterUpdateMultiValues: obj => {
                  const { values, cbSuccess } = obj
                  let objUpdate: any = {}
                  objUpdate = { ...values }
                  if (values?.[`from_MoreInfo`]) {
                    const location = HUtils.get(
                      values,
                      `from_MoreInfo[0].geometry.location`
                    )
                    if (location?.lat) {
                      objUpdate.lat = location.lat()
                    }
                    if (location?.lng) {
                      objUpdate.long = location.lng()
                    }
                    delete objUpdate["from_MoreInfo"]
                    cbSuccess && cbSuccess()
                    setMyFilter({
                      ...myFilter,
                      ...objUpdate,
                    })
                  }
                },
              } as any
            }
            renderFooter={() => {
              return (
                <div className="form-group" style={{ flex: 1 }}>
                  <button
                    name="btnsearch"
                    className="btn btn-info btnsearch"
                    disabled={getDisabledSearch()}
                    onClick={() => {
                      HLink.openUrl(getLinkSearch(), "_self")
                      MyModal.hideModalComponent()
                    }}
                  >
                    <i className="flaticon-search"></i>
                  </button>
                </div>
              )
            }}
          />
        </div>
      </div>
    </Wrap>
  )
}
const MUISearchbar = props => {
  return (
    <Wrap className="container">
      <div
        className="search-bar"
        onClick={() => {
          MyModal.showFromComponent(<Search />, {
            title: "Bạn muốn thuê xe?",
            backdrop: "static",
            modalClassName: "default",
          })
        }}
      >
        <i className="flaticon-search"></i>
        <div className="intro-text">
          <div className="main">Bạn muốn thuê xe?</div>
          <div className="des">Nhấn vào đây để chọn địa điểm và thời gian</div>
        </div>
      </div>
    </Wrap>
  )
}
const Wrap = styled.div`
  &.search-popup {
    & .btnsearch {
      margin-bottom: 0px !important;
    }
    & .wpsearchsl {
      margin-bottom: 0px !important;
      padding: 0px;
    }
  }
  & .search-bar {
    cursor: pointer;
    margin-top: 0.5rem;
    box-shadow: 0 3px 10px rgba(0 0 0 / 0.1);
    border: 0.5px solid rgba(0 0 0 / 0.08);
    min-height: 56px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    & i {
      margin-top: 0.25rem;
      font-size: 25px;
      color: var(--color-text-363636);
    }
    & .intro-text {
      margin-left: 1rem;
      & .main {
        font-weight: 600;
        color: var(--color-text-222222);
        font-size: 16px;
        line-height: 1.5rem;
        letter-spacing: normal;
      }
      & .des {
        font-size: 12px;
        line-height: 1rem;
        color: var(--color-text-search-bar);
        letter-spacing: normal;
      }
    }
  }
  & .label-text {
    color: #555;
    font-size: 16px;
    cursor: default;
  }
  & .filter-top {
    display: flex;
    align-items: center;
    & > .form-group {
      height: 48px;
      margin: 0px !important;
      & label {
        line-height: 30px;
        margin: 0 !important;
        font-weight: 700;
        color: #555;
        line-height: 25px;
      }
      & > div:nth-child(2) {
        flex: 1;
      }
      & .react-datepicker-wrapper {
        width: 90px !important;
      }
    }
    & .mc-input-datetime {
      justify-content: space-between;
    }
    & .nice-select .current {
      line-height: 1;
    }
    & input {
      padding-left: 0 !important;
      padding-bottom: 0 !important;
      height: 22px;
    }
    & .mc_input_address {
      .clear > button {
        height: 23px;
      }
    }
  }
  & .btnsearch {
    &:disabled {
      color: #fff;
      background-color: var(--mau1);
      border-color: var(--mau1);
    }
  }
  @media (min-width: 768px) and (max-width: ${mobileWidth}px) {
    .wpinput.mid {
      border-left: none;
      border-right: none;
    }
  }
  @media (max-width: ${mobileWidth}px) {
    & .wpsearchsl {
      max-width: 100%;
      height: auto;
      padding: 10px;
      margin-bottom: 15px;
    }
    & .filter-top {
      flex-direction: column;
      & .form-group {
        margin-bottom: 20px !important;
        padding: 0 10px;
        width: 100%;
      }
      & .btnsearch {
        width: 100%;
        margin-top: 5px;
        border-radius: 20px;
      }
    }
    & .wpsearchsl {
      padding-bottom: 0;
    }
  }
`
export default MUISearchbar
