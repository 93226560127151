import { MyControlHelpers } from "@macashipo/mlib"
import InputText from "./InputText"
import InputPassword from "./InputPassword"
import InputTextarea from "./InputTextarea"
import InputSelect from "./InputSelect"
import InputCheckbox from "./InputCheckbox"
import InputSwitch from "./InputSwitch"
import InputRadio from "./InputRadio"
import InputRange from "./InputRange"
import InputColor from "./InputColor"
import InputDate from "./InputDate"
import InputTime from "./InputTime"
import SelectCheckbox from "./SelectCheckbox"
import SelectRadio from "./SelectRadio"
import SelectSwitch from "./SelectSwitch"
const ListNoLazy = {
  text: InputText,
  password: InputPassword,
  textarea: InputTextarea,
  select: InputSelect,
  checkbox: InputCheckbox,
  switch: InputSwitch,
  radio: InputRadio,
  range: InputRange,
  color: InputColor,
  date: InputDate,
  time: InputTime,
  select_checkbox: SelectCheckbox,
  select_switch: SelectSwitch,
  select_radio: SelectRadio,
}

MyControlHelpers.addTypesFromListWithoutLazy(ListNoLazy)

export default MyControlHelpers
