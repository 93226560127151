import React from "react"
import { Input } from "reactstrap"
import Ext_Input from "./Ext_Input"
class InputCheckbox extends Ext_Input {
  render() {
    const propsInput = this.getPropsInput()
    return (
      <Input
        {...propsInput}
        type="checkbox"
        onBlur={() => {}}
        onChange={e => {
          if (this.props?.onBlur) {
            this.props.onBlur(e)
          }
        }}
      />
    )
  }
}

//value: string
//defaultValue: string
//onChange: value = string

export default InputCheckbox
