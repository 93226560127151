const ServiceWorker = {
  isSupported() {
    if (!("serviceWorker" in navigator)) {
      // Service Worker isn't supported on this browser, disable or hide UI.
      return false
    }

    if (!("PushManager" in window)) {
      // Push isn't supported on this browser, disable or hide UI.
      return false
    }

    return true
  },
  registerSW(link) {
    return navigator.serviceWorker.register(link)
  },
  getSW(link) {
    return navigator.serviceWorker.getRegistration(link)
  },
  enableNotification(linkSW) {
    Notification.requestPermission().then(result => {
      if (result === "granted") {
        return ServiceWorker.registerSW(linkSW)
      }
    })
  },
  disableNotification(linkSW) {
    ServiceWorker.getSW(linkSW).then(sw => {
      if (sw) {
        sw.unregister()
      }
    })
  },
  showNotification(linkSW, body, tag, data) {
    ServiceWorker.getSW(linkSW).then(sw => {
      if (sw) {
        const title = "Notification with Data"
        const options = {
          body:
            body ||
            "This notification has data attached to it that is printed " +
              "to the console when it's clicked.",
          tag: tag || "data-notification",
          data: data || {
            createdAt: new Date(Date.now()).toString(),
            message: "Hello, World!",
          },
        }
        sw.showNotification(title, options)
      } else {
        console.warn("Missing service worker", sw)
      }
    })
  },
}

export default ServiceWorker
