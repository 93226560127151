import React, { useEffect, useRef, useState } from "react"
import { HAuth } from "src/core/helpers"
import { MyForm, MyModal, MyUI } from "src/core/components"
import { SGPaths } from "src/modules/app_sigo/constants/paths"
import dfAvatar from "src/modules/app_sigo/images/user.png"
import dfLogo from "src/modules/app_sigo/images/logo-sigo.png"
import styled from "styled-components"
import { logout } from "src/App/App.actions"
import { ConnectedProps, connect } from "react-redux"
import classNames from "classnames"
import { HCF, HEE, HFormat, HLocalStorage, HUtils } from "@macashipo/mlib"
import { KAuth } from "src/helpers/Flows/Auth"
import { categoryFields } from "src/modules/app_sigo/constants/fields"
import { Button } from "reactstrap"
import { IoSearchOutline } from "react-icons/io5"
import { useForceUpdate } from "src/modules/app_sigo/hooks/useForceUpdate"
import { showAlert } from "src/modules/app_sigo/helpers/ui"
import { getLoadingPage } from "../../MyPage/LoadingHelper"
import { headerTabList, pageKey } from "src/modules/app_sigo/constants"

const configKeyHeader = {
  menu_left: "menu_left",
  item_link: "item_link",
  item_title: "item_title",
  sub_items: "sub_items",
  sub_items_url: "url",
  sub_items_name: "name",
  // menu right
  menu_right: "menu_right",
  classname_icon: "classname_icon",
  classname_link: "classname_link",
  item_classname_wrap: "item_classname_wrap",
  right_item_link: "item_link",
  right_item_title: "item_title",
  right_sub_menu: "sub_menu",
  right_sub_url: "url",
  right_sub_name: "name",
  // menu mobile
  abouts: "abouts",
  rental_types: "rental_types",
  url: "url",
  name: "name",
}
const mapStateToProps = state => ({
  isAuthenticated: state.app.isAuthenticated,
  dataFirstSetting: state.app.dataFirstSetting,
})

const mapDispatchToProps = {
  logout,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
interface Props extends ConnectedProps<typeof connector> {
  fnOpenFilter?: any
  data?: any
  fnList?: any
  arrayTypeFields?: any
  moreConfig?: any
}
const tabList = [
  {
    key: "xe-tu-lai",
    title: "Xe tự lái",
    link: "/",
  },
  {
    key: "xe-co-tai",
    title: "Xe có tài",
    link: "/xe-co-tai",
  },
  {
    key: "thue-dai-han",
    title: "Thuê dài hạn",
    link: "/thue-dai-han",
  },
]

const MyUIHeader = (props: Props) => {
  const {
    isAuthenticated,
    logout,
    dataFirstSetting,
    fnOpenFilter,
    data,
    fnList,
    arrayTypeFields,
    moreConfig,
  } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [curTab, setCurTab] = useState("xe-tu-lai")
  const toggle = () => setDropdownOpen(prevState => !prevState)
  const logo = HAuth.getUserLogoApp() || dfLogo //"/config/logo.png"
  const [avatar, setAvatar] = useState(HAuth.getUserAvatar() || dfAvatar)
  const [userName, setUsername] = useState(HAuth.getUserName())
  const [isMobileView, setIsMobileView] = useState(false)
  const [isMobileViewV2, setIsMobileViewV2] = useState(false)
  const [curData, setCurData] = useState({} as any)
  const refSearchForm = useRef<MyForm>(null)
  const [myFilter, setMyFilter] = useState(data)
  const [keySearchForm, forceUpdateFnc] = useForceUpdate()
  const [isFocusSearch, setIsFocusSearch] = useState(false)
  const [needClearOtherFilter, setNeedClearOtherFilter] = useState(false)
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)
  const [configFirstSetting, setConfigFirstSetting] = useState(
    HUtils.get(dataFirstSetting, "DefaultValues.websiteSetting") || {}
  )
  const [activeSelectTime, setActiveSelectTime] = useState(false)
  let mobileWidth = 1010
  if (moreConfig?.mobileWidth) {
    mobileWidth = moreConfig?.mobileWidth
  }
  let mobileWidthV2 = 1010
  if (moreConfig?.mobileWidthV2) {
    mobileWidthV2 = moreConfig?.mobileWidthV2
  }

  useEffect(() => {
    setConfigFirstSetting(
      HUtils.get(dataFirstSetting, "DefaultValues.websiteSetting")
    )
    // eslint-disable-next-line
  }, [dataFirstSetting])
  const _updateAuthInfo = () => {
    setUsername(HAuth.getUserName())
    setAvatar(HAuth.getUserAvatar())
  }
  useEffect(() => {
    HEE.on(KAuth.eeUpdateAuthInfo, _updateAuthInfo)
    return () => {
      HEE.off(KAuth.eeUpdateAuthInfo, _updateAuthInfo)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    let newData = {
      [categoryFields.fToDate]: data?.[categoryFields.fToDate] || null,
      [categoryFields.fFromDate]: data?.[categoryFields.fFromDate] || null,
      [categoryFields.fAddress]: data?.[categoryFields.fAddress] || null,
      [categoryFields.fLatitude]: data?.[categoryFields.fLatitude],
      [categoryFields.fLongitude]: data?.[categoryFields.fLongitude],
    }
    setCurData(newData)
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (
      Object.keys(curData)?.length > 0 &&
      (data?.[categoryFields.fFromDate] !==
        curData?.[categoryFields.fFromDate] ||
        data?.[categoryFields.fToDate] !== curData?.[categoryFields.fToDate])
    ) {
      setCurData({
        ...curData,
        [categoryFields.fToDate]: data?.[categoryFields.fToDate],
        [categoryFields.fFromDate]: data?.[categoryFields.fFromDate],
      })
      forceUpdateFnc()
    }
  }, [data])
  useEffect(() => {
    const ro = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        const { left, top, width, height } = entry.contentRect
        if (entry.target == document.body) {
          if (width <= mobileWidth) {
            if (isMobileView === false) {
              console.log(
                `RO Mobile Element's size: ${width}px x ${height}px`,
                isMobileView
              )
            }
            setIsMobileView(true)
          } else {
            setIsMobileView(false)
          }
          if (width <= mobileWidthV2) {
            setIsMobileViewV2(true)
          } else {
            setIsMobileViewV2(false)
          }
        }
      }
    })
    ro.observe(document.body)
    // eslint-disable-next-line
  }, [])

  const closeDropdownMobile = e => {
    if (
      !document
        .getElementsByClassName("mobile-menu-dropdown")[0]
        ?.contains(e.target) &&
      !document
        .getElementsByClassName("dropdown-mobile-btn")[0]
        ?.contains(e.target)
    ) {
      setDropdownOpen(false)
    }
  }
  useEffect(() => {
    window.addEventListener("click", closeDropdownMobile)
    return () => {
      window.removeEventListener("click", closeDropdownMobile)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const updateViewportHeight = () => {
      setViewportHeight(window.innerHeight)
    }

    // Cập nhật chiều cao viewport khi cửa sổ thay đổi kích thước
    window.addEventListener("resize", updateViewportHeight)

    // Cập nhật chiều cao viewport khi tải trang
    updateViewportHeight()

    return () => {
      window.removeEventListener("resize", updateViewportHeight)
    }
    // eslint-disable-next-line
  }, [])

  const getDisabledBtnSearch = () => {
    if (
      !curData ||
      !curData?.[categoryFields.fToDate] ||
      !curData?.[categoryFields.fFromDate] ||
      !curData?.[categoryFields.fAddress]
      // || curData[categoryFields.fFromDate] >= curData?.[categoryFields.fToDate]
    ) {
      return true
    }
    return false
  }
  /**Get */
  const getFilterList = () => {
    const sourceList = fnList?.getSourceList && fnList.getSourceList()
    const filterList = [
      {
        title: "Sắp xếp",
        actionType: "dropdown",
        configForm: {
          defaultValues: myFilter,
          controls: [
            {
              fieldName: categoryFields.fOrderBy,
              type: "list_radio",
              more: {
                optionKey: "OrderByList",
              },
            },
          ],
        },
      },
      {
        title: "Số chỗ",
        actionType: "dropdown",
        configForm: {
          defaultValues: myFilter,
          controls: [
            {
              fieldName: categoryFields.fVehicleNoOfSeatIds,
              type: "list_checkbox",
              more: {
                optionKey: "NoOfSeatList",
                multi: true,
              },
            },
          ],
        },
      },
      {
        title: "Mức giá",
        actionType: "modal",
        configForm: {
          defaultValues: myFilter,
          controls: [
            {
              fieldName: categoryFields.fMaxRentalPrice,
              type: "input_range",
              more: {
                optionKey: "RentalPrice",
                morePropsRange: {
                  fields: {
                    min: categoryFields.fMinRentalPrice,
                    max: categoryFields.fMaxRentalPrice,
                  },
                  getLabelTop: (min, val) => {
                    return (
                      <>
                        Giá từ{" "}
                        <b>
                          {HFormat.format(min, {
                            type: "money_vnd",
                          })}
                        </b>
                        vnđ/ngày -{" "}
                        <b>
                          {HFormat.format(val, {
                            type: "money_vnd",
                          })}
                        </b>
                        vnđ/ngày
                      </>
                    )
                  },
                  getMin: () =>
                    HUtils.get(sourceList, `RentalPrice.MinValue.Value`),
                  getMax: () =>
                    HUtils.get(sourceList, `RentalPrice.MaxValue.Value`),
                  getStep: () => 100000,
                },
              },
            },
          ],
        },
        configModal: {
          showHeader: false,
          showHeaderClose: true,
          showFooter: false,
        },
      },
      {
        title: "Hộp số",
        actionType: "dropdown",
        configForm: {
          defaultValues: myFilter,
          controls: [
            {
              fieldName: categoryFields.fVehicleTransmissionTypeIds,
              type: "list_checkbox",
              more: {
                optionKey: "VehicleTransmissionTypeList",
                multi: true,
              },
            },
          ],
        },
      },
      {
        title: "Bộ lọc khác",
        icon: (
          <i className="flaticon-filter" style={{ marginRight: "0.2rem" }} />
        ),
        actionType: "modal",
        classNameWrapForm:
          "mCustomScrollBox mCS-light mCSB_vertical mCSB_inside max-height-330px overflow-auto",
        classNameForm: "mCSB_container position-relative left-0",
        configForm: {
          defaultValues: myFilter,
          controls: [
            {
              label: "Hãng Xe",
              fieldName: categoryFields.fVehicleMakeIds,
              type: "input_select",
              more: {
                optionKey: "VehicleMakeList",
                multi: false,
              },
            },
            {
              label: "Nhiên Liệu",
              fieldName: categoryFields.fFuelTypeIds,
              type: "input_select",
              more: {
                optionKey: "FuelTypeList",
                multi: false,
              },
            },
            {
              label: "Màu Sắc",
              fieldName: categoryFields.fVehicleColorIds,
              type: "input_select",
              more: {
                optionKey: "VehicleColorList",
                multi: false,
              },
            },
            {
              label: "Năm Sản Xuất",
              fieldName: categoryFields.fYearModels,
              type: "input_select",
              more: {
                optionKey: "YearModelList",
                multi: false,
              },
            },
            {
              label: "Giấy Tờ Thế Chấp",
              fieldName: categoryFields.fDocumentIds,
              type: "list_checkbox",
              more: {
                optionKey: "DocumentList",
                classNameWrap: "row",
                classNameItem: "col-md-6",
                multi: true,
              },
            },
            {
              label: "Tiện Ích Nâng Cao",
              fieldName: categoryFields.fFeatureIds,
              type: "list_checkbox",
              more: {
                optionKey: "FeatureList",
                classNameWrap: "row",
                classNameItem: "col-md-6",
                multi: true,
              },
            },
          ],
        },
        configModal: {
          title: "Bộ lọc khác",
          showFooter: true,
          showFooterClose: true,
          showFooterOK: true,
          titleBtnClose: "Xóa tất cả",
          titleBtnOK: "Áp dụng",
          modalClassName: "default footer-space-between modal-filter",
          classNameBody: "mCustomScrollbar _mCS_1 max-height-400px",
        },
      },
    ]
    return filterList
  }
  const getConfigForm = () => {
    const address = getConfigFromFirstSettingByKey("address") || {}
    const dateFromToConfig =
      getConfigFromFirstSettingByKey("date_from_to") || {}
    const configForm = {
      defaultValues: {
        ...curData,
        DateFromTo: [
          data?.[categoryFields.fFromDate],
          data?.[categoryFields.fToDate],
        ],
      },
      controls: [
        {
          label: "",
          fieldName: categoryFields.fAddress,
          type: "button_select_address",
          more: {
            classNameGroup: "col-md-4 col-sm-12",
            morePropsInputAddress: {
              mappingFields: {
                fieldAddress: categoryFields.fAddress,
              },
            },
            configBtnAddress: {
              ...address,
              fieldAddress: categoryFields.fAddress,
            },
          },
        },
        {
          label: "",
          fieldName: "DateFromTo",
          type: "input_date_from_to_v2",
          more: {
            morePropsDateFlatPickr: {
              calendarStartDay: 0,
            },
            moreOptionsDateFlatPickr: {
              disable: [
                function (date) {
                  return (
                    date < new Date().setHours(0, 0, 0, 0) &&
                    date.getTime() !== new Date().setHours(0, 0, 0, 0)
                  )
                },
              ],
            },
            configMergeText: {
              titleWhenMergeText: "Chọn ngày",
            },
            mappingField: {
              fFrom: categoryFields.fFromDate,
              fTo: categoryFields.fToDate,
            },
            configSelectTime: {
              titleTimeFrom: "Giờ nhận xe",
              titleTimeTo: "Giờ trả xe",
            },
            configSelectDate: {
              placeholderTo: "Chọn ngày trả",
              placeholderFrom: "Chọn ngày nhận",
              breakCharacter: "-ngày ",
              labelFrom: "Ngày nhận",
              labelTo: "Ngày trả",
              ...dateFromToConfig,
            },
            defaultActiveDropdown: activeSelectTime,
            classNameGroup: "datefromto",
          },
        },
      ],
    }
    return configForm
  }
  const getConfigFromFirstSettingByKey = key => {
    if (configFirstSetting) {
      const { config_search_in_header } = configFirstSetting || {}
      return HUtils.get(config_search_in_header, key)
    }
    return
  }
  const getConfigLoadingSkeleton = () => {
    const config = {
      customLoading: () => {
        return getLoadingPage({
          key: pageKey.onlyHeader,
          tabList: headerTabList,
          logo: logo,
        })
      },
      loading: moreConfig?.loading,
      moreConfig: {},
    }
    return config
  }
  /** */

  const onOpenFilter = () => {
    return MyModal.showFromMyUI(
      {
        type: "sg_filter_category",
        data: data,
        configFormFilter: configFormFilter,
        configFilterList: getFilterList(),
        fnList: {
          ...fnList,
          onCloseModal: () => {
            MyModal.hideModalMyUI()
          },
        },
        arrayTypeFields: arrayTypeFields,
      },
      {
        title: "Bộ lọc",
        size: "lg",
        modalClassName: "default modal-header-left",
      }
    )
  }
  const onUpdateFilter = (data, { moreConfig }: any = {}) => {
    const { DateFromTo, ...other } = data
    fnList?.onSetFilterObject && fnList?.onSetFilterObject({ ...other })
  }
  const onHandleCollapseNav = value => {
    const navbar = document.getElementById("navbar-category-v2")
    const sticky: any = navbar?.offsetTop
    if (value === false && navbar?.classList.contains("truot") && navbar) {
      navbar.classList.remove("truot")
    } else if (
      navbar &&
      value === true &&
      ((window.pageYOffset >= sticky &&
        window.pageYOffset > 0 &&
        !navbar?.classList.contains("truot")) ||
        moreConfig?.skipShowFullHeader)
    ) {
      navbar.classList.add("truot")
    }
    // if (navbar) {
    //   if (!navbar?.classList.contains("truot") && value) {
    //     navbar.classList.add("truot")
    //   } else if (!value && navbar?.classList.contains("truot")) {
    //     navbar.classList.remove("truot")
    //   }
    // }
  }

  const renderMobile = () => {
    const { header } = configFirstSetting || {}
    const abouts = header?.[configKeyHeader.abouts] || []
    const rentalTypes = header?.[configKeyHeader.rental_types] || []
    return (
      <div
        className={classNames(
          "collapse navbar-collapse hidden-desktop collapseRight mobile-menu-dropdown",
          dropdownOpen && "open"
        )}
        data-show="menumember"
        id="collapseRightMobile"
      >
        {isAuthenticated ? (
          <ul className="nav navbar-nav navbar-right">
            <li className="active">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                }}
              >
                {userName}
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGPaths.taikhoancuatoi}>
                Tài khoản
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGPaths.thongbao}>
                Thông báo <span className="text-danger">*</span>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGPaths.chuyendicuatoi}>
                Chuyến đi của tôi
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGPaths.tichluy}>
                Tích lũy
              </a>
            </li>
            <li className="divider"></li>
            <li>
              <a className="dropdown-item" href={SGPaths.donhanghientai}>
                Quản lý xe cho thuê <span className="text-danger">*</span>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGPaths.thanhtoan}>
                Thanh toán
              </a>
            </li>
            {/* <li>
              <a
                className="dropdown-item"
                href="/tai-khoan/hieu-suat"
              >
                Hiệu suất
              </a>
            </li> */}
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="dropdown-item signout bgxam"
                href="#"
                onClick={e => {
                  e.preventDefault()
                  logout({})
                  window.location.reload()
                }}
              >
                Đăng xuất
              </a>
            </li>
          </ul>
        ) : (
          <ul className="nav navbar-nav navbar-right">
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                data-toggle="modal"
                data-target="#signin"
                onClick={e => {
                  e.preventDefault()
                  MyModal.showFromMyUI(
                    {
                      type: "sg_login",
                      afterLogin: () => {
                        MyModal.hideModalMyUI()
                        window.location.reload()
                      },
                      actionType: "register",
                      // field: "PhoneNumber",
                      field: "MobilePhone",
                    },
                    {
                      size: "top",
                      showHeader: false,
                      showHeaderClose: false,
                      backdrop: "static",
                      modalClassName: "modal-login",
                    }
                  )
                }}
              >
                Đăng ký
              </a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                data-toggle="modal"
                data-target="#signin"
                onClick={e => {
                  e.preventDefault()
                  MyModal.showFromMyUI(
                    {
                      type: "sg_login",
                      afterLogin: () => {
                        MyModal.hideModalMyUI()
                        window.location.reload()
                      },
                      actionType: "login",
                      field: "MobilePhone",
                    },
                    {
                      size: "top",
                      showHeader: false,
                      showHeaderClose: false,
                      backdrop: "static",
                      modalClassName: "modal-login",
                    }
                  )
                }}
              >
                Đăng nhập
              </a>
            </li>
            <li className="divider"></li>
            {abouts &&
              abouts?.length > 0 &&
              abouts.map((v, i) => {
                const url = v?.[configKeyHeader.url] || "#"
                const name = v?.[configKeyHeader.name] || ""
                return (
                  <li key={`about-${i}`}>
                    <a className="dropdown-item" href={url}>
                      {name}
                    </a>
                  </li>
                )
              })}
            {rentalTypes &&
              rentalTypes?.length > 0 &&
              rentalTypes.map((v, i) => {
                const url = v?.[configKeyHeader.url] || "#"
                const name = v?.[configKeyHeader.name] || ""
                return (
                  <li key={`rent-${i}`}>
                    <a className="dropdown-item hidden-desktop" href={url}>
                      {name}
                    </a>
                  </li>
                )
              })}
          </ul>
        )}
      </div>
    )
  }
  const renderMenuLeft = () => {
    const menuLeft = data?.[configKeyHeader.menu_left] || []
    if (menuLeft && menuLeft?.length > 0) {
      return (
        <div
          className="collapse navbar-collapse pull-sm-left hidden-mobile"
          id="collapseLeft"
        >
          <ul className="nav navbar-nav">
            {menuLeft.map((v, i) => {
              const title = v?.[configKeyHeader.item_title] || ""
              const link = v?.[configKeyHeader.item_link] || "#"
              const subMenu = v?.[configKeyHeader.sub_items] || ""
              const isDropdown = subMenu && subMenu !== ""
              return (
                <li
                  className={isDropdown ? "nav-item dropdown menumember" : ""}
                  key={`leftmenu-${i + 1}`}
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  {isDropdown ? (
                    <a
                      href={link}
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      {title}
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {subMenu.map((item, j) => {
                          const url =
                            item?.[configKeyHeader.sub_items_url] || "#"
                          const name = item?.[configKeyHeader.sub_items_name]
                          return (
                            <li key={`about-${j}`}>
                              <a className="dropdown-item" href={url}>
                                {name}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </a>
                  ) : (
                    <a href={link}>{title}</a>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
    return <></>
  }
  const renderMenuRight = () => {
    const menuRight = data?.[configKeyHeader.menu_right] || []
    if (menuRight && menuRight?.length > 0) {
      return (
        <div
          className="collapse navbar-collapse collapseRight "
          data-show="menumember"
          id="collapseRightDesktop"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* {menuRight.map((v, i) => {
              const classNameWrap = v?.[configKeyHeader.item_classname_wrap]
              const classNameLink = v?.[configKeyHeader.classname_link]
              const icon = v?.[configKeyHeader.classname_icon] || ""
              const link = v?.[configKeyHeader.right_item_link] || "#"
              const title = v?.[configKeyHeader.right_item_title] || ""
              const subMenu = v?.[configKeyHeader.right_sub_menu]
              const isHasDropdown = subMenu && subMenu?.length > 0
              return (
                <li
                  className={classNameWrap ? classNameWrap : "hidden-mobile"}
                  key={`right-menu-${i}`}
                >
                  <a
                    className={classNameLink ? classNameLink : ""}
                    href={link}
                    role="button"
                  >
                    {icon && <i className={icon}></i>} {title}
                  </a>
                  {isHasDropdown && (
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {subMenu.map((sub, j) => {
                        const url = sub?.[configKeyHeader.right_sub_url] || "#"
                        const name = sub?.[configKeyHeader.right_sub_name] || ""
                        return (
                          <li key={`rent-${j}`}>
                            <a className="dropdown-item" href={url}>
                              {name}
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>
              )
            })} */}
            <MyUI type="sg_avatar" />
          </ul>
        </div>
      )
    }
    return (
      <div
        className="collapse navbar-collapse collapseRight "
        data-show="menumember"
        id="collapseRightDesktop"
      >
        <ul className="nav navbar-nav navbar-right">
          <MyUI type="sg_avatar" />
        </ul>
      </div>
    )
  }
  const configFormFilter = {
    defaultValues: data,
    controls: [
      {
        label: "Địa điểm",
        fieldName: categoryFields.fAddress,
        type: "input_address",
        more: {
          classNameGroup: "col-md-12 col-sm-12",
          morePropsInputAddress: {
            mappingFields: {
              fieldAddress: categoryFields.fAddress,
            },
            props4Search: {
              configSelect: {
                placeholder: "Bạn đặt xe ở … ",
              },
            },
          },
        },
      },
      {
        label: "Bắt đầu",
        fieldName: categoryFields.fFromDate,
        type: "input_datetime",
        more: {
          classNameGroup: "col-md-4 col-sm-12",
          configDate: {
            minDate: new Date(),
            placeholderDate: "Chọn ngày",
          },
          configTime: {
            placeholderTime: "Chọn giờ",
          },
        },
      },
      {
        label: "Kết thúc",
        fieldName: categoryFields.fToDate,
        type: "input_datetime",
        more: {
          classNameGroup: "col-md-4 col-sm-12",
          configDate: {
            minDate: new Date(),
            placeholderDate: "Chọn ngày",
          },
          configTime: {
            placeholderTime: "Chọn giờ",
          },
        },
      },
    ],
  }

  if (isMobileView) {
    if (moreConfig?.hideDefaultMobile) {
      return <></>
    }
    return (
      <WrapMobileView id="navbar-v2-mobile" className="navbar navbar-v2-mobile">
        <div className="container">
          <div className="navbar-header">
            <MyUI type="sg_header_filter" data={data} fnList={fnList} />
            {!isMobileViewV2 && (
              <>
                {moreConfig?.isShowInMobile && (
                  <a
                    className="navavatar hidden-desktop dropdown-mobile-btn"
                    data-toggle="dropdown"
                    href="/"
                    onClick={e => {
                      e.preventDefault()
                      toggle()
                    }}
                  >
                    <span className="wp-icobar">
                      <span className="icon-bar"></span>
                      <span className="icon-bar mid"></span>
                      <span className="icon-bar"></span>
                    </span>
                    <span className="numalert">0</span>
                    <img src={avatar} className="arvartimg2" alt="" />
                  </a>
                )}
                {
                  <button
                    className="nav-link btn-filter btn btn-default mr-10px item btn-radius"
                    onClick={onOpenFilter}
                  >
                    <i
                      className="flaticon-filter"
                      style={{ marginRight: "0.2rem" }}
                    ></i>
                    Bộ lọc
                  </button>
                }
                <a className="navbar-brand" href="/">
                  <img className="logo" src={logo} alt="logo" />
                </a>
              </>
            )}
          </div>
          {!isMobileViewV2 && renderMenuLeft()}
          {!isMobileViewV2 && renderMenuRight()}
          {moreConfig?.isShowInMobile && renderMobile()}
          <div className="line hidden-mobile"></div>
        </div>
      </WrapMobileView>
    )
  }

  const renderSearchForm = () => {
    const cfAddress = getConfigFromFirstSettingByKey("address")
    return (
      <MyForm
        className={classNames("form-search")}
        ref={refSearchForm}
        key={keySearchForm}
        configForm={getConfigForm()}
        fnList={
          {
            ...fnList,
            onSetShowOverlay: value => {
              fnList?.onSetShowOverlay && fnList?.onSetShowOverlay(value)
              setIsFocusSearch(value)
            },
            onHandleCollapseNav: onHandleCollapseNav,
            afterUpdateValueControl: opts => {
              const { fieldName, newValue } = opts
              let newData: any = JSON.parse(JSON.stringify(curData))
              if (fieldName === "DateFromTo") {
                const [start, end] = newValue
                newData[categoryFields.fFromDate] = start
                newData[categoryFields.fToDate] = end
                newData[fieldName] = newValue
              }
              setCurData(newData)
              if (
                newData?.[categoryFields.fFromDate] &&
                newData?.[categoryFields.fToDate]
              ) {
                onUpdateFilter(newData)
              }
            },
            afterCloseSelectTime: value => {
              let [start, end] = value
              let newData: any = JSON.parse(JSON.stringify(curData))
              let minDate = new Date().setHours(0, 0, 0, 0)
              let needFormatValue = false
              if (!start && !end) {
                const { ToDate, FromDate } = data
                newData[categoryFields.fFromDate] = FromDate
                newData[categoryFields.fToDate] = ToDate
                newData["DateFromTo"] = [FromDate, ToDate]
                start = FromDate
                end = ToDate
                needFormatValue = true
              }
              // Chỉ format khi chưa chọn thời gian trả và khi thời gian thuê > = thời gian trả
              if (!end || start >= end || !start) {
                needFormatValue = true
              }
              if (needFormatValue) {
                let _newToDate = start + 86400000
                let _newFromDate = end - 86400000
                let endHours = new Date(start).getHours()
                let endMinute = new Date(start).getMinutes()
                let startHours = new Date(end).getHours()
                let startMinute = new Date(end).getMinutes()

                if (start >= end && end && start) {
                  endHours = new Date(end).getHours()
                  endMinute = new Date(end).getMinutes()
                  _newToDate = new Date(
                    new Date(_newToDate).setHours(endHours, endMinute, 0, 0)
                  ).getTime()
                  newData[categoryFields.fToDate] = _newToDate
                  newData[categoryFields.fFromDate] = start
                  newData["DateFromTo"] = [start, _newToDate]
                }

                if (!end && start) {
                  _newToDate = new Date(
                    new Date(_newToDate).setHours(endHours, endMinute, 0, 0)
                  ).getTime()
                  newData[categoryFields.fToDate] = _newToDate
                  newData[categoryFields.fFromDate] = start
                  newData["DateFromTo"] = [start, _newToDate]
                }

                if (!start && end) {
                  _newFromDate = new Date(
                    new Date(_newFromDate).setHours(
                      startHours,
                      startMinute,
                      0,
                      0
                    )
                  ).getTime()
                  if (_newFromDate < minDate) {
                    _newFromDate = minDate
                  }
                  newData[categoryFields.fFromDate] = _newFromDate
                  newData[categoryFields.fToDate] = end
                  newData["DateFromTo"] = [_newFromDate, end]
                }
                setCurData(newData)
                if (
                  newData?.[categoryFields.fFromDate] &&
                  newData?.[categoryFields.fToDate]
                ) {
                  onUpdateFilter(newData)
                }
                forceUpdateFnc()
              }
            },
            afterUpdateMultiValues: (obj, { afterUpdate }: any = {}) => {
              const { values } = obj
              let objUpdate: any = {}
              objUpdate = { ...values }
              if (values?.[`${categoryFields.fAddress}_MoreInfo`]) {
                const location = HUtils.get(
                  values,
                  `${categoryFields.fAddress}_MoreInfo[0].geometry.location`
                )
                if (location?.lat) {
                  objUpdate[categoryFields.fLatitude] = location.lat()
                }
                if (location?.lng) {
                  objUpdate[categoryFields.fLongitude] = location.lng()
                }
                const _newData = {
                  ...curData,
                  ...objUpdate,
                }
                setCurData(_newData)
                onUpdateFilter(objUpdate)
                setNeedClearOtherFilter(true)
                setActiveSelectTime(true)
                forceUpdateFnc()
                // if (cfAddress?.autoCloseAfterUpdate) {
                //   const delay = setTimeout(() => {
                //     afterUpdate && afterUpdate()
                //   }, 0)
                //   timer.current = delay as any
                // }
              } else {
                // Trường hợp clear địa chỉ
                const _newData = {
                  ...curData,
                  ...objUpdate,
                }
                setCurData(_newData)
              }
            },
            updateAddress: (obj, { afterUpdate }: any = {}) => {
              const { Name, ...other } = obj
              let newData = {
                ...curData,
                ...other,
                [`${categoryFields.fAddress}_MoreInfo`]: [],
              }
              setCurData(newData)
              onUpdateFilter(newData)
              setNeedClearOtherFilter(true)
              // Tự động close dropdown sau khi chọn xong address ở vùng Gợi ý
              const { autoCloseAfterUpdate } = cfAddress || {}
              if (autoCloseAfterUpdate?.needAutoClose) {
                // const _delayTime = autoCloseAfterUpdate?.delayTime || 0
                // const delay = setTimeout(() => {
                //   afterUpdate && afterUpdate()
                // }, _delayTime)
                // timer.current = delay as any
                // Xử lý trường hợp chọn nhanh địa chỉ --> Mở dropdown select thời gian
                afterUpdate && afterUpdate()
                setActiveSelectTime(true)
                forceUpdateFnc()
              }
            },
            updateAddressWithTime: (
              obj,
              { afterUpdate, fnIsExpired }: any = {}
            ) => {
              const { FromDate, ToDate, ...other } = obj
              let newData: any = {
                ...curData,
                ...other,
              }
              let isFormat = false
              if (FromDate && ToDate) {
                if (
                  FromDate <= new Date().getTime() ||
                  ToDate <= new Date().getTime()
                ) {
                  fnIsExpired && fnIsExpired()
                  // showAlert({
                  //   component: (
                  //     <MyUI
                  //       type="alert"
                  //       alertType="warning"
                  //       title="Thông báo"
                  //       msg="Thời gian thuê xe không hợp lệ, bạn hãy chọn lại nhé."
                  //     />
                  //   ),
                  // })
                } else if (FromDate >= ToDate) {
                  let _newToDate = FromDate + 86400000
                  let endHours = new Date(ToDate).getHours()
                  let endMinute = new Date(ToDate).getMinutes()
                  newData[categoryFields.fToDate] = new Date(
                    new Date(_newToDate).setHours(endHours, endMinute, 0, 0)
                  ).getTime()
                  newData[categoryFields.fFromDate] = FromDate
                  isFormat = true
                } else {
                  newData[categoryFields.fToDate] = ToDate
                  newData[categoryFields.fFromDate] = FromDate
                }
              }
              if (
                (FromDate === newData[categoryFields.fFromDate] &&
                  ToDate === newData[categoryFields.fToDate]) ||
                isFormat
              ) {
                fnList.onFilterAddress(
                  { ...newData },
                  { needClearOtherFilter: true }
                )
                setCurData({ ...curData, ...other })
                afterUpdate && afterUpdate()
                setNeedClearOtherFilter(false)
              } else {
                onUpdateFilter(newData)
                setCurData(newData)
                setNeedClearOtherFilter(true)
                // Xử lý trường hợp recently address chưa có thời gian hoặc thời gian đã cũ
                afterUpdate && afterUpdate()
                setActiveSelectTime(true)
                forceUpdateFnc()
                if (!FromDate && !ToDate) {
                }
              }
            },
            setActiveSelectTime: setActiveSelectTime,
          } as any
        }
        renderFooter={() => {
          return (
            <div className="wrap-btn-search">
              <Button
                color="search"
                onClick={() => {
                  const { DateFromTo, MoreInfo, ...other } = curData
                  let newAddress = {
                    [categoryFields.fAddress]:
                      curData?.[categoryFields.fAddress],
                    [categoryFields.fLongitude]:
                      curData?.[categoryFields.fLongitude],
                    [categoryFields.fLatitude]:
                      curData?.[categoryFields.fLatitude],
                    [`${categoryFields.fAddress}_MoreInfo`]: MoreInfo,
                  }
                  if (needClearOtherFilter && fnList?.onFilterAddress) {
                    fnList.onFilterAddress(
                      { ...other },
                      {
                        needSaveLocal: true,
                        newValueLocal: newAddress,
                        needClearOtherFilter: needClearOtherFilter,
                      }
                    )
                  } else {
                    if (fnList?.onFilter) {
                      fnList.onFilter(
                        { ...other },
                        {
                          needSaveLocal: true,
                          newValueLocal: newAddress,
                        }
                      )
                    }
                  }
                  setNeedClearOtherFilter(false)
                }}
                className={`${isFocusSearch ? "has-text" : ""}`}
                disabled={getDisabledBtnSearch()}
              >
                <IoSearchOutline /> {isFocusSearch ? <>Tìm kiếm</> : <></>}
              </Button>
            </div>
          )
        }}
      />
    )
  }
  const renderRightHeader = () => {
    const menuItem = getConfigFromFirstSettingByKey("menu_item.right") || []
    return (
      <RightMenu
        className="collapse navbar-collapse collapseRight right-menu"
        data-show="menumember"
        id="collapseRightDesktop"
      >
        {menuItem &&
          menuItem?.length > 0 &&
          menuItem.map((v, i) => {
            return (
              <a href={v?.link} className="custom-link right-item" key={i}>
                {v?.name}
              </a>
            )
          })}
        <ul className="nav navbar-nav navbar-right navbar_right-v2 avatar-menu right-item">
          <MyUI type="sg_avatar" />
        </ul>
      </RightMenu>
    )
  }
  const renderTopHeader = () => {
    return (
      <WrapTopHeader
        className={classNames(
          "container wrap-header only-desktop",
          moreConfig?.onlyShowLogo && "only-logo"
        )}
        viewportHeight={viewportHeight}
      >
        <div className="navbar-header">
          <a className="navbar-brand" href="/">
            <img className="logo" src={logo} alt="logo" />
          </a>
        </div>
        {!moreConfig?.onlyShowLogo && (
          <>
            <div className="mid-menu">
              <div className="first-menu">
                {tabList &&
                  tabList?.length > 0 &&
                  tabList?.map(v => {
                    return (
                      <a
                        className={classNames(
                          "menu-item",
                          curTab === v?.key && "active"
                        )}
                        href={v?.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {v?.title}
                      </a>
                    )
                  })}
              </div>
            </div>
            {curTab === "xe-tu-lai" && renderSearchForm()}
            {renderRightHeader()}
          </>
        )}
      </WrapTopHeader>
    )
  }
  const render = () => {
    if (moreConfig?.showLoading) {
      const config: any = getConfigLoadingSkeleton()
      return (
        <MyUI
          type="sg_loading_skeleton"
          isLoading={config?.loading}
          // isLoading={true}
          isEmpty={false}
          customLoading={config?.customLoading}
          moreConfig={config?.moreConfig}
        >
          {renderTopHeader()}
          {!moreConfig?.isHideBottomFilter && (
            <>
              <hr />
              <MyUI
                type="sg_header_category"
                data={data}
                fnList={fnList}
                arrayTypeFields={arrayTypeFields}
                config={getConfigFromFirstSettingByKey("config_show_amount")}
              />
            </>
          )}
        </MyUI>
      )
    }
    return (
      <>
        {renderTopHeader()}
        {!moreConfig?.isHideBottomFilter && (
          <WrapBottomHeader className="position-relative">
            <div className="only-desktop">
              <hr />
              <MyUI
                type="sg_header_category"
                data={data}
                fnList={fnList}
                arrayTypeFields={arrayTypeFields}
                config={getConfigFromFirstSettingByKey("config_show_amount")}
              />
            </div>
          </WrapBottomHeader>
        )}
      </>
    )
  }
  return (
    <Wrap
      id="navbar-category-v2"
      className="navbar header-full-v2"
      mobileWidth={mobileWidth}
    >
      {render()}
    </Wrap>
  )
}
const RightMenu = styled.div`
  &.right-menu {
    &.collapse.navbar-collapse {
      display: flex !important;
      align-items: center;
      // gap: 20px;
      flex: 1 0 140px;
      justify-content: flex-end;
      padding-right: 0;
      & .right-item {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    & .navbar-right.avatar-menu {
      float: unset;
      margin-top: 0 !important;
    }
    & .custom-link {
      color: var(--color-text-000);
      font-size: 14px;
      font-weight: 500;
    }
    & .navbar_right-v2 {
      & ul.dropdown-menu {
        padding-top: 0;
      }
    }
  }
`
const WrapTopHeader: any = styled.div`
  & .wrap-btn-search {
    min-width: 129px;
    display: flex;
    justify-content: flex-end;
  }
  &.wrap-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 135px;
    transition: background 0.25s;
    width: 100%;
    position: relative;
    z-index: 334;
    padding: 0px;
    &.only-logo {
      justify-content: flex-start;
      height: 50px;
    }
  }
  & .navbar-header {
    transition: all 0.25s;
    & .navbar-brand {
      padding: 0 !important;
    }
  }
  & .logo {
    filter: unset;
  }

  & .first-menu {
    display: flex;
    // gap: 50px;
    & .menu-item {
      margin-right: 50px;
      color: #5b5959;
      font-weight: 500;
      font-size: 16px;
      min-width: 60px;
      &:last-child {
        margin-right: 0;
      }
    }
    & .menu-item.active {
      color: var(--color-text-000);
      font-weight: 700;
    }
  }
  & .form-search {
    // max-width: 75%;
    height: 5em;
    top: 50px;
    transform: translate(-50%, 0);
    border-radius: 37px;
    width: 1000px;
    position: absolute;
    align-items: center;
    background: #e9e9e9;
    box-shadow: 0px 2px 10px 0px #0000001a;
    border: 1px solid #e3e1e1;
    cursor: pointer;
    display: flex;
    padding: 0 0 0 0.5em;
    transition: height 0.25s;
    left: 50%;
    justify-content: flex-end;
    & .mb-3 {
      margin-bottom: 0 !important;
    }
    & .datefromto-dropdown-menu {
      transform: none !important;
      left: -65% !important;
      top: 10px !important;
      width: 950px;
      padding-right: 0;
      max-height: calc(${(props: any) => `${props.viewportHeight}px`} - 165px);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      & .w-dropdown-menu {
        max-height: calc(
          ${(props: any) => `${props.viewportHeight}px`} - 165px
        );
        overflow: auto;
        padding-right: 25px;
      }
      & .wrap-select-time {
        max-width: 792px;
        margin: 30px auto 15px auto;
      }
    }
    & .input-address-dropdown-menu {
      max-height: calc(${(props: any) => `${props.viewportHeight}px`} - 165px);
      & > div {
        overflow-y: auto;
        max-height: calc(
          ${(props: any) => `${props.viewportHeight}px`} - 165px
        );
        margin: -25px 0;
        padding: 25px 0;
        margin-right: -25px;
        padding-right: 25px;
      }
    }
    & .select-address-dropdown-menu {
      transform: translate(-29%, 2%) !important;
      left: 100% !important;
      top: 2px !important;
      backface-visibility: hidden;
    }
  }
  & .form-search {
    & .form-group {
      width: 35%;
    }
    & .form-group.datefromto {
      width: calc(65% - 70px - 18px);
    }
  }
  & .mid-menu {
    left: 50%;
    position: absolute;
    top: 20px;
    visibility: visible;
    transform: translateX(-50%) translateY(-50%) scale(1);
    transition: all 0.25s;
  }
  & .btn-search {
    width: 56px;
    height: 56px;
    box-shadow: none;
    border: none;
    border-radius: 37px;
    font-size: 16px;
    background-color: var(--mau1);
    color: var(--color-text-fff);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-right: 10px;
    font-weight: 600;
    transition: none;
    &.has-text {
      border-radius: 37px;
      width: 100%;
      & svg {
        margin-right: 5px;
        width: 20px;
        height: 20px;
      }
    }
    & svg {
      width: 25px;
      height: 25px;
    }
  }
  & .btn-search:hover {
    box-shadow: none;
    border: none;
  }
  // @media (min-width: 1500px) {
  //   & .form-search {
  //     width: 1000px;
  //     & .datefromto-dropdown-menu {
  //       transform: translate(-26%, 2%) !important;
  //       backface-visibility: hidden;
  //     }
  //     & .select-address-dropdown-menu {
  //       transform: translate(-26%, 2%) !important;
  //       backface-visibility: hidden;
  //     }
  //   }
  // }
  @media (max-width: 1199px) {
    & .form-search {
      max-width: 100%;
      width: 1000px;
    }
  }
`
const WrapBottomHeader = styled.div`
  @media (min-width: 1200px) {
    & .only-desktop {
      max-width: 100% !important;
      & .sg-all-filter {
        & .wpfillter {
          max-width: 90%;
          margin: 0 auto;
        }
        &.show-overlay-filter {
          max-width: 100% !important;
          margin-bottom: 0 !important;
        }
      }
    }
  }
`
const Wrap: any = styled.div`
  &.header-full-v2 {
    padding-bottom: 0;
    & .wpfillter {
      padding-bottom: 10px !important;
    }
  }
  &.navbar {
    background-color: var(--color-text-fff) !important;
    // border-bottom: 1px solid var(--color-border-e4);
    box-shadow: rgb(0 0 0 / 16%) 0 0 6px;
    transition: none;
    &.only-logo {
      padding-right: 8px;
    }
  }
  & .bottom-menu {
    display: flex;
  }
  hr {
    margin-top: 5px !important;
    margin-bottom: 0;
    border-color: var(--color-border-e4);
    background-color: var(--color-border-e4);
  }
  & .sg-all-filter {
    & #wpfillter {
      margin-bottom: 0;
      padding: 10px 0px 0px;
      & .btn {
        margin-bottom: 0;
      }
      & .container {
        padding: 0 0px;
        max-width: 100%;
      }
    }
  }
  &.navbar.truot {
    // box-shadow: none;
    & .wrap-header {
      height: 50px;
    }
    & .mid-menu {
      left: 50%;
      visibility: hidden;
      top: -20px;
      transform: translateX(-50%) translateY(-50%) scale(0);
      backface-visibility: hidden;
      -webkit-font-smoothing: subpixel-antialiased;
    }
    & .form-search {
      & .form-group {
        width: 50%;
        padding-left: 0;
        & label {
          display: none;
        }
      }
      & .form-group.datefromto {
        width: 40%;
      }
    }
    & .btn-search {
      width: 38px;
      height: 38px;
      font-size: 16px;
      margin-left: 0px;
      margin-right: 5px;
    }
    & .form-search {
      height: 48px;
      border-radius: 3em;
      max-width: 50%;
      top: -2px;
      // top: 22px;
      // transform: translate(-50%, -50%);
      width: 580px;
      background: var(--color-text-fff);
      & .datefromto-dropdown-menu {
        left: -65% !important;
        top: 10px !important;
      }
      & .select-address-dropdown-menu {
        transform: translate(-50%) !important;
        top: 13px !important;
        backface-visibility: hidden;
      }
      & .mc-input-date-from-to {
        & .btn-time-select {
          display: none;
        }
        & .btn-time-select.custom-text {
          display: flex;
        }
      }
      & .mc_btn_address_v2 {
        & .btn-input-address {
          display: none;
        }
        & .btn-input-address.custom-text {
          display: flex;
        }
      }
    }
  }
  & .wrap-button-form {
    & .btn-default:active:not(.btn-active),
    & .btn-default:focus:not(.btn-active) {
      background-color: #fff !important;
      border-color: #ccc !important;
    }
  }
  @media (min-width: 1500px) {
    &.navbar.truot {
      & .form-search {
        width: 625px;
        & .datefromto-dropdown-menu {
          left: -75% !important;
          top: 6px !important;
        }
      }
      & .btn-search {
        margin-left: 20px;
      }
    }
  }
  @media (max-width: 1199px) {
    &.navbar.truot {
      & .form-search {
        transform: translate(-56%, 0);
        max-width: 580px;
      }
    }
    & .mid-menu {
      & .another {
        & .datefromto-dropdown-menu {
          left: calc(-50% + 60px) !important;
          top: 10px !important;
        }
      }
    }
    &.navbar.truot {
      & .mid-menu {
        & .another {
          & .datefromto-dropdown-menu {
            left: calc(-50% - 45px) !important;
            top: 10px !important;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1011px) and (max-width: 1099px) {
    & .wrap-header {
      width: calc(100vw - 20px);
    }
    & .only-desktop {
      & .wpfillter {
        & .container {
          width: calc(100vw - 20px);
        }
      }
    }
  }
  @media screen and (min-width: 1100px) and (max-width: 1299px) {
    & .only-desktop {
      max-width: 90%;
      margin: 0 auto;
      & .wrap-switch {
        min-width: 216px;
      }
      & .wpfillter {
        & .container {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: ${(props: any) => `${props.mobileWidth}px`}) {
    & .only-desktop {
      display: none;
    }
  }
`
const WrapMobileView = styled.nav`
  background-color: #fff;
  box-shadow: 0px 0px 4px #888;
  & .navavatar {
    padding: 5px 15px;
    padding-right: 5px !important;
    display: flex;
    align-items: center;
    & .arvartimg2 {
      border-radius: 50%;
      max-width: 30px;
      width: 30px;
      height: 30px;
      max-height: 30px;
    }
    & .wp-icobar {
      padding-top: 0px !important;
      margin-right: 5px;
    }
  }
  // & .btn-filter {
  //   float: right;
  //   margin: 5px 15px 0;
  //   color: #222;
  //   cursor: pointer;
  // }
  & ul.dropdown-menu {
    padding-top: 0;
  }
  @media (max-width: 767px) {
    &.navbar-v2-mobile {
      z-index: 999;
    }
  }
  @media (max-width: 1010px) {
    &.navbar {
      display: unset !important;
    }
    &.navbar-v2-mobile {
      padding-top: 0;
      padding-bottom: 0;
    }
    & .navbar-header {
      min-width: 100%;
      margin-left: -16px;
      margin-right: -16px;
    }
  }
`
export default connector(MyUIHeader)
