import React from "react"
import classnames from "classnames"
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap"
import { logW } from "src/core/helpers/log"
import { isFunction, objHasKey } from "src/core/helpers/utils"
import MyForm from "src/core/components/MyForm/MyForm"
import { MyUI, MyLoading } from "src/core/components"
import ErrorBoundary from "../MyComponent"
interface IProps {
  className?: any
  style?: any
  type: string
}

interface IState {
  isShow?: boolean
  opts?: IConfigModal
  showLoading?: boolean
}

interface IConfigModal {
  component?: any
  showHeader?: boolean
  showFooter?: boolean
  showHeaderClose?: boolean
  modalClassName?: string
  modalCentered?: boolean
  modalScrollInside?: boolean
  title?: string
  usingModalBody?: boolean
  usingModalHeader?: boolean
  size?: string
  backdrop?: any | "static"
  fnList?: any
  msg?: any
  msgHtml?: any
  classNameBody?: string
  onClickClose?: any
  onClickOK?: any
  showFooterClose?: boolean
  showFooterOK?: boolean
  titleBtnOK?: string
  titleBtnClose?: string
  disabledBtnOk?: boolean
  onClose?: any
  onScrollContent?: boolean | false
}

class MyModal extends React.Component<IProps, IState> {
  html: HTMLHtmlElement | undefined
  state: any = {
    isShow: false,
    showLoading: false,
    opts: {},
  }
  _fnList: any = {}
  componentDidMount() {
    const { type } = this.props
    MyModal.Helpers.initComponent(type, this)
    const elements = document.getElementsByTagName("html")
    if (elements && elements[0]) {
      this.html = elements[0]
    }
    this._fnList = {
      getModal: () => {
        return this
      },
      hideModal: () => {
        this.hide()
      },
      showLoadingModal: v => {
        this.showLoading(v)
      },
      hideLoadingModal: () => {
        this.hideLoading()
      },
    }
  }
  show = (opts: any) => {
    this.setState(
      {
        isShow: true,
        opts: { ...MyModal.Helpers.defaultConfigModal, ...opts },
      },
      () => {
        this.hideScrollContent(true)
      }
    )
  }

  hideScrollContent = (v = false) => {
    const { opts } = this.state
    console.log("hide scroll", opts)
    if (!this.html || opts.onScrollContent) {
      return
    }
    if (v === false) {
      this.html.style.overflowY = "scroll"
    } else {
      this.html.style.overflowY = "hidden"
    }
  }

  showLoading = (v = true) => {
    this.setState({
      showLoading: v,
    })
  }
  hideLoading = () => {
    this.setState({
      showLoading: false,
    })
  }
  hide = ({ skipHideScrollContent = false }: any = {}) => {
    this.setState(
      {
        isShow: false,
        showLoading: false,
        opts: {},
      },
      () => {
        if (!skipHideScrollContent) {
          this.hideScrollContent(false)
        }
      }
    )
  }
  toggle = () => {
    this.setState(
      {
        isShow: !this.state.isShow,
      },
      () => {
        const { opts, isShow } = this.state
        if (isShow === false) {
          if (opts?.onClose) {
            opts.onClose()
          }
        }
        this.hideScrollContent(this.state.isShow)
      }
    )
  }
  _getConfig = key => {
    const { opts } = this.state
    if (objHasKey(opts, key)) {
      return opts[key]
    }
  }
  _renderContent = () => {
    let _ui = null
    const { type } = this.props
    const { opts, isShow } = this.state
    if (isShow !== true) {
      return <div></div>
    }
    switch (type) {
      case MyModal.Helpers.types.mydialog:
        if (opts.component) {
          if (isFunction(opts.component)) {
            return opts.component({
              inMyModal: this,
              fnList: { ...this._fnList, ...opts.fnList },
              configFromModal: opts,
            })
          } else {
            return opts.component
          }
        } else {
          if (opts.msgHtml) {
            return <MyUI type="html" html={opts.msgHtml} />
          } else if (opts.msg) {
            return opts.msg
          }
          return ""
        }
      case MyModal.Helpers.types.myui:
        return <MyUI {...opts?.configMyUI} />
      case MyModal.Helpers.types.component:
        if (opts.component) {
          if (isFunction(opts.component)) {
            return opts.component({
              inMyModal: this,
              fnList: { ...this._fnList, ...opts.fnList },
              configFromModal: opts,
            })
          } else {
            return opts.component
          }
        } else {
          logW("Missing component")
          return <div></div>
        }
      case MyModal.Helpers.types.myform:
        if (opts.configForm) {
          return (
            <MyForm
              inMyModal={this}
              configFromModal={opts}
              configForm={opts.configForm}
              fnList={opts.configForm.fnList || {}}
            />
          )
        } else {
          logW("Missing configForm")
          return <div></div>
        }
    }
    return _ui
  }
  _renderBody = () => {
    const { opts } = this.state
    if (opts.usingModalBody === true) {
      return (
        <ModalBody className={opts.classNameBody || ""}>
          <ErrorBoundary>{this._renderContent()}</ErrorBoundary>
        </ModalBody>
      )
    }
    return this._renderContent()
  }
  _renderHeaderContent = () => {
    const { opts } = this.state
    return opts.title
  }
  _renderHeader = () => {
    const { opts } = this.state
    if (opts.showHeader === false) {
      let mProps: any = {}
      if (opts.showHeaderClose) {
        mProps.toggle = this.toggle
      }
      return (
        <ModalHeader
          style={{ padding: "0", borderBottom: 0 }}
          {...mProps}
        ></ModalHeader>
      )
    }
    if (opts.usingModalHeader === true) {
      return (
        <ModalHeader toggle={this.toggle}>
          {this._renderHeaderContent()}
        </ModalHeader>
      )
    }
    return this._renderContent()
  }
  _renderFooter = () => {
    const { opts } = this.state
    if (opts.showFooter === true) {
      return (
        <ModalFooter>
          {opts.showFooterClose && (
            <Button
              color={opts.colorBtnClose || "secondary"}
              onClick={() => {
                if (opts.onClickClose) {
                  opts.onClickClose(this)
                } else {
                  this.hide()
                }
              }}
            >
              {opts.titleBtnClose || "Close"}
            </Button>
          )}
          {opts.showFooterOK && (
            <Button
              disabled={opts.disabledBtnOk}
              color={opts.colorBtnOK || "primary"}
              onClick={() => {
                if (opts.onClickOK) {
                  opts.onClickOK(this)
                }
              }}
            >
              {opts.titleBtnOK || "OK"}
            </Button>
          )}
        </ModalFooter>
      )
    }
    return null
  }
  _getClassname = () => {
    const { opts } = this.state
    return classnames(opts.modalClassName, {
      "modal-dialog-centered": opts.modalCentered,
      [`modal-${opts.size}`]: true, //sm,lg,xl
      "modal-dialog-scrollable": opts.modalScrollInside,
    })
  }
  _renderOverlay = () => {
    if (this.state.showLoading === true) {
      return (
        <div className="a-overlay">
          <MyLoading />
        </div>
      )
    }
  }
  render() {
    const { isShow, opts } = this.state
    const { backdrop } = opts
    return (
      <Modal
        isOpen={isShow === true}
        fade
        backdrop={backdrop != null ? backdrop : true}
        toggle={this.toggle}
        className={this._getClassname()}
      >
        {this._renderHeader()}
        {this._renderBody()}
        {this._renderFooter()}
        {this._renderOverlay()}
      </Modal>
    )
  }
  static Helpers = {
    defaultConfigModal: {
      usingModalBody: true,
      usingModalHeader: true,
      showHeader: true,
      size: "lg",
      onScrollContent: false,
    },
    types: {
      component: "component",
      myui: "myui",
      myform: "myform",
      mypage: "mypage",
      lightbox: "lightbox",
      mydialog: "mydialog",
    },
    initComponent: (type, component) => {
      if (type && component) {
        MyModal.Helpers.components[type] = component
      }
    },
    components: {},
  }
  static showWithType = (type, opts) => {
    if (MyModal.Helpers.components[type]) {
      MyModal.Helpers.components[type].show(opts)
    }
  }
  static showFromMyUI = (configMyUI, configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.myui, {
      configMyUI: configMyUI,
      ...configModal,
    })
  }
  static showFromMyForm = (configForm, configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.myform, {
      configForm: configForm,
      ...configModal,
    })
  }
  static showFromComponent = (component, configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.component, {
      component: component,
      ...configModal,
    })
  }
  static showMyDialog = (configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.mydialog, {
      ...configModal,
    })
  }
  static showConfirm = (configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.mydialog, {
      size: "xs",
      showFooter: true,
      showFooterOK: true,
      showFooterClose: true,
      backdrop: "static",
      component: () => {
        if (configModal?.msg) {
          return (
            <div style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
              {configModal?.msg}
            </div>
          )
        } else if (configModal?.msgHtml) {
          return (
            <div style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
              <MyUI type="html" html={configModal?.msgHtml} />
            </div>
          )
        }
      },
      ...configModal,
    })
  }
  static hideModalComponent = () => {
    if (MyModal.Helpers.components[this.Helpers.types.component]) {
      MyModal.Helpers.components[this.Helpers.types.component].hide()
    }
  }
  static hideModalMyUI = () => {
    if (MyModal.Helpers.components[this.Helpers.types.myui]) {
      MyModal.Helpers.components[this.Helpers.types.myui].hide()
    }
  }
  static hideModal = type => {
    if (MyModal.Helpers.components[type]) {
      MyModal.Helpers.components[type].hide()
    }
  }
  static hideModalScrollContent = type => {
    if (MyModal.Helpers.components[type]) {
      MyModal.Helpers.components[type].hide({
        skipHideScrollContent: true,
      })
    }
  }
}

export default MyModal
