import React from "react"
import { MyUIHelpers } from "@macashipo/mlib"

const MUI_admintable_table = React.lazy(() =>
  import("./Types/admintable_table")
)
const MUISelectBoxSearchV2 = React.lazy(() =>
  import("./Types/select_box_search_v2")
)
const MUIATFilter = React.lazy(() => import("./Types/admintable_filter"))
const List = {
  admintable_table: MUI_admintable_table,
  select_box_search_v2: MUISelectBoxSearchV2,
  admintable_filter: MUIATFilter,
}
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List)
  },
}

export default MyUIOne
