import { HUtils } from "src/core/helpers"

export const K = {
  t_bottom_2fa_setup: "t_bottom_2fa_setup",
  t_bottom_2fa_verify: "t_bottom_2fa_verify",
}

const Setting2FAHelpers = {
  Auth2FAEnabled: false,
  UniqueId: "",
  fnUpdateSetting2FAPart: null,
  FA2Methods: [],
  seletedMethod: null,
  // t_bottom_2fa_setup: `<div style=" font-size: 12px; margin-top: 1rem; ">Please contact <a href="mailto:itsupport@ezyservicecentre.com">itsupport@ezyservicecentre.com</a> if have any problem</div>`,
  // t_bottom_2fa_verify: `<div style=" font-size: 12px; margin-top: 1rem; ">To view your pay slip please enter the 6-digit OTP. If you did not initiate this request please contact <a href="mailto:itsupport@ezyservicecentre.com">itsupport@ezyservicecentre.com</a>.<br></div>`,
  t_bottom_2fa_setup: `<div style=" font-size: 12px; margin-top: 1rem; color: var(--color-text-main);">Please contact <a href="mailto:itsupport@allianceitsc.com">itsupport@allianceitsc.com</a> if have any problem</div>`,
  t_bottom_2fa_verify: `<div style=" font-size: 12px; margin-top: 1rem; color: var(--color-text-main);">Please enter the 6-digit OTP. If you did not initiate this request please contact <a href="mailto:itsupport@allianceitsc.com">itsupport@allianceitsc.com</a>.<br></div>`,
}

export const updateSettingByResponse = res => {
  if (res && res.Data) {
    Setting2FAHelpers.Auth2FAEnabled = res.Data.Auth2FAEnabled
    Setting2FAHelpers.UniqueId = res.Data.UniqueId
    Setting2FAHelpers.FA2Methods = res.Data.FA2Methods
  }
  forceUpdateSetting2FAPart()
}
export const updateSettingOneMethodByResponse = res => {
  if (res && res.Data && Setting2FAHelpers.FA2Methods) {
    for (let i = 0; i < Setting2FAHelpers.FA2Methods.length; i++) {
      let _method: any = Setting2FAHelpers.FA2Methods[i]
      if (res.Data.Id === _method.Id) {
        HUtils.mergeObjIfExist(_method, res.Data)
      }
    }
  }
  forceUpdateSetting2FAPart()
}
export const updateFnUpdateSetting2FAPart = fnUpdate => {
  if (fnUpdate) {
    Setting2FAHelpers.fnUpdateSetting2FAPart = fnUpdate
  }
}
export const getCurrentMethod = () => {
  return Setting2FAHelpers.seletedMethod
}

export const getCurrentMethodId = () => {
  return HUtils.get(Setting2FAHelpers.seletedMethod, "FA2MethodId")
}

export const getCurrentOtpRecipientRequired = () => {
  return HUtils.get(
    Setting2FAHelpers.seletedMethod,
    "Configs.OtpRecipientRequired"
  )
}
export const getCurrentDisplayQRCode = () => {
  return HUtils.get(Setting2FAHelpers.seletedMethod, "Configs.DisplayQRCode")
}
export const getCurrentOTPDescription = () => {
  return HUtils.get(Setting2FAHelpers.seletedMethod, "Configs.OTPDescription")
}
export const getCurrentFA2MethodName = () => {
  return HUtils.get(Setting2FAHelpers.seletedMethod, "FA2MethodName")
}
export const forceUpdateSetting2FAPart = () => {
  if (Setting2FAHelpers.fnUpdateSetting2FAPart) {
    HUtils.runFuntion(Setting2FAHelpers, "fnUpdateSetting2FAPart", [])
  }
}
export default Setting2FAHelpers
