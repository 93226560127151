import { ConnectedProps, connect } from "react-redux"
import { MyUI } from "@macashipo/mlib"
import styled from "styled-components"

const mapStateToProps = state => ({
  isAuthenticated: state.app.isAuthenticated,
  dataFirstSetting: state.app.dataFirstSetting,
  uiNavbarType: state.app.uiNavbarType,
})
const mapDispatchToProps = {}
const connector = connect(mapStateToProps, mapDispatchToProps)
interface Props extends ConnectedProps<typeof connector> {
  moreConfig?: any
}
const MyUINavbar = (props: Props) => {
  const { uiNavbarType, isAuthenticated } = props
  console.warn("uiNavType", uiNavbarType)

  return (
    <Wrap id="sg-navbar-mobile">
      <MyUI type={uiNavbarType} isAuthenticated={isAuthenticated} />
    </Wrap>
  )
}
const Wrap = styled.div`
  position: fixed;
  height: 85px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  &.hide {
    display: none;
  }
`
export default connector(MyUINavbar)
