import React from "react"
import MyUIHelpers from "src/core/components/MyUI/MyUIHelpers"

//MyUI
const MyUI_readonly_cofigstyle = React.lazy(() =>
  import("./TypesMyUI/readonly_configstyle")
)

const ListMyUI = {
  readonly_configstyle: MyUI_readonly_cofigstyle,
}
const MyTableCell = {
  init() {
    MyUIHelpers.addTypesFromListLazy(ListMyUI)
  },
}

export default MyTableCell
