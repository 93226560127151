import { MyUI, MyIcon } from "@macashipo/mlib"
import { HAuth } from "src/core/helpers"
import classNames from "classnames"
import { IoSearchOutline } from "react-icons/io5"
import Skeleton from "react-loading-skeleton"
import styled from "styled-components"
import dfLogo from "src/modules/app_sigo/images/logo-sigo.png"

export const getLoadingCategory = props => {
  const { firstLoading, tabList, arr, loadingMore, moreConfig } = props
  const logo = HAuth.getUserLogoApp() || dfLogo //"/config/logo.png"
  let _arr = [...arr]
  if (!firstLoading) {
    _arr = Array.from({ length: 12 }, (_, i) => i)
  }
  let scrollEnd = false
  if (moreConfig?.scroll_end) {
    scrollEnd = true
  }
  return (
    <WrapSkeletonLoading className="page-category-skeleton">
      {firstLoading && (
        <div className="navbar only-desktop">
          <WrapTopHeader className="container wrap-header">
            <div className="navbar-header">
              <a className="navbar-brand" href="/">
                <img className="logo" src={logo} alt="logo" />
              </a>
            </div>
            <div className="mid-menu">
              <div className="first-menu">
                {tabList &&
                  tabList?.length > 0 &&
                  tabList?.map(v => {
                    return (
                      <a
                        className={classNames("menu-item")}
                        href={v?.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Skeleton />
                      </a>
                    )
                  })}
              </div>
            </div>
            <div className="form-search">
              <div className="mb-3 col-md-4 col-sm-12 form-group">
                <div className="mc_btn_address_v2">
                  <div className="btn-input-address custom-text">
                    <div className="value merge-mode">
                      <MyIcon data="type:ic_location_pin" />
                      <span className="wrap-skeleton">
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                  <div className="btn-input-address">
                    <div className="title">{<Skeleton />}</div>
                    <div className="value">
                      <Skeleton />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 datefromto form-group">
                <div className="mc-input-date-from-to">
                  <div className="btn-time-select custom-text">
                    <div className="divider-line custom"></div>
                    <span className="text-merge">
                      <Skeleton />
                    </span>
                  </div>
                  <div className="btn-time-select">
                    <div className="divider-line"></div>
                    <div className="start item">
                      <div className="title">
                        <Skeleton />
                      </div>
                      <div className="time">
                        <Skeleton />
                      </div>
                    </div>
                    <div className="divider-line"></div>
                    <div className="end item">
                      <div className="title">
                        <Skeleton />
                      </div>
                      <Skeleton />
                    </div>
                  </div>
                </div>
              </div>
              <button className="btn btn-search">
                <IoSearchOutline />
              </button>
            </div>
            <RightMenu
              className="collapse navbar-collapse collapseRight right-menu"
              data-show="menumember"
              id="collapseRightDesktop"
            >
              <ul className="nav navbar-nav navbar-right avatar-menu">
                <MyUI type="sg_avatar" />
              </ul>
            </RightMenu>
          </WrapTopHeader>
          <hr />
          <WrapBottomHeader className="wpfillter">
            <div className="container">
              <div className="d-flex wrap-bottom-filter align-items-end justify-content-space-between">
                <div className="d-flex wrap-bottom-filter align-items-end flex-1">
                  <button className="btn btn-default btn-radius btn-refresh loading">
                    <Skeleton />
                  </button>
                  <div className="wrap-filter-left d-flex wrap-filter-type">
                    <button className="btn btn-default mr-10px item btn-radius modal-toggle btn-form-skeleton loading">
                      <Skeleton />
                    </button>
                    <button className="btn btn-default mr-10px item btn-radius modal-toggle btn-form-skeleton loading">
                      <Skeleton />
                    </button>
                  </div>
                  <div className="wrap-filter-left d-flex">
                    <button className="btn btn-default mr-10px item btn-radius modal-toggle btn-form-skeleton loading">
                      <Skeleton />
                    </button>
                  </div>
                </div>
                <div className="wrap-switch loading">
                  <Skeleton />
                </div>
              </div>
            </div>
          </WrapBottomHeader>
        </div>
      )}
      <WrapPageContent
        id="content"
        className={classNames(
          "page-category",
          loadingMore ? "is-load-more" : ""
        )}
      >
        <div className="container">
          {firstLoading && (
            <div className="wrap-total mb-20px d-flex align-items-center skeleton">
              <Skeleton />
            </div>
          )}
          <div className="m-auto-list">
            <div className="myui-wrap-content">
              <div className="row">
                {_arr &&
                  _arr?.length > 0 &&
                  _arr.map((v, i) => {
                    return (
                      <div
                        className="wrap-skaleton col-xxl-2-custom col-md-3 col-mm-4 col-ms-6 col-xs-12 col-xxs-12"
                        key={i}
                      >
                        <Skeleton className="image-skeleton" />
                        <div className="wrap-content-skeleton">
                          <div className="d-flex carname-skeleton ">
                            <Skeleton />
                            <Skeleton />
                          </div>
                          <div className="address-skeleton">
                            {" "}
                            <Skeleton />
                          </div>
                          <div className="distance-skeleton">
                            {" "}
                            <Skeleton />
                          </div>
                          <div className="price-skeleton">
                            {" "}
                            <Skeleton />
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </WrapPageContent>
      {!loadingMore && (
        <LoadMore>
          <div className="wrap-load-more">
            <div className="title-loading-more mb-20px">
              Tiếp tục khám phá những chiếc xe phù hợp với bạn
            </div>
            <div className="d-flex justify-content-center ">
              <button className="btn btn-loading-more">Hiển thị thêm</button>
            </div>
          </div>
        </LoadMore>
      )}
    </WrapSkeletonLoading>
  )
}
const LoadMore = styled.div`
  padding: 50px;
  & .wrap-load-more {
    min-height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  & .title-loading-more {
    font-weight: 700;
    font-size: 20px;
    color: var(--color-text-000);
    text-align: center;
  }
  & .btn-loading-more {
    padding-inline: 50px;
    border-radius: 6px;
    background-color: #353535;
    color: #fff;
    font-weight: 700;
    border: 1px solid #bababa;
    &:hover {
      background-color: #353535;
    }
    &:active {
      border: 1px solid #bababa;
    }
  }
  @media (max-width: 1199px) {
    &.hidden-desktop {
      display: unset !important;
    }
  }
`
const WrapSkeletonLoading = styled.div`
  &.page-category-skeleton {
  }
  & .navbar {
    border-bottom: 1px solid var(--color-border-e4);
    background-color: var(--color-text-fff) !important;
  }
  hr {
    margin-top: 5px !important;
    margin-bottom: 0;
    border-color: var(--color-border-e4);
    background-color: var(--color-border-e4);
  }
  & .only-mobile {
    display: none;
  }
  @media (max-width: 1199px) {
    // display: none;
    & .only-mobile {
      display: unset;
    }
    & .only-desktop {
      display: none;
    }
    hr {
      display: none;
    }
    & .navbar {
      padding: 15px 10px 0px;
    }
  }
`
const WrapPageContent = styled.div`
  &.page-category {
    padding-top: 170 !important;
    &.is-load-more {
      padding-top: 0;
      margin-top: -180px;
    }
  }
  & .wrap-total.skeleton {
    & > span {
      min-width: 250px;
      height: 25px;
      & > span {
        height: 100%;
        display: block;
      }
    }
  }
  & .wrap-skaleton {
    margin-bottom: 15px;
    & .image-skeleton {
      max-height: 234px;
      min-height: 234px;
      height: 234px;
    }
    & .wrap-content-skeleton {
      padding: 10px 0;
      & .carname-skeleton {
        width: 100%;
        justify-content: space-between;
        padding-bottom: 10px;
        & > span:first-child {
          width: 50%;
          height: 25px;
          & > span {
            height: 25px;
          }
        }
        & > span:last-child {
          width: 30%;
          height: 25px;
          & > span {
            height: 25px;
          }
        }
      }
      & .address-skeleton {
        width: 70%;
      }
      & .distance-skeleton {
        width: 50%;
      }
    }
  }
  @media (max-width: 1199px) {
    &.page-category {
      padding-top: 10px;
    }
  }
  @media (max-width: 767px) {
    &.page-category {
      padding-top: 10px;
    }
  }
  @media ((min-width: 1500px)) {
    &.col-xxl-2-custom {
      flex: 0 0 auto;
      width: 16.66667%;
    }
  }
`
const RightMenu = styled.div`
  &.right-menu {
    &.collapse.navbar-collapse {
      display: flex !important;
      align-items: center;
      gap: 20px;
      flex: 1 0 140px;
      justify-content: flex-end;
      padding-right: 0;
    }
    & .navbar-right.avatar-menu {
      float: unset;
      margin-top: 0 !important;
    }
    & .custom-link {
      color: var(--color-text-000);
      font-size: 14px;
      font-weight: 500;
    }
  }
`
const WrapTopHeader = styled.div`
  &.wrap-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 135px;
    transition: background 0.25s;
    width: 100%;
    position: relative;
    z-index: 334;
    padding: 0px;
  }
  & .navbar-header {
    transition: all 0.25s;
    & .navbar-brand {
      padding: 0 !important;
    }
    & .btn-filter {
      display: none;
    }
  }
  & .logo {
    filter: unset;
  }

  & .first-menu {
    display: flex;
    gap: 50px;
    & .menu-item {
      margin-right: 50px;
      color: #5b5959;
      font-weight: 500;
      font-size: 16px;
      min-width: 60px;
      &:last-child {
        margin-right: 0;
      }
    }
    & .menu-item.active {
      color: var(--color-text-000);
      font-weight: 700;
    }
  }
  & .form-search {
    // max-width: 75%;
    height: 5em;
    top: 50px;
    transform: translate(-50%, 0);
    border-radius: 37px;
    width: 1000px;
    position: absolute;
    align-items: center;
    background: #e9e9e9;
    box-shadow: 0px 2px 10px 0px #0000001a;
    border: 1px solid #e3e1e1;
    cursor: pointer;
    display: flex;
    padding: 0 0 0 0.5em;
    transition: all 0.25s;
    left: 50%;
    justify-content: flex-end;
    & .mb-3 {
      margin-bottom: 0 !important;
    }
    & .datefromto-dropdown-menu {
      transform: translate(-29%, 2%) !important;
    }
    & .select-address-dropdown-menu {
      transform: translate(-29%, 2%) !important;
      left: 100% !important;
      top: 5px !important;
      backface-visibility: hidden;
    }
  }
  & .form-search {
    & .form-group {
      width: 30%;
    }
    & .form-group.datefromto {
      width: calc(70% - 70px - 18px);
    }
  }
  & .mid-menu {
    left: 50%;
    position: absolute;
    top: 20px;
    visibility: visible;
    transform: translateX(-50%) translateY(-50%) scale(1);
    transition: all 0.25s;
  }
  & .btn-search {
    width: 56px;
    height: 56px;
    box-shadow: none;
    border: none;
    border-radius: 50%;
    font-size: 25px;
    background-color: var(--mau1);
    color: var(--color-text-fff);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-right: 10px;
  }
  & .btn-search:hover {
    box-shadow: none;
    border: none;
  }
  & .btn-time-select {
    padding: 0;
    color: var(--color-text-search-bar);
    box-shadow: none;
    border: none;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    & .item {
      padding: 10px;
      padding-left: 20px;
      text-align: left;
      width: 48%;
      position: relative;
      z-index: 1;

      & .title {
        font-weight: 700;
        color: var(--color-text-000);
        font-size: 14px;
      }
      & .time {
        padding-top: 5px;
        font-size: 16px;
        color: var(--color-text-search-bar);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & .item.active::after {
      content: "";
      top: -2px;
      left: 0;
      right: 0;
      z-index: -1;
      margin: 0 2px;
      width: 100%;
      height: calc(100% + 2px);
      position: absolute;
      border: 1px solid transparent;
      background-color: var(--color-text-fff);
      box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
      border-radius: 37px;
    }
    & .divider-line {
      border-left: 1px solid #d8d8d8;
      width: 1px;
      height: 30px;
    }
    & .divider-line.custom {
      margin-left: -5px;
    }
    & .text-merge {
      font-size: 14px;
      color: var(--color-text-search-bar);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  & .btn-time-select.custom-text {
    display: none;
  }
  & .btn-input-address {
    color: var(--color-text-000);
    text-align: left;
    border: none;
    box-shadow: none;
    width: 100%;
    padding: 6px;
    &.custom-text {
      display: none;
    }
    & .title {
      font-weight: 700;
      font-size: 14px;
      // min-width: 100%;
    }
    & .value {
      padding-top: 5px;
      font-size: 16px;
      color: var(--color-text-search-bar);
      &:not(.merge-mode) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.merge-mode {
        padding-top: 0px;
        font-size: 14px;
        display: flex;
        align-items: center;
        width: 100%;
        & svg {
          margin-right: 5px;
        }
        span {
          width: calc(100% - 20px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &.active {
      position: relative;
    }
    &.active::before {
      content: "";
      top: -6px;
      left: -20px;
      right: 0;
      z-index: -1;
      margin: 0 2px;
      width: calc(100% + 20px);
      height: calc(100% + 10px);
      position: absolute;
      border: 1px solid transparent;
      background-color: var(--color-text-fff);
      box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
      border-radius: 37px;
    }
  }
  @media (max-width: 1199px) {
    & .form-search {
      max-width: 100%;
    }
    &.wrap-header {
      height: 53px;
    }
    & .navbar-header {
      & .btn-filter {
        display: inline-block;
        margin: 5px 15px 0px;
        color: rgb(34, 34, 34);
        min-width: 81px;
        height: 39px;
        padding: 0;
        border-color: transparent;
        & > span {
          height: 100%;
          & > span {
            height: 100%;
            display: block;
            border-radius: 20px;
          }
        }
      }
    }
    & .form-search,
    & .mid-menu {
      display: none;
    }
  }
`
const WrapBottomHeader = styled.div`
  &.wpfillter {
    margin-bottom: 0px;
    padding: 10px 0px 0px;
  }
  & .justify-content-space-between {
    justify-content: space-between;
  }
  & .wrap-bottom-filter {
    & .subtitle {
      text-wrap: nowrap;
      font-size: 12px;
      color: #898989;
      width: 0;
    }
    & .wrap-filter-left {
      align-items: stretch;
      & .wrap-button-form {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: end;
      }
      &.wrap-filter-type {
        // border-right: 1px solid #dedede;
        // margin-right: 10px;
        & .subtitle {
          display: none;
        }
        & .wrap-button-form:first-child .subtitle {
          display: block;
        }
      }
    }
  }
  & .btn-refresh {
    padding: 6px 15px;
    margin-right: 8px;
    position: relative;
    &.loading {
      width: 57px;
      min-height: 39px;
      border-color: transparent;
      & > span {
        display: block;
        height: 39px;
        margin: -7px -15px;
        & > span {
          height: 100%;
          border-radius: 20px;
          display: block;
        }
      }
      & > svg {
        display: none;
      }
    }
    & > span {
      display: none;
    }
    &:hover,
    &:focus {
      border-color: #ccc !important;
      background: transparent !important;
    }
    &.btn-active-filter {
      background: var(--mau1);
      border-color: var(--color-border-blue-light);
      &:after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        position: absolute;
        right: 10px;
        top: 6px;
        background: red;
      }
    }
  }
  & .btn-form-skeleton.loading {
    width: 125px;
    height: 39px;
    border-color: transparent;
    user-select: none;
    pointer-events: none;
    touch-action: none;
    & > span:first-child {
      display: block !important;
      height: 38px;
      margin: -8px -12px;
      & > span {
        height: 100%;
        border-radius: 20px;
        display: block;
      }
    }
    & > span:nth-child(3) {
      display: none;
    }
    & > svg {
      display: none;
    }
  }
  & .wrap-switch.loading {
    display: flex;
    align-items: center;
    &.loading {
      & .form-group {
        display: none;
      }
      & > span {
        min-width: 217px;
        display: block;
        height: 39px;
        & > span {
          height: 100%;
          border-radius: 20px;
          display: block;
        }
      }
    }
  }
  @media (max-width: 1199px) {
    display: none;
  }
`
