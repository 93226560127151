import React from "react"
import Ext_Control from "./Ext_Control"
import { HUI, HIs } from "@macashipo/mlib"
const NotPropsInput = [
  "controlOpts",
  "innerRef",
  "controlRef",
  "fieldName",
  "isControlFirst",
  "hint",
  "onUpdateValue",
  "label",
]
const PropsInput = [
  "placeholder",
  "autoComplete",
  "autoFocus",
  "disabled",
  "rows",
  "onKeyDown",
  "onChange",
]
class Ext_Input extends Ext_Control {
  _cInput: any = null
  constructor(props) {
    super(props)
    HUI.initInnerRef(this)
  }
  getRefInput = () => {
    return this._cInput
  }
  getInputValue = () => {
    if (this._cInput) {
      return this._cInput.value
    }
    return ""
  }
  setValue = v => {
    console.warn("setValue:", v)
    if (this._cInput) {
      if (HIs.isString(v)) {
        this._cInput.value = v
      } else if (HIs.isBoolean(v)) {
        this._cInput.checked = v
      }
      this.onUpdateValue(v)
      this.onAfterSetValue(v)
    }
  }
  onAfterSetValue = v => {}
  getPropsInput = () => {
    let _moreProps = {
      autoComplete: "off",
      onBlur: (ev: any) => {},
      innerRef: r => {
        this._cInput = r
      },
    }
    let _keys = Object.keys(this.props)
    for (let k of _keys) {
      if (k === "controlOpts") {
        // _moreProps = { ..._moreProps, ...this.props[k] }
        if (this.props.controlOpts) {
          for (let kk of Object.keys(this.props.controlOpts)) {
            if (PropsInput.indexOf(kk) !== -1) {
              _moreProps[kk] = this.props.controlOpts[kk]
            }
          }
        }
      } else if (NotPropsInput.indexOf(k) === -1) {
        _moreProps[k] = this.props[k]
      }
    }
    // console.warn("_moreProps:", _moreProps, this.props, this.props.controlOpts)
    return {
      ..._moreProps,
      ...this.getAttributes4Selenium(),
    }
  }
  render() {
    return <div></div>
  }
}

export default Ext_Input
