import { HLink, HUtils } from "@macashipo/mlib"
import classNames from "classnames"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const HomeCategoryFields = {
  fCarCount: "carCount",
  fDate: "date",
}
const HomeCategoryCard = props => {
  const { data } = props

  const _url = HUtils.get(data, `${homeSlidesFields.fOneSlideMoreInfo}.url`)
  const img = data?.[homeSlidesFields.fOneSlideImageUrl]
  const title = data?.[homeSlidesFields.fOneSlideTitle]
  const carCount = HUtils.get(data, `Data.${HomeCategoryFields.fCarCount}`)
  const date = HUtils.get(data, `Data.${HomeCategoryFields.fDate}`)
  const onHandleBtn = () => {
    if (_url) {
      HLink.openUrl(_url, "_self")
    }
  }
  return (
    <Wrap
      onClick={onHandleBtn}
      className={classNames(_url && "cursor-pointer")}
    >
      <img className="lazy" src={img} alt={title || ""} />
      <div className="content">
        <div className="title">{title}</div>
        {carCount && <div className="car-count">{carCount}</div>}
        {date && <div className="date">{date}</div>}
      </div>
    </Wrap>
  )
}
const Wrap = styled.div`
  background-color: var(--color-text-fff);
  border-radius: 15px;
  &.cursor-pointer {
    cursor: pointer;
  }
  & img {
    height: 300px;
    object-fit: cover;
    border-radius: 15px 15px 0 0;
  }
  & .content {
    border-radius: 0 0 15px 15px;
    padding: 15px;
    min-height: 110px;
    & .title {
      font-size: 20px;
      font-weight: 700;
      color: var(--color-text-000);
    }
    & .car-count {
      font-size: 16px;
      color: var(--color-text-000);
      min-height: 25px;
    }
    & .date {
      color: #998f8f;
      font-size: 12px;
      padding-top: 5px;
    }
  }
  @media (max-width: 996px) {
    & img {
      height: 250px;
    }
  }
`
export default HomeCategoryCard
