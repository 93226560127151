import { HLink } from "@macashipo/mlib"
import { useState } from "react"
import { Button } from "reactstrap"
import { MyUI } from "src/core/components"
import { historyFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const AddressDirection = props => {
  const { data, fnList, buttonCode, isPaid, canShowButton } = props
  const [copied, setCopied] = useState(false)
  const UserTakeLat = data?.[historyFields.fLatitude]
  const UserTakeLng = data?.[historyFields.fLongitude]

  const getDirectionLink = () => {
    return `https://www.google.com/maps/dir/?api=1&destination=${UserTakeLat},${UserTakeLng}`
  }
  const getCopyLink = () => {
    return `https://maps.google.com/?q=${UserTakeLat},${UserTakeLng}`
  }
  const getDescription = () => {
    let des = ""
    if (data?.[historyFields.fIsUserTakeSelected] && isPaid) {
      des = data?.[historyFields.fAddress]
    } else {
      des = data?.[historyFields.fDeliveryAddress]
    }
    return des
  }
  const copyLink = async () => {
    let src = getCopyLink()
    if (src) {
      await navigator.clipboard.writeText(src)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 1200)
    }
  }
  const openLink = () => {
    const link = getDirectionLink()
    HLink.openUrl(link, "_blank")
  }
  // Render
  const renderMessage = () => {
    const msg = "Sao chép thành công!"
    if (copied && msg) {
      return (
        <WrapMessage>
          <i className="flaticon-check" />
          {msg}
        </WrapMessage>
      )
    }
    return <></>
  }
  const renderDescription = () => {
    let text = getDescription() || ""
    return <div className="description">{text}</div>
  }
  const renderMap = () => {
    const UserTakeLat = data?.[historyFields.fLatitude]
    const UserTakeLng = data?.[historyFields.fLongitude]
    if (UserTakeLng && UserTakeLat) {
      return (
        <MyUI
          type="sg_vehicle_map"
          data={{ longitude: UserTakeLng, latitude: UserTakeLat }}
          moreConfig={{ isShowRedMarker: true }}
        />
      )
    }
    return <></>
  }
  const renderActions = () => {
    if (canShowButton) {
      return (
        <Footer className="mt-30px">
          <Button color="outline" className="btn-sg" onClick={copyLink}>
            Sao chép địa chỉ
          </Button>
          <Button color="primary-v2" className="btn-sg" onClick={openLink}>
            Mở hướng dẫn chỉ đường
          </Button>
        </Footer>
      )
    }
    return <></>
  }
  return (
    <Wrap className="w-address-direction">
      {renderDescription()}
      {renderMap()}
      {renderActions()}
      {renderMessage()}
    </Wrap>
  )
}
const WrapMessage = styled.div`
  border: 1px solid var(--color-border-dfdfdf);
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 5px 10px;
  border-radius: 8px;
  position: absolute;
  background-color: var(--color-text-fff);
  font-size: 14px;
  bottom: -30px;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  animation: smooth-appear 0.5s ease-in-out forwards;
  @keyframes smooth-appear {
    to {
      bottom: 5px;
      opacity: 1;
    }
  }
  & .flaticon-check {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    height: 16px;
    width: 16px;
    color: var(--color-text-fff);
    font-size: 10px;
    background-color: green;
    margin-right: 5px;
    margin-top: -1px;
  }
`
const Footer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  gap: 1rem;
  & button {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
`
const Wrap = styled.div`
  & .description {
    font-size: 24px;
    line-height: 33px;
    padding-bottom: 20px;
    margin-top: -15px;
  }
  & .wrap-map {
    height: 349px !important;
  }
`
export default AddressDirection
