import React, { useEffect } from "react"
import { connect, ConnectedProps } from "react-redux"
import { useHistory } from "react-router-dom"
import { logout, toggleSideNav } from "src/App/App.actions"
// import { HAuth, HConfig } from "src/core/helpers"
import { HCF, MyUI } from "@macashipo/mlib"
import { PATH } from "src/helpers/paths"
// import Avatar from "./Avatar"
// import HeaderTitle from "./HeaderTitle"
// import { LogoApp, MenuStyle, RightHeader } from "./Header.styles"
// import Menu from "./Menu"
// import LogoDefault from "src/modules/app_jet/assets/images/logo.png"
const mapStateToProps = state => ({})
const mapDispatchToProps = {
  logout,
  toggleSideNav,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface Props extends ConnectedProps<typeof connector> {}

const Header = (props: Props) => {
  const { logout } = props
  // const { currentHeaderTitle } = useShareableState()
  const history = useHistory()
  const logoUrl = HCF.getCF("logoUrl") || "/"
  const targetLogo = HCF.getCF("targetLogoUrl")
  const handleLogout = () => {
    console.log("logout")
    logout({
      cbLogout: () => {
        console.log("cbLogout")
        history.push(PATH.LOGIN)
      },
    })
    // history.push(PATH.LOGIN)
  }

  useEffect(() => {}, [history])
  return (
    <div className="c-header">
      <MyUI type="sg_header" />
    </div>
  )
}
export default connector(Header)
