import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import styled from "styled-components"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import { MyCard } from "@macashipo/mlib"
import classNames from "classnames"

const configKeySlide = {
  slide_options: "slide_options",
  url: "url",
  always_show_nav: "always_show_nav",
  only_image: "only_image",
  default_image: "default_image",
  slide_setting: "slide_setting",
  use_data: "use_data",
  slide_options_mobile: "slide_options_mobile",
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <i className="flaticon-right-arrow circel" />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <i className="flaticon-left-arrow circel" />
    </div>
  )
}

const MUISlideCardV2 = props => {
  const { data, className, config, fnList, classNameWrap, moreInfo } = props
  let slideImages = data?.[homeSlidesFields.fSlideImages]
  if (config?.[configKeySlide.use_data]) {
    slideImages = data
  }
  const configOptions = config?.[configKeySlide.slide_options_mobile] || {}
  const slideSetting = config?.[configKeySlide.slide_setting] || {}
  const refWrapSlide = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (refWrapSlide.current) {
      const slickList = refWrapSlide.current?.querySelector(".slick-list")

      const parentNode = slickList?.parentNode as HTMLButtonElement
      if (parentNode && slickList) {
        // Kiểm tra xem wrapper đã tồn tại hay chưa
        if (!parentNode.classList.contains("custom-wrapper-slick")) {
          const wrapperDiv = document.createElement("div")
          wrapperDiv.className = "custom-wrapper-slick"
          parentNode.insertBefore(wrapperDiv, slickList)
          wrapperDiv.appendChild(slickList)
        }
      }
    }
  }, [data])

  var options = {
    dots: true,
    infinite: false,
    swipeToSlide: true,
    slidesToShow: 3,
    speed: 250,
    slidesToScroll: 1,
    accessibility: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    loop: false,
    centerPadding: "15px",
    ...configOptions,
  }
  const alwaysShowNav =
    config?.[configKeySlide.always_show_nav] && options?.loop

  const renderTitle = () => {
    const _url = moreInfo?.[configKeySlide.url]
    const title = data?.[homeSlidesFields.fTitle]
    const description = data?.[homeSlidesFields.fDescription] || ""
    if (title) {
      return (
        <WrapTitle className="col-sm-12 title-home d-flex">
          {title && (
            <h2
              className="maintxt"
              dangerouslySetInnerHTML={{ __html: title }}
            ></h2>
          )}
          {description && (
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          )}
          {/* {_url && (
            <a className="pull-right hidden-mobile more-btn" href={_url}>
              Xem thêm
            </a>
          )} */}
        </WrapTitle>
      )
    }
    return <></>
  }

  const _url = moreInfo?.[configKeySlide.url]
  const _configBtnNavigation = slideSetting?.configBtnNavigation || {}
  const _configDots = slideSetting?.configDots || {}
  const classNameSlide = slideSetting?.classNameSlide || ""
  // const isCenter = getIsCenter()
  const slideLength = slideImages?.length
  if (slideLength > 0) {
    return (
      <Wrap
        className={className}
        configBtnNavigation={_configBtnNavigation}
        configDots={_configDots}
        configOptions={configOptions}
        ref={refWrapSlide}
      >
        {renderTitle()}
        <div
          className={classNames(
            "home-slide",
            classNameWrap,
            alwaysShowNav && "always-show-nav",
            classNameSlide && classNameSlide
            // isCenter && "always-center"
          )}
        >
          <Slider
            {...options}
            ref={ref => {
              console.log("ref", ref)
            }}
          >
            {slideImages.map((v, i) => {
              return (
                <div className="item" key={i}>
                  <MyCard
                    type={config?.cardType}
                    data={v}
                    fnList={fnList}
                    config={config}
                    configMoreInfo={moreInfo}
                  />
                </div>
              )
            })}
          </Slider>
        </div>
      </Wrap>
    )
  }
  return <></>
}

const WrapTitle = styled.div`
  &.title-home {
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
  }
  & .wrap-title {
    align-items: center;
    justify-content: space-between;
  }
  & .maintxt {
    font-size: 36px;
    font-weight: 700;
    color: var(--color-text-000);
    text-align: center;
    line-height: 49px;
    margin-bottom: 0;
    max-width: 80%;
  }
  & .description {
    color: var(--color-text-000);
    font-size: 16px;
    text-align: center;
    padding-top: 15px;
    max-width: 80%;
  }
  @media (max-width: 1199px) {
    & .maintxt {
      max-width: 90%;
    }
  }
  @media (max-width: 767px) {
    & .maintxt {
      font-size: 30px;
      line-height: 39px;
      max-width: 100%;
    }
    & .description {
      padding-top: 10px;
      max-width: 100%;
    }
  }
`

const Wrap: any = styled.div`
  & .wrap-item {
    display: block;
  }
  & .slick-arrow {
    width: 35px;
    height: 35px;
    z-index: 2;
    &.slick-disabled {
      display: none !important;
    }
    &:before {
      display: none;
    }
    & i {
      display: block;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 0 6px #999;
      height: 35px;
      width: 35px;
      text-align: center;
      line-height: 39px;
      color: #888;
      font-size: 16px;
    }
    top: ${(props: any) =>
      props?.configBtnNavigation && props?.configBtnNavigation?.default
        ? props?.configBtnNavigation?.default
        : "calc(50% - 15px)"};
    &.slick-next {
      right: ${(props: any) =>
        props?.configBtnNavigation && props?.configBtnNavigation?.rightDefault
          ? props?.configBtnNavigation?.rightDefault
          : "-15px"};
    }
    &.slick-prev {
      left: ${(props: any) =>
        props?.configBtnNavigation && props?.configBtnNavigation?.leftDefault
          ? props?.configBtnNavigation?.leftDefault
          : "-15px"};
    }
  }
  & .slick-dots {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    max-width: 80px;
    overflow: hidden;
    bottom: ${(props: any) =>
      props?.configDots && props?.configDots?.bottom
        ? props?.configDots?.bottom
        : "10px"};
    & li {
      margin: 0;
      width: auto;
      height: auto;
      &.slick-active button {
        background-color: ${(props: any) =>
          props?.configDots && props?.configDots?.activeColor
            ? props?.configDots?.activeColor
            : "var(--color-text-fff)"};
        width: ${(props: any) =>
          props?.configDots && props?.configDots?.activeWidth
            ? props?.configDots?.activeWidth
            : "7px"};
        height: ${(props: any) =>
          props?.configDots && props?.configDots?.activeWidth
            ? props?.configDots?.activeWidth
            : "7px"};
        min-width: ${(props: any) =>
          props?.configDots && props?.configDots?.activeWidth
            ? props?.configDots?.activeWidth
            : "7px"};
      }
      & button {
        margin: 0;
        border-radius: 50%;
        padding: 0;
        width: ${(props: any) =>
          props?.configDots && props?.configDots?.defaultWidth
            ? props?.configDots?.defaultWidth
            : "5px"};
        height: ${(props: any) =>
          props?.configDots && props?.configDots?.defaultWidth
            ? props?.configDots?.defaultWidth
            : "5px"};
        min-width: ${(props: any) =>
          props?.configDots && props?.configDots?.defaultWidth
            ? props?.configDots?.defaultWidth
            : "5px"};
        background-color: ${(props: any) =>
          props?.configDots && props?.configDots?.defaultColor
            ? props?.configDots?.defaultColor
            : "var(--color-text-e1e1e1)"};
        margin-right: ${(props: any) =>
          props?.configDots && props?.configDots?.marginRight
            ? props?.configDots?.marginRight
            : "5px"};
        &:before {
          display: none;
        }
      }
    }
  }

  & .slide-item {
    background: yellow;
    border-right: 1px solid #000;
  }
  & .custom-wrapper-slick {
    overflow: hidden;
  }
  & .slick-list {
    margin-right: -${(props: any) => (props?.configOptions?.margin ? props?.configOptions?.margin / 2 : 7.5)}px;
    margin-left: -${(props: any) => (props?.configOptions?.margin ? props?.configOptions?.margin / 2 : 7.5)}px;
  }
  & .slick-slide {
    padding-left: ${(props: any) =>
      props?.configOptions?.margin ? props?.configOptions?.margin / 2 : 7.5}px;
    padding-right: ${(props: any) =>
      props?.configOptions?.margin ? props?.configOptions?.margin / 2 : 7.5}px;
    & img {
      width: 100%;
    }
  }
`

export default MUISlideCardV2
