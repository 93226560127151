import { useEffect, useRef, useState } from "react"
const useCountdown = (targetDate, { stopWhenFinish } = {}) => {
  // const [stop, setStop] = useState(false)
  const countDownDate = new Date(targetDate).getTime()
  const config = useRef({
    needStopInterval: false,
  }).current

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  )
  // console.log("countDownDate:", countDownDate, countDown)
  useEffect(() => {
    const interval = setInterval(() => {
      // console.log("interval tick", stopWhenFinish, config.needStopInterval)
      if (stopWhenFinish && config.needStopInterval) {
        clearInterval(interval)
      } else {
        let _countDown = countDownDate - new Date().getTime()
        if (_countDown <= 0) {
          config.needStopInterval = true
        }
        setCountDown(_countDown)
      }
    }, 500)
    return () => clearInterval(interval)
  }, [countDownDate, stopWhenFinish, config])

  return getReturnValues(countDown)
}

const getReturnValues = countDown => {
  // calculate time left

  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)
  const totalSeconds = Math.floor(countDown / 1000)
  const finish = countDown <= 0 ? true : false
  return [days, hours, minutes, seconds, totalSeconds, finish]
}

export { useCountdown }
