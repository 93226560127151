import styled from "styled-components"
import { forwardRef, useEffect, useRef, useState } from "react"
import classnames from "classnames"
import { MyForm, MyModal } from "src/core/components"
import { useForceUpdate } from "src/modules/app_sigo/hooks/useForceUpdate"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"

const EnterDate = props => {
  const { fnList, data } = props
  const refTopForm = useRef<MyForm>(null)
  const [keySearchDateUI, forceUpdateSearchDate] = useForceUpdate()
  const [dataFilter, setDataFilter] = useState(data)

  useEffect(() => {
    forceUpdateSearchDate()
  }, [data])
  const configFormBooking: any = {
    defaultValues: {
      ...dataFilter,
      DateFromTo: [
        dataFilter?.[homeSlidesFields.fFromDate],
        dataFilter?.[homeSlidesFields.fToDate],
      ],
    },
    controls: [
      {
        label: "",
        fieldName: "DateFromTo",
        type: "input_date_from_to_search",
        more: {
          morePropsDateFlatPickr: {
            calendarStartDay: 0,
            numberOfMonths: 2,
            orientation: "verticalScrollable",
          },
          moreOptionsDateFlatPickr: {
            disable: [
              function (date) {
                return (
                  date < new Date().setHours(0, 0, 0, 0) &&
                  date.getTime() !== new Date().setHours(0, 0, 0, 0)
                )
              },
            ],
          },
          configMergeText: {
            titleWhenMergeText: "Chọn ngày",
          },
          mappingField: {
            fFrom: homeSlidesFields.fFromDate,
            fTo: homeSlidesFields.fToDate,
          },
          configSelectTime: {
            titleTimeFrom: "Giờ nhận xe",
            titleTimeTo: "Giờ trả xe",
          },
          configSelectDate: {
            titleDatePicker: "Chuyến đi của bạn diễn ra khi nào?",
            labelFrom: "Ngày nhận",
            labelTo: "Ngày trả",
          },
          classNameGroup: "w-100 datefromto",
          classNameControl: "date-from-to",
        },
      },
    ],
  }

  const onHandleUpdate = (values = {}, needReloadUI = false) => {
    setDataFilter({ ...dataFilter, ...values })
    needReloadUI && forceUpdateSearchDate()
  }

  return (
    <Wrap>
      <MyForm
        key={keySearchDateUI}
        ref={refTopForm}
        className="d-flex flex-wrap check-in-out-time"
        configForm={configFormBooking}
        fnList={
          {
            afterUpdateValueControl: opts => {
              const { fieldName, newValue } = opts
              let needReload = false
              let values = {
                [fieldName]: newValue,
              }
              if (fieldName === "DateFromTo") {
                values[homeSlidesFields.fFromDate] = newValue[0]
                values[homeSlidesFields.fToDate] = newValue[1]
              }
              onHandleUpdate(values)
            },
            onCloseSelectTime: () => {
              fnList?.onCloseCollapse && fnList?.onCloseCollapse()
            },
            afterSaveSelectTime: obj => {
              fnList?.onUpdateDate && fnList?.onUpdateDate(dataFilter)
            },
          } as any
        }
        renderFooter={() => <></>}
      />
    </Wrap>
  )
}
const Wrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  & .mc-input-date-from-to .wrap-title {
    padding: 16px;
    padding-bottom: 0;
  }

  & .check-in-out-time {
    flex: 1 1 auto;
  }
  & .mc-input-date-from-to,
  & .date-from-to {
    height: 100%;
  }
  & .input-react-dates {
    height: 100% !important;
  }
  & .mc-input-date-from-to > div {
    height: 100%;
    & .wrap-time {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`
export default forwardRef(EnterDate)
