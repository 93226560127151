import { getIconPath } from "./Types"
import Lottie from "react-lottie"

const MAnimationIcon = props => {
  const { type, config, width, height, moreProps } = props
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: getIconPath(type),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    ...config,
  }
  if (getIconPath(type)) {
    return (
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        {...moreProps}
      />
    )
  }
  return <></>
}

export default MAnimationIcon
