import classNames from "classnames"
import { HUtils } from "@macashipo/mlib"
import Skeleton from "react-loading-skeleton"
import { Button } from "reactstrap"
import { MyModal } from "src/core/components"
import { detailFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const MyUICarName = props => {
  const { data, fnList } = props
  const name = data?.[detailFields.fName]

  const getConfigFromFirstSettingDetail = key => {
    if (fnList?.getDataFirstSettingByKey) {
      const detail = fnList?.getDataFirstSettingByKey(
        "DefaultValues.websiteSetting.detail"
      )
      return HUtils.get(detail, key)
    }
    return
  }

  const onHanleBtnShare = () => {
    const shareSettings = getConfigFromFirstSettingDetail("shareSettings") || {}
    MyModal.showFromMyUI(
      {
        type: "sg_share",
        data: data,
        shareSettings: shareSettings,
      },
      {
        size: "top",
        showHeader: false,
        showHeaderClose: false,
        modalClassName: "modal-share",
      }
    )
  }
  const isShowLike = getConfigFromFirstSettingDetail("canShowLike")
  return (
    <Wrap className="wrap-carname container">
      <div className="wrap-content">
        <div className={classNames("name", !name && "has-loading")}>
          {name || <Skeleton />}
        </div>
        {name && (
          <div
            className={classNames("wrap-actions", !isShowLike && "justify-end")}
          >
            <div className="one-button" onClick={onHanleBtnShare}>
              <i className="flaticon-share circle" />
              <Button color="underline-black">Chia sẻ</Button>
            </div>
            {isShowLike && (
              <div className="one-button">
                <i className="flaticon-heart circle" />
                <Button color="underline-black">Yêu thích</Button>
              </div>
            )}
          </div>
        )}
      </div>
    </Wrap>
  )
}
const Wrap = styled.div`
  padding-bottom: 20px;
  & .wrap-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  & .name {
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    max-width: calc(100% - 250px - 15px);
    padding-right: 15px;
    color: var(--color-text-000);
    &.has-loading {
      min-width: calc(100% - 250px - 15px);
    }
  }
  & .wrap-actions {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 250px;
    &.justify-end {
      justify-content: flex-end;
    }
    & .one-button {
      display: flex;
      justify-content: space-between;
    }
  }
  & .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    height: 38px;
    width: 38px;
    text-align: center;
    line-height: 39px;
    color: #888;
    font-size: 17px;
    border: 1px solid var(--color-border-dfdfdf);
    margin-right: 7px;
    cursor: pointer;
  }
  @media (max-width: 767px) {
    & .name {
      font-size: 32px;
      line-height: 45px;
      max-width: calc(100% - 80px - 15px);
      padding-right: 15px;
      color: var(--color-text-000);
      &.has-loading {
        min-width: calc(100% - 80px - 15px);
      }
    }
    & .wrap-actions {
      padding-top: 5px;
      width: 80px;
      & .one-button {
        & button {
          display: none;
        }
      }
    }
  }
`
export default MyUICarName
