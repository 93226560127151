import ModuleAppSigo from "../app_sigo"
import ModuleAppSigoResponsive from "../app_sigo_responsive"
interface IModuleAppSigoGeneric extends ICore.IOneModule {
  runAfterInit: (HConfig: any) => void
}
const ModuleAppSigoGeneric: IModuleAppSigoGeneric = {
  _key: "app_sigo",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "app_sigo"
  },
  runAfterInit: (HConfig: any) => {
    if (window.innerWidth <= 1010) {
      ModuleAppSigoResponsive.runAfterInit(HConfig)
    } else {
      ModuleAppSigo.runAfterInit(HConfig)
    }
  },
}

export default ModuleAppSigoGeneric
