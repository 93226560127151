import { MyUI } from "src/core/components"
import styled from "styled-components"
import { getLoadingPage } from "../../MyPage/LoadingHelper"
import { headerTabList, pageKey } from "src/modules/app_sigo/constants"
import dfLogo from "src/modules/app_sigo/images/logo-sigo.png"
import { HAuth } from "src/core/helpers"

const logo = HAuth.getUserLogoApp() || dfLogo

const MyUIHeaderMobileV2 = props => {
  const { loading, onlyShowLogo } = props
  const getConfigLoadingSkeleton = () => {
    const config = {
      customLoading: () => {
        return getLoadingPage({
          key: pageKey.onlyHeader,
          tabList: headerTabList,
          logo: logo,
        })
      },
      loading: loading,
      moreConfig: {},
    }
    return config
  }
  const config: any = getConfigLoadingSkeleton()
  return (
    <MyUI
      type="sg_loading_skeleton"
      isLoading={config?.loading}
      // isLoading={true}
      isEmpty={false}
      customLoading={config?.customLoading}
      moreConfig={config?.moreConfig}
    >
      <WrapMobile className="wrap-mobile">
        <div className="c-header">
          <MyUI
            type="sg_header"
            moreConfig={{
              hideMenuLeft: true,
              hideMenuRight: true,
              onlyShowLogo: onlyShowLogo,
            }}
          />
        </div>
        <MyUI type="sg_home_searchbar" />
      </WrapMobile>
    </MyUI>
  )
}
const WrapMobile = styled.div`
  &.wrap-mobile {
    display: none;
    & .c-header {
      position: relative;
    }
  }
  @media (min-width: 768px) and (max-width: 820px) {
    & .wrap-mobile {
      & .navbar-header {
        background-color: #fff;
      }
      & .logo {
        filter: brightness(0) invert(1);
      }
      & .truot {
        & .logo {
          filter: none;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1010px) {
    & .navbar {
      position: relative;
      background-color: #fff !important;
      &.truot {
        position: fixed;
      }
      & .navbar-nav > li > a {
        color: #222 !important;
      }
      & .navbar-nav > li.active > a {
        color: white !important;
      }
    }
    & .c-header {
      position: relative;
    }
  }
  @media (max-width: 1010px) {
    &.wrap-mobile {
      display: block;
      margin-bottom: 1rem;
      & .wrap-header.container {
        padding: 0 !important;
      }
      & #slider {
        padding-top: 110px;
        margin-bottom: 0;
        min-height: 400px !important;
        width: 100%;
      }
      & .logo {
        filter: none !important;
      }
    }
    & .header-full-v2,
    & .navbar-v2-mobile {
      display: none;
    }
    &.page-home {
      padding-top: 0;
      & .navbar {
        padding-bottom: 0px;
      }
    }
    & .container {
      width: 100%;
      max-width: 100%;
      padding: 0 15px !important;
    }
  }
  @media (max-width: 820px) {
    & .wrap-mobile {
      & #slider {
        padding-top: 15px;
        min-height: unset !important;
      }
    }
  }
  @media (max-width: 767px) {
    & .navbar-header {
      margin: 0 !important;
    }
    & .wrap-mobile {
      & .navbar-right {
        padding: 0 8px;
      }
    }
    & .search-bar {
      margin-top: 1.25rem !important;
    }
  }
`
export default MyUIHeaderMobileV2
