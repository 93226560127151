import { HModules, HCF, HLink, HUtils } from "@macashipo/mlib"
import MyUIOne from "./MyUI"
import MyTableCellOne from "./MyTableCell"
const ModuleAdminTable = {
  _key: "admintable",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "admintable"
  },
  runAfterInit: HConfig => {
    HModules.initDefault(ModuleAdminTable, { MyUIOne, MyTableCellOne })
  },
}

export default ModuleAdminTable
