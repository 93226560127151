import classNames from "classnames"
import { HLink, MyUI } from "@macashipo/mlib"
import { historyFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"
import Skeleton from "react-loading-skeleton"
import { useState } from "react"

const OneOrderCard = props => {
  const { data, fnList } = props
  const [canShow, setCanShow] = useState(false)

  const address = data?.[historyFields.fOwnerAddress]
  const name = data?.[historyFields.fRentalServiceName]
  const img = data?.[historyFields.fAvatarUrl]
  const blogs = data?.[historyFields.fBlogs]
  const departureDate = data?.[historyFields.fDaysBeforeDeparture_Text]
  const rentalDate = data?.[historyFields.fRentalDate_Text]
  const status = data?.[historyFields.fStatusName]
  const icon = data?.[historyFields.fIcon] || ""
  const iconColor = data?.[historyFields.fIconColor] || "var(--mau-den)"

  const hideDetailOrder = () => {
    setCanShow(false)
    fnList?.hideScrollContent && fnList?.hideScrollContent(false)
    const navBar = document.getElementById("sg-navbar-mobile")
    if (navBar) {
      navBar.classList.remove("hide")
    }
  }
  const showDetailOrder = () => {
    setCanShow(true)
    fnList?.hideScrollContent && fnList?.hideScrollContent(true)
    const navBar = document.getElementById("sg-navbar-mobile")
    if (navBar) {
      navBar.classList.add("hide")
    }
  }
  return (
    <div className="one-order row">
      <div className="card col-lg-8 cursor-pointer" onClick={showDetailOrder}>
        <div className="info">
          <div className="wrap-name-address">
            {name && <div className="name mauden">{name}</div>}
            {address && <div className="address">{address}</div>}
          </div>
          <div className="d-flex wrap-date-status">
            {rentalDate && (
              <div className="border-right rental-date">{rentalDate}</div>
            )}
            {status && (
              <div className="status mauden">
                {icon && <i className={icon} style={{ color: iconColor }} />}
                <span>{status}</span>
              </div>
            )}
          </div>
        </div>
        <img alt="" src={img} className="car-avtimg" />
        {departureDate && (
          <div className="departure-date mauden">{departureDate}</div>
        )}
      </div>
      {blogs && blogs?.length > 0 && (
        <div className="col-lg-4 blogs">
          {blogs.map((b, j) => {
            const _link = b?.BlogUrl
            return (
              <div
                className={classNames("one-blog", _link && "cursor-pointer")}
                key={`blog-${j}`}
                title={b?.Title}
                onClick={() => {
                  if (_link) {
                    HLink.openUrl(_link, "_blank")
                  }
                }}
              >
                {b?.AvatarUrl && <img src={b?.AvatarUrl} alt="" />}
                <div className="title-blog mauden">{b?.Title}</div>
              </div>
            )
          })}
        </div>
      )}
      <MyUI
        type="sg_trip_detail"
        data={data}
        fnList={{ ...fnList, hideDetailOrder: hideDetailOrder }}
        canShow={canShow}
      />
    </div>
  )
}
const NowCard = props => {
  const { data, loading, fnList } = props
  const title = data?.[historyFields.fTitle]

  const getShowTitle = () => {
    const orders = data?.[historyFields.fOrders]
    if (orders && orders?.length > 0 && title) {
      return true
    }
    return false
  }
  const hideScrollContent = (v = false) => {
    const html: any = document.getElementsByTagName("html")
    const header = document.getElementById("navbar-category-v2")
    if (!html) {
      return
    }
    if (html[0]) {
      if (v === false) {
        html[0].style.overflowY = "scroll"
      } else {
        html[0].style.overflowY = "hidden"
      }
    }
    if (header) {
      if (v === false) {
        header?.classList.remove("only-logo")
      } else {
        header?.classList.add("only-logo")
      }
    }
  }
  const renderOthers = () => {
    const orders = data?.[historyFields.fOrders]
    if (orders && orders?.length > 0) {
      return (
        <WrapOrder className="orders">
          {orders.map((v, i) => {
            return (
              <>
                <OneOrderCard
                  data={v}
                  key={i}
                  fnList={{
                    ...fnList,
                    hideScrollContent: hideScrollContent,
                  }}
                />
              </>
            )
          })}
        </WrapOrder>
      )
    }
    return <></>
    // return <div className="empty-msg">Bạn chưa có chuyến đi</div>
  }
  if (loading) {
    return (
      <WrapLoading>
        <div className="title-loading space">
          <Skeleton />
        </div>
        <div className="one-order">
          <div className="card-loading">
            <Skeleton />
          </div>
          <div className="blogs-loading">
            <Skeleton />
          </div>
        </div>
      </WrapLoading>
    )
  }
  return (
    <Wrap
      className={classNames("mytrips_now", getShowTitle() ? "pd-10px" : "")}
    >
      {getShowTitle() && <div className="title space">{title}</div>}
      {renderOthers()}
    </Wrap>
  )
}
const WrapLoading = styled.div`
  margin-bottom: 20px;
  & .title-loading {
    width: 500px;
    height: 25px;
    margin-bottom: 30px;
    & > span {
      display: block;
      height: 100%;
      & > span {
        height: 100%;
      }
    }
  }
  & .one-order {
    display: flex;
    & .card-loading {
      width: 66.66667%;
      height: 240px;
      & > span {
        display: block;
        height: 100%;
        & > span {
          height: 100%;
        }
      }
    }
  }
  & .blogs-loading {
    margin-left: 20px;
    width: calc(33.333% - 20px);
    height: 240px;
    & > span {
      display: block;
      height: 100%;
      & > span {
        height: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    & .title-loading {
      width: 100%;
    }
    & .one-order {
      flex-direction: column;
      & .card-loading {
        width: 100%;
        height: 408px;
      }
    }
    & .blogs-loading {
      margin-left: 0;
      margin-top: 446px;
      width: 100%;
    }
  }
`
const Wrap = styled.div`
  &.pd-10px {
    padding-bottom: 10px;
  }
  &:last-child {
    padding-bototm: 0;
  }
`
const WrapOrder = styled.div`
  & .row {
    margin: 0;
  }
  & .one-order {
    display: flex;
    margin-bottom: 20px;
    & .card {
      box-shadow: 0px 4px 15px 0px #0000001a;
      border-radius: 10px;
      border: 1px solid var(--color-e7e7e7);
      flex-direction: row;
      padding: 0;
      height: fit-content;
      position: relative;
    }
    & .info {
      padding: 25px;
      width: 50%;
      & .name {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
      }
      & .address {
        color: var(--color-text-747171);
      }
      & .wrap-date-status {
        padding-top: 15px;
        height: 50%;
        & > div {
          width: 50%;
        }
        & .border-right {
          border-right: 1px solid var(--color-d9d9d9);
        }
        & .rental-date {
          padding-right: 10px;
          line-height: 26px;
        }
        & .status {
          padding-left: 10px;
          font-weight: 700;
          line-height: 26px;
          & i {
            display: flex;
            margin-bottom: 5px;
            font-size: 20px;
            width: 20px;
            height: 20px;
          }
          & span {
            text-decoration: underline;
          }
        }
      }
    }
    & .wrap-name-address {
      height: 50%;
      border-bottom: 1px solid var(--color-d9d9d9);
    }
    & img.car-avtimg {
      width: 50%;
      max-height: 240px;
      object-fit: cover;
      border-radius: 0 10px 10px 0;
    }
    & .departure-date {
      position: absolute;
      left: calc(50% + 15px);
      top: 15px;
      background-color: var(--color-text-fff);
      font-size: 12px;
      font-family: "Lexend", sans-serif;
      padding: 5px;
      border-radius: 5px;
      line-height: 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  & .blogs {
    display: grid;
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 10px;
    padding-left: 20px;
    padding-right: 0;
    & .one-blog {
      display: flex;
      & img {
        width: 89px;
        height: 66px;
        object-fit: cover;
        border-radius: 8px;
        margin-right: 8px;
      }
    }
    & .title-blog {
      width: calc(100% - 97px);
      font-size: 12px;
      font-weight: 600;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-height: 70px;
      line-height: 22px;
    }
  }
  @media (max-width: 991px) {
    & .blogs {
      display: none;
      margin-top: 20px;
      padding-left: 0px;
    }
  }
  @media (max-width: 767px) {
    & .one-order {
      & .card {
        flex-direction: column-reverse;
      }
      & .info {
        padding: 15px;
        width: 100%;
        & .wrap-date-status {
          padding-top: 15px;
          height: 50%;
        }
      }
      & .wrap-name-address {
        padding-bottom: 15px;
      }
      & img.car-avtimg {
        width: 100%;
        border-radius: 10px 10px 0 0;
      }
      & .departure-date {
        left: 15px;
      }
    }
    & .blogs {
      margin-top: 20px;
      grid-template-columns: repeat(1, minmax(0px, 1fr));
      display: none;
    }
  }
`
export default NowCard
