import React from "react"
import styled from "styled-components"

interface IProps {}
interface IState {
  isShow?: boolean
  opts?: IConfigModal
}
interface IConfigModal {}
class GlobalLockScreen extends React.Component<IProps, IState> {
  state: any = {
    isShow: true,
    opts: {},
  }
  componentDidMount() {
    GlobalLockScreen.Helpers.initComponent(this)
  }
  show = (opts: any) => {
    this.setState({
      isShow: true,
      opts: { ...opts },
    })
  }
  hide = () => {
    this.setState({
      isShow: false,
      opts: {},
    })
  }
  render() {
    const { isShow } = this.state
    if (isShow) {
      return <Wrap></Wrap>
    }
    return <></>
  }
  static Helpers = {
    initComponent: component => {
      if (component) {
        GlobalLockScreen.Helpers.component = component
      }
    },
    component: null as any,
  }
  static show = () => {
    if (GlobalLockScreen.Helpers.component) {
      GlobalLockScreen.Helpers.component.show()
    }
  }
  static hide = () => {
    if (GlobalLockScreen.Helpers.component) {
      GlobalLockScreen.Helpers.component.hide()
    }
  }
}
const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: transparent;
  animation: bganimation 15s linear infinite;
  @keyframes bganimation {
    0% {
      background-color: transparent;
    }
    25% {
      background-color: #ffffff66;
    }
    50% {
      background-color: #ffffff99;
    }
    75% {
      background-color: #ffffff66;
    }
    100% {
      background-color: transparent;
    }
  }
`
export default GlobalLockScreen
