import React, { lazy, Suspense } from "react"
import { Router, Switch, Route, Redirect } from "react-router-dom"
import { HLink, HConfig } from "src/core/helpers"
import { HCF } from "@macashipo/mlib"
import AuthenticatedGuard from "src/guards/AuthenticatedGuard"
import PageGeneric from "src/pages/PageGeneric/PageGeneric"
import Loading from "src/components/Loading/Loading"

const PageNotFoundv2 = lazy(() => import("src/pages/PageNotFound/demo2"))

export default function Routes() {
  const history = HLink.getHistory()
  const publicRoutes = HConfig.getPublicRoutes()
  const privateRoutes = HConfig.getPrivateRoutes()
  const configLogin = HCF.getCF("configLogin") || {}
  // console.warn("history:", history)
  return (
    <Router history={history}>
      <Switch>
        {/* <AuthenticatedGuard
          exact
          path={PATH.ACCOUNTSELECTION}
          component={
            props => {
              const configPage = {
                UIUrl: "/customer-accounts",
                UIType: "account_selection",
              }
              const configMainLayout = {
                isHideHeader: true,
                isHideFooter: true,
                isHideSideNav: true,
                isFull: true,
                ...props,
              }
              return (
                <MainLayout {...configMainLayout}>
                  <MyPage configPage={configPage} />
                </MainLayout>
              )
            }
            // (
            //   <MainLayout {...props}>{CSuppense(props, Home)}</MainLayout>
            // )
          }
        />
        <Route
          path={PATH.LOGIN}
          component={() => (
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          )}
        />
        <Route path={PATH.FORGOT} component={() => <Forgot />} />
        <Route
          path={PATH.LOGIN_AUTO_MEZYBROKER}
          component={() => <LoginAuto />}
        />
        {configLogin.showRegister !== false && (
          <Route path={PATH.REGISTER} component={() => <Register />} />
        )}
        <Route path={`${PATH.RESET}/:tokenkey`} component={() => <Reset />} /> */}
        {privateRoutes?.map((v, i) => {
          const configMainLayout = {
            ...v?.configMainLayout,
          }
          return (
            <AuthenticatedGuard
              key={i}
              path={v.UIUrl || ""}
              redirectTo={v.RedirectUrl}
              component={props => (
                <PageGeneric
                  {...props}
                  configPage={v}
                  configMainLayout={configMainLayout}
                />
              )}
            />
          )
        })}
        {publicRoutes?.map((v, i) => {
          const configMainLayout = {
            ...v?.configMainLayout,
          }
          return (
            <Route
              key={i}
              path={v.UIUrl || ""}
              render={props => {
                if (v.RedirectUrl) {
                  const searchParams = window.location.search
                  let newUrl = v.RedirectUrl
                  if (searchParams) {
                    newUrl += searchParams
                  }
                  return <Redirect to={newUrl} />
                }
                return (
                  <PageGeneric
                    {...props}
                    configPage={v}
                    configMainLayout={configMainLayout}
                  />
                )
              }}
            />
          )
        })}
        <Route
          key={"all"}
          path={"*"}
          exact={true}
          render={props => {
            return (
              <Suspense fallback={<Loading />}>
                <PageNotFoundv2 />
              </Suspense>
            )
          }}
        />
      </Switch>
    </Router>
  )
}
