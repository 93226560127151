import React from "react"
import { Input, InputGroup, InputGroupText } from "reactstrap"
import { MyIcon } from "src/core/components"
import Ext_Input from "./Ext_Input"
class InputPassword extends Ext_Input {
  state = {
    showPassword: false,
  }
  render() {
    const { showPassword } = this.state
    return (
      <InputGroup>
        <Input
          {...this.getPropsInput()}
          type={showPassword ? "text" : "password"}
        />
        <InputGroupText
          onClick={() => {
            this.setState({
              showPassword: !showPassword,
            })
          }}
        >
          <MyIcon
            data={showPassword ? "type:HiOutlineEye" : "type:HiOutlineEyeOff"}
          />
        </InputGroupText>
      </InputGroup>
    )
  }
}
export default InputPassword
