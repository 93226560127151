import { HFormat, HIs, HColor } from "@macashipo/mlib"
import HC from "./c"
import moment from "moment"
import Numberal from "src/libs/NumeralJs/numeral"

HFormat.Types["date"] = {
  format: (value: any, configFormat: any) => {
    // console.warn("format date:", value, configFormat)
    if (HIs.isDateValue(value)) {
      return moment(value).format(
        configFormat.format || HC.getDefaultFormatDate()
      )
    }
    return ""
  },
}
HFormat.Types["money"] = {
  format: (value: any) => {
    let _value = value
    _value = Numberal(value).format("0,0.00")

    return _value
  },
}

HFormat.Types["colorTextByBackground"] = {
  format: (value: any, configFormat: any) => {
    let _color = HColor.getTextWB(value, configFormat)
    return _color
  },
}
export default HFormat
