import React, { useEffect, useState } from "react"
import classnames from "classnames"
import styled from "styled-components"
import MyLoadingPage from "../../MyLoading"
import Skeleton from "react-loading-skeleton"

interface IProps {
  className?: string
  classNameEmpty?: string
  isLoading?: boolean
  keepDataLoading?: boolean
  isEmpty?: boolean
  isError?: boolean
  msgEmpty?: string
  msgError?: string
  children?: any
  renderLoadMore?: any
  customLoading?: any
  moreConfig?: any
}
const MyUIWLoadingSkeleton = (props: IProps) => {
  const {
    className,
    classNameEmpty,
    isLoading,
    keepDataLoading,
    isEmpty,
    isError,
    msgEmpty,
    msgError,
    children,
    renderLoadMore,
    customLoading,
    moreConfig,
  } = props
  const { isSearching, showError } = moreConfig || {}
  const renderLoading = () => {
    if (isLoading) {
      if (customLoading) {
        return customLoading()
      }
      return (
        <Loading>
          <MyLoadingPage />
        </Loading>
      )
    }
  }
  const renderEmpty = () => {
    if (!isLoading && isEmpty && msgEmpty) {
      return (
        <Message className="r-card">{msgEmpty || "Không có dữ liệu"}</Message>
      )
    }
  }
  const renderError = () => {
    if (!isLoading && isError && showError) {
      return <Message>Có lỗi xảy ra! Vui lòng thử lại.</Message>
    }
  }
  const renderMain = () => {
    if (!isLoading || keepDataLoading) {
      return children
    }
  }

  return (
    <Wrap
      className={classnames(
        "myui-loading-skeleton",
        className,
        isEmpty ? classNameEmpty : ""
      )}
    >
      {renderMain()}
      {renderEmpty()}
      {renderError()}
      {renderLoading()}
    </Wrap>
  )
}
export const Wrap = styled.div``
export const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`
export const Loading = styled.div`
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export default MyUIWLoadingSkeleton
