import React from "react"
import styled from "styled-components"
import classNames from "classnames"

interface IProps {
  id: string
  classNameWrap?: any
  renderMore?: any
  idHeader?: any
  className?: any
  style?: any
  type: string
}

interface IState {
  isShow?: boolean
}
class MWEaseLayout extends React.Component<IProps, IState> {
  state: any = {
    isShow: false,
    opts: {},
  }
  componentDidMount() {
    const { type } = this.props
    MWEaseLayout.Helpers.initComponent(type, this)
  }
  show = (opts: any) => {
    this.setState(
      {
        isShow: true,
      },
      () => {
        this.hideScrollContent(true)
        window.document.body.classList.add("modal-custom-style")
        const navBar = document.getElementById("sg-navbar-mobile")
        if (navBar) {
          navBar.classList.add("hide")
        }
      }
    )
  }
  hide = ({ skipHideScrollContent = false }: any = {}) => {
    this.setState(
      {
        isShow: false,
      },
      () => {
        if (skipHideScrollContent !== true) {
          this.hideScrollContent(false)
          window.document.body.classList.remove("modal-custom-style")
          const navBar = document.getElementById("sg-navbar-mobile")
          if (navBar) {
            navBar.classList.remove("hide")
          }
        }
      }
    )
  }
  hideScrollContent = (v = false) => {
    const { idHeader } = this.props
    const html: any = document.getElementsByTagName("html")
    const header = document.getElementById(idHeader)
    if (!html) {
      return
    }
    if (html[0]) {
      if (v === false) {
        html[0].style.overflowY = "scroll"
      } else {
        html[0].style.overflowY = "hidden"
      }
    }
    if (header) {
      if (v === false) {
        header?.classList.remove("only-logo")
      } else {
        header?.classList.add("only-logo")
      }
    }
  }
  render() {
    const { children, id, classNameWrap, renderMore } = this.props
    const { isShow } = this.state
    return (
      <Wrap
        className={classNames(
          "wrap-content-overlay overlay",
          isShow ? "show" : "",
          classNameWrap ? classNameWrap : ""
        )}
        id={id}
      >
        {isShow && (
          <>
            {children}
            {renderMore && renderMore()}
          </>
        )}
      </Wrap>
    )
  }
  static Helpers = {
    types: {
      component: "component",
    },
    initComponent: (type, component) => {
      if (type && component) {
        MWEaseLayout.Helpers.components[type] = component
      }
      if (!type) {
        MWEaseLayout.Helpers.components["component"] = component
      }
    },
    components: {},
  }
  static open = () => {
    MWEaseLayout.Helpers.components["component"].show()
  }
  static close = (opts: any = {}) => {
    MWEaseLayout.Helpers.components["component"].hide({ ...opts })
  }
  static openWithType = type => {
    if (MWEaseLayout.Helpers.components[type]) {
      MWEaseLayout.Helpers.components[type].show()
    }
  }
  static closeWithType = (type, opts: any = {}) => {
    if (MWEaseLayout.Helpers.components[type]) {
      MWEaseLayout.Helpers.components[type].hide({
        ...opts,
      })
    }
  }
}
const Wrap = styled.div`
  &.overlay {
    height: 100%;
    position: fixed;
    z-index: 11;
    top: 65px;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.7s;
    padding: 0;
    opacity: 1;
    left: -100%;
    width: 100%;
    &.header-no-search {
      top: 60px;
    }
    &.wrap-order-detail {
      top: 60px;
      padding-top: 0;
      background: #f8f8f8;
    }
  }
  &.show {
    width: 100%;
    padding-top: 30px;
    opacity: 1;
    left: 0;
    padding-bottom: 70px;
  }
  & .hide {
    display: none;
  }
  @media (max-width: 820px) {
    &.overlay {
      top: 0px !important;
    }
    &.show {
      padding-top: 15px;
    }
  }
`
export default MWEaseLayout
