import classnames from "classnames"
import { MyUI } from "src/core/components"
import styled from "styled-components"

const BlogSuggestion = props => {
  const { data, moreConfig } = props
  const _ui_type =
    moreConfig?.default_ui_type ||
    moreConfig?.default_ui_type_mobile ||
    "slide_card"
  return (
    <Wrap className="blog-suggestion">
      <div className="subtitle mb-30px">
        Khám phá những điều thú vị tại những nơi mà bạn sắp đến trong chuyến đi
        của mình
      </div>
      <MyUI
        type={_ui_type}
        config={{
          cardType: "order_detail_blog",
          use_data: true,
          ...moreConfig,
        }}
        classNameWrap="wrap-home-slide"
        data={data}
        className={classnames("custom-wrap-slide")}
      />
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  & .owl-carousel {
    & .owl-stage-outer {
      overflow: -webkit-paged-x;
      position: relative;
    }
  }
  & .lick-slider {
    overflow: -webkit-paged-x;
    position: relative;
  }
`
export default BlogSuggestion
