import { MyFormControlHelpers } from "@macashipo/mlib"
import { FlowGetConfigFormFromConfigServer } from "src/helpers/Flows/Form"
// import MyComponentHelpers from "../MyCore/MyComponentHelpers"
// import MyFormControlTypes from "./MyFormControlTypes"
// const MyFormControlHelpers = {
//   getTypeFn(props: any) {
//     // console.warn("MyFormControlTypes:", MyFormControlTypes.Types, props)
//     return MyComponentHelpers.getTypeFn(MyFormControlTypes.Types, props)
//   },
// }
/**
Disabled: null
FieldName: "DisplayName"
Hint: null
Label: "Full Name"
Props: null
Required: null
Source: null
SourceField: null
SourceList: null
Style: null
SubmitAfterChange: null
Type: "text"
 */
// MyFormControlHelpers["getConfigFormFromConfigV1"] = function (configV1: any) {
//   let _controls = []
//   if (configV1.Data && configV1.Data.length > 0) {
//     _controls = configV1.Data.map((v, i) => {
//       return {
//         label: v.Label,
//         fieldName: v.FieldName,
//         type: v.Type,
//         more: {
//           hint: v.Hint,
//           optionKey: v.SourceField,
//         },
//       }
//     })
//   }
//   return {
//     title: configV1.Title,
//     closeMyModalAfterSubmit: true,
//     defaultValues: {},
//     controls: _controls,
//   }
// }
MyFormControlHelpers["getConfigFormFromConfigV1"] = function (
  configV1: any,
  { defaultValues, fnList } = { defaultValues: {}, fnList: {} }
) {
  return FlowGetConfigFormFromConfigServer(configV1, {
    defaultValues,
    fnList,
    moreDefaultValues: null,
  })
}
export default MyFormControlHelpers
