import React from "react"
import { Input } from "reactstrap"
import { HOptions } from "src/core/helpers"
import Ext_Select from "./Ext_Select"
class InputSelect extends Ext_Select {
  render() {
    const onUpdateValue = this.getOnUpdateValue()
    const sourceData = this.getSourceData()
    return (
      <Input
        {...this.props}
        type="select"
        onBlur={() => {}}
        onChange={ev => {
          if (onUpdateValue) {
            onUpdateValue(ev.currentTarget.value)
          }
        }}
      >
        {HOptions.getListSelectTagBySourceData(sourceData)}
      </Input>
    )
  }
}

//value: string
//defaultValue: string
//onChange: value = string

export default InputSelect
