import styled from "styled-components"
import { historyFields } from "src/modules/app_sigo/constants/fields"
import { MyIcon } from "@macashipo/mlib"
import { MyModal } from "src/core/components"

const InsuranceInfo = props => {
  const { data } = props
  const insuranceInfo = data?.[historyFields.fDetailInsuranceInfo]
  const icon = insuranceInfo?.[historyFields.fDetailInsuranceIcon]
  const description = insuranceInfo?.[historyFields.fDetailInsuranceDescription]
  const title = insuranceInfo?.[historyFields.fDetailInsuranceTitle]
  const content = insuranceInfo?.[historyFields.fDetailInsuranceContent]
  const url = insuranceInfo?.[historyFields.fDetailInsuranceUrl]
  return (
    <Wrap className="insurance-info">
      {icon && (
        <div className="i-icon">
          <MyIcon data={`type:${icon}`} />
        </div>
      )}
      <div className="i-title">{title}</div>
      <div className="i-des">{description}</div>
      {content && (
        <div
          className="i-more"
          onClick={() => {
            MyModal.showFromComponent(
              <>
                {content && (
                  <div dangerouslySetInnerHTML={{ __html: content }}></div>
                )}
              </>,
              {
                title: title,
                modalClassName: "default modal-filter",
                modalScrollInside: true,
                onScrollContent: true,
              }
            )
          }}
        >
          Tìm hiểu thêm
        </div>
      )}
      {url && (
        <div className="i-file devitem-line">
          <a href={url} target="_blank" rel="noreferrer">
            <i className="flaticon-addition" />
            <div className="flex-1">File hợp đồng</div>
            <i className="flaticon-right-arrow"></i>
          </a>
        </div>
      )}
    </Wrap>
  )
}
const Wrap = styled.div`
  & .i-title {
    font-size: 20px;
    font-weight: 600;
    margin-top: 2rem;
    color: var(--mauden);
  }
  & .i-des {
    margin-top: 0.5rem;
  }
  & .i-icon {
    & svg {
      height: 73px;
      width: 73px;
    }
  }
  & .i-more {
    margin-top: 0.5rem;
    color: var(--mauden);
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }
  & .i-file {
    & a {
      color: var(--mauden);
      width: 100%;
      display: flex;
      align-items: center;
      & i {
        &:first-child {
          font-size: 20px;
          display: flex;
          color: #535151;
          margin-right: 10px;
        }
      }
    }
    & .flex-1 {
      flex: 1;
    }
  }
`
export default InsuranceInfo
