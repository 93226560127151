import { MyLoadingHelpers, CSuppense } from "@macashipo/mlib"

import React from "react"
const CCircle = React.lazy(
  () => import("src/core/components/MyLoading/Types/circle")
)
const CArc = React.lazy(() => import("src/core/components/MyLoading/Types/arc"))
const CEllipsis = React.lazy(
  () => import("src/core/components/MyLoading/Types/ellipsis")
)
const MyLoadingOne = {
  init() {
    MyLoadingHelpers.addTypes("circle", props => {
      return CSuppense(props, CCircle)
    })
    MyLoadingHelpers.addTypes("arc", props => {
      return CSuppense(props, CArc)
    })
    MyLoadingHelpers.addTypes("ellipsis", props => {
      return CSuppense(props, CEllipsis)
    })
    MyLoadingHelpers.addTypes("in_card", props => {
      return <div className="m-3 p-3 text-center">{CSuppense(props, CArc)}</div>
    })
    MyLoadingHelpers.Types["_defaultlazy"] = (props: any) => {
      return (
        <div>
          <i
            key="iconloading"
            className={`fa fa-spinner fa-spin ${
              props.className != null ? props.className : ""
            }`}
            style={props.style}
          />
        </div>
      )
    }
  },
}

console.warn(
  "MyLoadingHelpers",
  MyLoadingHelpers,
  MyLoadingHelpers.Types["_defaultLazy"]
)
export default MyLoadingOne
