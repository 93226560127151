import { HUtils } from "@macashipo/mlib"
import classNames from "classnames"
import { isNotEmpty } from "src/core/helpers/utils"
import { historyFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"
import { UITypes } from "./UITypes"

const OwnerInfo = props => {
  const { data, fnList } = props
  const ownerInfo = data?.[historyFields.fAnotherUserInfo] || {}
  const statusCode = data?.[historyFields.fOrderStatusCode]
  const name = HUtils.get(ownerInfo, historyFields.fAnotherUsername)
  const avt = HUtils.get(ownerInfo, historyFields.fAnotherUserAvatarUrl)
  const phoneNumber = HUtils.get(ownerInfo, historyFields.fAnotherUserPhone)
  const description = HUtils.get(
    ownerInfo,
    historyFields.fAnotherUserDescription
  )
  const getPartsConfig = () => {
    return [
      {
        type: "call_with_icon",
        canShow:
          statusCode && statusCode !== "DONE" && phoneNumber ? true : false,
        classWrap: "devitem-line",
        title: "Gọi cho chủ xe",
        mobileNumber: phoneNumber,
        icon: "flaticon-telephone",
      },
    ]
  }
  const renderPart = () => {
    const parts = getPartsConfig()
    return (
      <>
        {parts &&
          parts?.length > 0 &&
          parts.map((v, i) => {
            if (!isNotEmpty(v?.canShow) || v?.canShow) {
              return (
                <div
                  className={classNames(
                    "mb-15px",
                    v?.classWrap ? v?.classWrap : ""
                  )}
                  key={`part-${i}`}
                >
                  {UITypes?.[v.type]
                    ? UITypes[v.type](data, v, {
                        fnList: {
                          ...fnList,
                        },
                      })
                    : null}
                </div>
              )
            }
            return <></>
          })}
      </>
    )
  }
  return (
    <Wrap className="owner-info">
      <div className="wrap-avt">
        <span className="mauden">Chủ xe: {name}</span>
        <img src={avt} alt="" />
      </div>
      {description && (
        <div className="description">
          <div className="label">Giới thiệu về chủ xe</div>
          <div>{description}</div>
        </div>
      )}
      {renderPart()}
    </Wrap>
  )
}
const Wrap = styled.div`
  & .wrap-avt {
    display: flex;
    justify-content: space-between;
    & > span {
      font-size: 20px;
      font-weight: bold;
      width: calc(100% - 63px);
      padding-right: 10px;
    }
    img {
      width: 63px;
      height: 63px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  & .label {
    font-weight: 600;
    color: var(--mauden);
  }
`
export default OwnerInfo
