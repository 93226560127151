import { HLink, HUtils } from "@macashipo/mlib"
import classNames from "classnames"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const HomePromotionCard = props => {
  const { data } = props

  const _url = HUtils.get(data, `${homeSlidesFields.fOneSlideMoreInfo}.url`)
  const img = data?.[homeSlidesFields.fOneSlideImageUrl]
  const title = data?.[homeSlidesFields.fOneSlideTitle]
  const onHandleClick = () => {
    if (_url) {
      HLink.openUrl(_url, "_blank")
    }
  }
  return (
    <Wrap
      onClick={onHandleClick}
      className={classNames(_url && "cursor-pointer")}
    >
      <img className="lazy" src={img} data-original={img} alt={title} />
    </Wrap>
  )
}
const Wrap = styled.div`
  &.cursor-pointer {
    cursor: pointer;
  }
  & img {
    object-fit: cover;
  }
`
export default HomePromotionCard
