import React from "react"
const MC_ic_self_driving = props => {
  const { fill, style } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="113"
      height="113"
      viewBox="0 0 113 113"
      fill="none"
    >
      <path
        d="M22.459 87.7736H13.5333C11.1303 87.7736 9.18234 89.7216 9.18234 92.1246V103.405C9.18234 105.808 11.1303 107.756 13.5333 107.756H22.459C24.8619 107.756 26.8099 105.808 26.8099 103.405V92.1246C26.8099 89.7216 24.8619 87.7736 22.459 87.7736Z"
        fill={fill || "#323E66"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.26621 65.4493H20.3279V61.8223H15.916L15.9167 59.7801C15.9171 58.8468 15.5843 58.0436 14.9244 57.3837C14.2643 56.724 13.4609 56.3914 12.5276 56.3921L6.26312 56.3967C4.15011 56.3983 2.42752 58.122 2.42752 60.2352V61.6111C2.42752 63.7252 4.15187 65.4495 6.26599 65.4495L6.26621 65.4493Z"
        fill={fill || "#323E66"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M106.734 65.4493H92.6721V61.8223H97.084L97.0833 59.7801C97.0829 58.8468 97.4157 58.0436 98.0756 57.3837C98.7357 56.724 99.5391 56.3914 100.472 56.3921L106.737 56.3967C108.85 56.3983 110.573 58.122 110.573 60.2352V61.6111C110.573 63.7252 108.848 65.4495 106.734 65.4495L106.734 65.4493Z"
        fill={fill || "#323E66"}
      />
      <path
        d="M99.4667 87.7736H90.541C88.1381 87.7736 86.1901 89.7216 86.1901 92.1246V103.405C86.1901 105.808 88.1381 107.756 90.541 107.756H99.4667C101.87 107.756 103.818 105.808 103.818 103.405V92.1246C103.818 89.7216 101.87 87.7736 99.4667 87.7736Z"
        fill={fill || "#323E66"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M106.979 94.911V76.7164C106.979 73.6032 105.457 70.9296 102.78 69.3396L100.028 67.7049C97.8715 66.4239 96.2811 64.8761 95.027 62.7038L94.5181 61.8223H18.4821L17.9732 62.7038C16.7189 64.8761 15.1285 66.4239 12.9721 67.7049L10.2199 69.3396C7.5432 70.9294 6.02145 73.6032 6.02145 76.7164V94.911C6.02145 96.6519 7.44146 98.0719 9.18258 98.0719H103.818C105.559 98.0719 106.979 96.6519 106.979 94.911Z"
        fill="#0E82F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M106.979 94.911V76.7164C106.979 73.6032 105.457 70.9296 102.78 69.3396L100.028 67.7049C97.8715 66.4239 96.2811 64.8761 95.027 62.7038L94.5181 61.8223H56.5V98.0719H103.818C105.559 98.0719 106.979 96.6519 106.979 94.911Z"
        fill="#2A92FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M97.1003 65.4493C96.314 64.6523 95.6313 63.7505 95.0268 62.7037C92.3936 58.1431 89.7602 53.5819 87.1272 49.021C86.4532 47.854 85.492 47.0007 84.3247 46.5147C81.3161 45.2616 69.2026 44.6198 56.4998 44.5507C43.797 44.6195 31.6835 45.2616 28.6749 46.5147C27.5076 47.0007 26.5464 47.8537 25.8724 49.021C23.2394 53.5819 20.6059 58.1429 17.9727 62.7037C17.3682 63.7508 16.6856 64.6526 15.8992 65.4493H97.1003Z"
        fill="#70C1F9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4521 65.4493H94.5479L87.1274 52.5964C86.4534 51.4293 85.4922 50.5761 84.3249 50.0901C81.3163 48.837 69.2028 48.1951 56.5 48.1261C43.7972 48.1949 31.6837 48.837 28.6751 50.0901C27.5078 50.5761 26.5466 51.4291 25.8726 52.5964L18.4521 65.4493Z"
        fill="#AADBFB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M97.1003 65.4493C96.314 64.6523 95.6313 63.7505 95.0268 62.7037C92.3936 58.1431 89.7602 53.5819 87.1272 49.021C86.4532 47.854 85.492 47.0007 84.3247 46.5147C81.3161 45.2616 69.2026 44.6198 56.4998 44.5507V65.4493H97.1001H97.1003Z"
        fill="#AADBFB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.5 48.1261V65.4493H94.5479L87.1274 52.5964C86.4534 51.4293 85.4922 50.5761 84.3249 50.0901C81.3163 48.837 69.2028 48.1951 56.5 48.1261Z"
        fill="#C8E8FB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.7747 79.1046L42.6701 86.1786C42.8846 86.9793 43.5858 87.5174 44.415 87.5174H68.5851C69.4142 87.5174 70.1154 86.9793 70.3299 86.1786L72.2253 79.1046C72.3756 78.544 72.2668 77.998 71.9137 77.5376C71.5606 77.0772 71.0609 76.8309 70.4807 76.8309H42.5196C41.9393 76.8309 41.4397 77.0772 41.0866 77.5376C40.7334 77.998 40.6246 78.5443 40.7749 79.1046H40.7747Z"
        fill="#004CBD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.5 87.5174H68.585C69.4142 87.5174 70.1154 86.9793 70.3299 86.1786L72.2253 79.1046C72.3756 78.544 72.2668 77.998 71.9137 77.5376C71.5606 77.0772 71.0609 76.8309 70.4807 76.8309H56.5002V87.5174H56.5Z"
        fill="#0062D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.3015 81.0706H71.6985L71.107 83.2777H41.893L41.3015 81.0706Z"
        fill="#003EA8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9634 94.0787C12.3541 94.0787 11.8599 93.5845 11.8599 92.9752C11.8599 92.3658 12.3541 91.8716 12.9634 91.8716H33.5303C34.1397 91.8716 34.6338 92.3658 34.6338 92.9752C34.6338 93.5845 34.1397 94.0787 33.5303 94.0787H12.9634Z"
        fill="#003EA8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M79.4697 94.0787C78.8603 94.0787 78.3662 93.5845 78.3662 92.9752C78.3662 92.3658 78.8603 91.8716 79.4697 91.8716H100.037C100.646 91.8716 101.14 92.3658 101.14 92.9752C101.14 93.5845 100.646 94.0787 100.037 94.0787H79.4697Z"
        fill="#003EA8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.5851 94.0787C43.9758 94.0787 43.4816 93.5845 43.4816 92.9752C43.4816 92.3658 43.9758 91.8716 44.5851 91.8716H68.4149C69.0243 91.8716 69.5184 92.3658 69.5184 92.9752C69.5184 93.5845 69.0243 94.0787 68.4149 94.0787H44.5851Z"
        fill="#003EA8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0509 75.4547L32.2149 78.1578C32.9362 78.2715 33.4897 78.7694 33.6786 79.4745L34.7733 83.5602C34.8711 83.9255 34.7927 84.283 34.5513 84.5737C34.3098 84.8645 33.9726 85.0071 33.5959 84.978L14.6304 83.511C13.6873 83.4379 12.9634 82.656 12.9634 81.71V77.2388C12.9634 76.6919 13.1804 76.2212 13.596 75.8658C14.0118 75.5105 14.5106 75.3695 15.0509 75.4547Z"
        fill="#E9E9FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.8892 83.9122C25.7479 83.9122 27.2641 82.3962 27.2641 80.5374C27.2641 78.6787 25.7481 77.1624 23.8892 77.1624C22.0302 77.1624 20.5142 78.6785 20.5142 80.5374C20.5142 82.3964 22.0302 83.9122 23.8892 83.9122Z"
        fill="#FDC72E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.4581 77.2106C26.0511 77.4809 27.2639 78.8674 27.2639 80.5375C27.2639 82.2075 26.0509 83.5937 24.4581 83.8643C22.8651 83.594 21.6523 82.2075 21.6523 80.5375C21.6523 78.8674 22.8653 77.4812 24.4581 77.2106Z"
        fill="#FDDB1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M97.9492 75.4547L80.7849 78.1578C80.0636 78.2715 79.5101 78.7694 79.3212 79.4745L78.2265 83.5602C78.1287 83.9255 78.2071 84.283 78.4485 84.5737C78.69 84.8645 79.0272 85.0071 79.4039 84.978L98.3696 83.511C99.3127 83.4379 100.037 82.656 100.037 81.71V77.2388C100.037 76.6919 99.8196 76.2212 99.404 75.8658C98.9882 75.5105 98.4897 75.3695 97.9494 75.4547H97.9492Z"
        fill="#E9E9FF"
      />
      <path
        d="M89.9241 83.7716C91.7377 83.341 92.8589 81.5217 92.4283 79.7081C91.9977 77.8945 90.1785 76.7733 88.3648 77.2039C86.5512 77.6344 85.43 79.4537 85.8606 81.2674C86.2912 83.081 88.1105 84.2022 89.9241 83.7716Z"
        fill="#FDC72E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M88.5419 77.2106C86.9489 77.4809 85.7361 78.8674 85.7361 80.5375C85.7361 82.2075 86.9491 83.5937 88.5419 83.8643C90.1349 83.594 91.3477 82.2075 91.3477 80.5375C91.3477 78.8674 90.1347 77.4812 88.5419 77.2106Z"
        fill="#FDDB1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.0898 27.2683L56.0155 20.2974L53.7931 20.7596L26.9189 5.24368L20.1727 8.32249L20.8796 15.7043L25.5976 18.4283L30.3122 15.3603L30.0125 20.9772L32.1977 22.2389L36.9124 19.1709L36.6127 24.7878L47.7537 31.22L48.4646 33.376L60.5389 40.347L68.0898 27.2683Z"
        fill="#A3A3CC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.0898 27.2683L56.0155 20.2974L53.7931 20.7596L26.9189 5.24368L20.1727 8.32249L20.5861 12.6401L25.7474 15.6198L30.4621 12.5518L30.1624 18.1687L32.3476 19.4305L37.0622 16.3625L36.7625 21.9794L47.9034 28.4116L48.6145 30.5676L61.7925 38.1757L68.0898 27.2683Z"
        fill="#BCBEE2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.4513 21.5755L90.6609 32.666C92.728 33.8594 93.4428 36.5272 92.2495 38.5941L82.4275 55.6061C81.234 57.6733 78.5663 58.3881 76.4992 57.1945L57.2897 46.104C55.2226 44.9107 54.5077 42.2428 55.7013 40.1759L65.5232 23.1639C66.7166 21.0968 69.3842 20.3819 71.4513 21.5755Z"
        fill="#5D689E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.4513 21.5755L90.6609 32.6661C91.1583 32.9532 91.577 33.3257 91.9101 33.7548C91.8275 34.2522 91.6565 34.7426 91.3905 35.2035L81.5686 52.2155C80.375 54.2826 77.7074 54.9975 75.6403 53.8039L57.4393 43.2956C56.4237 42.7091 55.7348 41.7667 55.438 40.7147C55.5124 40.5317 55.5995 40.3514 55.7008 40.1757L65.5228 23.1637C66.7161 21.0966 69.3838 20.3817 71.4509 21.5753L71.4513 21.5755Z"
        fill="#6A75AD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.2331 32.4941L85.844 35.1562C86.4042 35.4796 86.5979 36.2026 86.2746 36.763L79.379 48.7065C79.0554 49.2669 78.3324 49.4607 77.7722 49.1371L73.1613 46.475C72.601 46.1515 72.4074 45.4284 72.7307 44.8683L79.6264 32.9247C79.9499 32.3646 80.6729 32.1708 81.2331 32.4943V32.4941Z"
        fill="#5D689E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.4706 33.8148L85.0815 36.4769C85.6419 36.8003 85.8354 37.5233 85.5121 38.0836L78.6165 50.0272C78.2929 50.5876 77.5699 50.7814 77.0097 50.4578L72.3988 47.7957C71.8384 47.4724 71.6449 46.7493 71.9682 46.189L78.8639 34.2454C79.1874 33.6853 79.9104 33.4913 80.4706 33.8148Z"
        fill="#E9E9FF"
      />
    </svg>
  )
}
export default MC_ic_self_driving
