import React from "react"
import { MyControlHelpers } from "@macashipo/mlib"
const MCInputAddress = React.lazy(() => import("./Types/input_address"))
const MCRadioList = React.lazy(() => import("./Types/list_radio"))
const MCCheckboxList = React.lazy(() => import("./Types/list_checkbox"))
const MCInputRange = React.lazy(() => import("./Types/input_range"))
const MCInputSelect = React.lazy(() => import("./Types/input_select"))
const MCInputDatetime = React.lazy(() => import("./Types/input_datetime"))
const MCNiceSelect = React.lazy(() => import("./Types/nice_select"))
const MCInputRate = React.lazy(() => import("./Types/input_rate"))
const MCButtonModalForm = React.lazy(() => import("./Types/button_modal_form"))
const MCInputDate = React.lazy(() => import("./Types/input_date"))
const MCSelectCustom = React.lazy(() => import("./Types/select_custom"))
const MCInputText = React.lazy(() => import("./Types/input_text"))
const MCInputPassword = React.lazy(() => import("./Types/input_password"))
const MCInputDateString = React.lazy(() => import("./Types/input_date_string"))
const MCInputCurrency = React.lazy(() => import("./Types/input_currency"))
const MCInputPhoneNumber = React.lazy(
  () => import("./Types/input_phone_number")
)
const MCInputPasswordv2 = React.lazy(() => import("./Types/input_password_v2"))
const MCInputDateFromTo = React.lazy(() => import("./Types/input_date_from_to"))
const MCInputDateFromToV2 = React.lazy(
  () => import("./Types/input_date_from_to_v2")
)
const MCButtonSelectAddress = React.lazy(
  () => import("./Types/button_select_address")
)
const MCCheckWithIcon = React.lazy(() => import("./Types/check_with_icon"))
const MCInputDualRange = React.lazy(() => import("./Types/input_dual_range"))
const MCSelect2CustomFilter = React.lazy(
  () => import("./Types/select2_custom_filter")
)
const MCInputDateTimeV2 = React.lazy(() => import("./Types/input_datetime_v2"))
const MCInputAddressV2 = React.lazy(() => import("./Types/input_address_v2"))
const MCInputDateFromToDetail = React.lazy(
  () => import("./Types/input_date_from_to_detail")
)
const MCInputDateFromToSearch = React.lazy(
  () => import("./Types/input_date_from_to_search")
)
const MCInputTimeFromToSearch = React.lazy(
  () => import("./Types/input_time_from_to_search")
)
const List = {
  input_address: MCInputAddress,
  input_range: MCInputRange,
  list_radio: MCRadioList,
  list_checkbox: MCCheckboxList,
  input_select: MCInputSelect,
  input_datetime: MCInputDatetime,
  nice_select: MCNiceSelect,
  input_rate: MCInputRate,
  button_modal_form: MCButtonModalForm,
  input_date: MCInputDate,
  select_custom: MCSelectCustom,
  input_text: MCInputText,
  input_password: MCInputPassword,
  input_date_string: MCInputDateString,
  input_currency: MCInputCurrency,
  input_phone_number: MCInputPhoneNumber,
  input_password_v2: MCInputPasswordv2,
  input_date_from_to: MCInputDateFromTo,
  input_date_from_to_v2: MCInputDateFromToV2,
  button_select_address: MCButtonSelectAddress,
  check_with_icon: MCCheckWithIcon,
  input_dual_range: MCInputDualRange,
  select2_custom_filter: MCSelect2CustomFilter,
  input_datetime_v2: MCInputDateTimeV2,
  input_address_v2: MCInputAddressV2,
  input_date_from_to_detail: MCInputDateFromToDetail,
  input_date_from_to_search: MCInputDateFromToSearch,
  input_time_from_to_search: MCInputTimeFromToSearch,
}
const MyControlOne = {
  init() {
    MyControlHelpers.addTypesFromListLazy(List)
  },
}

export default MyControlOne
