import React from "react"
import styled from "styled-components"
import { historyFields } from "src/modules/app_sigo/constants/fields"
import { MyUI } from "src/core/components"
import { HUtils } from "@macashipo/mlib"
const MessageAboveActions = props => {
  const { data, fnList } = props
  const headerInfo = data?.[historyFields.fHeaderInfo]
  const title = headerInfo?.Title
  const endtime = headerInfo?.[historyFields.fEndTime]
  const content = headerInfo?.Content
  const url = headerInfo?.Url
  const canShow = headerInfo?.CanShow
  const getConfigMsgFromFirstSetting = () => {
    if (fnList && fnList?.getConfigFromFirstSettingByKey) {
      return fnList?.getConfigFromFirstSettingByKey("messageAboveActions")
    }
    return
  }
  const getText = () => {
    const list = getConfigMsgFromFirstSetting() || {}
    const statusCode = data?.[historyFields.fOrderStatusCode]
    let _text = list?.[statusCode] || ""
    const matches = _text.match(/\[(.*?)\]/)
    if (matches && matches?.length > 0) {
      const beforeBracket = _text.split("[")[0]
      const afterBracket = _text.split("]")[1]
      return {
        before: beforeBracket,
        after: afterBracket,
      }
    }
    return {}
  }
  const renderMessageAbove = () => {
    const text = getText()
    const footerInfo = data?.[historyFields.fFooterInfo]
    if (endtime && endtime > new Date().getTime()) {
      return (
        <span>
          {text?.before}
          <MyUI
            type="countdown_time"
            endTime={endtime}
            moreConfig={{
              delay: 1,
            }}
            fnList={{
              onFinishCountdown: () => {
                fnList?.onReload && fnList.onReload()
              },
            }}
          />
          {text?.after}
        </span>
      )
    } else if (footerInfo?.Title) {
      return <span className="w-footer-msg">{footerInfo?.Title}</span>
    }
    return <></>
  }
  return <Wrap className={`w-msg-above-actions`}>{renderMessageAbove()}</Wrap>
}
const Wrap = styled.div`
  text-align: center;
  color: var(--mauden);
  &.pb-15px {
    padding-bottom: 15px;
  }
  & .mui-countdown-time {
    padding-top: 0px;
    display: inline-block;
    & .countdown {
      display: inline-block;
      font-size: 16px;
      & .red {
        color: var(--mauden);
      }
    }
    & .title {
      display: none;
    }
  }
  & .h-header,
  & .h-content {
    text-align: center;
  }
`
export default MessageAboveActions
