import {
  HLink,
  HLocalStorage,
  MyControl,
  MyUI,
  OneButton,
} from "@macashipo/mlib"
import classnames from "classnames"
import React, { useRef } from "react"
import { MyForm, MyModal } from "src/core/components"
import { HApi } from "src/core/helpers"
import { isArray, isNotEmpty } from "src/core/helpers/utils"
import {
  categoryFields,
  historyFields,
} from "src/modules/app_sigo/constants/fields"
import { useForceUpdate } from "src/modules/app_sigo/hooks/useForceUpdate"
import Dropzone from "react-dropzone"
import styled from "styled-components"
import { showAlert } from "src/modules/app_sigo/helpers/ui"
import NoImage from "src/modules/app_sigo/assets/imgs/no-image.jpg"
import { kObjCategoryLocal } from "src/modules/app_sigo/constants"
import MWEaseLayout from "../WEaseLayout"
import { WrapPartWithIcon } from "./UITypes"
/** Component */
const ReviewModal = props => {
  const { onSubmit, fnList } = props
  const [data, setData] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [isEdited, setIsEdited] = React.useState(true)
  const [quickReview, setQuickReview] = React.useState([] as any)
  const [keyReview, forceUpdateReview] = useForceUpdate()
  const refReviewForm = React.useRef<MyForm>(null)

  const getQuickReview = ({ values }: any) => {
    let _arr = [...quickReview]
    let _tmp: any = []
    if (values && values?.length > 0) {
      if (!isEdited) {
        values.forEach(v => {
          if (!_arr.find(k => k === v)) {
            _tmp.push(v)
            _arr.push(v)
          }
        })
      } else {
        _tmp = [...values]
      }

      _arr = [...values]
    }
    setQuickReview(_arr)
    return _tmp
  }
  const getQuickReviewContent = ({ newValue, _reviewList }) => {
    let str = ""
    newValue?.forEach((v, i) => {
      const _index = _reviewList.findIndex(k => k?.Value === v)
      if (_index > -1) {
        if (i === 0) {
          str += _reviewList[_index]?.Text
        } else {
          str += ". " + _reviewList[_index]?.Text
        }
      }
    })
    return str
  }
  const getDisabledReviewBtn = () => {
    if (data && data?.[historyFields.fReviewRating]) {
      return false
    }
    return true
  }
  return (
    <WrapReview>
      <MyForm
        key={keyReview}
        ref={refReviewForm}
        configForm={
          {
            defaultValues: data,
            controls: [
              {
                label: "",
                fieldName: historyFields.fReviewRating,
                type: "input_rate",
                more: {
                  configRate: {
                    fieldRating: historyFields.fReviewRating,
                    titleList: ["Poor", "Fair", "Good", "Excellent", "WOW!!!"],
                  },
                },
              },
              {
                label: data?.[historyFields.fReviewRating] ? (
                  <div className="success-box d-block">
                    <div className="clearfix"></div>
                    <img
                      alt=""
                      width="32"
                      src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0MjYuNjY3IDQyNi42NjciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQyNi42NjcgNDI2LjY2NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+CjxwYXRoIHN0eWxlPSJmaWxsOiM2QUMyNTk7IiBkPSJNMjEzLjMzMywwQzk1LjUxOCwwLDAsOTUuNTE0LDAsMjEzLjMzM3M5NS41MTgsMjEzLjMzMywyMTMuMzMzLDIxMy4zMzMgIGMxMTcuODI4LDAsMjEzLjMzMy05NS41MTQsMjEzLjMzMy0yMTMuMzMzUzMzMS4xNTcsMCwyMTMuMzMzLDB6IE0xNzQuMTk5LDMyMi45MThsLTkzLjkzNS05My45MzFsMzEuMzA5LTMxLjMwOWw2Mi42MjYsNjIuNjIyICBsMTQwLjg5NC0xNDAuODk4bDMxLjMwOSwzMS4zMDlMMTc0LjE5OSwzMjIuOTE4eiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
                    />
                    <div className="text-message">
                      <span>
                        Cảm ơn bạn, bạn đã đánh giá{" "}
                        {data?.[historyFields.fReviewRating]} sao.
                      </span>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                ) : null,
                type: "only_label",
              },
              {
                label: "",
                fieldName: historyFields.fReviewOption,
                type: "select_custom",
                more: {
                  sourceData:
                    fnList?.fnGetOptionList && fnList?.fnGetOptionList(),
                  multi: true,
                },
              },
              {
                label: "",
                fieldName: historyFields.fReviewContent,
                type: "textarea",
                more: {
                  placeholder: "Nội dung đánh giá",
                },
              },
            ],
          } as any
        }
        fnList={
          {
            afterUpdateValueControl: opts => {
              const { fieldName, newValue } = opts
              let newData = { ...data }
              if (fieldName !== historyFields.fReviewOption) {
                newData[fieldName] = newValue
              } else {
                if (fnList?.fnGetOptionList) {
                  const _reviewList = fnList.fnGetOptionList()
                  let str = data[historyFields.fReviewContent]
                  if (_reviewList && _reviewList?.length && isArray(newValue)) {
                    const _newReviewValue = getQuickReview({
                      values: newValue,
                    })
                    let _str = getQuickReviewContent({
                      newValue: _newReviewValue,
                      _reviewList,
                    })
                    if (str && !isEdited) {
                      if (_newReviewValue?.length > 0) {
                        str += ". " + _str
                      }
                      newData[historyFields.fReviewContent] = str
                      if (
                        refReviewForm?.current?.fnListFromExtControl?.[
                          historyFields.fReviewContent
                        ]
                      ) {
                        refReviewForm.current.fnListFromExtControl[
                          historyFields.fReviewContent
                        ].updateValue(str)
                        setIsEdited(false)
                      }
                    } else {
                      str = getQuickReviewContent({
                        newValue: _newReviewValue,
                        _reviewList,
                      })
                      newData[historyFields.fReviewContent] = str
                      if (
                        refReviewForm?.current?.fnListFromExtControl?.[
                          historyFields.fReviewContent
                        ]
                      ) {
                        refReviewForm.current.fnListFromExtControl[
                          historyFields.fReviewContent
                        ].updateValue(str)
                        setIsEdited(true)
                      }
                    }
                  }
                }
              }
              if (fieldName === historyFields.fReviewContent) {
                if (newValue) {
                  if (data[historyFields.fReviewContent] != newValue) {
                    setIsEdited(false)
                  }
                } else {
                  setIsEdited(true)
                }
              }
              setData({ ...newData })
            },
          } as any
        }
        renderFooter={() => {
          return (
            <div className="d-flex justify-content-start">
              <button
                className="btn btn-info btnguidg"
                onClick={() => {
                  setLoading(true)
                  if (onSubmit) {
                    onSubmit({
                      modalData: data,
                      cbSuccess: () => {
                        setLoading(false)
                      },
                      cbError: () => {
                        setLoading(false)
                      },
                    })
                  }
                }}
                disabled={getDisabledReviewBtn()}
              >
                <i
                  className={
                    loading ? "fa fa-spinner fa-pulse" : "fa fa-envelope"
                  }
                ></i>{" "}
                Gửi Đánh Giá
              </button>
            </div>
          )
        }}
      />
    </WrapReview>
  )
}
const PaymentModal = props => {
  const { data, paymentInfos, fnList, moreConfig } = props
  const [isCopied, setCopied] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [countdown, setShowCountdown] = React.useState(false)
  const [isPaid, setIsPaid] = React.useState(false)
  const [msg, setMsg] = React.useState("")
  const [canCheck, setCanCheck] = React.useState(true)
  const [clicked, setClicked] = React.useState(false)

  const copyLink = async text => {
    await navigator.clipboard.writeText(text)
    setCopied(true)
  }
  const getCanShowBtn = () => {
    if (data?.StatusCode === "CUS2DEPOSIT") {
      return true
    }
    return false
  }
  const getDisabledBtn = () => {
    if (loading || countdown || !canCheck) {
      return true
    }
    return false
  }
  const getEndTime = () => {
    let defaultWaitingTime = 30
    if (moreConfig?.defaultWaitingTime) {
      defaultWaitingTime = moreConfig?.defaultWaitingTime
    }
    const date = new Date()
    date.setSeconds(date.getSeconds() + defaultWaitingTime)
    return date.getTime()
  }

  const intervalRef = useRef<number>()
  const timeoutRef = useRef<number>()

  const reloadList = (cb: () => void) => {
    fnList.fnReloadList({
      cbSuccess: data => {
        if (data?.[historyFields.fOrderStatusCode] === "WAITING2DEPARTURE") {
          setIsPaid(true)
          setLoading(false)
          MyModal.hideModalScrollContent("component")
          setCanCheck(false)
          setShowCountdown(false)
          cb?.()
        }
      },
      cbError: () => {
        // setLoading(false)
      },
    })
  }
  const onHandleCheckPaid = () => {
    let defaultWaitingTime = 30 * 1000
    if (moreConfig?.defaultWaitingTime) {
      defaultWaitingTime = moreConfig?.defaultWaitingTime * 1000
    }
    if (fnList?.fnReloadList) {
      setLoading(true)
      setShowCountdown(true)
      intervalRef.current = setInterval(() => {
        reloadList(() => {
          clearInterval(intervalRef.current)
          clearTimeout(timeoutRef.current)
        })
      }, 10000) as any
      timeoutRef.current = setTimeout(() => {
        clearInterval(intervalRef.current)
        setLoading(false)
        setMsg(moreConfig?.messageCheckFail)
        setCanCheck(false)
        setShowCountdown(false)
      }, defaultWaitingTime) as any
      reloadList(() => {
        clearInterval(intervalRef.current)
        clearTimeout(timeoutRef.current)
      })
    }
  }
  return (
    <WrapPayment className="content">
      <div className="w-payinfo">
        {paymentInfos?.length > 0 &&
          paymentInfos.map((v, i) => {
            const moneyToPay = v[historyFields.fMoneyToPay]
            const transferContent = v[historyFields.fTransferContent]
            const bankInfo = v[historyFields.fBankInfo]
            const qrCode = v[historyFields.fQRCodeUrl]
            return (
              <div key={i} className="d-flex">
                {qrCode && (
                  <div className="qr-code">
                    <img src={qrCode} alt="" />
                  </div>
                )}
                <div className="right">
                  <p>
                    Số tiền cần thanh toán:{" "}
                    <b className="red money">{moneyToPay}</b>
                  </p>
                  <p>
                    Nội dung chuyển khoản:{" "}
                    <b className="txtndck">{transferContent}</b>{" "}
                    <span
                      className="copyma btn-link"
                      onClick={() => copyLink(transferContent)}
                    >
                      <i className="flaticon-copy"></i>
                    </span>
                  </p>
                  <div
                    style={isCopied ? {} : { display: "none" }}
                    className="text-success thanhcong mb-15px"
                  >
                    Đã sao chép thành công !
                  </div>
                  <div
                    className="alert alert-info"
                    dangerouslySetInnerHTML={{ __html: bankInfo }}
                  ></div>
                  {!countdown && !msg && !loading && (
                    <div className="msg info-msg only-desktop">
                      <i className="flaticon-information" />
                      <div>
                        Nếu bạn đã thanh toán, vui lòng nhấn xác nhận chuyển
                        khoản
                      </div>
                    </div>
                  )}
                  {!countdown && msg && (
                    <div className="msg warning-msg only-desktop">
                      <i className="flaticon-information" />
                      <div dangerouslySetInnerHTML={{ __html: msg }}></div>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
      </div>
      {getCanShowBtn() && (
        <div
          className={classnames(
            "wrap-confirm mt-15px",
            countdown ? "justify-between" : "justify-end"
          )}
        >
          {!countdown && !msg && !loading && (
            <div className="msg text-align-center only-mobile">
              Nếu bạn đã thanh toán, vui lòng nhấn xác nhận chuyển khoản
            </div>
          )}
          {!countdown && msg && (
            <div
              className="msg text-align-center only-mobile"
              dangerouslySetInnerHTML={{ __html: msg }}
            ></div>
          )}
          {countdown && (
            <div className="text-align-center">
              <span>
                {moreConfig?.messageWaiting}{" "}
                <MyUI
                  type="countdown_time"
                  endTime={getEndTime()}
                  moreConfig={{
                    delay: 1,
                    hideTitle: true,
                    onlyShowSecond: true,
                  }}
                  fnList={{
                    onFinishCountdown: () => {
                      setShowCountdown(false)
                      setCanCheck(false)
                      setMsg("")
                    },
                  }}
                />
              </span>
            </div>
          )}
          <MyUI
            type="sg_button"
            color="primary-v2"
            className="btn-lg btn-block btnlogin mt-15px"
            loading={loading}
            btnName="Xác nhận chuyển khoản"
            loadingColor={"#fff"}
            onClick={onHandleCheckPaid}
            disabled={getDisabledBtn()}
          />
        </div>
      )}
    </WrapPayment>
  )
}
const CancelModal = props => {
  const { onSubmit, msgWarning, fnList } = props
  const [data, setData] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [keyUI, forceUpdate] = useForceUpdate()
  return (
    <WrapCancel>
      <MyForm
        key={keyUI}
        configForm={
          {
            defaultValues: data,
            controls: [
              {
                label: "",
                fieldName: historyFields.fCancelReasonId,
                type: "v1_mfc_select2",
                more: {
                  multi: false,
                  optionKey: "OptionList",
                  morePropsSelect2: {
                    placeholder: "Vui lòng chọn lý do hủy chuyến",
                    isSearchable: false,
                  },
                },
              },
              {
                label: "",
                fieldName: historyFields.fCancelReasonDetail,
                type: "textarea",
                more: {
                  placeholder: "Nội dung chi tiết lý do hủy",
                },
              },
            ],
          } as any
        }
        fnList={
          {
            ...fnList,
            afterUpdateValueControl: opts => {
              const { fieldName, newValue } = opts
              let needReload = false
              data[fieldName] = newValue
              if (fieldName === historyFields.fCancelReasonId) {
                const options =
                  fnList?.getSourceList && fnList.getSourceList()?.OptionList
                if (options?.length > 0) {
                  const option = options.find(v => v.Value === newValue)
                  if (option) {
                    data[historyFields.fCancelReasonDetail] =
                      option.Text_Display
                    needReload = true
                  }
                }
              }
              setData({ ...data })
              forceUpdate()
            },
          } as any
        }
        renderFooter={() => {
          return (
            <div className="d-flex flex-wrap">
              {msgWarning && (
                <div className="text-danger w-100 mb-2">
                  <i
                    className="flaticon-information"
                    style={{ marginRight: "0.25rem" }}
                  />
                  {msgWarning}
                </div>
              )}
              <button
                className="btn btn-danger btn-block"
                onClick={() => {
                  setLoading(true)
                  if (onSubmit) {
                    onSubmit({
                      modalData: data,
                      cbSuccess: () => {
                        setLoading(false)
                      },
                      cbError: () => {
                        setLoading(false)
                      },
                    })
                  }
                }}
              >
                {loading && <i className="fa fa-spinner fa-pulse"></i>} Hủy
                Chuyến
              </button>
            </div>
          )
        }}
      />
    </WrapCancel>
  )
}
const OneFile = props => {
  const { obj, fnList } = props
  const [loading, setLoading] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)
  const acceptedFiles = "image/*"
  const requestUpload = files => {
    if (files?.length > 0) {
      setLoading(true)
      HApi.apiUpload({
        url: "RentalServiceUpload/UploadReceiveImage",
        files: files,
        customHeader: {
          Id: obj.id,
        },
      })
        .then(response => {
          setLoading(false)
          if (fnList?.cbUpload) {
            fnList.cbUpload(response.Data)
          }
        })
        .catch(error => {
          setLoading(false)
        })
    }
  }
  const requestDelete = key => {
    if (key) {
      setDeleting(true)
      HApi.apiPost({
        url: "RentalServiceUpload/DeleteReceiveImage",
        data: {
          Key: key,
        },
      })
        .then(response => {
          setDeleting(false)
          if (fnList?.cbDelete) {
            fnList.cbDelete(key)
          }
        })
        .catch(error => {
          setDeleting(false)
        })
    }
  }

  return (
    <li key={obj?.key} className="list-group-item">
      <span>
        {obj?.title}
        {obj?.required ? <span className="text-danger">*</span> : null}
      </span>
      <Dropzone
        multiple={obj?.maxFile > 1 ? true : false}
        onDrop={acceptedFiles => {
          requestUpload(acceptedFiles)
        }}
        accept={acceptedFiles}
        disabled={obj?.maxFile === obj?.files?.length}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className="upload"
            {...getRootProps()}
            style={{ width: "fit-content" }}
          >
            <label
              className={classnames(
                "btn btn-xs btn-info",
                loading || obj?.maxFile === obj?.files?.length ? "disabled" : ""
              )}
            >
              Tải Ảnh
            </label>
            <input
              {...getInputProps()}
              disabled={obj?.maxFile === obj?.files?.length}
            />
          </div>
        )}
      </Dropzone>
      {obj?.files?.length > 0 ? (
        <>
          {obj.files.map((v, i) => {
            return (
              <div key={i} className="w-image">
                <img
                  key={v?.Key}
                  src={v?.[historyFields.fThumbnailUrl]}
                  alt=""
                />
                <div className="img-action">
                  <span
                    className="btn-delete"
                    onClick={() => requestDelete(v?.Key)}
                  >
                    {deleting && (
                      <i
                        className="fa fa-spinner fa-pulse"
                        style={{ marginRight: "0.25rem" }}
                      />
                    )}
                    Xóa Ảnh
                  </span>
                </div>
              </div>
            )
          })}
        </>
      ) : (
        <img src={NoImage} alt="" />
      )}
      <div className="clearfix"></div>
    </li>
  )
}
const RentModal = props => {
  const { data, onSubmit } = props
  const [loading, setLoading] = React.useState(false)
  const receiveImages = data?.[historyFields.fReceiveImages]
  // const isRequired = data?.[historyFields.fIsRequired]
  const [myData, setMyData] = React.useState(receiveImages || [])
  if (myData?.length > 0) {
    const listImg = myData.map(v => {
      return {
        id: v?.Id,
        title: v?.[historyFields.fServiceImageTypeName],
        files: v?.[historyFields.fFiles],
        maxFile: v?.[historyFields.fLimit],
        required: v?.[historyFields.fIsRequired],
      }
    })
    return (
      <WrapRent>
        <h3 className="titletab">Ảnh thực tế</h3>
        <p className="mb-10px">
          Vui lòng cung cấp hình ảnh để SIGO hoàn thiện hợp đồng bảo hiểm
        </p>
        <ul className="list-group listhinh">
          {listImg.map((v, i) => {
            return (
              <OneFile
                key={i}
                obj={v}
                fnList={{
                  cbUpload: files => {
                    let newData = myData
                    let myFiles = v.files
                    if (!myFiles) {
                      myFiles = []
                    }
                    if (Array.isArray(files)) {
                      myFiles = [...myFiles, ...files]
                    } else {
                      myFiles.push(files)
                    }
                    newData[i][historyFields.fFiles] = myFiles
                    setMyData([...newData])
                  },
                  cbDelete: key => {
                    let newData = myData
                    let myFiles = v.files
                    if (myFiles?.length > 0) {
                      myFiles = myFiles.filter(v => v.Key !== key)
                    }
                    newData[i][historyFields.fFiles] = myFiles
                    setMyData([...newData])
                  },
                }}
              />
            )
          })}
        </ul>
        <MyUI
          type="sg_button"
          onClick={() => {
            if (onSubmit) {
              setLoading(true)
              onSubmit({
                modalData: {},
                cbSuccess: () => {
                  setLoading(false)
                  showAlert({
                    component: (
                      <MyUI
                        type="alert"
                        alertType="success"
                        title=""
                        msg="Bạn đã xác thực nhận xe thành công"
                      />
                    ),
                    opts: {
                      onScrollContent: true,
                    },
                  })
                },
                cbError: () => {
                  setLoading(false)
                },
              })
            }
          }}
          color="primary-v2"
          className="btn btn-link btnsubxacnhan"
          disabled={loading}
          loading={loading}
          btnName="Xác Nhận"
          loadingColor={"#fff"}
        />
      </WrapRent>
    )
  }
  return <></>
}
/** button */
const Pay = props => {
  const { key, configButton, fnList } = props
  const [loading, setLoading] = React.useState(false)
  const requestPaymentInfos = () => {
    setLoading(true)
    HApi.apiPost({
      url: configButton?.APIName,
      data: configButton?.RequestData,
    })
      .then(response => {
        showPopup(response.Data?.[historyFields.fPaymentInfos], response.Data)
        setTimeout(() => {
          setLoading(false)
        }, 200)
      })
      .catch(error => {
        setLoading(false)
      })
  }
  const showPopup = (paymentInfos, data) => {
    MyModal.showFromComponent(
      <PaymentModal
        data={data}
        paymentInfos={paymentInfos}
        fnList={fnList}
        moreConfig={configButton?.config4Modal}
      />,
      {
        title: (<span style={{ fontSize: 19 }}>Thanh Toán</span>) as any,
        size: "lg",
        modalClassName: "default modal-filter modal-scrollable-inside",
        onScrollContent: true,
        modalScrollInside: true,
      }
    )
  }
  return (
    <MyUI
      type="sg_button"
      onClick={requestPaymentInfos}
      color=""
      className="btn btn-link"
      disabled={loading}
      loading={loading}
      btnName={configButton?.Name}
      loadingColor={"#fff"}
      style={{
        background: configButton?.Color_BG,
      }}
    />
  )
}
const Review = props => {
  const { key, configButton, fnList } = props
  const requestSubmitReview = ({ apiUrl, query, cbSuccess, cbError }) => {
    HApi.apiPost({
      url: apiUrl,
      data: query,
    })
      .then(response => {
        fnList?.fnReloadList && fnList.fnReloadList()
        cbSuccess && cbSuccess()
      })
      .catch(error => {
        cbError && cbError()
      })
  }
  return (
    <button
      key={key}
      type="button"
      className="btn btn-info"
      onClick={() => {
        MyModal.showFromComponent(
          <ReviewModal
            onSubmit={({ modalData, cbSuccess, cbError }) => {
              requestSubmitReview({
                apiUrl: configButton.APIName,
                query: {
                  ...modalData,
                  ...configButton?.RequestData,
                },
                cbSuccess: () => {
                  MyModal.hideModalComponent()
                  cbSuccess && cbSuccess()
                },
                cbError: cbError,
              })
            }}
            fnList={fnList}
          />,
          {
            title: (<span style={{ fontSize: "19px" }}>Đánh Giá</span>) as any,
            size: "default",
            modalClassName: "default modal-header-left",
            onScrollContent: true,
          }
        )
      }}
    >
      {configButton.Name}
    </button>
  )
}
const Insurance = props => {
  const { key, configButton } = props
  const [iloading, setILoading] = React.useState(false)
  const requestGetInsuranceContact = () => {
    setILoading(true)
    HApi.apiPost({
      url: configButton?.APIName,
      data: configButton?.RequestData,
    })
      .then(response => {
        setILoading(false)
        showPopup(response.Data?.[historyFields.fInsuranceContact])
      })
      .catch(error => {
        setILoading(false)
      })
  }
  const showPopup = data => {
    const contacts = data?.ContactNumbers
    let component: any = []
    if (contacts?.length > 0) {
      contacts.forEach(v => {
        component.push(
          <div className="w-50 p-1">
            <a className="btn btn-info btn-radius w-100" href={`tel:${v}`}>
              {v}
            </a>
          </div>
        )
      })
    }
    MyModal.showFromComponent(<div className="d-flex">{component}</div>, {
      title: (<span style={{ fontSize: 19 }}>{data?.Title}</span>) as any,
      size: "default",
      modalClassName: "default modal-filter",
      onScrollContent: true,
    })
  }
  return (
    <MyUI
      type="sg_button"
      onClick={requestGetInsuranceContact}
      color=""
      className="btn btn-warning"
      disabled={iloading}
      loading={iloading}
      btnName={configButton?.Name}
      loadingColor={"#fff"}
      style={{
        background: configButton?.Color_BG,
      }}
    />
  )
  // return (
  //   <button
  //     key={key}
  //     type="button"
  //     className="btn btn-warning"
  //     onClick={() => {
  //       requestGetInsuranceContact()
  //     }}
  //   >
  //     {iloading === true ? (
  //       <i
  //         className="fa fa-spinner fa-pulse"
  //         style={{ marginRight: "0.25rem" }}
  //       ></i>
  //     ) : null}
  //     {configButton.Name}
  //   </button>
  // )
}
const Cancel = props => {
  const { key, data, configButton, fnList } = props
  const [loading, setLoading] = React.useState(false)
  const requestGetCancelInfo = ({ cbSuccess, cbError }) => {
    if (data?.OrderNumber) {
      setLoading(true)
      HApi.apiPost({
        url: "RentalService/GetCancelOrderInfo",
        data: {
          OrderNumber: data?.OrderNumber,
        },
      })
        .then(response => {
          setLoading(false)
          cbSuccess && cbSuccess(response?.Data)
        })
        .catch(error => {
          setLoading(false)
          cbError && cbError()
        })
    }
  }
  const requestSubmitCancel = ({ apiUrl, query, cbSuccess, cbError }) => {
    HApi.apiPost({
      url: apiUrl,
      data: query,
    })
      .then(response => {
        fnList?.fnReloadList && fnList.fnReloadList()
        cbSuccess && cbSuccess()
      })
      .catch(error => {
        cbError && cbError()
      })
  }
  const showPopup = data => {
    MyModal.showFromComponent(
      <CancelModal
        msgWarning={data?.Message}
        onSubmit={({ modalData, cbSuccess, cbError }) => {
          requestSubmitCancel({
            apiUrl: configButton.APIName,
            query: {
              ...modalData,
              ...configButton?.RequestData,
            },
            cbSuccess: () => {
              MyModal.hideModalComponent()
              cbSuccess && cbSuccess()
            },
            cbError: cbError,
          })
        }}
        fnList={{
          getSourceList: () => {
            return {
              OptionList: configButton?.OptionList,
            }
          },
        }}
      />,
      {
        title: (<span style={{ fontSize: 19 }}>Hủy Chuyến</span>) as any,
        size: "default",
        modalClassName: "default modal-header-left",
        backdrop: "static",
        onScrollContent: true,
      }
    )
  }
  return (
    <button
      key={key}
      type="button"
      className="btn btn-link"
      onClick={() => {
        requestGetCancelInfo({
          cbSuccess: data => {
            showPopup(data)
          },
          cbError: () => {},
        })
      }}
      style={{
        background: configButton?.Color_BG,
      }}
    >
      {loading && <i className="fa fa-spinner fa-pulse"></i>}
      {configButton.Name}
    </button>
  )
}
const RentCarBegin = props => {
  const { key, data, configButton, fnList } = props
  const [isSubmited, setIsSubmited] = React.useState(false)
  const requestSubmitRentBegin = ({ apiUrl, query, cbSuccess, cbError }) => {
    setIsSubmited(true)
    HApi.apiPost({
      url: apiUrl,
      data: query,
    })
      .then(response => {
        fnList?.fnReloadList && fnList.fnReloadList()
        setIsSubmited(false)
        cbSuccess && cbSuccess()
      })
      .catch(error => {
        setIsSubmited(false)
        cbError && cbError()
      })
  }
  const showPopup = () => {
    MyModal.showFromComponent(
      <RentModal
        data={data}
        onSubmit={({ modalData, cbSuccess, cbError }) => {
          requestSubmitRentBegin({
            apiUrl: configButton.APIName,
            query: {
              ...modalData,
              ...configButton?.RequestData,
            },
            cbSuccess: () => {
              MyModal.hideModalScrollContent("component")
              cbSuccess && cbSuccess()
            },
            cbError: cbError,
          })
        }}
      />,
      {
        title: (<span style={{ fontSize: 19 }}>Xác Nhận Nhận Xe</span>) as any,
        size: "lg",
        modalClassName: "default modal-filter modal-scrollable-inside",
        backdrop: "static",
        onClose: () => {
          if (isSubmited === false) {
            fnList?.fnReloadList && fnList.fnReloadList()
          }
        },
        onScrollContent: true,
        modalScrollInside: true,
      }
    )
  }
  return (
    <MyUI
      type="sg_button"
      onClick={showPopup}
      color=""
      className="btn btn-link"
      btnName={configButton?.Name || ""}
      loadingColor={"#fff"}
      style={{
        background: configButton?.Color_BG,
      }}
    />
  )
}
const RentAgain = props => {
  const { key, configButton } = props
  const mapParamsNFields = {
    lat: categoryFields.fLatitude,
    long: categoryFields.fLongitude,
    from: categoryFields.fAddress,
    datetimefrom: categoryFields.fFromDate,
    datetimeto: categoryFields.fToDate,
  }
  return (
    <button
      key={key}
      type="button"
      className="btn btn-link"
      onClick={() => {
        let objSaveLocal: any = {}
        const rqData = configButton?.RequestData
        const filterInSearch = HLocalStorage.getObj(
          kObjCategoryLocal,
          null as any
        )
        let params = "?"
        if (rqData) {
          Object.keys(mapParamsNFields).forEach(f => {
            let canFillParam = false
            if (
              rqData?.[categoryFields.fAddress] &&
              rqData?.[categoryFields.fLongitude] &&
              rqData?.[categoryFields.fLatitude] &&
              (mapParamsNFields[f] === categoryFields.fAddress ||
                mapParamsNFields[f] === categoryFields.fLongitude ||
                mapParamsNFields[f] === categoryFields.fLatitude)
            ) {
              canFillParam = true
            }
            if (
              rqData?.[categoryFields.fToDate] &&
              rqData?.[categoryFields.fFromDate] &&
              rqData?.[categoryFields.fToDate] >
                rqData?.[categoryFields.fFromDate] &&
              (mapParamsNFields[f] === categoryFields.fFromDate ||
                mapParamsNFields[f] === categoryFields.fToDate)
            ) {
              canFillParam = true
            }
            if (rqData?.[mapParamsNFields[f]] && canFillParam) {
              if (f === "from") {
                params =
                  params +
                  "&" +
                  f +
                  "=" +
                  rqData?.[mapParamsNFields[f]]?.replaceAll(" ", "+")
              } else {
                params = params + "&" + f + "=" + rqData?.[mapParamsNFields[f]]
              }
            }
          })
          // if (
          //   rqData?.[categoryFields.fFromDate] &&
          //   rqData?.[categoryFields.fToDate] &&
          //   rqData?.[categoryFields.fFromDate] <
          //     rqData?.[categoryFields.fToDate]
          // ) {
          //   objSaveLocal[categoryFields.fFromDate] =
          //     rqData[categoryFields.fFromDate]
          //   objSaveLocal[categoryFields.fToDate] =
          //     rqData[categoryFields.fToDate]
          // }
        }
        // if (filterInSearch) {
        //   HLocalStorage.saveObj(kObjCategoryLocal, {
        //     ...filterInSearch,
        //     ...objSaveLocal,
        //   })
        // }
        if (configButton?.Url) {
          let link = configButton.Url?.startsWith("/")
            ? configButton.Url
            : `/${configButton.Url}`
          if (params !== "?") {
            link = link + params
          }
          HLink.openUrl(link, "_self")
          MyModal.hideModalMyUI()
        }
      }}
      style={{
        background: configButton?.Color_BG,
      }}
    >
      {configButton.Name}
    </button>
  )
}
const Understand = props => {
  const { key, configButton } = props
  return (
    <button
      key={key}
      type="button"
      className="btn btn-primary-v2"
      onClick={() => {
        configButton?.fnOnClick && configButton?.fnOnClick()
      }}
      style={{
        background: configButton?.Color_BG,
      }}
    >
      {configButton.Name}
    </button>
  )
}
const NewCancel = props => {
  const { key, data, configButton, fnList } = props
  const { icon } = configButton || {}
  const showPopup = () => {
    MWEaseLayout.open()
  }
  return (
    <>
      <WrapPartWithIcon
        className="cursor-pointer cancel-btn"
        onClick={() => {
          showPopup()
        }}
      >
        <div className="d-flex wrap-title">
          {icon && <i className={icon} />}
          <div className="mauden">{configButton?.Name}</div>
        </div>
        <i className="flaticon-right-arrow mauden" />
      </WrapPartWithIcon>
      <MWEaseLayout
        type="component"
        idHeader="navbar-category-v2"
        id="cancel_order"
        classNameWrap="header-no-search bg-white pt-15px"
      >
        <MyUI
          type="sg_cancel_order"
          data={data}
          configButton={configButton}
          fnList={{
            getSourceList: () => {
              return {
                OptionList: configButton?.OptionList,
              }
            },
            onClose: () => {
              MWEaseLayout.close({
                skipHideScrollContent: true,
              })
            },
            afterSubmit: () => {
              MWEaseLayout.close({
                skipHideScrollContent: true,
              })
              fnList?.fnReloadList && fnList.fnReloadList()
            },
          }}
        />
      </MWEaseLayout>
    </>
  )
}
const NewReview = props => {
  const { key, configButton, fnList, data, moreConfig } = props
  const { canReview, defaultRating } = configButton || {}
  const [disabledReview, setDisabledReview] = React.useState(!canReview)
  const requestSubmitReview = ({ submitData, cbSuccess, cbError }) => {
    HApi.apiPost({
      url: configButton.APIName,
      data: {
        ...submitData,
        ...configButton?.RequestData,
      },
    })
      .then(response => {
        setDisabledReview(true)
        cbSuccess && cbSuccess()
      })
      .catch(error => {
        cbError && cbError()
      })
  }
  const onOpenReview = () => {
    MWEaseLayout.openWithType("review")
  }
  return (
    <div className="wrap-rating">
      <div className="tt">Đánh giá của bạn</div>
      <MyControl
        type="input_rate"
        defaultValue={defaultRating}
        controlOpts={{
          fieldName: "danhgiachuyendi",
          disabled: true,
          configRate: {
            fieldRating: "danhgiachuyendi",
            configBtn: {
              fnClick: () => {
                onOpenReview()
                // MyModal.showFromComponent(
                //   <ReviewModal
                //     onSubmit={({ modalData, cbSuccess, cbError }) => {
                //       requestSubmitReview({
                //         apiUrl: configButton.APIName,
                //         query: {
                //           ...modalData,
                //           ...configButton?.RequestData,
                //         },
                //         cbSuccess: () => {
                //           MyModal.hideModalComponent()
                //           cbSuccess && cbSuccess()
                //         },
                //         cbError: cbError,
                //       })
                //     }}
                //     fnList={fnList}
                //   />,
                //   {
                //     title: (
                //       <span style={{ fontSize: "19px" }}>Đánh Giá</span>
                //     ) as any,
                //     size: "default",
                //     modalClassName: "default modal-header-left",
                //     onScrollContent: true,
                //   }
                // )
              },
              disabled: disabledReview,
            },
          },
          onUpdateValue: val => {},
          getDefaultValues: () => {
            return {
              danhgiachuyendi: defaultRating,
            }
          },
        }}
      />
      <MWEaseLayout
        type="review"
        idHeader="navbar-category-v2"
        id="review_order"
        classNameWrap="header-no-search bg-white pt-15px"
      >
        <MyUI
          type="sg_review_order"
          data={data}
          configButton={configButton}
          fnList={{
            getSourceList: () => {
              return {
                OptionList: configButton?.OptionList,
              }
            },
            onClose: () => {
              MWEaseLayout.closeWithType("review", {
                skipHideScrollContent: true,
              })
              fnList?.fnReloadList && fnList.fnReloadList()
            },
            afterSubmit: () => {
              MWEaseLayout.closeWithType("review", {
                skipHideScrollContent: true,
              })
              fnList?.fnReloadList && fnList.fnReloadList()
            },
            requestSubmitReview: requestSubmitReview,
          }}
          moreConfig={moreConfig}
        />
      </MWEaseLayout>
    </div>
  )
}
/** */
const MyUIOrderAction = props => {
  const { className, data, buttonList, fnList, moreConfig } = props
  const getMyButtons = () => {
    let list: any = []
    if (buttonList?.length > 0) {
      buttonList.forEach(v => {
        list.push(
          new OneButton({
            configButton: {
              ...v,
              NeedReloadList: true,
              Color: "custom",
            },
          })
        )
      })
    }
    return list
  }
  const buttons = getMyButtons()
  // const myBtnTypes = Object.keys(UIButtonTypes)
  return (
    <Wrap className={classnames("m-order-action", className)}>
      {buttons?.length > 0 &&
        buttons.map((v, i) => {
          const configButton = v?._configButton
          const p = {
            key: `${i}_${data?.Id}_${configButton.Code}`,
            data: data,
            configButton: configButton,
            fnList: {
              fnReloadList: () => {
                if (fnList?.fnReloadList) {
                  fnList.fnReloadList()
                }
              },
              fnGetOptionList: () => {
                if (configButton && configButton?.OptionList) {
                  return configButton?.OptionList
                }
                return null
              },
            },
          }
          if (configButton?.Code === "Pay") {
            const p = {
              key: `${i}_${data?.Id}_${configButton.Code}`,
              data: data,
              configButton: {
                ...configButton,
                config4Modal: moreConfig?.["Pay"],
              },
              fnList: {
                fnReloadList: ({ cbSuccess, cbError }) => {
                  if (fnList?.fnReloadList) {
                    fnList.fnReloadList({ cbSuccess, cbError })
                  }
                },
                fnGetOptionList: () => {
                  if (configButton && configButton?.OptionList) {
                    return configButton?.OptionList
                  }
                  return null
                },
                fnReloadListWith: () => {},
              },
            }
            return <Pay {...p} />
          } else if (configButton?.Code === "RentCar_Begin") {
            return <RentCarBegin {...p} />
          } else if (configButton?.Code === "Review") {
            return <Review {...p} />
          } else if (configButton?.Code === "Insurance") {
            return <Insurance {...p} />
          } else if (configButton?.Code === "Cancel") {
            return <Cancel {...p} />
          } else if (configButton?.Code === "RentAgain") {
            return <RentAgain {...p} />
          } else if (configButton?.Code === "Understand") {
            return <Understand {...p} />
          } else if (configButton?.Code === "NewCancel") {
            return <NewCancel {...p} />
          } else if (configButton?.Code === "NewReview") {
            return <NewReview {...p} moreConfig={moreConfig?.["Review"]} />
          }
          return v.renderMyButton({
            key: i,
            fnList: {
              fnRequestList: () => {
                if (fnList?.fnReloadList) {
                  fnList.fnReloadList()
                }
              },
            },
          })
        })}
    </Wrap>
  )
}
const Wrap = styled.div`
  & button {
    font-size: 17px !important;
    border-radius: 8px !important;
    &.disabled {
      background-color: #c9c9c9 !important;
      opacity: 1;
      background-image: unset;
    }
  }
  & .btn-link {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #fff !important;
    &:focus,
    &:active {
      box-shadow: inset 0px 0px 19px rgb(0 0 0 / 15%) !important;
    }
  }
`
const WrapReview = styled.div`
  & textarea.form-control {
    min-height: 78px;
  }
  & .react-rater {
    justify-content: center;
    & .fa-star {
      font-size: 40px;
      width: 50px;
      color: #ccc;
      text-align: center;
      &.active,
      &.hover {
        color: #ffcc36;
      }
    }
  }
  & .success-box {
    margin: 10px 0;
    padding: 10px 10px;
    border: 1px solid #eee;
    background: #f9f9f9;
    display: none;
    & > div {
      vertical-align: top;
      display: inline-block;
      color: #888;
    }
    & img {
      margin-right: 10px;
      display: inline-block;
      vertical-align: top;
    }
  }
  & .btn.btn-info:disabled {
    background-color: var(--mau1);
    color: #fff;
    border-color: var(--mau1);
  }
`
const WrapPayment = styled.div`
  & .qr-code {
    display: flex;
    justify-content: center;
    & img {
      box-shadow: rgb(175 230 255) 0px 2px 8px 0px;
      border-radius: 4px;
    }
    padding: 0.5rem 0;
  }
  & .w-payinfo {
    max-height: calc(100vh - 4.5rem - 81px - 185px);
    overflow-y: auto;
    & .qr-code {
      width: 50%;
    }
    & .right {
      width: 50%;
      padding-left: 20px;
    }
  }
  & .only-mobile {
    display: none;
  }
  & .mui-countdown-time {
    padding-top: 5px;
    display: inline-block;
    & .item.red {
      color: var(--mauden);
    }
    & .wrap-countdown {
    }
    & .countdown {
      font-size: 16px;
      display: inline-block;
    }
  }
  & .btn-primary-v2 {
    font-weight: 400;
  }
  & .info-msg {
    padding: 10px;
    border: 1px solid #ede7e7;
    border-radius: 5px;
    background: #f3f3f3;
    color: #414141;
    font-size: 15px;
    display: flex;
    i {
      margin-right: 5px;
      margin-top: 2px;
      color: var(--mau1);
    }
  }
  & .warning-msg {
    padding: 10px;
    border: #fde8cc 1px solid;
    border-radius: 5px;
    background: #fff5e8;
    color: #e99c07;
    font-size: 15px;
    display: flex;
    i {
      margin-right: 5px;
      margin-top: 2px;
    }
  }
  & .wrap-confirm {
    display: flex;
    align-items: center;
    & .btn-primary-v2 {
      max-width: 300px;
    }
  }
  @media (max-width: 996px) {
    & .w-payinfo {
      & .d-flex {
        flex-direction: column-reverse;
      }
      & .qr-code {
        width: 100%;
      }
      & .right {
        width: 100%;
        padding-bottom: 20px;
        padding-left: 0;
      }
    }
    & .only-mobile {
      display: block;
    }
    & .only-desktop {
      display: none;
    }
    & .wrap-confirm {
      display: unset;
      & .btn-primary-v2 {
        max-width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    & .w-payinfo {
      max-height: calc(100vh - 3.5rem - 81px - 210px);
      overflow-y: auto;
    }
  }
`
const WrapCancel = styled.div`
  & .mc-select2 {
    font-size: 15px;
    & .form-control {
      height: auto;
    }
  }
  & textarea.form-control {
    min-height: 78px;
  }
  & i {
    &:before {
      line-height: inherit;
    }
  }
`
const WrapRent = styled.div`
  & .listhinh {
    & .w-image {
      width: 150px;
      height: 150px;
      float: right;
      margin-right: 10px;
      position: relative;
      & .img-action {
        display: none;
      }
      & img {
        margin-right: 0px;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      &:hover {
        & .img-action {
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          position: absolute;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          & .btn-delete {
            color: #fff;
            cursor: pointer;
            font-size: 12px;
            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }
        }
      }
    }
    & img {
      max-width: 150px;
      float: right;
      margin-right: 10px;
    }
    & .upload {
      float: right;
      & .btn {
        font-size: 12px;
      }
      & .btn-info.disabled {
        color: #fff;
        background-color: var(--mau1);
        border-color: var(--mau2);
        opacity: 0.65;
      }
    }
  }
  & .btnsubxacnhan {
    min-width: 100px;
  }
`
export default MyUIOrderAction
