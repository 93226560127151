import { pageKey } from "src/modules/app_sigo/constants"
import { getLoadingCategory } from "./CategoryLoading"
import { getLoadingHome } from "./HomeLoading"
import { getLoadingHeader } from "./HeaderCategoryFullv2Loading"

export const getLoadingPage = opts => {
  switch (opts?.key) {
    case pageKey.category:
      return getLoadingCategory(opts)
    case pageKey.home:
      return getLoadingHome(opts)
    case pageKey.onlyHeader:
      return getLoadingHeader(opts)
    default:
      return []
  }
}
