import React from "react"
import { BiInfoCircle } from "react-icons/bi"
import styled from "styled-components"
import { MyUI } from "@macashipo/mlib"

interface IProps {
  controlOpts?: IControlOpts
  value?: any
  onChange?: (ev: any) => void
  invalid?: any
  defaultValue?: any
}

class Control_Ext extends React.Component<IProps, any> {
  getDefaultValue = () => {
    return this.props.defaultValue
  }
  getControlOpts = () => {
    const { controlOpts } = this.props
    return controlOpts || {}
  }
  getFnList = () => {
    const controlOpts = this.getControlOpts()
    return controlOpts.fnList || {}
  }
  getFieldName = () => {
    const { controlOpts } = this.props
    if (controlOpts && controlOpts.fieldName) {
      return controlOpts.fieldName
    }
    return ""
  }
  getOnUpdateValue = () => {
    const { controlOpts } = this.props
    console.warn("getOnUpdateValue", controlOpts)
    if (controlOpts && controlOpts.onUpdateValue) {
      return controlOpts.onUpdateValue
    }
    return () => {
      console.warn("Missing onUpdateValue on controlOpts", this.props)
    }
  }
  getAttributes4Selenium = () => {
    const controlOpts: any = this.getControlOpts()
    let relativeControls: any = null
    if (controlOpts?.fnList?.getRelativeControls) {
      relativeControls = controlOpts.fnList.getRelativeControls()
    }
    let _attr = {}
    if (relativeControls) {
      const _relativeFields = Object.keys(relativeControls)
      if (_relativeFields.includes(this.getFieldName())) {
        _attr["data-action-type"] = "changeUI"
      }
    }
    if (!_attr["data-action-type"]) {
      if (controlOpts?.actionType) {
        _attr["data-action-type"] = controlOpts?.actionType
      }
    }
    return _attr
  }
  onUpdateValue = (v, opts: any = null) => {
    const onUpdateValue = this.getOnUpdateValue()
    if (onUpdateValue) {
      onUpdateValue(v, opts)
    }
  }
  onUpdateValueWithFieldName = (v, fieldName = "") => {
    const { controlOpts } = this.props
    if (fieldName) {
      if (controlOpts && controlOpts.onUpdateValueWithFieldName) {
        controlOpts.onUpdateValueWithFieldName(v, fieldName)
      }
    }
  }
  onUpdateMulti = (obj = {}) => {
    const { controlOpts } = this.props
    if (controlOpts && controlOpts.onUpdateMulti) {
      controlOpts.onUpdateMulti(obj)
    }
  }
  getFormat = () => {
    const controlOpts = this.getControlOpts()
    return controlOpts.format
  }
  renderMessage = () => {
    const controlOpts = this.getControlOpts()
    const { ui }: any = controlOpts
    if (ui?.showError && ui.textError) {
      return (
        <Error className="__msgerr" style={ui.styleError}>
          <BiInfoCircle />
          <MyUI type="html" html={ui.textError} style={{ flex: 1 }} />
        </Error>
      )
    } else if (ui?.showInfo && ui.textInfo) {
      return (
        <Info className="__msginf">
          <BiInfoCircle />
          <MyUI type="html" html={ui.textInfo} style={{ flex: 1 }} />
        </Info>
      )
    }
    return <></>
  }
  render() {
    return <div></div>
  }
}

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-text-info);
  font-size: 13px;
  margin: 0.5rem 0;
  & > svg {
    margin-right: 0.25rem;
    width: 15px;
    height: 15px;
  }
`
const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-danger);
  font-size: 13px;
  margin: 0.5rem 0;
  & > svg {
    margin-right: 0.25rem;
    width: 15px;
    height: 15px;
  }
`

export default Control_Ext
