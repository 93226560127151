import styled from "styled-components"
import { forwardRef, useEffect, useRef, useState } from "react"
import classnames from "classnames"
import { MyForm, MyModal } from "src/core/components"
import { useForceUpdate } from "src/modules/app_sigo/hooks/useForceUpdate"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"

const EnterTime = props => {
  const { fnList, tabActive, data } = props
  const refTopForm = useRef<MyForm>(null)
  const [keySearchDateUI, forceUpdateSearchDate] = useForceUpdate()
  const [dataFilter, setDataFilter] = useState(data)
  useEffect(() => {
    forceUpdateSearchDate()
  }, [tabActive])
  const configFormBooking: any = {
    defaultValues: {
      ...dataFilter,
      DateFromTo: [
        dataFilter?.[homeSlidesFields.fTimeStart],
        dataFilter?.[homeSlidesFields.fTimeEnd],
      ],
    },
    controls: [
      {
        label: "",
        fieldName: "DateFromTo",
        type: "input_time_from_to_search",
        more: {
          morePropsDateFlatPickr: {
            calendarStartDay: 0,
            numberOfMonths: 2,
            orientation: "vertical",
          },
          moreOptionsDateFlatPickr: {},
          configMergeText: {
            titleWhenMergeText: "Chọn ngày",
          },
          mappingField: {
            fFrom: homeSlidesFields.fTimeStart,
            fTo: homeSlidesFields.fTimeEnd,
          },
          configSelectTime: {
            titleTimeFrom: "Giờ nhận xe",
            titleTimeTo: "Giờ trả xe",
            titleTimePicker: "Chọn giờ nhận/trả xe",
            descriptionTimePicker: "Thêm giờ chính xác bạn muốn nhận và trả xe",
          },
          classNameGroup: "w-100 datefromto",
        },
      },
    ],
  }

  const onHandleUpdate = (values = {}, needReloadUI = false) => {
    setDataFilter({ ...dataFilter, ...values })
    fnList?.onUpdateTime && fnList?.onUpdateTime({ ...dataFilter, ...values })
    needReloadUI && forceUpdateSearchDate()
  }

  return (
    <Wrap>
      <MyForm
        key={keySearchDateUI}
        ref={refTopForm}
        className="d-flex flex-wrap check-in-out-time"
        configForm={configFormBooking}
        fnList={
          {
            afterUpdateValueControl: opts => {
              const { fieldName, newValue } = opts
              let values = {
                [fieldName]: newValue,
              }
              if (fieldName === "DateFromTo") {
                values[homeSlidesFields.fTimeStart] = newValue[0]
                values[homeSlidesFields.fTimeEnd] = newValue[1]
              }
              onHandleUpdate(values)
            },
          } as any
        }
        renderFooter={() => <></>}
      />
    </Wrap>
  )
}
const Wrap = styled.div`
  & .mc-input-date-from-to .wrap-select-time {
    padding: 0;
  }
`
export default forwardRef(EnterTime)
