import React from "react"
import { Input } from "reactstrap"
import Ext_Input from "./Ext_Input"

class InputTextarea extends Ext_Input {
  render() {
    const propsInput = this.getPropsInput()
    if (propsInput?.disabled) {
      return <input type="textarea" className="form-control" {...propsInput} />
    }
    return <Input {...propsInput} type="textarea" />
  }
}

export default InputTextarea
