import React from "react"
import MyPageHelpers from "src/core/components/MyPage/MyPageHelpers"
const PageHome = React.lazy(
  () => import("src/modules/app_sigo_responsive/components/MyPage/Types/Home")
)
const PageCategory = React.lazy(
  () =>
    import("src/modules/app_sigo_responsive/components/MyPage/Types/Category")
)
const PageDetail = React.lazy(
  () => import("src/modules/app_sigo_responsive/components/MyPage/Types/Detail")
)
const PageHistory = React.lazy(
  () =>
    import("src/modules/app_sigo_responsive/components/MyPage/Types/History")
)
const PageAdminListCard = React.lazy(
  () =>
    import(
      "src/modules/app_sigo_responsive/components/MyPage/Types/AdminListCard"
    )
)
const PageUserProfile = React.lazy(
  () =>
    import(
      "src/modules/app_sigo_responsive/components/MyPage/Types/UserProfile"
    )
)
const PageVerifyAccount = React.lazy(
  () =>
    import(
      "src/modules/app_sigo_responsive/components/MyPage/Types/VerifyAccount"
    )
)
const PageMyService = React.lazy(
  () =>
    import("src/modules/app_sigo_responsive/components/MyPage/Types/MyService")
)
const PageEditMyService = React.lazy(
  () =>
    import(
      "src/modules/app_sigo_responsive/components/MyPage/Types/EditMyService"
    )
)
const PageAddService = React.lazy(
  () =>
    import("src/modules/app_sigo_responsive/components/MyPage/Types/AddService")
)
const PageWSlug = React.lazy(
  () => import("src/modules/app_sigo_responsive/components/MyPage/Types/Slug")
)
const PageSaving = React.lazy(
  () => import("src/modules/app_sigo_responsive/components/MyPage/Types/Saving")
)
const PageHelpUs = React.lazy(
  () => import("src/modules/app_sigo_responsive/components/MyPage/Types/Helpus")
)
const PageNotification = React.lazy(
  () =>
    import(
      "src/modules/app_sigo_responsive/components/MyPage/Types/Notification"
    )
)
const PagePayment = React.lazy(
  () =>
    import("src/modules/app_sigo_responsive/components/MyPage/Types/Payment")
)
const Dev_Test = React.lazy(
  () =>
    import("src/modules/app_sigo_responsive/components/MyPage/Types/Dev_Test")
)
const PageMyTrips = React.lazy(
  () =>
    import("src/modules/app_sigo_responsive/components/MyPage/Types/MyTrips")
)
const PageMyTripsNow = React.lazy(
  () =>
    import("src/modules/app_sigo_responsive/components/MyPage/Types/MyTripsNow")
)
const PageMyTripsHistoty = React.lazy(
  () =>
    import(
      "src/modules/app_sigo_responsive/components/MyPage/Types/MyTripsHistory"
    )
)
const PageMyOrders = React.lazy(
  () =>
    import("src/modules/app_sigo_responsive/components/MyPage/Types/MyOrders")
)
const PageAccount = React.lazy(
  () =>
    import("src/modules/app_sigo_responsive/components/MyPage/Types/Account")
)
const PageMyActivitySchedule = React.lazy(
  () =>
    import(
      "src/modules/app_sigo_responsive/components/MyPage/Types/MyActivitySchedule"
    )
)
const List = {
  home: PageHome,
  category: PageCategory,
  detail: PageDetail,
  history: PageHistory,
  admin_list_card: PageAdminListCard,
  sg_user_profile: PageUserProfile,
  sg_verify_account: PageVerifyAccount,
  sg_my_service: PageMyService,
  sg_edit_my_service: PageEditMyService,
  sg_add_service: PageAddService,
  slug: PageWSlug,
  sg_saving: PageSaving,
  helpus: PageHelpUs,
  sg_notification: PageNotification,
  sg_payment: PagePayment,
  sg_dev_test: Dev_Test,
  mytrips: PageMyTrips,
  my_trips_now: PageMyTripsNow,
  my_trips_history: PageMyTripsHistoty,
  my_orders: PageMyOrders,
  sg_account: PageAccount,
  my_activity_schedule: PageMyActivitySchedule,
}
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List)
  },
}

export default MyPageOne
