import { HLink, HUtils, MyControl, MyUI } from "@macashipo/mlib"
import { MyModal } from "src/core/components"
import { historyFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"
import { isNotEmpty } from "src/core/helpers/utils"
import classNames from "classnames"
import { useForceUpdate } from "src/modules/app_sigo/hooks/useForceUpdate"
import AddressDirection from "src/modules/app_sigo/components/MyUI/Types/TripDetail/AddressDirection"
import RentalDocuments from "src/modules/app_sigo/components/MyUI/Types/TripDetail/RentalDocuments"

const UITypes = {
  documents: (data, config, { fnList }) => {
    const { icon, title, description, fieldData } = config || {}
    const handleOpenModal = () => {
      MyModal.showFromComponent(<RentalDocuments data={data} />, {
        size: "lg",
        modalClassName:
          "default modal-filter modal-understood-footer modal-scrollable-inside",
        onScrollContent: true,
        title: "Thủ tục nhận xe",
        modalScrollInside: true,
        showFooterClose: true,
        titleBtnClose: "Đã hiểu",
        showFooter: true,
        onClickClose: () => {
          MyModal.hideModalScrollContent("component")
        },
      })
    }
    return (
      <WrapOnePart
        className="delivery-address cursor-pointer"
        onClick={handleOpenModal}
      >
        {icon && <i className={icon} />}
        <div className="wrap-content">
          <div className="label">{title}</div>
          <div className="des">{description}</div>
        </div>
      </WrapOnePart>
    )
  },
  address_direction: (data, config, { fnList, buttonCode }) => {
    const { icon, title, fieldData, statusNotPaidYet } = config || {}
    const getIsPaid = () => {
      const statusCode = data?.[historyFields.fOrderStatusCode]
      let notPaidYet = false
      if (statusCode && statusNotPaidYet && statusNotPaidYet?.length > 0) {
        if (statusNotPaidYet.findIndex(v => v === statusCode) > -1) {
          notPaidYet = true
        }
      }
      return notPaidYet
    }
    const getCanShowDirectionButton = () => {
      let notPaidYet = getIsPaid()
      if (data?.[historyFields.fIsUserTakeSelected] && notPaidYet) {
        return false
      }
      return true
    }
    const getDescription = () => {
      let notPaidYet = getIsPaid()
      let des = ""
      if (data?.[historyFields.fIsUserTakeSelected] && notPaidYet) {
        des = "Địa điểm chính xác sẽ hiển thị khi hoàn tất đặt cọc"
      } else {
        des = data?.[historyFields.fDeliveryAddress]
      }
      return des
    }
    const handleOpenDirection = () => {
      MyModal.showFromComponent(
        <AddressDirection
          fnList={fnList}
          data={data}
          buttonCode={buttonCode}
          isPaid={getIsPaid()}
          canShowButton={getCanShowDirectionButton()}
        />,
        {
          size: "lg",
          modalClassName: "default modal-filter modal-scrollable-inside",
          onScrollContent: true,
          title: "Hướng dẫn chỉ đường",
          modalScrollInside: true,
        }
      )
    }
    return (
      <WrapOnePart
        className="delivery-address cursor-pointer"
        onClick={handleOpenDirection}
      >
        {icon && <i className={icon} />}
        <div className="wrap-content">
          <div className="label">{title}</div>
          <div className="des">{getDescription()}</div>
        </div>
      </WrapOnePart>
    )
  },
  popup_html: (data, config) => {
    const { icon, title, description, fieldData, code } = config || {}
    const listThingsToKnow = data?.[fieldData] || []
    let _data: any = null
    if (listThingsToKnow?.length > 0 && code) {
      listThingsToKnow.every(v => {
        if (v?.Code === code) {
          _data = { ...v }
          return false
        }
        return true
      })
    }
    const fullDescription = _data?.Content || ""
    const onOpenModal = () => {
      MyModal.showFromComponent(
        <div
          dangerouslySetInnerHTML={{
            __html: fullDescription,
          }}
        ></div>,
        {
          title: _data?.Title,
          modalClassName:
            "default modal-filter modal-understood-footer modal-scrollable-inside",
          onScrollContent: true,
          titleBtnClose: "Đã hiểu",
          modalScrollInside: true,
          showFooter: true,
          showFooterClose: true,
          onClickClose: () => {
            MyModal.hideModalScrollContent("component")
          },
        }
      )
    }
    if (_data) {
      return (
        <WrapOnePart
          className="w-things-to-know cursor-pointer"
          onClick={onOpenModal}
        >
          {icon && <i className={icon} />}
          <div className="wrap-content">
            <div className="label">{title}</div>
            <div className="des">{description}</div>
          </div>
        </WrapOnePart>
      )
    }
    return <></>
  },
  link: (data, config) => {
    const { icon, title, description, fieldData, moreStyleIcon } = config || {}
    const link = data?.[fieldData]
    const onOpenLink = () => {
      if (link) {
        window.document.body.classList.remove("modal-custom-style")
        const navBar = document.getElementById("sg-navbar-mobile")
        if (navBar) {
          navBar.classList.remove("hide")
        }
        const html: any = document.getElementsByTagName("html")
        if (html?.[0]) {
          html[0].style.overflowY = "scroll"
        }
        HLink.openUrl(`/${link}`, "_self")
      }
    }
    return (
      <WrapOnePart
        className={classNames("delivery-address", link && "cursor-pointer")}
        onClick={onOpenLink}
      >
        {icon && <i className={icon} style={moreStyleIcon} />}
        <div className="wrap-content">
          <div className="label">{title}</div>
          <div className="des">{description}</div>
        </div>
      </WrapOnePart>
    )
  },
}
const GeneralInfo = props => {
  const { data, config, fnList, buttonCode, statusCodeList, statusNotPaidYet } =
    props
  const statusCode = data?.[historyFields.fOrderStatusCode]
  const [keyUI, forceUpdate] = useForceUpdate()

  /** get */
  const getPartsConfig = () => {
    return [
      {
        type: "address_direction",
        canShow: true,
        classWrap: "",
        fieldData: historyFields.fDeliveryAddress,
        title: "Hướng dẫn địa chỉ nhận xe",
        icon: "flaticon-pin",
        statusNotPaidYet: statusNotPaidYet,
      },
      {
        type: "documents",
        canShow: true,
        classWrap: "",
        title: "Thủ tục nhận xe",
        description: "Giấy tờ thuê xe và tài sản thế chấp",
        icon: "flaticon-driving-license",
      },
      {
        type: "popup_html",
        canShow: true,
        classWrap: "",
        title: "Những điều cần biết",
        description: "Quy định chung về sử dụng xe & hủy chuyến",
        icon: "flaticon-resume",
        code: "Things_To_Know_Rent_Experience",
        fieldData: historyFields.fThingsToKnow,
      },
      {
        type: "link",
        canShow: true,
        classWrap: "",
        title: "Thông tin xe thuê",
        description: `${data?.[historyFields.fName]} - ${
          data?.[historyFields.fAddress]
        }`,
        fieldData: historyFields.fSlug,
        icon: "flaticon-car-3",
        moreStyleIcon: {
          fontSize: 40,
          width: 30,
        },
      },
    ]
  }
  /**other */

  /** render */
  const renderRating = () => {
    const rating = data?.[historyFields.fMyRating]
    let btnConfig = fnList?.getButtonConfigByCode(buttonCode?.Review)
    if (btnConfig) {
      const canReview = fnList?.getCanShowByBtnList(buttonCode?.Review)
      btnConfig = {
        ...btnConfig,
        Name: "Đánh giá của bạn",
        Code: "NewReview",
        canReview: canReview,
        defaultRating: rating,
      }
    }
    const buttonList = [{ ...btnConfig }]
    const moreConfigActions =
      fnList?.getConfigFromFirstSettingByKey("moreConfigActions") || {}
    // only when status = DONE
    if (statusCode === statusCodeList?.DONE) {
      return (
        <MyUI
          type="tripdetail_action"
          data={data}
          fnList={{
            ...fnList,
            fnReloadList: () => {
              fnList?.fnReloadList &&
                fnList?.fnReloadList({
                  cbSuccess: () => {
                    forceUpdate()
                  },
                })
            },
          }}
          buttonList={buttonList}
          key={keyUI}
          moreConfig={{
            ...moreConfigActions,
            Review: {
              ...moreConfigActions?.["Review"],
              review: {
                reviewContent: {
                  title: "Viết đánh giá công khai",
                  description:
                    "Chúng tôi sẽ hiển thị phần đánh giá này cho các khách thuê khác, và sau khi kết thúc giai đoạn đánh giá, chúng tôi sẽ đăng trên trang chủ của chủ xe [AnotherUserInfo.Name]",
                },
                reviewRating: {
                  title: "Về tổng thể, chuyến đi của bạn thế nào?",
                  avatarField: `${historyFields.fAnotherUserInfo}.${historyFields.fAnotherUserAvatarUrl}`,
                  description:
                    "Hãy cho chúng tôi một đánh giá chung nhất về toàn bộ trải nghiệm chuyến đi của bạn từ xe của chủ xe này",
                },
              },
            },
          }}
        />
      )
    }
    return <></>
  }
  const renderRetalDate = () => {
    const timeText = data?.[historyFields.fHeaderInfo] || {}
    const _slideConfig =
      fnList?.getConfigFromFirstSettingByKey("configTripCarImageSlide") || {}
    const dfImage = config?.defaultImage
    let detailUrl = ""
    const imgList = data?.[historyFields.fRentalServiceItemImageUrls]
    const fromDate = data?.[historyFields.fFromDate_Format_1]
    const toDate = data?.[historyFields.fToDate_Format_1]
    return (
      <WrapRentalDate>
        {/* {timeText?.Title && (
          <div className="subtitle mb-25px">{timeText?.Title}</div>
        )} */}
        {renderRating()}
        <MyUI
          type="slide_card"
          config={{
            only_image: true,
            slide_options: {
              items: 1,
              loop: false,
              dots: true,
              rewind: false,
              nav: true,
              lazyLoad: true,
              ..._slideConfig,
            },
            default_image: dfImage,
          }}
          classNameWrap="wrap-detail-slide mb-40px"
          data={{
            SlideImages: [...imgList],
            Url: detailUrl,
            UrlTarget: "_blank",
          }}
        />
        <div className="d-flex rental-date">
          <div className="from">
            <div className="label mauden">Nhận xe</div>
            <div style={{ whiteSpace: "pre-wrap" }}>{fromDate}</div>
          </div>
          <div className="to ">
            <div className="label mauden">Trả xe</div>
            <div style={{ whiteSpace: "pre-wrap" }}>{toDate}</div>
          </div>
        </div>
      </WrapRentalDate>
    )
  }
  const renderInformationAndGuide = () => {
    const parts = getPartsConfig()
    return (
      <WrapParts className="devitem-line">
        {parts &&
          parts?.length > 0 &&
          parts.map((v, i) => {
            if (!isNotEmpty(v?.canShow) || v?.canShow) {
              return (
                <div
                  className={classNames(
                    "one-info mb-15px",
                    v?.classWrap ? v?.classWrap : ""
                  )}
                  key={`part-${i}`}
                >
                  {UITypes?.[v.type]
                    ? UITypes[v.type](data, v, {
                        fnList: {
                          ...fnList,
                        },
                        buttonCode: buttonCode,
                      })
                    : null}
                </div>
              )
            }
            return <></>
          })}
      </WrapParts>
    )
  }
  return (
    <Wrap className="generalInfo">
      {renderRetalDate()}
      {renderInformationAndGuide()}
    </Wrap>
  )
}
const Wrap = styled.div``
const WrapRentalDate = styled.div`
  & .react-rater {
    & .fa-star {
      font-size: 22px;
      width: 25px;
      color: #ccc;
      text-align: center;
      &.active,
      &.hover {
        color: var(--mau1);
      }
    }
  }
  & .img {
    width: 100%;
    object-fit: cover;
    max-height: 378px;
    border-radius: 15px;
    border: 1px solid #dfdfdf;
  }
  & .wrap-detail-slide {
    img {
      width: 100%;
      object-fit: cover;
      max-height: 378px;
      border-radius: 15px;
      border: 1px solid #dfdfdf;
    }
  }
  & .rental-date {
    justify-content: space-between;
    & .label {
      text-transform: uppercase;
      font-weight: 600;
      line-height: 26px;
      padding-bottom: 8px;
    }
    & .to,
    & .from {
      width: 50%;
      line-height: 26px;
    }
    & .from {
      border-right: 1px solid #edecec;
    }
    & .to {
      text-align: right;
    }
  }
  & .wrap-rating {
    margin-top: -10px;
    margin-bottom: 25px;
    & .tt {
      line-height: 22px;
      color: #5f5d5d;
    }
    & .mc_input_rate {
      margin-top: 10px;
    }
  }
`
const WrapParts = styled.div`
  & .label {
    font-weight: 600;
    color: var(--mauden);
  }
  & .des {
    color: #9f9d9d;
  }
`
const WrapOnePart = styled.div`
  display: flex;
  align-items: flex-start;
  i {
    font-size: 30px;
    display: flex;
    height: 30px;
    margin-right: 15px;
    color: var(--mauden);
  }
  & .wrap-content {
    width: calc(100% - 50px);
  }
`
const WrapPartWithModal = styled.div``
export default GeneralInfo
