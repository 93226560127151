import { HApi, HConfig } from "src/core/helpers"

export const logException = ({ AIPUrl, Data, Error }: any) => {
  let _userId = HConfig.getAuthUserId() || ""
  let _userName = HConfig.getAuthUserName() || "Guest"
  let _errorMsg = ""
  if (Error?.name) {
    _errorMsg = Error.name + " - "
  }
  if (Error?.message) {
    _errorMsg += Error.message
  }
  if (Error?.stack) {
    let _stack = Error.stack.split("\n")
    if (_stack?.length > 1) {
      _errorMsg += _stack[1]
    }
  }
  let _data = {
    LogBy: `[${_userId}] ${_userName}`,
    AIPUrl: AIPUrl || "",
    Url: window.location.href,
    Error: _errorMsg,
    Data: Data || {},
  }
  HApi.apiGeneric({
    method: "POST",
    url: "Exception/SaveLog",
    data: _data,
  })
    .then(response => {
      console.warn("axios response:", response)
    })
    .catch(error => {
      console.warn("axios error:", error)
    })
}
