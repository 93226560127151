import React from "react"
import MyButtonHelpers from "src/core/components/MyButton/MyButtonHelpers"
import { CSuppense } from "src/core/components"

const MyButtonLink = React.lazy(() => import("./Types/link"))
const MyButtonApi = React.lazy(() => import("./Types/api"))
const MyButtonForm = React.lazy(() => import("./Types/form"))
const MyUIOne = {
  init() {
    MyButtonHelpers.addTypes("link", props => {
      return CSuppense(props, MyButtonLink)
    })
    MyButtonHelpers.addTypes("api", props => {
      return CSuppense(props, MyButtonApi)
    })
    MyButtonHelpers.addTypes("form", props => {
      return CSuppense(props, MyButtonForm)
    })
  },
}

export default MyUIOne
