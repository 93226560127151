import { historyFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const RentalDocuments = props => {
  const { data } = props
  const getDescription = () => {
    const list = data?.[historyFields.fDocuments] || []
    if (list?.length > 1) {
      return `Bạn phải chuẩn bị ${list?.length} loại giấy tờ cá nhân sau đây`
    }
    return "Bạn phải chuẩn bị giấy tờ cá nhân sau đây"
  }
  const renderRentalDocuments = () => {
    const list = data?.[historyFields.fDocuments]
    if (list && list?.length > 0) {
      return (
        <div className="">
          <div className="title">Giấy tờ khi thuê</div>
          <div className="des">{getDescription()}</div>
          <WrapDocuments className="w-documents wrap-list mt-15px">
            {list?.map((v, i) => {
              const icon = v?.Icon || v?.IconUrl || ""
              let isIconFont = false
              if (icon && icon?.startsWith("flaticon")) {
                isIconFont = true
              }
              return (
                <div className="one-document" key={`one-document-${i + 1}`}>
                  {isIconFont ? (
                    <i className={icon} />
                  ) : (
                    // <i className="flaticon-profile" />
                    <img src={v?.IconUrl} alt="" />
                  )}
                  <div className="name">{v?.Text}</div>
                </div>
              )
            })}
          </WrapDocuments>
        </div>
      )
    }
    return <></>
  }
  const renderSelectedSecurity = () => {
    const list = data?.[historyFields.fSecurity]
    if (list && list?.length > 0) {
      return (
        <div className="mt-15px devitem-line">
          <div className="title">Tài sản thế chấp</div>
          <div className="des">Tài sản thế chấp được lựa chọn</div>
          <WrapDocuments className="w-security wrap-list mt-15px">
            {list?.map((v, i) => {
              const icon = v?.Icon || v?.IconUrl || ""
              let isIconFont = false
              if (icon && icon?.startsWith("flaticon")) {
                isIconFont = true
              }
              return (
                <div className="one-document" key={`one-document-${i + 1}`}>
                  {isIconFont ? (
                    <i className={icon} />
                  ) : (
                    <img src={v?.IconUrl} alt="" />
                  )}
                  <div className="name">{v?.Text}</div>
                </div>
              )
            })}
          </WrapDocuments>
        </div>
      )
    }
    return <></>
  }
  return (
    <Wrap>
      {renderRentalDocuments()}
      {renderSelectedSecurity()}
    </Wrap>
  )
}
const Wrap = styled.div`
  & .title {
    color: #575757;
    font-size: 20px;
    font-weight: 700;
  }
  & .des {
    color: #575757;
    padding-top: 15px;
  }
  & .devitem-line {
    margin-top: 30px;
  }
`
const WrapDocuments = styled.div`
  &.wrap-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0px, 1fr));
    gap: 1rem;
    margin-right: 0px;
    margin-left: 0px;
  }
  & .one-document {
    border: 1px solid var(--color-border-d3d3d3);
    padding: 15px;
    border-radius: 10px;
    img {
      width: 40px;
      max-height: 40px;
      object-fit: cover;
      display: flex;
    }
    i {
      font-size: 40px;
      color: var(--color-text-000);
      display: flex;
    }
    & .name {
      padding-top: 15px;
      font-weight: 600;
      font-size: 16px;
      line-height: 21.86px;
      color: #404040;
    }
  }
  @media (max-width: 1499x) {
    &.wrap-list {
      grid-template-columns: repeat(3, minmax(0px, 1fr));
    }
  }
  @media (max-width: 1199x) {
    &.wrap-list {
      grid-template-columns: repeat(3, minmax(0px, 1fr));
    }
  }
  @media (max-width: 567px) {
    &.wrap-list {
      grid-template-columns: repeat(1, minmax(0px, 1fr));
      gap: 0.75rem;
    }
  }
`
export default RentalDocuments
