import React from "react"
import MyTableFilterControlHelpers from "src/core/components/MyTableFilterControl/MyTableFilterControlHelpers"
import { CSuppense } from "src/core/components"
const MyFilterControlDateFromTo = React.lazy(() => import("./Types/datefromto"))
const MyTableFilterControl = {
  init() {
    //wrap
    // MyTableFilterControlHelpers.addTypesWrap("_default", props => {
    //   return CSuppense(props, MyTFCWrap)
    // })
    //control
    let _mapControl = {
      datefromto: MyFilterControlDateFromTo,
    }
    for (let k of Object.keys(_mapControl)) {
      MyTableFilterControlHelpers.addTypes(k, props => {
        return CSuppense(props, _mapControl[k])
      })
    }
  },
}

export default MyTableFilterControl
