import React from "react"
import { MyTableCellHelpers, HUtils, HJson, HCF } from "@macashipo/mlib"
import { KMore } from "src/helpers/Flows/TableCell"
import { KConfigApp } from "src/helpers/K"
const MTC_readonly = React.lazy(() => import("./Types/readonly"))
const MTC_with_myui = React.lazy(() => import("./Types/with_myui"))
const List = {
  readonly: MTC_readonly,
  with_myui: MTC_with_myui,
}
const MyTableCellOne = {
  init() {
    MyTableCellHelpers.addTypesFromListLazy(List)
    MyTableCellHelpers.Types["_auto"] = (cell, row, extra, index) => {
      let _type = HUtils.get(extra, "Type.type")
      let _more = HUtils.get(extra, "Type.more")
      // console.log("_more:", _more)
      if (
        _more &&
        _more.indexOf(KMore.typeAdmin) > -1 &&
        HCF.getCF(KConfigApp.verAdmin) == "v5"
      ) {
        let _moreObj = HJson.getObj(_more)
        if (_moreObj && _moreObj[KMore.typeAdmin]) {
          _type = _moreObj[KMore.typeAdmin]
        }
      }
      if (MyTableCellHelpers.Types[_type]) {
        let TagCell = MyTableCellHelpers.Types[_type]
        //phai tra ve dang tag moi de nhan dien trong TableColumn
        return <TagCell cell={cell} row={row} extra={extra} index={index} />
      } else {
        if (MyTableCellHelpers.Types.notexist) {
          return MyTableCellHelpers.Types.notexist({ cell, row, extra, index })
        }
        return MyTableCellHelpers.Types._empty({ cell, row, extra, index })
      }
    }
    MyTableCellHelpers["ConfigByTypes"] = {
      api: {
        isHide: oneTableCell => {
          if (oneTableCell) {
            let _valueCell = oneTableCell.getValue()
            // console.log("check is Hide")
            if (_valueCell === "" || _valueCell === null) return true
          }
        },
      },
      datetime: {
        moreAttrs: {
          moreOptions: {
            enableTime: true,
            dateFormat: "d/m/Y H:i",
          },
        },
      },
    }
  },
}

export default MyTableCellOne
