import styled from "styled-components"
import { WrapOneCollapse } from "./Searchbar"
import { forwardRef, useEffect, useRef, useState } from "react"
import { Collapse } from "reactstrap"
import { HLocalStorage, HUtils } from "@macashipo/mlib"
import { geocodeByPlaceId } from "react-google-places-autocomplete"
import classnames from "classnames"
import { categoryFields } from "src/modules/app_sigo/constants/fields"
import { BsClock } from "react-icons/bs"
import GooglePlacesAutocomplete from "src/modules/app_sigo/components/MyLib/Types/search_address_mobile.js"
import { MyModal } from "src/core/components"

const EnterAddress = props => {
  const { open = false, title, label, moreConfig, fnList, address } = props
  const [value, setValue] = useState(address || "")
  const [loading, setLoading] = useState(false)
  const [focusing, setFocusing] = useState(false)
  const autoCompleteRef = useRef<any>()

  useEffect(() => {
    setValue(address || "")
  }, [address])

  /**Get */
  const getListRecentlyAddressFromLocal = () => {
    const list = HLocalStorage.getObj("recentlyAddress", [])
    return list
  }
  const getDateText = date => {
    const yyyy = date.getFullYear()
    let mm = date.getMonth() + 1
    let dd = date.getDate()
    return "Ngày " + dd + " tháng " + mm + " năm " + yyyy
  }
  const getCurAddressText = value => {
    const _address = HUtils.get(
      value,
      `${categoryFields.fAddress}_MoreInfo[0].address_components`
    )
    let _result = HUtils.get(value, `${categoryFields.fAddress}`)
    if (_address && _address?.length > 0) {
      _address.forEach(v => {
        const _type = HUtils.get(v, "types")
        const name = HUtils.get(v, "long_name")
        if (_type && _type?.length > 0) {
          _type.every(t => {
            if (t === "postal_code" || t === "country") {
              if (_result?.includes(name)) {
                _result = _result.replace(name, "")
              }
              return false
            }
            return true
          })
        }
      })
    } else {
      _result = onRemoveVietNam(_result)
    }
    return _result?.replace(/,+\s*$/, "")
  }
  const updateAdress = (v, { afterUpdate }: any = {}) => {
    setValue(v)
    afterUpdate && afterUpdate()
  }
  const doUpdate = (obj, { skipCloseDropdown = false }: any = {}) => {
    if (fnList?.afterUpdateMultiValues) {
      fnList.afterUpdateMultiValues(
        {
          values: obj,
        },
        {
          afterUpdate: () => {
            if (!skipCloseDropdown) {
              fnList?.onHanleDropdown && fnList?.onHanleDropdown(false)
            }
          },
        }
      )
    }
  }
  const onUpdate = (v, fnList) => {
    const fieldAddress = moreConfig?.fieldAddress || ""
    const fieldAddressInfo = `${fieldAddress}_MoreInfo`
    let more = {
      cbSuccess: v?.cbSuccess,
      cbError: v?.cbError,
    }

    if (v?.Value) {
      updateAdress(v.Text, {
        afterUpdate: () => {
          let obj: any = {
            [fieldAddress]: v.Text,
            [fieldAddressInfo]: null,
          }
          let _placeId = HUtils.get(v, "More.place_id")
          if (_placeId) {
            setLoading(false)

            geocodeByPlaceId(_placeId)
              .then(results => {
                setLoading(false)
                console.log("results:", results)
                let _formated = HUtils.get(results, "0.formatted_address")
                if (_formated) {
                  v.label = _formated
                  updateAdress(_formated)
                  obj[fieldAddress] = _formated
                }
                if (results && results.length > 0) {
                  obj[fieldAddressInfo] = results
                }
                doUpdate(obj)
              })
              .catch(error => {
                setLoading(false)
                doUpdate(obj)
              })
          } else {
            doUpdate(obj)
          }
        },
      })
    } else {
      updateAdress(null, {
        afterUpdate: () => {
          const obj = {
            [fieldAddress]: null,
            [fieldAddressInfo]: null,
          }
          doUpdate(obj, { skipCloseDropdown: true })
        },
      })
    }
    if (v?.cbChange) {
      v.cbChange(value)
    }
  }
  const onRemoveVietNam = str => {
    const regex = /\s*,?\s*việt nam/gi

    return str?.replace(regex, "") || ""
  }
  const onHandleSelectAddressFromList = value => {
    if (value) {
      setValue(value?.Address)
      fnList?.updateAddress &&
        fnList?.updateAddress(value, {
          afterUpdate: () => {},
        })
    }
  }
  const onHandleSelectAddressFromListRecently = value => {
    if (value) {
      setValue(value?.Address)
      fnList?.updateAddress &&
        fnList?.updateAddress(value, {
          afterUpdate: () => {},
          updateWithTime: true,
        })
    }
  }
  /** Render */
  const renderRecentlyAddress = () => {
    const list = getListRecentlyAddressFromLocal()
    if (list && list?.length > 0) {
      return (
        <WrapRecentlyAddress className="wrap-recommend">
          <div className="sub-title">{moreConfig?.recentSearch}</div>
          {list?.map((v, i) => {
            let isActive = false
            let _start = v?.[categoryFields.fFromDate]
            let _text = ""
            if (_start) {
              _text = getDateText(new Date(_start))
            }
            let _end = v?.[categoryFields.fToDate]
            if (_end) {
              _text += " - " + getDateText(new Date(_end))
            }
            return (
              <div
                key={i}
                className={classnames("one-address", isActive && "active")}
                onClick={e => {
                  e.stopPropagation()
                  onHandleSelectAddressFromListRecently(v)
                }}
              >
                <div className="wrap-ic">
                  <BsClock />
                </div>
                <div className="address-content">
                  <div className="address">{getCurAddressText(v)}</div>
                  <div className="time">
                    {_text || moreConfig?.defaultRecentlyLocationTimeText}
                  </div>
                </div>
              </div>
            )
          })}
        </WrapRecentlyAddress>
      )
    }
    return <></>
  }
  const renderPopularLocation = () => {
    const sourceList = fnList?.getSourceList && fnList?.getSourceList()
    let list: any = []
    if (sourceList) {
      list = sourceList?.PopularPlaces || []
    }
    if (list && list?.length > 0) {
      return (
        <WrapPopularAddress className="wrap-recommend">
          <div className="sub-title">{moreConfig?.popularAddressTitle}</div>
          <div className="list">
            {list?.map((v, i) => {
              let isActive = false
              return (
                <div
                  className={classnames("one-address", isActive && "active")}
                  onClick={() => {
                    onHandleSelectAddressFromList(v)
                  }}
                  key={i}
                >
                  <span>{v?.Name}</span>
                </div>
              )
            })}
          </div>
        </WrapPopularAddress>
      )
    }
    return <></>
  }
  return (
    <Wrap
      className={classnames("collapse-content", focusing ? "focusing" : "")}
    >
      {!focusing ? (
        <div
          className="btn-close-modal"
          onClick={() => {
            MyModal.hideModalComponent()
          }}
        >
          <i className="flaticon-close" />
        </div>
      ) : (
        <div
          className="btn-close-modal focusing"
          onClick={() => {
            ;(autoCompleteRef?.current as any)?.lostFocusInput()
            setFocusing(false)
            fnList?.onHandleHideFooter && fnList?.onHandleHideFooter(false)
          }}
        >
          <i className="ic-back flaticon-back-button" />
        </div>
      )}
      <div className="title mb-16px">Bạn muốn thuê xe ở đâu?</div>
      <GooglePlacesAutocomplete
        className="mc-select2"
        type="react_google_places_autocomplete_custom_dropdown"
        ref={autoCompleteRef}
        autocompletionRequest={{
          componentRestrictions: {
            country: "vn",
          },
        }}
        // key={keySearchAddress}
        apiOptions={{
          language: "vi",
        }}
        selectProps={{
          placeholder: moreConfig?.placeholderInputAddress || "Nhập địa điểm",
          defaultValue: value,
          onChange: (v, opts) => {
            if (opts?.isWaiting === false) {
              onUpdate(v, fnList)
            } else {
              onUpdate(v, fnList)
            }
          },
          configMsg: moreConfig?.configMsg4InputAddress || {},
          hasFirstDropDown: true,
          fnRenderPopularrLocation: () => {
            return (
              <div className="wrap-first-dropdown">
                {renderPopularLocation()}
              </div>
            )
          },
          fnRenderRecentlyAddress: () => {
            return (
              <div className="wrap-first-dropdown">
                {renderRecentlyAddress()}
              </div>
            )
          },
          moreConfig: {
            renderCustomOption: true,
          },
          setFocusing: value => {
            setFocusing(value)
            fnList?.onHandleHideFooter && fnList?.onHandleHideFooter(value)
          },
        }}
      />
    </Wrap>
  )
}
const Wrap = styled.div`
  & .title {
    color: var(--mauden);
    font-size: 22px;
    font-weight: 700;
  }
  & .sub-title {
    font-size: 14px;
    color: #7b7878;
    margin: 16px 0;
    line-height: 19.12px;
  }
  & .btn-close-modal {
    font-size: 20px;
    position: fixed;
    top: 19px;
    left: 16px;
    z-index: 1;
    color: var(--mauden);
    i {
      display: flex;
    }
    & .ic-back {
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }
  &.focusing {
    background: #fff;
    height: 100%;
    position: fixed;
    left: 0;
    width: 100%;
    max-width: 100%;
    top: 53px;
    padding: 16px;
    border-radius: 30px;
    box-shadow: 0px 4px 10px 0px #00000026;
  }
`
const WrapPopularAddress = styled.div`
  & .list {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    & .one-address {
      margin-bottom: 8px;
      margin-right: 8px;
      font-size: 14px;
    }
    & .one-address:last-child {
      margin-right: 0;
    }
  }
  & .one-address {
    border: 1px solid var(--color-border-control);
    transition: ease-in-out all 0.3s;
    padding: 5px 13px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 600;
    color: var(--color-text-363636);
    &.active {
      border-color: var(--color-border-blue-light);
      background-color: var(--color-bg-blue-light);
      user-select: none;
      pointer-events: none;
      cursor: context-menu;
    }
  }
`
const WrapRecentlyAddress = styled.div`
  & .one-address {
    margin-top: -5px;
    transition: ease-in-out all 0.3s;
    display: flex;
    align-items: flex-start;
    padding: 8px;
    margin-left: -8px;
    cursor: pointer;
    border: 1px solid transparent;
    & .wrap-ic {
      margin-top: 3px;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #dddddd;
      margin-right: 16px;
    }
    & svg {
      font-size: 18px;
      color: var(--color-text-000);
    }
    & .address-content {
      color: #7b7878;
      width: calc(100% - 56px);
      & .address {
        font-weight: 600;
        color: var(--color-text-363636);
        margin: 0;
        font-size: 14px;
        line-height: 19.12px;
      }
      & .time {
        margin-top: 3px;
        font-size: 13px;
        line-height: 16px;
      }
    }
    &.active {
      user-select: none;
      pointer-events: none;
      cursor: context-menu;
      background-color: #ebebeb;
      border-color: #ebebeb;
      border-radius: 8px;
    }
  }
`
export default forwardRef(EnterAddress)
