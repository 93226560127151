import MyIconHelpers from "src/core/components/MyIcon/MyIconHelpers"
// import * as IconHi from "react-icons/hi"
import {
  HiBadgeCheck,
  HiBell,
  HiCheck,
  HiMenu,
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineHome,
  HiOutlineKey,
  HiOutlineLockClosed,
  HiOutlineMail,
  HiOutlinePencilAlt,
  HiOutlinePhone,
  HiOutlineTrash,
  HiOutlineUser,
  HiOutlineViewGrid,
  HiRefresh,
  HiUser,
  HiUsers,
  HiX,
} from "react-icons/hi"
const ListIconHi = {
  HiBadgeCheck,
  HiBell,
  HiCheck,
  HiMenu,
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineHome,
  HiOutlineKey,
  HiOutlineLockClosed,
  HiOutlineMail,
  HiOutlinePencilAlt,
  HiOutlinePhone,
  HiOutlineTrash,
  HiOutlineUser,
  HiOutlineViewGrid,
  HiRefresh,
  HiUser,
  HiUsers,
  HiX,
}
const MyTableCellOne = {
  init() {
    console.warn("init icon:")
    // for (let k of Object.keys(IconHi)) {
    //   MyIconHelpers.addTypes(k.toLocaleLowerCase(), IconHi[k])
    // }
    for (let k of Object.keys(ListIconHi)) {
      MyIconHelpers.addTypes(k.toLocaleLowerCase(), ListIconHi[k])
    }
    // import("react-icons/hi").then(v => {
    //   console.warn("import:", v)
    //   if (v) {
    //     let _keys = Object.keys(v)
    //     for (let k of _keys) {
    //       MyIconHelpers.addTypes(k, v[k])
    //     }
    //   }
    // })
  },
}

export default MyTableCellOne
