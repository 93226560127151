import { HUtils, MyUI } from "@macashipo/mlib"
import { useEffect, useRef, useState } from "react"
import { Button } from "reactstrap"
import { HApi } from "src/core/helpers"
import { historyFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"
import GeneralInfo from "./GeneralInfo"
import { MyModal } from "src/core/components"
import { ConnectedProps, connect } from "react-redux"
import MessageAboveActions from "./MessageAboveActions"
import Skeleton from "react-loading-skeleton"

const mapStateToProps = state => ({
  dataFirstSetting: state.app.dataFirstSetting,
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)
interface Props extends ConnectedProps<typeof connector> {
  data?: any
  fnList?: any
  canShow?: boolean
}
const MyUITripDetail = (props: Props) => {
  const { data, fnList, canShow, dataFirstSetting } = props
  const [myData, setMyData] = useState(null as any)
  const [loading, setLoading] = useState(true)
  const [configFirstSetting, setConfigFirstSetting] = useState(
    HUtils.get(dataFirstSetting, "DefaultValues.websiteSetting") || {}
  )
  const [actionsHeight, setActionsHeight] = useState(0)
  const [topFixedHeight, setTopFixedHeight] = useState(0)
  const actionsRef = useRef(null)
  const topFixedRef = useRef(null)

  useEffect(() => {
    if (canShow) {
      setLoading(true)
      setTimeout(() => {
        requestData()
      }, 700)
    }
  }, [canShow])
  useEffect(() => {
    setConfigFirstSetting(
      HUtils.get(dataFirstSetting, "DefaultValues.websiteSetting")
    )
    // eslint-disable-next-line
  }, [dataFirstSetting])
  useEffect(() => {
    if (!actionsRef.current) return
    const actionsRefObserver = new ResizeObserver(entries => {
      let height = entries[0].contentRect.height
      if (height) {
        setActionsHeight(height)
      }
    })

    actionsRef.current && actionsRefObserver.observe(actionsRef.current)
    return () => {
      actionsRefObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionsRef.current, myData])
  useEffect(() => {
    if (!topFixedRef.current) {
      return
    }
    const topFixedRefObserve = new ResizeObserver(entries => {
      let height = entries[0].contentRect.height
      if (height) {
        setTopFixedHeight(height)
      }
    })
    topFixedRef.current && topFixedRefObserve.observe(topFixedRef.current)

    return () => {
      topFixedRefObserve.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topFixedRef.current, myData])
  useEffect(() => {
    const body = document.getElementsByTagName("body")
    if (body) {
      body[0].classList.add("modal-custom-style")
    }
  }, [])

  const requestData = ({
    notLoading = false,
    cbError,
    cbSuccess,
  }: any = {}) => {
    if (canShow && data?.[historyFields.fOrderNumber]) {
      if (!notLoading) {
        setLoading(true)
      }
      HApi.apiPost({
        url: "RentalService/GetOrderDetail",
        data: {
          OrderNumber: data?.[historyFields.fOrderNumber],
        },
      })
        .then(response => {
          setLoading(false)
          setMyData(response.Data)
          if (cbSuccess) {
            cbSuccess(response.Data)
          }
        })
        .catch(error => {
          setLoading(false)
          cbError && cbError()
        })
    }
  }
  /** Get */
  const getIdOverlay = () => {
    const id = data?.[historyFields.fOrderNumber] || ""
    return id
  }
  const getMyFnList = () => {
    return {
      getCanShowByBtnList: getCanShowByBtnList,
      getActionButtonCode: getActionButtonCode,
      getButtonConfigByCode: getButtonConfigByCode,
      getConfigFromFirstSettingByKey: getConfigFromFirstSettingByKey,
      fnReloadList: ({ cbSuccess, cbError }: any = {}) => {
        requestData({ notLoading: true, cbSuccess, cbError })
      },
    }
  }
  const getConfigFromFirstSettingByKey = key => {
    const orderDetail = HUtils.get(configFirstSetting, "order_details")
    if (orderDetail) {
      return HUtils.get(orderDetail, key)
    }
    return
  }
  const getActionButtonCode = () => {
    return getConfigFromFirstSettingByKey("buttonCode") || {}
  }
  const getCanShowByBtnList = code => {
    let btnList = myData?.[historyFields.fButtons]
    let canShow = false
    if (btnList && btnList?.length > 0) {
      btnList.every(v => {
        if (v?.Code === code) {
          canShow = true
          return false
        }
        return true
      })
    }
    return canShow
  }
  const getButtonList = () => {
    let list: any = []
    const listButtonNeedRemoveInFooter =
      getConfigFromFirstSettingByKey("exceptButtonCodes") || []
    const buttonList: any = myData?.[historyFields.fButtons] || []
    const statusCode = myData?.[historyFields.fOrderStatusCode]
    const listStatusCode = getConfigFromFirstSettingByKey("statusCode") || {}
    if (buttonList?.length > 0) {
      buttonList.forEach(v => {
        if (listButtonNeedRemoveInFooter.findIndex(c => c === v?.Code) === -1) {
          list.push(v)
        }
      })
    }
    if (
      statusCode === listStatusCode?.OWNER2CONFIRM
      // ||
      // statusCode === listStatusCode?.INTHETRIP
    ) {
      list.push({
        Color_BG: "var(--mau1)",
        Color: "custom",
        NeedReloadList: true,
        Name: "Đã hiểu",
        Code: "Understand",
        Url: null,
        fnOnClick: () => {
          onCloseDetailOrder()
        },
      })
    }
    return list
  }
  const getButtonConfigByCode = code => {
    let _btnConfig: any = {}
    const buttonList: any = myData?.[historyFields.fButtons] || []
    if (buttonList && buttonList?.length > 0) {
      buttonList.every(v => {
        if (v?.Code === code) {
          _btnConfig = { ...v }
          return false
        }
        return true
      })
    }
    return _btnConfig
  }
  /** Other */
  const onCloseDetailOrder = () => {
    fnList?.hideDetailOrder && fnList?.hideDetailOrder()
    fnList?.fnReloadList && fnList.fnReloadList()
  }
  /** Render */
  const renderMap = () => {
    const UserTakeLat = myData?.[historyFields.fLatitude]
    const UserTakeLng = myData?.[historyFields.fLongitude]
    const statusNotPaidYet = getConfigFromFirstSettingByKey("statusNotPaidYet")
    const statusCode = myData?.[historyFields.fOrderStatusCode]
    let notPaidYet = false
    if (statusCode && statusNotPaidYet && statusNotPaidYet?.length > 0) {
      if (statusNotPaidYet.findIndex(v => v === statusCode) > -1) {
        notPaidYet = true
      }
    }
    let msg = ""
    if (myData?.[historyFields.fIsUserTakeSelected] && notPaidYet) {
      msg = "Địa điểm chính xác sẽ hiển thị khi hoàn tất đặt cọc"
    }
    if (UserTakeLng && UserTakeLat) {
      return (
        <MyUI
          type="sg_vehicle_map"
          data={{ longitude: UserTakeLng, latitude: UserTakeLat }}
          moreConfig={{ titleMaker: msg, hideTooltip: msg ? false : true }}
        />
      )
    }
    // return (
    //   <MyUI
    //     type="sg_vehicle_map"
    //     data={{ longitude: 106.697534, latitude: 10.8058575 }}
    //   />
    // )
    return <></>
  }
  const renderGeneralInfo = () => {
    const statusCodeList = getConfigFromFirstSettingByKey("statusCode")
    const statusNotPaidYet = getConfigFromFirstSettingByKey("statusNotPaidYet")
    const buttonCode = getActionButtonCode()
    return (
      <div className="one-part">
        <MyUI
          type="tripdetail_general_info"
          data={myData}
          fnList={getMyFnList()}
          buttonCode={buttonCode}
          statusCodeList={statusCodeList}
          statusNotPaidYet={statusNotPaidYet}
        />
      </div>
    )
  }
  const renderVehicleInfo = () => {
    const buttonCode = getActionButtonCode()
    const vehicalInfoCf = getConfigFromFirstSettingByKey("vehicleInfo")
    return (
      <div className="one-part mt-15px">
        <MyUI
          type="tripdetail_vehicle_info"
          data={myData}
          fnList={getMyFnList()}
          buttonCode={buttonCode}
          moreConfig={vehicalInfoCf}
        />
      </div>
    )
  }
  const renderPaymentInfo = () => {
    return (
      <div className="one-part mt-15px">
        <MyUI
          type="tripdetail_payment_info"
          data={myData}
          fnList={getMyFnList()}
        />
      </div>
    )
  }
  const renderOwnerInfo = () => {
    return (
      <div className="one-part mt-15px">
        <MyUI
          type="tripdetail_owner_info"
          data={myData}
          fnList={getMyFnList()}
        />
      </div>
    )
  }
  const renderSupportInfo = () => {
    return (
      <div className="one-part mt-15px">
        <MyUI
          type="tripdetail_support_info"
          data={myData}
          fnList={getMyFnList()}
        />
      </div>
    )
  }
  const renderInsuranceInfo = () => {
    return (
      <div className="one-part mt-15px">
        <MyUI
          type="tripdetail_insurance_info"
          data={myData}
          fnList={getMyFnList()}
        />
      </div>
    )
  }
  const renderActions = () => {
    const moreConfigActions =
      getConfigFromFirstSettingByKey("moreConfigActions") || {}
    const footer = myData?.[historyFields.fFooterInfo]
    const buttonList = getButtonList()
    if ((buttonList && buttonList?.length > 0) || footer?.Title) {
      return (
        <WrapButtons
          className="wrap-buttons one-part"
          btnLength={buttonList?.length}
          ref={actionsRef}
        >
          <MessageAboveActions
            data={myData}
            fnList={{
              ...getMyFnList(),
              onReload: () => requestData({ notLoading: true }),
            }}
          />
          {buttonList?.length > 0 && (
            <MyUI
              type="tripdetail_action"
              data={myData}
              fnList={getMyFnList()}
              buttonList={buttonList}
              moreConfig={moreConfigActions}
            />
          )}
        </WrapButtons>
      )
    }
    return <></>
  }
  const renderHeaderFixed = () => {
    const timeText = myData?.[historyFields.fHeaderInfo] || {}
    return (
      <WrapHeaderFixed>
        <Header ref={topFixedRef}>
          <Button onClick={onCloseDetailOrder} color="back">
            <i className="flaticon-left-arrow" />
          </Button>
          {timeText?.Title && <div className="subtitle">{timeText?.Title}</div>}
        </Header>
      </WrapHeaderFixed>
    )
  }
  const renderBlogsSuggestion = () => {
    const blogs = myData?.[historyFields.fBlogs]
    const moreConfig =
      getConfigFromFirstSettingByKey("blogSuggestionConfig") || {}
    if (blogs && blogs?.length > 0) {
      return (
        <div className="one-part mt-15px">
          <MyUI
            type="tripdetail_blog_suggestion"
            data={blogs}
            fnList={getMyFnList()}
            moreConfig={moreConfig}
          />
        </div>
      )
    }
    return <></>
  }
  const renderContent = () => {
    if (myData && !loading) {
      return (
        <>
          <WrapContent
            className="trip-detail-content"
            actionsHeight={actionsHeight}
          >
            <div className="left" style={{ marginTop: topFixedHeight + 45 }}>
              {renderGeneralInfo()}
              {renderVehicleInfo()}
              {renderPaymentInfo()}
              {renderOwnerInfo()}
              {renderSupportInfo()}
              {renderBlogsSuggestion()}
              {renderInsuranceInfo()}
            </div>
          </WrapContent>
          <div className={`wrap-sticky container ${canShow && "show-sticky"}`}>
            <div className="wrap-left">
              {renderHeaderFixed()}
              {renderActions()}
            </div>
            <div
              className="wrap-right"
              style={{ marginBottom: 24, marginTop: 15 }}
            >
              {renderMap()}
            </div>
          </div>
        </>
      )
    } else if (loading) {
      return (
        <WrapLoading className="trip-detail-loading">
          <div className="left">
            <div className="one-part header-title">
              <Skeleton />
            </div>
            <div className="one-part">
              <Skeleton />
            </div>
            <div className="one-part actions">
              <Skeleton />
            </div>
          </div>
          <div className="right">
            <Skeleton />
          </div>
        </WrapLoading>
      )
    }
    return <></>
  }
  return (
    <MyUI
      type="sg_content_overlay"
      id={getIdOverlay()}
      canShow={canShow}
      classNameWrap="header-no-search pt-15px"
    >
      <Wrap className="mui-trip-detail container">
        <MyUI type="wcontent">
          {/* {renderProgress()} */}
          {renderContent()}
        </MyUI>
      </Wrap>
    </MyUI>
  )
}
const Header = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #0000001a;
  // margin-left: -3px;
  width: calc(100% - 3px);
  padding: 15px;

  & .btn-back {
    background-color: transparent;
    color: var(--color-text-000);
    box-shadow: none;
    font-size: 14px;
    padding: 0;
    height: fit-content;
    border: none;
    margin-right: 15px;
    pointer-events: auto;
    margin-top: 5px;
    i {
      display: flex;
    }
  }
  & .btn-back:hover,
  & .btn-back:active,
  & .btn-back:focus {
    color: var(--color-text-000);
    box-shadow: none;
    background-color: transparent;
    border: none;
  }
  @media (max-width: 1299px) {
    margin-left: -3px;
    width: 100%;
  }
  @media (max-width: 996px) {
    margin-left: 0;
    width: unset;
    margin-right: 5px;
  }
`
const WrapHeaderFixed = styled.div`
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 15px;
  padding-bottom: 0;
  background: #f8f8f8;
  min-width: 100%;
  @media (max-width: 996px) {
    padding-right: 4px;
  }
`
const Wrap = styled.div`
  & .subtitle {
    font-size: 20px;
    font-weight: 600;
    color: var(--mauden);
  }
  & .wrap-sticky {
    top: 60px;
    position: fixed;
    display: flex;
    left: -150%;
    transform: translateX(-50%);
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: 0.7s;
    height: calc(100% - 60px);
    z-index: 2;
    &.show-sticky {
      left: 50%;
      opacity: 1;
    }
    & .wrap-left {
      width: 40%;
      margin-right: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    & .wrap-map {
      // max-height: 680px;
      height: 100%;
      border-radius: 0;
      & .gm-control-active {
        pointer-events: auto;
      }
    }
    & .wrap-right {
      width: calc(100% - 40% - 15px);
      margin-bottom: 12px;
      & > * {
        height: 100%;
      }
    }
  }
  @media (max-width: 1010px) {
    & .wrap-sticky {
      top: 0;
      height: 100%;
      & .wrap-left {
        width: 100%;
      }
      & .wrap-right {
        display: none;
      }
    }
  }
  // @media (max-width: 996px) {
  //   & .wrap-sticky {
  //     & .wrap-left {
  //       width: 100%;
  //     }
  //     & .wrap-right {
  //       display: none;
  //     }
  //   }
  // }
  // @media (max-width: 820px) {
  //   & .wrap-sticky {
  //     top: 49px;
  //     height: calc(100% - 49px);
  //   }
  // }
  // @media (max-width: 767px) {
  //   & .wrap-sticky {
  //     top: 53px;
  //     height: calc(100% - 53px);
  //   }
  // }
`
const WrapContent: any = styled.div`
  margin: 0;
  display: flex;
  & .devitem-line {
    &:before {
      margin-left: 0;
      margin-right: 0;
    }
  }
  & .left {
    width: 40%;
    margin-right: 12px;
    margin-bottom: ${(props: any) =>
      props?.actionsHeight ? `${props?.actionsHeight + 55}px` : "0px"};
    margin-top: 95px;
  }
  & .right {
    position: fixed;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: 0.5s;
    &.show-right {
      transition-delay: 0.7s;
      left: 50%;
      opacity: 1;
    }
    & .wrap-left {
      width: 40%;
      margin-right: 12px;
      display: flex;
      flex-direction: column;
      justify-content: end;
    }
    & .wrap-right {
      width: calc(100% - 40% - 12px);

      & > * {
        height: 100%;
      }
    }
    & .wrap-map {
      height: 100%;
      max-height: calc(100% - 60px - 28px);
    }
  }

  & .one-part {
    background-color: var(--color-text-fff);
    box-shadow: 0px 0px 10px 0px #0000001a;
    padding: 24px;
  }
  & .wrap-map {
    max-height: 680px;
    height: 100%;
    border-radius: 0;
    & .gm-control-active {
      pointer-events: auto;
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
    & .left {
      width: 100%;
      margin-right: 0;
      overflow-y: auto;
    }
    & .right {
      display: none;
    }
    & .mobile-mt-30px {
      margin-top: 30px;
    }
    & .one-part {
      padding: 16px;
    }
  }
`
const WrapButtons: any = styled.div`
  &.wrap-buttons {
    margin-left: -12px;
    margin-right: -12px;
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 24px;
    background: #f8f8f8;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & .m-order-action {
      display: grid;
      grid-template-columns: repeat(
        ${(props: any) => props?.btnLength},
        minmax(0px, 1fr)
      );
      width: 85%;
      gap: 0.5rem;
      & button {
        pointer-events: auto;
      }
      & button:active {
        box-shadow: none !important;
        border: 1px solid transparent;
      }
    }
    & .w-msg-above-actions {
      width: 65%;
      margin-bottom: 16px;
    }
  }
  @media (max-width: 1299px) {
    &.wrap-buttons {
      padding-left: 6px;
    }
  }
  @media (max-width: 996px) {
    &.wrap-buttons {
      padding-left: 11px;
      padding-right: 8px;
      padding-bottom: 16px;
      margin-right: -20px;
      & .m-order-action {
        width: 100%;
      }
      & .w-msg-above-actions {
        width: 100%;
      }
    }
  }
`
const WrapLoading = styled.div`
  margin: 0;
  display: flex;
  & .left {
    width: 40%;
    margin-right: 12px;
    & .one-part {
      height: calc(100vh - 60px - 15px * 3 - 90px - 24px - 55px);
      margin-top: 15px;
      & > span {
        display: flex;
        height: 100%;
        & > span {
          height: 100%;
        }
      }
      &.actions {
        // margin-top: 15px;
        height: 90px;
      }
      &.header-title {
        height: 55px;
        margin-top: 0;
      }
    }
  }
  & .right {
    width: calc(60% - 12px);
    height: calc(100vh - 60px - 15px - 24px);
    & > span {
      display: flex;
      height: 100%;
      & > span {
        height: 100%;
      }
    }
  }
  @media (max-width: 1010px) {
    flex-direction: column;
    & .left {
      width: 100%;
    }
    & .right {
      display: none;
    }
    & .left {
      margin-right: 12px;
      & .one-part {
        height: calc(100vh - 15px * 3 - 90px - 24px - 55px);
      }
    }
  }
`
export const WrapPartWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .wrap-title {
    align-items: center;
    width: calc(100% - 20px);
    padding-right: 10px;
    color: var(--mauden);
    i {
      display: flex;
      font-size: 20px;
      margin-right: 10px;
      height: 25px;
      align-items: center;
    }
  }
`
export default connector(MyUITripDetail)
