import React, { useEffect, useRef, useState } from "react"
import { HAuth, HFormat, HUtils } from "src/core/helpers"
import {
  MyForm,
  MyIcon,
  MyModal,
  MyUI,
  Popover,
  Tooltip,
} from "src/core/components"
import { rentalTypes } from "src/modules/app_sigo/constants"
import { categoryFields } from "src/modules/app_sigo/constants/fields"
import ICVehicleType from "src/modules/app_sigo/assets/imgs/ic-vehicle-type.png"
import ICVehicleMaket from "src/modules/app_sigo/assets/imgs/ic-vehicle-make.png"
import dfLogo from "src/modules/app_sigo/images/logo-sigo.png"
import styled from "styled-components"
import { showAlert } from "src/modules/app_sigo/helpers/ui"
import { Button, FormGroup, Input, Label } from "reactstrap"
import { FaChevronDown } from "react-icons/fa"
import classNames from "classnames"
import { HCF } from "@macashipo/mlib"

const MyUIHeader = props => {
  const { data, fnList, arrayTypeFields, config } = props
  const [myFilter, setMyFilter] = useState(data)
  const logo = HAuth.getUserLogoApp() || dfLogo //"/config/logo.png"
  const [isMobileView, setIsMobileView] = useState(false)
  const [isShowAmount, setIsShowAmount] = useState(
    config?.defaultShowAmount || false
  )
  const refTopForm = useRef<MyForm>(null)
  useEffect(() => {
    setMyFilter(data)
  }, [data])
  useEffect(() => {
    const ro = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        const { left, top, width, height } = entry.contentRect
        if (entry.target == document.body) {
          if (width <= 768) {
            if (isMobileView === false) {
              console.log(
                `RO Mobile Element's size: ${width}px x ${height}px`,
                isMobileView
              )
            }
            setIsMobileView(true)
          } else {
            setIsMobileView(false)
          }
        }
      }
    })
    ro.observe(document.body)
  }, [])

  const configFormFilter = {
    defaultValues: data,
    controls: [
      // {
      //   label: "Địa điểm",
      //   fieldName: categoryFields.fAddress,
      //   type: "input_address",
      //   more: {
      //     classNameGroup: "col-md-4 col-sm-12",
      //     morePropsInputAddress: {
      //       mappingFields: {
      //         fieldAddress: categoryFields.fAddress,
      //       },
      //       props4Search: {
      //         configSelect: {
      //           placeholder: "Bạn đặt xe ở … ",
      //         },
      //       },
      //     },
      //   },
      // },
      // {
      //   label: "Bắt đầu",
      //   fieldName: categoryFields.fFromDate,
      //   type: "input_datetime",
      //   more: {
      //     classNameGroup: "col-md-4 col-sm-12",
      //     configDate: {
      //       minDate: new Date(),
      //       placeholderDate: "Chọn ngày",
      //     },
      //     configTime: {
      //       placeholderTime: "Chọn giờ",
      //     },
      //   },
      // },
      // {
      //   label: "Kết thúc",
      //   fieldName: categoryFields.fToDate,
      //   type: "input_datetime",
      //   more: {
      //     classNameGroup: "col-md-4 col-sm-12",
      //     configDate: {
      //       minDate: new Date(),
      //       placeholderDate: "Chọn ngày",
      //     },
      //     configTime: {
      //       placeholderTime: "Chọn giờ",
      //     },
      //   },
      // },
    ],
  }
  const getFilterList = () => {
    const sourceList = fnList?.getSourceList && fnList.getSourceList()
    const filterList = [
      {
        title: (
          <span>
            Loại xe
            <MyIcon
              data="type:ic_chevron_down"
              fill={"#606060"}
              style={{ marginLeft: 12 }}
            />
          </span>
        ),
        checkShow:
          sourceList &&
          sourceList["VehicleTypeSubList"] &&
          sourceList["VehicleTypeSubList"].length > 0,
        icon: (
          <MyIcon
            data="type:ic_vehicle_type"
            style={{ marginRight: 12 }}
            fill={"var(--color-text-000)"}
          />
        ),
        actionType: "modal",
        configForm: {
          defaultValues: myFilter,
          controls: [
            {
              fieldName: categoryFields.fVehicleTypeSubIds,
              type: "select_custom",
              more: {
                optionKey: "VehicleTypeSubList",
                multi: true,
                classNameOption: "option-vehicle-type",
                classNameWrap: "wrap-vehicles-type",
                emptyMsg: (
                  <div className="text-center">Không có loại xe nào!</div>
                ),
              },
            },
          ],
        },
        configModal: {
          title: "Chọn loại xe",
          showHeaderClose: false,
          showFooter: true,
          showFooterClose: false,
          showFooterOK:
            sourceList &&
            sourceList["VehicleTypeSubList"] &&
            sourceList["VehicleTypeSubList"].length > 0
              ? true
              : false,
          titleBtnOK: "Áp dụng",
          modalClassName: "default footer-btn-ok-full modal-filter",
          size: "lg",
        },
      },
      {
        title: (
          <span>
            Hãng xe
            <MyIcon
              data="type:ic_chevron_down"
              fill={"#606060"}
              style={{ marginLeft: 12 }}
            />
          </span>
        ),
        checkShow:
          sourceList &&
          sourceList["VehicleModelList"] &&
          sourceList["VehicleModelList"].length > 0,
        subTitle: ` `,
        icon: (
          <MyIcon
            data="type:ic_vehicle_make"
            style={{ marginRight: 12 }}
            fill={"var(--color-text-000)"}
          />
        ),
        actionType: "modal",
        classNameWrapForm:
          "mCustomScrollBox mCSB_inside max-height-440px overflow-auto",
        classNameForm: "mCSB_container position-relative left-0",
        configForm: {
          defaultValues: myFilter,
          controls: [
            {
              fieldName: categoryFields.fVehicleMakeIds,
              type: "check_with_icon",
              more: {
                optionKey: "VehicleMakeList",
                multi: false,
                children: {
                  title: "Dòng xe",
                  fieldName: categoryFields.fVehicleModelIds,
                  optionKey: "VehicleModelList",
                },
                emptyMsg: (
                  <div className="text-center">Không có hãng xe nào!</div>
                ),
              },
            },
          ],
        },
        configModal: {
          title: "Chọn hãng xe",
          showHeaderClose: false,
          showFooter: true,
          showFooterClose: false,
          showFooterOK:
            sourceList &&
            sourceList["VehicleModelList"] &&
            sourceList["VehicleModelList"].length > 0
              ? true
              : false,
          titleBtnOK: "Áp dụng",
          modalClassName:
            "default footer-btn-ok-full modal-filter scrollbar-right",
          size: "lg",
        },
      },
      {
        title: (
          <span>
            Bộ lọc
            <MyIcon
              data="type:ic_chevron_down"
              fill={"#606060"}
              style={{ marginLeft: 12 }}
            />
          </span>
        ),
        icon: (
          <MyIcon
            data="type:ic_filter_category"
            fill={"#000000"}
            style={{ marginRight: 12 }}
          />
        ),
        actionType: "modal",
        classNameWrapForm:
          "mCustomScrollBox mCS-light mCSB_vertical mCSB_inside max-height-category overflow-auto filter-category",
        classNameForm: "mCSB_container position-relative left-0",
        configForm: {
          defaultValues: myFilter,
          controls: [
            {
              label: "Sắp xếp kết quả",
              fieldName: categoryFields.fOrderBy,
              type: "list_radio",
              more: {
                optionKey: "OrderByList",
                multi: false,
                className: "wrap-order-by",
              },
            },
            {
              label: "Khoảng giá",
              fieldName: categoryFields.fMaxRentalPrice,
              type: "input_dual_range",
              more: {
                subLabel: "Giá thuê theo ngày chưa bao gồm phí và thuế",
                optionKey: "RentalPrice",
                morePropsRange: {
                  fields: {
                    min: categoryFields.fMinRentalPrice,
                    max: categoryFields.fMaxRentalPrice,
                  },
                  titleFrom: "Giá thấp nhất",
                  titleTo: "Giá cao nhất",
                  splitStep: 20,
                  getLabelFrom: val => {
                    return (
                      <>
                        <b>
                          {HFormat.format(val, {
                            type: "money_vnd",
                          })}
                          đ
                        </b>
                      </>
                    )
                  },
                  getLabelTo: val => {
                    return (
                      <>
                        <b>
                          {HFormat.format(val, {
                            type: "money_vnd",
                          })}
                          đ
                        </b>
                      </>
                    )
                  },
                  getMin: () =>
                    HUtils.get(sourceList, `RentalPrice.MinValue.Value`),
                  getMax: () =>
                    HUtils.get(sourceList, `RentalPrice.MaxValue.Value`),
                  getStep: () => 100000,
                },
              },
            },
            {
              label: "Năm sản xuất của xe",
              fieldName: categoryFields.fVehicleMaxYearModel,
              type: "input_dual_range",
              more: {
                optionKey: "CreatedYear",
                morePropsRange: {
                  fields: {
                    min: categoryFields.fVehicleMinYearModel,
                    max: categoryFields.fVehicleMaxYearModel,
                  },
                  titleFrom: "Tối thiểu",
                  titleTo: "Tối đa",
                  splitStep: 20,
                  getMin: () =>
                    HUtils.get(sourceList, `CreatedYear.MinValue.Value`),
                  getMax: () =>
                    HUtils.get(sourceList, `CreatedYear.MaxValue.Value`),
                  getStep: () => 1,
                },
              },
            },
            {
              label: "Màu Sắc",
              fieldName: categoryFields.fVehicleColorIds,
              type: "select_custom",
              more: {
                optionKey: "VehicleColorList",
                classNameWrap: "wrap-vehicle-color",
                useCollapse: true,
                minItemCollapse: 8,
                multi: true,
              },
            },
            {
              label: "Loại nhiên liệu",
              fieldName: categoryFields.fFuelTypeIds,
              type: "select_custom",
              more: {
                optionKey: "FuelTypeList",
                classNameWrap: "wrap-fuel-type",
                multi: true,
              },
            },
            {
              label: "Tiện ích của xe",
              fieldName: categoryFields.fFeatureIds,
              type: "list_checkbox",
              more: {
                optionKey: "FeatureList",
                multi: true,
                classNameWrap: "wrap-feature",
                useCollapse: true,
                minItemCollapse: 8,
                classNameGroup: "control-lastest",
              },
            },
          ],
        },
        configModal: {
          title: "Bộ lọc tìm kiếm",
          showFooter: true,
          showFooterClose: true,
          showFooterOK: true,
          titleBtnClose: "Xóa tất cả",
          titleBtnOK: "Áp dụng",
          modalClassName:
            "default footer-space-between modal-filter scrollbar-right filter-header-category",
          classNameBody: "mCustomScrollbar _mCS_1",
        },
      },
    ]
    return filterList
  }
  const getDefaultActiveResetFilterBtn = () => {
    if (fnList && fnList?.getDefaultActiveResetFilterBtn) {
      return fnList?.getDefaultActiveResetFilterBtn()
    }
    return false
  }
  const onOpenFilter = () => {
    return MyModal.showFromMyUI(
      {
        type: "sg_filter_category",
        data: data,
        configFormFilter: configFormFilter,
        configFilterList: getFilterList(),
        fnList: {
          ...fnList,
          onCloseModal: () => {
            MyModal.hideModalMyUI()
          },
        },
        arrayTypeFields: arrayTypeFields,
      },
      {
        title: "Bộ lọc",
        size: "lg",
        modalClassName: "default modal-header-left",
      }
    )
  }
  const renderForm = () => {
    return (
      <MyForm
        className="filter-top"
        ref={refTopForm}
        key={`form-desktop`}
        configForm={configFormFilter}
        fnList={{
          ...fnList,
          afterUpdateValueControl: opts => {
            const { fieldName, newValue } = opts
            let newData: any = JSON.parse(JSON.stringify(data))
            newData[fieldName] = newValue
            if (newValue !== data[fieldName]) {
              if (
                fieldName === categoryFields.fFromDate &&
                newData[categoryFields.fFromDate] >=
                  newData[categoryFields.fToDate]
              ) {
                let _newToDate = newData[categoryFields.fFromDate] + 86400000
                newData[categoryFields.fToDate] = _newToDate
                if (
                  refTopForm?.current?.fnListFromExtControl?.[
                    categoryFields.fToDate
                  ]
                ) {
                  refTopForm.current.fnListFromExtControl[
                    categoryFields.fToDate
                  ].updateValue(_newToDate)
                }
                if (fnList?.onFilter) {
                  fnList.onFilter({
                    [fieldName]: newValue,
                    [categoryFields.fToDate]: _newToDate,
                  })
                }
              } else if (
                fieldName === categoryFields.fToDate &&
                newData[categoryFields.fToDate] <=
                  newData[categoryFields.fFromDate]
              ) {
                if (refTopForm?.current?.fnListFromExtControl?.[fieldName]) {
                  refTopForm.current.fnListFromExtControl[
                    fieldName
                  ].updateValue(data[fieldName])
                }
                showAlert({
                  component: (
                    <MyUI
                      type="alert"
                      alertType="warning"
                      title="Thông báo"
                      msg="Thời gian thuê xe không hợp lệ, bạn hãy chọn lại nhé."
                    />
                  ),
                })
              } else {
                if (fnList?.onFilter) {
                  fnList.onFilter({ [fieldName]: newValue })
                }
              }
            }
          },
          afterUpdateMultiValues: obj => {
            const { values } = obj
            let objUpdate: any = {}
            objUpdate = { ...values }
            if (values?.[`${categoryFields.fAddress}_MoreInfo`]) {
              const location = HUtils.get(
                values,
                `${categoryFields.fAddress}_MoreInfo[0].geometry.location`
              )
              if (location?.lat) {
                objUpdate[categoryFields.fLatitude] = location.lat()
              }
              if (location?.lng) {
                objUpdate[categoryFields.fLongitude] = location.lng()
              }
              if (fnList?.onFilter) {
                fnList.onFilter(objUpdate)
              }
            }
          },
        }}
        renderFooter={() => {
          return <></>
        }}
      />
    )
  }

  const renderTop = () => {
    return (
      <nav className="navbar nvasearch">
        {!isMobileView && (
          <div className="container">
            <div className="navbar-header hidden-mobile">
              <div className="ttsearchh hidden-desktop">
                {/* <p className="dc">Thành phố Đà Lạt, Lâm Đồng</p>
              <p>13/11/2023 21:00 - 14/11/2023 20:00</p>
              <i className="flaticon-arrow-down-sign-to-navigate"></i> */}
              </div>
              <a className="navbar-brand" href="/">
                <img className="logo" src={logo} alt="logo" />
              </a>
            </div>
            <div className="ndsearch hidden-mobile px-2">{renderForm()}</div>
            <div
              className="collapse navbar-collapse collapseRight"
              data-show="menumember"
              id="collapseRight"
              style={{ height: 0 }}
            >
              <ul className="nav navbar-nav navbar-right">
                <li className="nav-item dropdown menumember">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    data-toggle="dropdown"
                  >
                    <i className="flaticon-dots-menu"></i> Dịch Vụ
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    {rentalTypes.map((v, i) => {
                      return (
                        <li key={`rent-${i}`}>
                          <a className="dropdown-item" href={v.url}>
                            {v.name}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </li>
                <MyUI type="sg_avatar" />
              </ul>
            </div>
            <div className="line"></div>
          </div>
        )}
        <div className="hidden-desktop">
          {isMobileView && (
            <MyUI type="sg_header" fnOpenFilter={onOpenFilter} />
          )}
        </div>
      </nav>
    )
  }
  const renderBottom = () => {
    const filterList = getFilterList()
    return (
      <div id="wpfillter" className="wpfillter">
        <div className="container">
          <div className="d-flex wrap-bottom-filter align-items-end">
            <div className="d-flex wrap-bottom-filter align-items-end flex-1">
              <MyUI
                type="sg_button_refresh"
                valuesFilter={myFilter}
                configFilter={{
                  listField: [
                    {
                      fieldName: categoryFields.fVehicleTypeSubIds,
                    },
                    {
                      fieldName: categoryFields.fVehicleMakeIds,
                    },
                    { fieldName: categoryFields.fMaxRentalPrice },
                    { fieldName: categoryFields.fVehicleMaxYearModel },
                    { fieldName: categoryFields.fVehicleColorIds },
                    { fieldName: categoryFields.fFuelTypeIds },
                    { fieldName: categoryFields.fFeatureIds },
                    { fieldName: categoryFields.fOrderBy },
                  ],
                  listOption: [
                    {
                      optionKey: "VehicleSpecialAttributeList",
                    },
                  ],
                }}
                fnList={fnList}
                defaultActive={getDefaultActiveResetFilterBtn()}
              />
              <div className="wrap-filter-left d-flex wrap-filter-type">
                {filterList.slice(0, 2).map((v, i) => {
                  // if (!v.checkShow) {
                  //   return <></>
                  // }
                  return (
                    <div className={classNames("wrap-button-form")}>
                      {/* <div className="subtitle">Tùy chọn kết quả</div> */}
                      <MyUI key={i} type="button_form" {...v} fnList={fnList} />
                    </div>
                  )
                })}
              </div>
              <div className={classNames("wrap-button-form")}>
                <MyUI
                  type="sg_filter_special_attribute"
                  configFilter={{
                    optionKey: "VehicleSpecialAttributeList",
                    defaultValues: myFilter,
                  }}
                  fnList={{
                    ...fnList,
                    onFilter: obj => {
                      fnList?.onFilter &&
                        fnList.onFilter(obj, { isFilterMore: true })
                    },
                  }}
                />
              </div>
              <div className="wrap-filter-left d-flex">
                {filterList.slice(2, 99).map((v, i) => {
                  return (
                    <div className={classNames("wrap-button-form")}>
                      {v.subTitle && (
                        <div className="subtitle">{v.subTitle}</div>
                      )}
                      <MyUI
                        key={i}
                        type="button_form"
                        {...v}
                        fnList={{
                          ...fnList,
                          onFilter: obj => {
                            fnList?.onFilter &&
                              fnList.onFilter(obj, { isFilterMore: true })
                          },
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="wrap-switch">
              <FormGroup>
                <div className="form-check form-switch">
                  <Label>{config?.title || "Hiển thị tổng trước thuế"}</Label>
                  <Input
                    type="checkbox"
                    checked={isShowAmount}
                    onClick={() => {
                      let _value = !isShowAmount
                      setIsShowAmount(_value)
                      fnList?.onSetShowAmount && fnList?.onSetShowAmount(_value)
                    }}
                  />
                </div>
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const renderOverlay = () => {
    return <div className="overlay"></div>
  }
  return (
    <Wrap id="sg-all-filter" className="sg-all-filter">
      {/* {renderTop()} */}
      {renderBottom()}
      {renderOverlay()}
    </Wrap>
  )
}
const Wrap = styled.div`
  background-color: #fff;
  & .overlay {
    display: none;
  }
  &.show-overlay-filter {
    // position: relative;
    & .overlay {
      background: var(--color-text-000);
      opacity: 0.4;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: block;
    }
  }
  & .label-text {
    color: #555;
    font-size: 16px;
    cursor: default;
  }
  & .flex-1 {
    flex: 1 !important;
  }
  & .ic-btn {
    height: 16px;
    margin-right: 12px;
  }
  & .filter-top {
    display: flex;
    align-items: center;
    & > .form-group {
      display: flex;
      align-items: center;
      & label {
        line-height: 30px;
        color: var(--mau1);
        margin: 0 !important;
      }
      & > div:nth-child(2) {
        flex: 1;
        margin-left: 0.25rem;
      }
      & .react-datepicker-wrapper {
        width: 90px !important;
      }
    }
    & .form-control {
      padding-bottom: 0px;
    }
    & .mc_input_address {
      .clear > button {
        height: 30px;
      }
    }
  }
  & .wrap-bottom-filter {
    & .subtitle {
      text-wrap: nowrap;
      font-size: 12px;
      color: #898989;
      width: 0;
    }
    & .wrap-filter-left {
      align-items: stretch;
      & .wrap-button-form {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: end;
      }
      &.wrap-filter-type {
        // border-right: 1px solid #dedede;
        // margin-right: 10px;
        & .subtitle {
          display: none;
        }
        & .wrap-button-form:first-child .subtitle {
          display: block;
        }
      }
    }
  }
  & .btn-default {
    color: var(--color-text-595959);
    &:active,
    &:focus {
      background: transparent !important;
    }
    &:hover,
    &:active,
    &:focus {
      color: var(--color-text-595959);
    }
    &:hover,
    &.btn-active {
      background-color: var(--color-bg-blue-light) !important;
      border: 1px solid var(--color-border-blue-light) !important;
    }
  }
  & .wrap-switch {
    display: flex;
    align-items: center;
    & .form-group {
      margin-bottom: 0;
      border: 1px solid #c8c8c8;
      border-radius: 20px;
      padding: 3px 5px;
      font-size: 14px;
      min-height: 39px;
      & .form-switch {
      }
      & .form-check {
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        label {
          margin: 0 0.5rem 0 0.25rem;
          color: var(--color-text-595959);
          font-weight: 500;
        }
        label::before {
          display: none;
        }
        input {
          margin-bottom: 0.23rem;
          cursor: pointer;
          box-shadow: none;
        }
        input[type="checkbox"] {
          display: unset;
        }
        & .form-check-input {
          width: 36px;
          height: 23px;
          margin-left: 0;
          float: right;
          background-color: var(--color-text-d9d9d9);
          border-color: var(--color-text-d9d9d9);
          background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%23fff' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'%3E%3C/path%3E%3C/svg%3E");
        }
        & .form-check-input:checked {
          background-color: var(--color-text-000);
          border-color: var(--color-text-000);
          background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%23fff' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z'%3E%3C/path%3E%3C/svg%3E");
        }
      }
    }
  }
  @media (min-width: 1200px) {
    &.show-overlay-filter {
      position: relative;
      margin-bottom: -10px;
      padding-bottom: 10px;
      & .overlay {
        background: var(--color-text-000);
        opacity: 0.4;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
      }
    }
  }
`
export default MyUIHeader
