import { HLink, HUtils } from "@macashipo/mlib"
import classNames from "classnames"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const HomeBlogFields = {
  fDate: "createdDate",
  fOverview: "bodyOverview",
}
const HomeBlogCard = props => {
  const { data } = props

  const _url = HUtils.get(data, `${homeSlidesFields.fOneSlideMoreInfo}.url`)
  const img = data?.[homeSlidesFields.fOneSlideImageUrl]
  const title = data?.[homeSlidesFields.fOneSlideTitle]
  const description = HUtils.get(data, `Data.${HomeBlogFields.fOverview}`)
  const date = HUtils.get(data, `Data.${HomeBlogFields.fDate}`)
  const onHandleClick = () => {
    if (_url) {
      HLink.openUrl(_url, "_blank")
    }
  }

  return (
    <Wrap
      onClick={onHandleClick}
      className={classNames(_url && "cursor-pointer")}
    >
      <img className="lazy" src={img} data-original={img} alt={title} />
      <div className="wrap-content">
        {title && (
          <div
            className="title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
        )}
        {description && (
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        )}
        {date && <div className="date">{date}</div>}
      </div>
    </Wrap>
  )
}
const Wrap = styled.div`
  border: 1px solid var(--color-border-d4d4d4);
  border-radius: 15px;
  background-color: var(--color-text-fff);
  &.cursor-pointer {
    cursor: pointer;
  }
  & img {
    height: 322px;
    border-radius: 15px 15px 0 0;
    object-fit: cover;
  }
  & .title {
    font-size: 20px;
    color: var(--color-text-000);
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 50px;
  }
  & .description {
    color: #696969;
    font-size: 16px;
    padding-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  & .date {
    font-size: 12px;
    color: #696969;
    padding-top: 15px;
  }
  & .wrap-content {
    padding: 15px;
    border-radius: 0 0 15px 15px;
  }
  @media (max-width: 767px) {
    & img {
      height: 280px;
    }
  }
`
export default HomeBlogCard
