export const categoryFields = {
  fAddress: "Address",
  fLatitude: "Latitude",
  fLongitude: "Longitude",
  fFromDate: "FromDate",
  fToDate: "ToDate",
  fPDAvatarUrl: "AvatarUrl",
  fPDName: "Name",
  fPDRate: "Rating",
  fPDTripCount: "ServedCount",
  fPDNumberOfSeat: "NumberOfSeat",
  fPDDistance: "Distance",
  fPDRentalPrice: "RentalPrice",
  fPDRentalPriceOriginal: "RentalPriceOriginal",
  fOrderBy: "OrderBy",
  fVehicleNoOfSeatIds: "VehicleNoOfSeatIds",
  fMinRentalPrice: "MinRentalPrice",
  fMaxRentalPrice: "MaxRentalPrice",
  fVehicleTransmissionTypeIds: "VehicleTransmissionTypeIds",
  fVehicleMakeIds: "VehicleMakeIds",
  fYearModels: "YearModels",
  fVehicleColorIds: "VehicleColorIds",
  fFuelTypeIds: "FuelTypeIds",
  fRentalSettings: "RentalSettings",
  fDocumentIds: "DocumentIds",
  fFeatureIds: "FeatureIds",
  fSlug: "Slug",
  fDeliveryAddress: "DeliveryAddress",
  fVehicleTypeSubIds: "VehicleTypeSubIds",
  fVehicleModelIds: "VehicleModelIds",
  fRentalDayCount_Text: "RentalDayCount_Text",
  fImageUrls: "ImageUrls",
  fRentalPriceByDayOriginal: "RentalPriceByDayOriginal",
  fRentalPriceByDay: "RentalPriceByDay",
  fVehicleMinYearModel: "VehicleMinYearModel",
  fVehicleMaxYearModel: "VehicleMaxYearModel",
  fRentalPriceDetails: "RentalPriceDetails",
  fFreeDaySegment_Text: "FreeDaySegment_Text",
}
export const detailFields = {
  fImageUrls: "ImageUrls",
  fServedCount: "ServedCount",
  fCharacteristics: "Characteristics",
  fOwnerInfo: "OwnerInfo",
  fOwnerInfoUrl: "InfoUrl",
  fJoinedDate: "JoinedDate",
  fReplyWithin: "ReplyWithin",
  fFullDescription: "FullDescription",
  fFeatures: "Features",
  fDocuments: "Documents",
  fSecurities: "Securities",
  fTerm: "Term",
  fCancelBookingPolicy: "CancelBookingPolicy",
  fReview: "Review",
  fReviewByRenters: "ReviewByRenters",
  fRVUser: "User",
  fRVRating: "Rating",
  fRVTime: "ReviewAt",
  fRVContent: "ReviewContent",
  fAddress: "Address",
  fInsuranceShortDescription: "InsuranceShortDescription",
  fRentalPriceDetails: "RentalPriceDetails",
  fVouchers: "Vouchers",
  fBookingInfoItems: "BookingInfoItems",
  fTotalPrice: "TotalPrice",
  fVoucherCode: "VoucherCode",
  fVoucherDiscountPrice: "VoucherDiscountPrice",
  fCanBooking: "CanBooking",
  fRentalServiceCategoryCode: "RentalServiceCategoryCode",
  fDeliveryAddress: "DeliveryAddress",
  fCanChangeDeliveryAddress: "CanChangeDeliveryAddress",
  fReviewByAvatarUrl: "ReviewByAvatarUrl",
  fName: "Name",
  fAvatarUrl: "AvatarUrl",
  fJoinedWhen: "JoinedWhen",
  fBookingFeatureInfos: "BookingFeatureInfos",
  fDeliveryInfo: "DeliveryInfo",
  fLongitude: "Longitude",
  fLatitude: "Latitude",
  fThingsToKnow: "ThingsToKnow",
  fPlateNumber: "PlateNumber",
  fPaymentInstructionInfo: "PaymentInstructionInfo",
  fCancelBookingPolicyInfo: "CancelBookingPolicyInfo",
  fGeneralProvisionInfo: "GeneralProvisionInfo",
  fSecurity: "Security",
  fOrderNumber: "OrderNumber",
  fNewOrderLinks: "NewOrderLinks",
  fNewOrderContent: "NewOrderContent",
  fNewOrderBookingDate: "NewOrderBookingDate",
  fInsuranceInfo: "InsuranceInfo",
  fRentalDate: "RentalDate",
}
export const historyFields = {
  fAvatarUrl: "AvatarUrl",
  fRentalServiceName: "RentalServiceName",
  fEntDate_Text: "EntDate_Text",
  fAnotherUsername: "Name",
  fFromDate_Text: "FromDate_Text",
  fToDate_Text: "ToDate_Text",
  fFromDate: "FromDate",
  fToDate: "ToDate",
  fTotalPrice_Text: "TotalPrice_Text",
  fAnotherUserContacts: "Contacts",
  fAnotherUserContactsType: "Type",
  fAnotherUserContactsIconUrl: "IconUrl",
  fAnotherUserContactsIsDisable: "IsDisable",
  fAnotherUserContactsValue: "Value",
  fAnotherUserRole: "Role",
  fAnotherUserAvatarUrl: "AvatarUrl",
  fAnotherUserRate: "Rating",
  fAnotherUserInfo: "AnotherUserInfo",
  fAnotherUserInfoUrl: "InfoUrl",
  fAnotherUserPhone: "MobilePhone",
  fAnotherUserJoinedWhen: "JoinedWhen",
  fAnotherUserTotalRatingCount: "TotalRatingCount",
  fAnotherUserIsVerified: "IsVerified",
  fIsUserTakeSelected: "IsUserTakeSelected",
  fStatusIconUrl: "StatusIconUrl",
  fStatusName: "StatusName",
  fStatusColorCode: "StatusColorCode",
  fButtons: "Buttons",
  fRating: "ReviewRating",
  fReviewRating: "ReviewRating",
  fReviewContent: "ReviewContent",
  fInsuranceContact: "InsuranceContact",
  fPaymentInfos: "PaymentInfos",
  fMoneyToPay: "MoneyToPay",
  fTransferContent: "TransferContent",
  fBankInfo: "BankInfo",
  fQRCodeUrl: "QRCodeUrl",
  fCancelReasonId: "CancelReasonId",
  fCancelReasonDetail: "CancelReasonDetail",
  fOrderNumber: "OrderNumber",
  fOrderSteps: "OrderSteps",
  fHaveInsurance: "HaveInsurance",
  fInsuranceContractUrl: "InsuranceContractUrl",
  fInsuranceDescription: "InsuranceDescription",
  fImageUrls: "ImageUrls",
  fEntDate: "EntDate",
  fPaymentTypeName: "PaymentTypeName",
  fTotalPrice: "TotalPrice",
  fRentalPriceDetails: "RentalPriceDetails",
  fBillingForOwnerInfo: "BillingForOwnerInfo",
  fBillingForOwnerTotalRentPrice: "TotalRentPrice",
  fBillingForOwnerCompletionFeePercentage: "CompletionFeePercentage",
  fBillingForOwnerCompletionFeeMoney: "CompletionFeeMoney",
  fBillingForOwnerTotal: "Total",
  fDepositAmount: "DepositAmount",
  fAmountRemain: "AmountRemain",
  fUserName: "UserName",
  fMessageToOwner: "MessageToOwner",
  fDeliveryAddress: "DeliveryAddress",
  fHotline: "Hotline",
  fRentalServiceStatus: "RentalServiceStatus",
  fName: "Name",
  fMyRentalServiceUrl: "MyRentalServiceUrl",
  fEndTime: "EndTime",
  fReceiveImages: "ReceiveImages",
  fServiceImageTypeName: "ServiceImageTypeName",
  fFiles: "Files",
  fFooterInfo: "FooterInfo",
  fThumbnailUrl: "ThumbnailUrl",
  fIsRequired: "IsRequired",
  fLimit: "Limit",
  fLongitude: "Longitude",
  fLatitude: "Latitude",
  fHeaderInfo: "HeaderInfo",
  fHeaderInfoTitle: "Title",
  fHeaderInfoDescription: "Description",
  fMyRating: "MyRating",
  /** review modal */
  fReviewOption: "fReviewOption",
  /** */
  fDeliveryInfo: "DeliveryInfo",
  /** Now fields */
  fTitle: "Title",
  fDescription: "Description",
  fOrders: "Orders",
  fBlogs: "Blogs",
  fOwnerAddress: "OwnerAddress",
  fDaysBeforeDeparture_Text: "DaysBeforeDeparture_Text",
  fRentalDate_Text: "RentalDate_Text",
  fIcon: "StatusIcon",
  fIconColor: "StatusColorCode",
  fRentalService_PlateNumber: "RentalService_PlateNumber",
  fOrderStatusCode: "OrderStatusCode",
  fOrderStatus: "OrderStatus",
  fOrderUserType: "OrderUserType",
  fID_GUID: "ID_GUID",
  fAddress: "Address",
  fSlug: "Slug",
  fPlateNumber: "PlateNumber",
  fExtraSurcharges: "ExtraSurcharges",
  fAnotherUserDescription: "Description",
  fRentalServiceItemImageUrls: "RentalServiceItemImageUrls",
  fFromDate_Format_1: "FromDate_Format_1",
  fToDate_Format_1: "ToDate_Format_1",
  fDocuments: "Documents",
  fSecurity: "Securities",
  fThingsToKnow: "ThingsToKnow",
  fButtonReport: "ButtonReport",
  fReportReasonId: "ReportReasonId",
  fReportReasonNote: "ReportReasonNote",
  fCharacteristics: "Characteristics",
  /** Insurance info */
  fDetailInsuranceInfo: "InsuranceInfo",
  fDetailInsuranceIcon: "Icon",
  fDetailInsuranceDescription: "Description",
  fDetailInsuranceContent: "Content",
  fDetailInsuranceTitle: "Title",
  fDetailInsuranceUrl: "Url",
  fCancelBookingPolicyInfo: "CancelBookingPolicyInfo",
  fFromDate_Format_2: "FromDate_Format_2",
  fToDate_Format_2: "ToDate_Format_2",
  fEntDate_Format_2: "EntDate_Format_2",
}
export const userProfileFields = {
  fId: "Id",
  fCanReportThisUser: "CanReportThisUser",
  fCanEditProfile: "CanEditProfile",
  // avatar
  fAvatarUrl: "AvatarUrl",
  // review
  fReview: "Review",
  fRating: "Rating",
  fTotalRatingCount: "TotalRatingCount",
  fRatingByRenterCount: "RatingByRenterCount",
  fRatingByOwnerCount: "RatingByOwnerCount",
  fReviewByRenters: "ReviewByRenters",
  fReviewByOwners: "ReviewByOwners",
  fReview_Rating: "Rating",
  fReview_ReviewBy: "ReviewBy",
  fReview_ReviewAt: "ReviewAt",
  fReview_ReviewContent: "ReviewContent",
  // Main info
  fServedAndRentCount: "ServedAndRentCount",
  fJoinedDate: "JoinedDate",
  fVerified: "Verified",
  fIconUrl: "IconUrl",
  fTitle: "Title",
  fContent: "Content",
  fCanShowButtonVerified: "CanShowButtonVerified",
  fDescription: "Description",
  fVerifiedInfos: "VerifiedInfos",
  fVerifiedInfos_Text: "Text",
  fVerifiedInfos_IsVerified: "IsVerified",
  fVerifiedInfos_RefText: "RefText",
  fVerifiedInfos_RefUrl: "RefUrl",
  fOwnerInteracts: "OwnerInteracts",
  fOwnerInteracts_Text: "Text",
  fOwnerInteracts_Value: "Value",
  fPersonalInfo: "PersonalInfo",
  fPersonalInfo_Nickname: "Nickname",
  fPersonalInfo_NeedChangePassword: "NeedChangePassword",
  fPersonalInfo_NewPassword: "NewPassword",
  fPersonalInfo_ConfirmNewPassword: "ConfirmNewPassword",
  fPersonalInfo_FullName: "FullName",
  fPersonalInfo_PhoneNumber: "PhoneNumber",
  fPersonalInfo_Email: "Email",
  fPersonalInfo_DateOfBirth: "DateOfBirth",
  fPersonalInfo_GenderCode: "GenderCode",
  // Report
  fReportUserReasonId: "ReportUserReasonId",
  fReportReason: "ReportReason",
  fUserLoginId: "UserLoginId",
  // Verify Fields
  fVerificationSteps: "VerificationSteps",
  fStep_IsUploaded: "IsUploaded",
  fStep_IconUrl: "IconUrl",
  fStep_Content: "Content",
  fStep_Code: "Code",
  fImages: "Images",
  fThumbnailUrl: "ThumbnailUrl",
  fUserImageTypeDetailName: "UserImageTypeDetailName",
  fUserImageTypeDetailId: "UserImageTypeDetailId",
  fLimit: "Limit",
  fImage_Id: "Id",
  fMessageShowInUI: "MessageShowInUI",
}
export const myServiceFields = {
  fRentalServiceStatus: "RentalServiceStatus",
  fAvatarUrl: "AvatarUrl",
  fStatus: "Status",
  fModel: "Model",
  fId: "Id",
  fRate_Txt: "Rate_Txt",
  fServedCount: "ServedCount",
  fRentalPrice_Txt: "RentalPrice_Txt",
  fCanEdit: "CanEdit",
}
export const homeSlidesFields = {
  fSlides: "Slides",
  fSlideImages: "SlideImages",
  fTitle: "Title",
  fDescription: "Description",
  fOneSlideImageUrl: "ImageUrl",
  fOneSlideTitle: "Title",
  fOneSlideMoreInfo: "MoreInfo",
  fAddress: "Address",
  fLatitude: "Latitude",
  fLongitude: "Longitude",
  fFromDate: "FromDate",
  fToDate: "ToDate",
  fOneSlideDescription: "Description",
  fTimeStart: "TimeStart",
  fTimeEnd: "TimeEnd",
}
export const savingFields = {
  fUsername: "DisplayName",
  fPoint: "Point",
  fImageUrl: "AvatarUrl",
  fInvitedFriendCount: "InvitedFriendCount",
  fCoinCollected: "Point",
  fInviteCode: "InviteCode",
  fBonusScreen: "BonusScreen",
  fHistoryDate: "Date",
  fHistoryPointChange: "PointChange",
  fHistoryStatus: "Status",
  fHistoryTitle: "Title",
  fInvitedFriendScreen: "InviteFriendScreen",
  fColorCode: "ColorCode",
  // history invited friend
  fInvitedDate: "Date",
  fInvitedPointChange: "PointChange",
  fInvitedStatus: "Status",
  fInvitedTitle: "Title",
  fInvitedDisplayName: "DisplayName",
}
export const contentPageFields = {
  fTitle: "Title",
  fBody: "Body",
  fLastUpdated: "LastUpdated",
  // Blog fields
  fRelatedPosts: "fRelatedPosts",
  fCreatedDate_Text: "CreatedDate_Text",
  fPostLink: "fPostLink",
  fPostTitle: "fPostTitle",
  fImageUrl: "fImageUrl",
  fPostCreatedDate: "fPostCreatedDate",
  fAllowComment: "AllowComment",
}
export const notificationFields = {
  fAvatarUrl: "AvatarUrl",
  fEntity: "Entity",
  fSubject: "Subject",
  fMessage: "Message",
  fIsSeen: "IsSeen",
  fNotifedAt: "NotifedAt",
  fId: "Id",
  fRequestData: "RequestData",
  fOrderNumber: "OrderNumber",
  fUserLoginRole: "UserLoginRole",
  fEntityCode: "EntityCode",
}
export const paymentFields = {
  fBankAccount: "BankAccount",
  fAccountName: "AccountName",
  fAccountNumber_NotFull: "AccountNumber_NotFull",
  fAccountNumber: "AccountNumber",
  fBeneficiaryBankName: "BeneficiaryBankName",
  fBeneficiaryBankId: "BeneficiaryBankId",
  fIsDefault: "IsDefault",
  fIsVerified: "IsVerified",
  fNeedDelete: "NeedDelete",
  // payment wallet
  fPaymentWallet: "PaymentWallet",
  fWithdrawFee: "WithdrawFee",
  fWithdrawFee_Text: "WithdrawFee_Text",
  fWalletMoney: "WalletMoney",
  fWalletMoney_Number: "WalletMoney_Number",
  fMaximumMoneyCanWithdraw: "MaximumMoneyCanWithdraw",
  fMaximumMoneyCanWithdraw_Text: "MaximumMoneyCanWithdraw_Text",
  fCanWithdraw: "CanWithdraw",
  fMinimumMoneyCanWithdraw: "MinMoneyCanWithdraw",
  fMinMoneyCanWithdraw_Text: "MinMoneyCanWithdraw_Text",
  // withdraw fields
  fWithdrawAmount: "WithdrawAmount",
  // Transaction
  fTransactionHistories: "TransactionHistories",
  fTransactionAt: "TransactionAt",
  fDetail: "Detail",
  fDescription: "Description",
  fPaidBy: "PaidBy",
  fBalanceChange: "BalanceChange",
}
