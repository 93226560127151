import { HConstant, HIs, HLocalStorage, HUtils } from "src/core/helpers"
import {
  MyPageOne,
  MyUIOne,
  MyLibOne,
  MyIconOne,
  MyControlOne,
  MyFormControlOne,
  MyButtonOne,
  MyCardOne,
  MyControlForm,
  MyTableFilterControl,
  MyTableCell,
} from "./components"
import "src/modules/app_sigo/assets/theme-sigo.scss"
import "src/modules/app_sigo/assets/fonts/flaticon.css"
import "src/modules/app_sigo/assets/guest/css/reset.css"
import "src/modules/app_sigo/assets/guest/css/nice-select.css"
import "src/modules/app_sigo/assets/guest/css/guest.css"
import "react-loading-skeleton/dist/skeleton.css"
import "src/modules/app_sigo/assets/icons/flaticon.css"
import "src/modules/app_sigo_responsive/assets/theme-sigo-responsive.scss"
import { kFirstSettingData } from "src/modules/app_sigo/constants"
import { store } from "src/store/store"
import { initFirstSettingData } from "src/App/App.actions"
import { SGNewPaths, SGPaths } from "src/modules/app_sigo/constants/paths"

interface IModuleAppSigo extends ICore.IOneModule {
  runAfterInit: (HConfig: any) => void
}
const ModuleAppSigoResponsive: IModuleAppSigo = {
  _key: "app_sigo",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "app_sigo"
  },
  runAfterInit: (HConfig: any) => {
    MyPageOne.init()
    MyUIOne.init()
    MyLibOne.init()
    MyIconOne.init()
    MyControlOne.init()
    MyFormControlOne.init()
    MyButtonOne.init()
    MyCardOne.init()
    MyControlForm.init()
    MyTableFilterControl.init()
    MyTableCell.init()
    window.document.body.classList.add("theme-sigo")
    window.document.body.classList.add("theme-sigo-responsive")
    window.document.body.classList.add("guest")
    const userAgent =
      window.navigator.userAgent && window.navigator.userAgent.toLowerCase()
    if (
      userAgent &&
      userAgent.indexOf("iphone") > -1 &&
      userAgent.indexOf("safari") > -1
    ) {
      window.document.body.classList.add("on-ios")
    }
    // xử lý init first setting
    const firstSettingLocal = HLocalStorage.getObj(kFirstSettingData, {})
    store.dispatch(initFirstSettingData(firstSettingLocal))
    HUtils.runFuntion(HConfig, HConstant.HConfig.FnList.addPrivateRoutes, [
      [
        {
          Id: "history",
          UIUrl: "/dev-v1/:status",
          UIType: "history",
          Title: "Chuyến đi của tôi",
        },
        {
          Id: "mytrips",
          UIUrl: SGPaths.chuyendicuatoi,
          UIType: "mytrips",
          Title: "Chuyến đi của tôi",
          RedirectUrl: SGNewPaths.chuyendicuatoi,
        },
        {
          Id: "mytrips-new",
          UIUrl: SGNewPaths.chuyendicuatoi,
          UIType: "mytrips",
          Title: "Chuyến đi của tôi",
          configMainLayout: {
            isHideFooter: true,
          },
        },
        {
          Id: "my-profile",
          UIUrl: SGPaths.taikhoancuatoi,
          UIType: "sg_user_profile",
          Title: "Tài khoản của tôi",
          APIName: "User",
          Config: {
            GetData: "User/MyProfile",
            UpdateData: "User/UpdateProfile",
            UpdateAvatar: "Upload/User/MyAvatar",
          },
          configMainLayout: {
            isHideHeader: true,
          },
          RedirectUrl: SGNewPaths.taikhoancuatoi,
        },
        {
          Id: "my-profile-new",
          UIUrl: SGNewPaths.taikhoancuatoi,
          UIType: "sg_user_profile",
          Title: "Tài khoản của tôi",
          APIName: "User",
          Config: {
            GetData: "User/MyProfile",
            UpdateData: "User/UpdateProfile",
            UpdateAvatar: "Upload/User/MyAvatar",
          },
          configMainLayout: {
            isHideHeader: true,
          },
        },
        {
          Id: "verify-account",
          UIUrl: SGPaths.xacthuctaikhoan,
          UIType: "sg_verify_account",
          Title: "Xác thực",
          APIName: "User/MyProfile",
          RedirectUrl: SGNewPaths.xacthuctaikhoan,
        },
        {
          Id: "verify-account-new",
          UIUrl: SGNewPaths.xacthuctaikhoan,
          UIType: "sg_verify_account",
          Title: "Xác thực",
          APIName: "User/MyProfile",
        },
        {
          Id: "my-saving",
          UIUrl: SGPaths.tichluy,
          UIType: "sg_saving",
          Title: "Tích lũy",
          APIName: "User/GetCoinReward",
          RedirectUrl: SGNewPaths.tichluy,
        },
        {
          Id: "my-saving-new",
          UIUrl: SGNewPaths.tichluy,
          UIType: "sg_saving",
          Title: "Tích lũy",
          APIName: "User/GetCoinReward",
        },
        {
          Id: "my-service",
          UIUrl: SGPaths.xecuatoi,
          UIType: "sg_my_service",
          Title: "Xe của tôi",
          RedirectUrl: SGNewPaths.xecuatoi,
          configMainLayout: {
            isHideFooter: true,
          },
        },
        {
          Id: "my-service-new",
          UIUrl: SGNewPaths.xecuatoi,
          UIType: "sg_my_service",
          Title: "Xe của tôi",
          configMainLayout: {
            isHideFooter: true,
          },
        },
        {
          Id: "add-service",
          UIUrl: SGPaths.themxe,
          UIType: "sg_add_service",
          Title: "Thêm xe",
          RedirectUrl: SGNewPaths.themxe,
        },
        {
          Id: "add-service-new",
          UIUrl: SGNewPaths.themxe,
          UIType: "sg_add_service",
          Title: "Thêm xe",
        },
        {
          UIUrl: SGPaths.lichhoatdong,
          UIType: "slug",
          Title: "Lịch hoạt động",
          RedirectUrl: SGNewPaths.lichhoatdong,
        },
        {
          UIUrl: SGNewPaths.lichhoatdong,
          UIType: "my_activity_schedule",
          Title: "Lịch hoạt động",
          configMainLayout: {
            isHideFooter: true,
          },
        },
        {
          UIUrl: SGPaths.danhmucxe,
          UIType: "slug",
          Title: "Danh mục xe cho thuê",
          RedirectUrl: SGNewPaths.danhmucxe,
        },
        {
          UIUrl: SGNewPaths.danhmucxe,
          UIType: "slug",
          Title: "Danh mục xe cho thuê",
        },
        {
          Id: "notification",
          UIUrl: SGPaths.thongbao,
          UIType: "sg_notification",
          Title: "Thông báo",
          APIName: "Notification",
          RedirectUrl: SGNewPaths.thongbao,
        },
        {
          Id: "notification-new",
          UIUrl: SGNewPaths.thongbao,
          UIType: "sg_notification",
          Title: "Thông báo",
          APIName: "Notification",
        },
        {
          Id: "payment",
          UIUrl: SGPaths.thanhtoan,
          UIType: "sg_payment",
          Title: "Thanh toán",
          RedirectUrl: SGNewPaths.thanhtoan,
        },
        {
          Id: "payment-new",
          UIUrl: SGNewPaths.thanhtoan,
          UIType: "sg_payment",
          Title: "Thanh toán",
        },
        {
          Id: "edit-my-service",
          UIUrl: SGPaths.chinhsuaxe,
          UIType: "sg_edit_my_service",
          Title: "Chỉnh sửa xe",
          RedirectUrl: SGNewPaths.chinhsuaxe,
        },
        {
          Id: "edit-my-service-new",
          UIUrl: SGNewPaths.chinhsuaxe,
          UIType: "sg_edit_my_service",
          Title: "Chỉnh sửa xe",
        },
        {
          Id: "my-orders",
          UIUrl: SGPaths.donhanghientai,
          UIType: "my_orders",
          Title: "Đơn đặt xe của bạn",
          RedirectUrl: SGNewPaths.donhanghientai,
        },
        {
          Id: "my-orders-new",
          UIUrl: SGNewPaths.donhanghientai,
          UIType: "my_orders",
          Title: "Đơn đặt xe của bạn",
          configMainLayout: {
            isHideFooter: true,
          },
        },
      ],
    ])
    HUtils.runFuntion(HConfig, HConstant.HConfig.FnList.addPublicRoutes, [
      [
        {
          Id: "profile",
          UIUrl: SGPaths.thongtintaikhoan,
          UIType: "sg_user_profile",
          Title: "",
          APIName: "User",
          Config: {
            GetData: "User/Profile",
            ReportUser: "User/ReportUser",
          },
        },
        {
          Id: "profile-new",
          UIUrl: SGNewPaths.thongtintaikhoan,
          UIType: "sg_user_profile",
          Title: "",
          APIName: "User",
          Config: {
            GetData: "User/Profile",
            ReportUser: "User/ReportUser",
          },
        },
        // isLocalDev() && {
        //   Id: "dev_test",
        //   UIUrl: SGPaths.dev_test,
        //   UIType: "sg_dev_test",
        //   Title: "",
        //   Config: {},
        // },
        {
          Id: "detail",
          UIUrl: SGPaths.chitietxe,
          UIType: "detail",
          Title: "Chi tiết xe",
          configMainLayout: {
            isHideHeader: true,
          },
        },
        {
          Id: "category",
          UIUrl: SGPaths.searchxe,
          UIType: "category",
          Title: "Danh sách xe",
          RedirectUrl: SGNewPaths.searchxe,
        },
        {
          Id: "category-new",
          UIUrl: SGNewPaths.searchxe,
          UIType: "category",
          Title: "Danh sách xe",
          configMainLayout: {
            isHideFooter: true,
          },
        },
        {
          Id: "helpus",
          UIUrl: SGPaths.huongdandatxe,
          UIType: "helpus",
          Title: "Trợ giúp",
        },
        {
          Id: "registerguide",
          UIUrl: SGPaths.huongdandangkyxe,
          UIType: "helpus",
          Title: "Hướng dẫn đăng ký xe",
        },
        {
          Id: "faqs",
          UIUrl: SGPaths.cauhoithuonggap,
          UIType: "helpus",
          Title: "Hỗ trợ",
        },
        {
          Id: "sg_account",
          UIUrl: SGPaths.taikhoan,
          UIType: "sg_account",
          Title: "Tài khoản",
          configMainLayout: {
            isHideFooter: true,
          },
        },
        {
          Id: "slug",
          UIUrl: SGPaths.slug,
          UIType: "slug",
          configMainLayout: {
            isHideHeader: true,
          },
        },
        {
          Id: "home",
          UIUrl: SGPaths.home,
          UIType: "home",
          Title: "Sigo",
        },
      ],
    ])
  },
}

export default ModuleAppSigoResponsive
