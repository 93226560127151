import {
  MyIcon,
  HIs,
  HUtils,
  MyUI,
  HCF,
  HJson,
  HFormat,
  MyTableCell,
} from "@macashipo/mlib"
import * as FlowTableExtraData from "./TableExtraData"
import * as FlowApi from "./Api"
import { ApiGeneric } from "src/services/apiServices"
import { fnListMyPage, fnListUI } from "src/helpers/fnList"
import { KConfigApp, KConfigModal, KConfigPageTable } from "../K"
export const KMore = {
  className: "className",
  style: "style",
  styleByValue: "styleByValue",
  configIcon: "configIcon",
  configCheckbox: "configCheckbox",
  icon: "icon",
  linkTitle: "linkTitle",
  fLinkTitle: "fLinkTitle",
  newWindow: "newWindow",
  showPhoto: "showPhoto",
  maxHeight: "maxHeight",
  maxWidth: "maxWidth",
  noScale: "noScale",
  styleImg: "styleImg",
  link: "link",
  fLink: "fLink",
  color: "color",
  fColor: "fColor",
  min: "min",
  fMin: "fMin",
  max: "max",
  fMax: "fMax",
  styleInput: "styleInput",
  classNameWithColField: "classNameWithColField",
  modal: "modal",
  placeHolder: "placeHolder",
  format: "format",
  dateFormat: "dateFormat",
  datetimeFormat: "datetimeFormat",
  dateTextFormat: "dateTextFormat",
  fBorderLeftColor: "fBorderLeftColor",
  configPage: "configPage",
  query: "query",
  configApi: "",
  fSelectValue: "fSelectValue", //for select2_modal_table
  querySelectValue: "querySelectValue", //for form_modal_table
  clickToSelect: "clickToSelect",
  button: "button",
  cbNormal: "cbNormal", //for checkbox
  cbPositive: "cbPositive", //for checkbox
  cbNegative: "cbNegative", //for checkbox
  configEditIcon: "configEditIcon", //
  configFileIcon: "configFileIcon",
  configTextDisplay: "configTextDisplay", //config text display {fDisplay,fShow,hasIconAndText,fIcon,icon}
  typeAdmin: "typeAdmin", //type danh rieng cho trang admin neu can thiet
  titleConfirm: "titleConfirm",
  confirmMsg: "confirmMsg", //truoc khi click api v.v..
  fConfirmMsg: "fConfirmMsg", //f confirmMsg
  myui: "myui",
  styleByValueField: "styleByValueField", //styleByValueField or logic
  showDisplayWithMyUI: "showDisplayWithMyUI", //custom display bang myui
  showClear: "showClear", //show nut clear
  configClear: "configClear",
  hideWhenEmpty: "hideWhenEmpty",
  hideWhenNull: "hideWhenNull",
  showDeleteRow: "showDeleteRow", //show nut clear row o right top bar
  showPreviewHTML: "showPreviewHTML", //show nut preview HTML
  configDisplay: "configDisplay", //bo sung config display cho cac cell show text
  virtualized: "virtualized", //ho tru virtualized true/false
  virtualizedMin: "virtualizedMin", //chuyen qua virtualized khi co min size la bao nhieu
  showSelectedOnFirstRow: "showSelectedOnFirstRow", //show selected item on first row, select modal
  apiCheckShowModal: "apiCheckShowModal", //check show modal o form
  autoOpenLink: "autoOpenLink", // auto open full path
  overideDefaultValues: "overideDefaultValues", //data truyen len se bao gom default Values, hien tai mac dinh se gom default values nen ko su dung nua
  rows: "rows",
  needReExpand: "needReExpand",
  needReloadOptionsAndList: "needReloadOptionsAndList",
  needReloadRow: "needReloadRow",
  needReload: "needReload",
  needReloadList: "needReloadList",
  needReloadTable: "needReloadTable",
  needReloadPage: "needReloadPage",
  needSendMsg: "needSendMsg",
  configFormat: "configFormat", //danh cho config format mac dinh ma cell su dung
  configProgress: "configProgress", //danh cho progress
  configPopover: "configPopover", //danh cho co su dung popover
  configTitleTooltip: "configTitleTooltip", //config title tooltip
}

export const KConfigInRow = {
  _UI_Loading: "_UI_Loading",
  _UI_Error: "_UI_Error",
  _UI_NeedUpdateUI: "_UI_NeedUpdateUI", //true: luon render lai ui
}

const KTypeCellShared = {
  _cell_loading: "_cell_loading",
}

export const FlowGetExtraDataFromExtra = (extra = {}) => {
  return extra.extraData || {}
}
export const FlowGetFieldNameFromExtra = (extra = {}) => {
  return extra.fieldName
}
export const FlowGetExtraOfFieldName = (extra = {}, fieldName) => {
  let _extraCol = extra
  if (fieldName && extra.fieldName != fieldName) {
    _extraCol = FlowTableExtraData.FlowGetColumnFromExtraData(
      FlowGetExtraDataFromExtra(extra),
      fieldName
    )
  }
  return _extraCol
}
export const FlowGetMore = ({ extra, more, fieldName }) => {
  let _fieldName = fieldName || FlowGetFieldNameFromExtra(extra)
  return (
    more ||
    FlowTableExtraData.FlowGetMoreOfCellFromExtraData(
      FlowGetExtraDataFromExtra(extra),
      _fieldName
    )
  )
}

//////// More

export const FlowActionClickApi = ({ extra, more, fieldName }) => {
  let _more = FlowGetMore({ extra, more, fieldName })
}
export const FlowActionClickForm = () => {}
export const FlowActionClickLink = ({ extra, row, more, fieldName, link }) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  let _link = link || FlowGetLink({ extra, row, more: _more, fieldName })
  if (_link) {
    window.open(
      _link,
      FlowGetLinkTarget({ extra, row, more: _more, fieldName })
    )
  }
  console.warn("Click link", _link)
}

export const FlowParseResponseAndNeedReload = ({
  extra,
  more,
  row,
  fieldName,
  oneTableCell,
  response,
  configApi,
}) => {
  // let _more = FlowGetMore({ extra, more, fieldName }) || {}
  console.warn("parseResponse", oneTableCell)
  if (oneTableCell) {
    let _configApi = configApi || oneTableCell.getConfigApi()
    oneTableCell.parseResponse(response)
    oneTableCell.parseNeedReload(_configApi, response)
  }
}
export const FlowCheckShowModal = ({
  extra,
  more,
  row,
  fieldName,
  defaultValue,
}) => {
  let _more = FlowGetMore({ extra, more, fieldName }) || {}
  let _configModal = _more[KMore.modal]
  let _canShowModal = defaultValue != null ? defaultValue : true
  if (_configModal) {
    if (_configModal.canShowModal == false) {
      _canShowModal = false
    }
    if (
      _configModal.fCanShowModal &&
      row[_configModal.fCanShowModal] === false
    ) {
      _canShowModal = false
    }
  }
  return _canShowModal
}

export const FlowGetRequestFromConfigObj = ({ row, configApi }) => {
  let _request = {
    method: "POST" || configApi.method,
    url: configApi.url,
    path: configApi.path,
    name: configApi.name,
  }
  if (_request.url) {
    if (_request.url.indexOf("[") > -1) {
      _request.url = HUtils.getTextWithData(_request.url, row)
    }
  }
  if (_request.name) {
    if (_request.name.indexOf("[") > -1) {
      _request.name = HUtils.getTextWithData(_request.name, row)
    }
  }
  return _request
}

export const FlowGetRequestQueryFromConfigObj = ({ row, query, df }) => {
  let _query = df || {}
  if (query) {
    _query = Object.assign(_query, query)
    let _keys = Object.keys(_query)
    for (let k of _keys) {
      let _value = query[k]
      if (row.hasOwnProperty(_value) == true) {
        if (row[_value] != null) {
          _query[k] = row[_value]
        }
      }
    }
  }
  return _query
}

export const FlowCheckApiShowModal = ({
  extra,
  more,
  row,
  fieldName,
  cb,
  fnList,
}) => {
  let _more = FlowGetMore({ extra, more, fieldName }) || {}
  let _configCheckShow = _more[KMore.apiCheckShowModal]
  let _canShowModal = FlowCheckShowModal({ extra, more, row })
  //test
  // FlowApi.FlowApiShowFullPath({
  //   response: {},
  //   configFullPath: { FullPath: "http://google.com" },
  // })
  // cb({
  //   canShow: true,
  // })
  // return
  //
  //clear focus button
  document.activeElement.blur()
  if (cb) {
    if (_canShowModal == false) {
      cb({
        canShow: false,
      })
    } else if (_configCheckShow) {
      let _request = FlowGetRequestFromConfigObj({
        row,
        configApi: _configCheckShow,
      })
      let _query = FlowGetRequestQueryFromConfigObj({
        row,
        query: _configCheckShow.query,
      })
      ApiGeneric({
        request: _request,
        data: _query,
        successCallBack: response => {
          let _config = HUtils.get(response, "Data.Config")
          if (_config) {
            FlowApi.FlowApiShowFullPath({
              response,
              configFullPath: _config,
              autoOpenLink:
                more[KMore.autoOpenLink] != null
                  ? more[KMore.autoOpenLink]
                  : null,
            })
          }
          if (_config && _config.IsShow) {
            let _resultModel = HUtils.get(response, "Data.Config.ResultModel")
            if (_resultModel && row) {
              //update model vao row
              HUtils.runFuntion(fnList, "onUpdateRow", [
                {
                  row,
                  newRow: _resultModel,
                },
              ])
            }
            if (cb) {
              cb({
                canShow: true,
                config: _config,
                data: _config.FormData,
              })
            }
          } else {
            if (cb) {
              cb({
                canShow: false,
              })
            }
          }
          // oneButton.changeIsLoading(false)
        },
        errorCallBack: (error, response) => {
          // oneButton.changeIsLoading(false)
          if (cb) {
            cb({
              canShow: false,
            })
          }
        },
      })
    } else {
      cb({
        canShow: true,
      })
    }
  }
  return false
}
export const FlowCheckConfirmMsg = ({ extra, more, row, fieldName, cb }) => {
  let _more = FlowGetMore({ extra, more, fieldName }) || {}
  let _confirmMsg = _more[KMore.confirmMsg]
  let _confirmTitle = _more[KMore.titleConfirm] || "Confirm"
  if (row && _more[KMore.fConfirmMsg] && row[_more[KMore.fConfirmMsg]]) {
    _confirmMsg = row[_more[KMore.fConfirmMsg]]
  }
  if (_confirmMsg) {
    fnListUI.fnshowConfirm({
      title: _confirmTitle,
      msg: _confirmMsg,
      onClickOK: () => {
        if (cb) {
          cb()
        }
      },
    })
  } else {
    if (cb) {
      cb()
    }
  }
}
export const FlowGetConfigMyUI = ({ extra, more, row, fieldName }) => {
  let _configMyUI = {}
  let _more = FlowGetMore({ extra, more, fieldName }) || {}
  if (_more[KMore.myui]) {
    _configMyUI = _more[KMore.myui]
  }
  return _configMyUI
}
//thay the TableHelper render icon
export const FlowRenderIconFromMore = ({ extra, more, fieldName }) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more) {
    let _icon = _more[KMore.configIcon] || _more[KMore.icon]
    if (_icon) {
      if (HIs.isString(_icon)) {
        return <MyIcon data={_icon} />
      } else if (HIs.isObject(_icon)) {
        if (_icon.className) {
          return <MyIcon data={_icon.className} style={_icon.style} />
        }
      }
    }
  }
}

export const FlowGetTextDisplay = ({
  extra,
  more,
  fieldName,
  oneTableCell,
  cell,
  valueCell,
  isButton,
}) => {
  if (oneTableCell) {
    let _configTextDisplay = {}
    if (isButton) {
      _configTextDisplay = FlowGetConfigTextDisplayOfButton({
        extra,
        more,
        fieldName,
      })
    }
    return oneTableCell.getTextDisplay(_configTextDisplay)
  }
  return cell || valueCell
}

export const FlowGetCustomDisplay = ({
  extra,
  more,
  row,
  fieldName,
  oneTableCell,
  props,
  value,
  defaultConfigDisplay,
}) => {
  if (more && more[KMore.showDisplayWithMyUI]) {
    const configMyUI = FlowGetConfigMyUI({ extra, more, fieldName })
    if (configMyUI && configMyUI.type) {
      return (
        <div>
          <MyUI
            {...props}
            type={configMyUI.type || "notypemyui"}
            data={configMyUI.dataIsRow ? row : value}
            row={row}
            config={configMyUI}
            {...configMyUI.moreProps}
          />
        </div>
      )
    }
  }
  let _valueDisplay = value
  if (typeof value == "string") {
    let _configDisplay = more[KMore.configDisplay] || defaultConfigDisplay
    if (_configDisplay) {
      if (_configDisplay.showShortText) {
        let _countShort = _configDisplay.countShort || 50
        _valueDisplay = value.slice(0, _countShort)
        _valueDisplay += `${_valueDisplay.length == _countShort ? "..." : ""}`
      } else if (_configDisplay.text) {
        _valueDisplay = HUtils.getTextWithData(_configDisplay.text, row)
      } else if (_configDisplay.configFormat) {
        _valueDisplay = HFormat.format(_valueDisplay, {
          ..._configDisplay.configFormat,
          row: row,
        })
      } else if (_configDisplay.isHide) {
        _valueDisplay = ""
      }
    }
  }
  return <div>{String(_valueDisplay)}</div>
}

export const FlowGetConfigTextDisplayOfButton = ({
  extra,
  more,
  fieldName,
}) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  let _config = {}
  if (_more) {
    let _configButton = more[KMore.button]
    if (_configButton) {
      _config.icon = _configButton.icon
      _config.title = _configButton.title
      _config.hasIconAndText = _configButton.hasIconAndText
    }
  }
  return _config
}

export const FlowRenderStatus = ({
  extra,
  more,
  fieldName,
  row,
  valueCell,
  link,
  canEdit,
}) => {
  let _ui = []
  if (more) {
    if (
      more[KMore.configEditIcon] &&
      more[KMore.configEditIcon].using &&
      canEdit
    ) {
      _ui.push(
        <MyTableCell extra={extra} row={row} more={more} type="_editIcon" />
      )
    }
  }
  if (_ui.length > 0) {
    return <div className="mct-status no-print"></div>
  }
}

export const FlowRenderTitleOfLink = ({
  extra,
  more,
  fieldName,
  row,
  valueCell,
  link,
}) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.showPhoto] === true) {
    let _maxHeight = "120px"
    let _maxWidth = "120px"
    if (_more[KMore.maxHeight]) {
      _maxHeight = _more[KMore.maxHeight]
    }
    if (_more[KMore.maxWidth]) {
      _maxWidth = _more[KMore.maxWidth]
    }
    let _styleImg = { width: "100%", height: "100%", maxHeight: _maxHeight }
    if (_more[KMore.noScale] == true) {
      _styleImg = {
        maxHeight: _maxHeight,
        display: "block",
        maxWidth: _maxWidth,
        width: "auto",
        height: "auto",
      }
    }
    if (_more[KMore.styleImg]) {
      _styleImg = Object.assign(_styleImg, _more[KMore.styleImg])
    }
    if (link) {
      return <img alt="" src={link} style={_styleImg} />
    } else {
      return <div></div>
    }
  }
  let _uiIcon = FlowRenderIconFromMore({ extra, more, fieldName })
  if (_uiIcon) {
    return _uiIcon
  }
  let _title = valueCell
  if (_more[KMore.linkTitle]) {
    _title = _more[KMore.linkTitle]
  } else if (row && _more[KMore.fLinkTitle] && row[_more[KMore.fLinkTitle]]) {
    _title = row[_more[KMore.fLinkTitle]]
  }
  return _title
}

export const FlowGetLinkTarget = ({ extra, more, fieldName }) => {
  let _target = "_blank"
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.newWindow] === true) {
    return "_blank"
  } else if (_more[KMore.newWindow] === false) {
    return "_self"
  }
  return _target
}

export const FlowGetStyleInput = ({ extra, more, fieldName, defaultStyle }) => {
  let _styleInput = defaultStyle || {}
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.styleInput]) {
    _styleInput = { ..._styleInput, ..._more[KMore.styleInput] }
  }
  return _styleInput
}

export const FlowGetMoreAttrsInput = ({
  extra,
  more,
  fieldName,
  defaultAttrs,
}) => {
  let _attrs = defaultAttrs || {}
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.placeHolder]) {
    _attrs = Object.assign(_attrs, {
      placeholder: _more[KMore.placeHolder],
    })
  }
  return _attrs
}

export const FlowGetLink = ({ extra, row, more, fieldName, valueCell }) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  let _link = valueCell
  if (extra && extra.Type && extra.Type.link) {
    _link = extra.Type.link
  }
  if (_more[KMore.fLink]) {
    if (row && row[_more[KMore.fLink]]) _link = row[_more[KMore.fLink]]
    else {
      _link = ""
    }
  } else if (_more[KMore.link]) {
    _link = _more[KMore.link]
  }
  if (_link && typeof _link == "string" && _link.indexOf("[") > -1) {
    _link = HUtils.getTextWithData(_link, row)
  }
  return _link
}

export const FlowGetHeaderTitle = ({
  extra,
  more,
  row,
  fieldName,
  defaultValue,
  notUseFieldName,
  customTitle,
} = {}) => {
  let _title = defaultValue || ""
  let _extraOfColumn = FlowGetExtraOfFieldName(extra, fieldName)
  // let _more = FlowGetMore({ extra, more, fieldName })
  // console.warn("FlowGetHeaderTitle", _title)
  if (notUseFieldName !== true && _title == "") {
    _title = fieldName || extra.fieldName
  }
  if (_extraOfColumn.Header) {
    //for header json
    if (_extraOfColumn.Header.startsWith("{")) {
      let _objHeader = HJson.getObj(_extraOfColumn.Header)
      _title = _objHeader.header || ""
    } else {
      _title = _extraOfColumn.Header
    }
  }
  if (customTitle) {
    return customTitle(_title)
  }
  return _title
}

export const FlowGetTitleOfColumn = ({ extra, row, more, fieldName }) => {
  return FlowGetHeaderTitle({ extra, row, more, fieldName })
}

//Dung cho cac kieu title de hover show tooltip, button
export const FlowGetTitleTooltipOfColumn = ({
  extra,
  row,
  more,
  fieldName,
}) => {
  let _title = FlowGetTitleOfColumn({ extra, fieldName })
  let _configTitleTooltip = FlowGetConfigOfMore({
    extra,
    row,
    fieldName,
    key: KMore.configTitleTooltip,
  })
  if (_configTitleTooltip) {
    if (_configTitleTooltip.fTitle != null) {
      _title = row[_configTitleTooltip.fTitle]
    } else if (_configTitleTooltip.title != null) {
      _title = HUtils.getTextWithData(_configTitleTooltip.title, row)
    }
  }
  return _title
}

export const FlowGetTableConfig = ({ extra, row, more, key, defaultValue }) => {
  let _fnList = FlowGetFnList({ extra, fieldName: extra.fieldName })
  if (_fnList) {
    let _value = null
    //v1
    if (_fnList.fnGetTableConfig) {
      _value = _fnList.fnGetTableConfig(key, defaultValue)
    } else {
      _value = fnListMyPage.fnGetConfigPageTable(_fnList, [key])
    }
  }
}
export const FlowGetConfigTrueByAllLevel = ({ key, extra, row, more } = {}) => {
  const _configInTableConfig = FlowGetTableConfig({ extra, row, more, key })
  if (more && more[key] === true) {
    // console.warn("more:",more)
    return true
  } else if (_configInTableConfig === true) {
    // console.warn("_configInTableConfig:",_configInTableConfig)
    return true
  } else if (HCF.getCF(key) === true) {
    // console.warn("AppConfig:",HConfig)
    return true
  }
  return false
}

export const FlowGetBackgroundStyleCell = ({
  extra,
  more,
  row,
  fieldName,
  defaultStyle,
}) => {
  let _style = defaultStyle || {}
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.style]) {
    _style = { ..._style, ..._more[KMore.style] }
  }
  if (extra != null && row != null) {
    if (extra.Type) {
      //style from bgF
      if (extra.Type.bgF != null) {
        if (row[extra.Type.bgF] != null) {
          // let _color = HColor.getTextWB(row[extra.Type.bgF]);
          _style = Object.assign(_style, { background: row[extra.Type.bgF] })
        } else if (
          extra.Type.bgF.startsWith("#") ||
          extra.Type.bgF.startsWith("linear-gradient")
        ) {
          // let _color = HColor.getTextWB(extra.Type.bgF);
          _style = Object.assign(_style, { background: extra.Type.bgF })
        }
      }
    }
  }
  console.warn("FlowGetBackgroundStyleCell:", _style)
  return _style.background
}

export const FlowGetStyleCell = ({
  extra,
  more,
  row,
  fieldName,
  defaultStyle,
  overideStyle,
}) => {
  let _styleDefaultCell = HCF.getCF(KConfigApp.styleDefaultCell)
  let _style = { ..._styleDefaultCell, ...defaultStyle }
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.style]) {
    _style = { ..._style, ..._more[KMore.style] }
  }
  if (extra != null && row != null) {
    let _styleInRow = null
    //from row.StyleRow
    if (row && row.StyleRow) {
      _styleInRow = row.StyleRow
    }
    //from row.UI_StyleRow
    if (row && row.UI_StyleRow) {
      _styleInRow = row.UI_StyleRow
    }
    if (_styleInRow) {
      if (typeof _styleInRow == "object") {
        _style = Object.assign(_style, _styleInRow)
      } else if (HIs.isJsonString(_styleInRow)) {
        _style = Object.assign(_style, HJson.getObj(_styleInRow))
      }
    }

    //from extra.Style
    if (extra.Style) {
      if (HIs.isJsonString(extra.Style)) {
        _style = { ..._style, ...HJson.getObj(extra.Style) }
      } else if (HIs.isObject(extra.Style)) {
        _style = { ..._style, ...extra.Style }
      }
    }
    if (extra.Type) {
      //style from bgF
      if (extra.Type.bgF != null) {
        if (row[extra.Type.bgF] != null) {
          // let _color = HColor.getTextWB(row[extra.Type.bgF]);
          _style = Object.assign(_style, { background: row[extra.Type.bgF] })
        } else if (
          extra.Type.bgF.startsWith("#") ||
          extra.Type.bgF.startsWith("linear-gradient")
        ) {
          // let _color = HColor.getTextWB(extra.Type.bgF);
          _style = Object.assign(_style, { background: extra.Type.bgF })
        }
      }
      //style from colorF
      if (extra.Type.colorF != null) {
        if (row[extra.Type.colorF] != null) {
          _style = Object.assign(_style, { color: row[extra.Type.colorF] })
        } else if (extra.Type.colorF.startsWith("#")) {
          _style = Object.assign(_style, { color: extra.Type.colorF })
        }
      }
    }
  }
  //styleByValue
  // console.warn("styleByValue 1",extra.Type,_more,_more[KMore.styleByValue])
  if (_more[KMore.styleByValue]) {
    // console.warn("styleByValue",_more[KMore.styleByValue])
    let _fieldName = fieldName || FlowGetFieldNameFromExtra(extra)
    let _valueCell = row[_fieldName]
    if (HFormat.Types.configByValue) {
      let _moreStyle = HFormat.format(_valueCell, {
        type: "configByValue",
        configByValue: _more[KMore.styleByValue],
      })
      if (_moreStyle) {
        _style = Object.assign(_style, _moreStyle)
      }
    }
  }
  //bo sung cho dep
  if (
    _more[KMore.fBorderLeftColor] &&
    row[_more[KMore.fBorderLeftColor]] &&
    _style
  ) {
    _style.borderLeft = `4px solid ${row[_more[KMore.fBorderLeftColor]]}`
  }

  //bo sung overideStyle
  if (overideStyle) {
    _style = { ..._style, ...overideStyle }
  }
  // console.warn("_style", _style, _more, extra)
  return _style
}

export const FlowGetClassCell = ({
  extra,
  more,
  row,
  fieldName,
  defaultClass,
}) => {
  let _className = defaultClass || ""
  let _more = FlowGetMore({ extra, more, fieldName })
  //class from extra
  if (extra.ClassName) {
    _className += ` ${extra.ClassName}`
  }
  //class from more
  if (_more[KMore.className]) {
    _className += ` ${_more[KMore.className]}`
  }
  //class fieldName
  if (extra.fieldName) {
    if (
      FlowGetConfigTrueByAllLevel({
        key: KConfigApp.showClassFieldOnCell,
        extra,
        row,
        more: _more,
      })
    ) {
      _className += ` col-${extra.fieldName}`
    } else if (_more.classNameWithColField != null) {
      if (_more.classNameWithColField === true) {
        _className += ` col-${extra.fieldName}`
      } else {
        _className += ` ${_more.classNameWithColField}-${extra.fieldName}`
      }
    }
  }
  return _className
}

export const FlowGetCanEdit = ({ extra, row, more, fieldName } = {}) => {
  let _canEdit = true
  let _more = FlowGetMore({ extra, more, fieldName })
  //check row locked
  if (row && row.IsLocked === true) {
    return false
  }
  //check extraColumn.CanEdit
  let _extraCol = extra
  if (_extraCol) {
    if (fieldName && extra.fieldName != fieldName) {
      _extraCol = FlowTableExtraData.FlowGetColumnFromExtraData(
        FlowGetExtraDataFromExtra(extra),
        fieldName
      )
      if (_extraCol && _extraCol.CanEdit != null) {
        _canEdit = _extraCol.CanEdit
      }
    } else if (_extraCol.CanEdit != null) {
      _canEdit = _extraCol.CanEdit
    }

    //
    if (_canEdit === true && _more && row) {
      //check fCanEdit
      if (_more.fCanEdit == null && _more.canEditF != null) {
        _more.fCanEdit = _more.canEditF
      }
      if (typeof _more.fCanEdit === "boolean") {
        _canEdit = _more.fCanEdit
      } else if (typeof _more.fCanEdit === "string") {
        if (_more.fCanEdit.length > 0) {
          if (row[_more.fCanEdit] != null) {
            _canEdit = row[_more.fCanEdit]
          } else if (_more.fCanEdit.indexOf(",") > -1) {
            let _arrcanEditF = _more.fCanEdit.split(",")
            if (_arrcanEditF && _arrcanEditF.length > 0) {
              for (let f of _arrcanEditF) {
                if (row[f] == false) {
                  //only need one cannot edit -> cannot edit
                  _canEdit = false
                  break
                }
              }
            }
          }
        }
      }

      //check fLock
      if (_more.lockF != null || _more.fLock != null) {
        let _fLock = _more.lockF || _more.fLock
        if (typeof _fLock == "boolean") {
          _canEdit = !_fLock
        } else if (typeof _fLock == "string") {
          if (_fLock.length > 0) {
            if (row[_fLock] != null) {
              _canEdit = !row[_fLock]
            } else if (_fLock.indexOf(",") > -1) {
              let _arrLock = _fLock.split(",")
              if (_arrLock && _arrLock.length > 0) {
                for (let f of _arrLock) {
                  if (row[f] == true) {
                    //only need one lock edit -> cannot edit
                    _canEdit = false
                    break
                  }
                }
              }
            }
          }
        }
      }
      //
    }
  }
  // console.warn("canedit:",_canEdit,_extraCol.fieldName,fieldName)
  return _canEdit
}

export const FlowGetConfigOfMore = ({
  extra,
  more,
  row,
  fieldName,
  key,
  defaultValue,
  fKey,
} = {}) => {
  let _value = defaultValue
  let _more = FlowGetMore({ extra, more, fieldName })
  if (fKey && _more.hasOwnProperty(fKey) && row[_more.hasOwnProperty(fKey)]) {
    _value = row[_more.hasOwnProperty(fKey)]
  } else if (_more.hasOwnProperty(key)) {
    _value = _more[key]
  }
  return _value
}

export const FlowGetConfigModal = ({
  extra,
  more,
  row,
  fieldName,
  defaultValue,
} = {}) => {
  let _configModal = FlowGetConfigOfMore({
    extra,
    more,
    row,
    fieldName,
    key: KMore.modal,
    defaultValue: defaultValue || {},
  })
  if (
    _configModal[KConfigModal.fTitle] &&
    row[_configModal[KConfigModal.fTitle]]
  ) {
    _configModal.title = row[_configModal[KConfigModal.fTitle]]
    delete _configModal[KConfigModal.fTitle] //xoa fTitle trong _configModal
  }
  return _configModal
}

export const FlowClickToSelectInput = ({ extra, fieldName, more, ev } = {}) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  if (ev && ev.target && _more && _more[KMore.clickToSelect] === true) {
    ev.target.select()
  }
}

export const FlowGetDateFormat = ({
  extra,
  fieldName,
  more,
  defaultFormat,
} = {}) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  let _format =
    _more[KMore.dateFormat] ||
    _more[KMore.format] ||
    defaultFormat ||
    HCF.getCF(KConfigApp.dateFormat)
  return _format
}

export const FlowGetDateTimeFormat = ({
  extra,
  fieldName,
  more,
  defaultFormat,
} = {}) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  let _format =
    _more[KMore.dateFormat] ||
    _more[KMore.format] ||
    defaultFormat ||
    HCF.getCF(KConfigApp.datetimeFormat)
  return _format
}

export const FlowGetColor = ({
  extra,
  more,
  row,
  fieldName,
  defaultColor,
} = {}) => {
  let _color = defaultColor || "gray"
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.fColor] && row && row[_more[KMore.fColor]]) {
    _color = row[_more[KMore.fColor]]
  } else if (_more[KMore.color]) {
    _color = _more[KMore.color]
  }
  return _color
}

export const FlowGetFnList = ({ extra, fieldName } = {}) => {
  let _extraCol = FlowGetExtraOfFieldName(extra, fieldName)
  if (_extraCol.fnList) {
    return _extraCol.fnList
  } else if (extra.fnList) {
    return extra.fnList
  }
  return {}
}

export const FlowGetIsUpdatingCell = ({ extra, row, fieldName } = {}) => {
  let _fieldName = fieldName || FlowGetFieldNameFromExtra(extra)
  if (
    row &&
    row[KConfigInRow._UI_Loading] &&
    row[KConfigInRow._UI_Loading][_fieldName] === true
  ) {
    return true
  }
  return false
}

export const FlowAfterUpdateCell = ({ row, response, fieldName, fnList }) => {
  //show msg
  fnListUI.fnShowToast(response.Msg)

  //update new Data to row
  if (response && response.Data && response.Data.Id == row.Id) {
    HUtils.mergeObjIfExist(row, response.Data)
  }
  //remove row UI loading, error
  if (row[KConfigInRow._UI_Loading] != null) {
    delete row[KConfigInRow._UI_Loading][fieldName]
  }
  if (row[KConfigInRow._UI_Error] != null) {
    delete row[KConfigInRow._UI_Error][fieldName]
  }

  let _arrFieldNeedReload = []
  let _fieldNeedReload = fnListMyPage.fnGetConfigPageTable(fnList, [
    KConfigPageTable.needReloadAfterUpdateFields,
  ])
  if (typeof _fieldNeedReload == "string") {
    _arrFieldNeedReload.push(_fieldNeedReload)
  } else if (Array.isArray(_fieldNeedReload)) {
    _arrFieldNeedReload = [..._fieldNeedReload]
  }
  if (_arrFieldNeedReload.indexOf(fieldName) > -1) {
    console.warn("RELOAD after update field", fnList)
    fnListMyPage.fnReloadList(fnList, [])
  }
}

export const FlowUpdateCell = ({
  extra,
  more,
  row,
  fieldName,
  newValue,
  alwaysUpdate,
  fnForceUpdateCell,
  fnSuccessCallBack,
} = {}) => {
  let _fieldName = fieldName || FlowGetFieldNameFromExtra(extra)
  let _valueCell = row[_fieldName]
  let _fnList = FlowGetFnList({ extra, fieldName })
  // let _extraCol = FlowGetExtraOfFieldName(extra,fieldName)
  //lay fnRequestUpdate
  // let _fnRequestUpdate = _fnList.fnRequestUpdate;
  // if(_extraCol.fnRequestUpdate){
  //   _fnRequestUpdate = _extraCol.fnRequestUpdate;
  // }
  // console.warn("FlowUpdateCell:",_valueCell,"[new]:",newValue,_fnRequestUpdate)

  //init UILoading,Error o row
  if (row[KConfigInRow._UI_Loading] == null) {
    row[KConfigInRow._UI_Loading] = {}
  }
  if (row[KConfigInRow._UI_Error] == null) {
    row[KConfigInRow._UI_Error] = {}
  }

  let _canUpdate = false
  //kiem tra co update dc ko
  if (newValue != _valueCell && !(_valueCell == null && newValue == "")) {
    _canUpdate = true
  } else if (newValue == _valueCell) {
    if (
      row[KConfigInRow._UI_Error] &&
      row[KConfigInRow._UI_Error][_fieldName] != null
    ) {
      _canUpdate = true
    } else if (alwaysUpdate == true) {
      _canUpdate = true
    }
    console.warn("New Update = current value cell:", newValue, _valueCell)
  }
  //tien hanh update
  if (_canUpdate) {
    console.warn("Need Update:", newValue, _fnList)
    //gan gia tri updating vao row
    if (row[KConfigInRow._UI_Loading] != null) {
      row[KConfigInRow._UI_Loading][_fieldName] = true
    }

    //loading update
    if (fnForceUpdateCell) {
      fnForceUpdateCell()
    }

    //Bo sung goi request update neu truyen tu ben ngoai vao, mot so man hinh cu van su dung, de update detail
    if (extra.fnRequestUpdate) {
      extra.fnRequestUpdate(row, _fieldName, newValue, {
        fnUpdateUILoading: (isLoading, opts) => {
          if (row[KConfigInRow._UI_Loading] == null) {
            row[KConfigInRow._UI_Loading] = {}
          }
          if (row[KConfigInRow._UI_Loading] != null) {
            row[KConfigInRow._UI_Loading][_fieldName] = isLoading
          }
          if (opts && opts.component && opts.component.forceUpdate) {
            opts.component.forceUpdate()
          }
          if (fnForceUpdateCell) {
            fnForceUpdateCell()
          }
        },
        fnUpdateUIError: (msg, opts) => {
          if (row[KConfigInRow._UI_Error] == null) {
            row[KConfigInRow._UI_Error] = {}
          }
          if (msg == null) {
            //msg == null nghia la xoa error di
            delete row[KConfigInRow._UI_Error][_fieldName]
          } else {
            row[KConfigInRow._UI_Error][_fieldName] = {
              msg: msg,
            }
          }
          // console.warn("fnUpdateUIError",msg,fieldName,row,opts,component);
          if (opts && opts.component && opts.component.forceUpdate) {
            opts.component.forceUpdate()
          }
          if (fnForceUpdateCell) {
            fnForceUpdateCell()
          }
        },
        fnSuccessCallBack: (response, opts) => {
          if (fnSuccessCallBack) {
            fnSuccessCallBack(response, opts)
          }
        },
      })
    } else {
      //goi ham request update
      FlowApi.FlowApiUpdateFields({
        row,
        apiController: fnListMyPage.fnGetApiPath(_fnList) || "NoApiPath",
        sharedRequestData: fnListMyPage.fnGetSharedRequestData(_fnList),
        multiObjUpdate: {
          [_fieldName]: newValue,
        },
        cbSuccess: response => {
          console.warn("success:", response)
          FlowAfterUpdateCell({
            row,
            response,
            fieldName: _fieldName,
            fnList: _fnList,
          })

          //force update cell
          if (fnForceUpdateCell) {
            fnForceUpdateCell()
          }
          //forece update table de update row khac neu co su thay doi
          fnListMyPage.fnForceUpdateTable(_fnList)
        },
        cbError: (error, response) => {
          console.warn("error:", error, response)
          if (fnForceUpdateCell) {
            fnForceUpdateCell()
          }
          //remove row UI loading, update error
          if (row[KConfigInRow._UI_Loading] != null) {
            delete row[KConfigInRow._UI_Loading][_fieldName]
          }
          if (row[KConfigInRow._UI_Error] != null) {
            row[KConfigInRow._UI_Error][_fieldName] = error || response.Msg
          }
          //force update cell
          if (fnForceUpdateCell) {
            fnForceUpdateCell()
          }
        },
      })
    }
  }
}

export const FlowGetQueryFromConfigQuery = ({ row, configQuery } = {}) => {
  let _newQuery = {}
  if (configQuery && row) {
    let _keys = Object.keys(configQuery)
    if (_keys && _keys.length > 0) {
      for (let k of _keys) {
        if (
          HIs.isString(configQuery[k]) &&
          row.hasOwnProperty(configQuery[k])
        ) {
          _newQuery[k] = row[configQuery[k]]
        } else {
          _newQuery[k] = configQuery[k]
        }
      }
    }
  }
  return _newQuery
}

export const FlowRenderLoading = ({ extra, row, fieldName, uiLoading }) => {
  if (FlowGetIsUpdatingCell({ extra, row, fieldName }) === true) {
    if (uiLoading) {
      return uiLoading
    }
    return <MyUI type={KTypeCellShared._cell_loading} />
  }
}

export const FlowRenderOverlay = ({ extra, row, fieldName, uiLoading }) => {
  //render loading, error, addable, infomation, clear
}

export const FlowCheckUpdateValueAfterWillReceiveProps = (
  props,
  nextProps,
  valueDefault = ""
) => {
  const { cell, row, extra } = props
  const newCell = nextProps.cell
  if (row && extra && row._UI_NeedUpdate) {
    let _fieldName = extra.fieldName
    if (_fieldName && row._UI_NeedUpdate.hasOwnProperty(_fieldName)) {
      let _value = row._UI_NeedUpdate[_fieldName]
      delete row._UI_NeedUpdate[_fieldName]
      return {
        needUpdate: true,
        value: _value,
      }
    }
  }
  if (newCell != null && cell != null && newCell != cell) {
    return {
      needUpdate: true,
      value: newCell,
    }
  } else if (cell == null && newCell != cell) {
    return {
      needUpdate: true,
      value: newCell,
    }
  } else if (newCell == null && newCell != cell) {
    return {
      needUpdate: true,
      value: valueDefault,
    }
  }
  return {
    needUpdate: false,
    newCell: newCell,
  }
}
