import classNames from "classnames"
import { Button } from "reactstrap"
import { MyModal, MyUI } from "src/core/components"
import { isNotEmpty } from "src/core/helpers/utils"
import { historyFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"
import { UITypes } from "./UITypes"
import { HLink, HUtils } from "@macashipo/mlib"

const VehicleInfo = props => {
  const { data, fnList, buttonCode, moreConfig } = props
  const name = data?.[historyFields.fName]
  const plate = data?.[historyFields.fPlateNumber]
  const img = data?.[historyFields.fAvatarUrl]
  const orderNumber = data?.[historyFields.fOrderNumber]
  // const buttonCode = fnList?.getActionButtonCode() || {}
  /** Get */
  const getCanShowByBtnCancel = () => {
    if (fnList?.getCanShowByBtnList) {
      return fnList?.getCanShowByBtnList(buttonCode?.Cancel)
    }
    return false
  }
  const getPartsConfig = () => {
    return [
      {
        title: "Chính sách hủy chuyến",
        fieldData: historyFields.fCancelBookingPolicyInfo,
        type: "popup_html",
        classWrap: "devitem-line",
      },
      {
        type: "part_with_icon",
        canShow: false,
        classWrap: "devitem-line",
        title: "Thay đổi đơn đặt xe",
        dataField: "",
        icon: "",
      },
      {
        type: "cancel_order",
        canShow: getCanShowByBtnCancel(),
        classWrap: "devitem-line",
        title: "Hủy đơn đặt xe",
        dataField: "",
        icon: "flaticon-block",
      },
      {
        type: "part_with_icon",
        canShow: true,
        classWrap: "devitem-line",
        title: "Mẫu hợp đồng thuê xe",
        dataField: "",
        icon: "flaticon-list",
        fnOnClick: onOpenFile,
      },
    ]
  }
  const onOpenFile = () => {
    const file = HUtils.get(moreConfig, "rentalContractLink") || ""
    if (file) {
      HLink.openUrl(file, "_blank")
    }
  }
  const renderPart = () => {
    const parts = getPartsConfig()
    return (
      <>
        {parts &&
          parts?.length > 0 &&
          parts.map((v, i) => {
            if (!isNotEmpty(v?.canShow) || v?.canShow) {
              return (
                <div
                  className={classNames(
                    "mb-15px",
                    v?.classWrap ? v?.classWrap : ""
                  )}
                  key={`part-${i}`}
                >
                  {UITypes?.[v.type]
                    ? UITypes[v.type](data, v, {
                        fnList: {
                          ...fnList,
                        },
                        buttonCode: buttonCode,
                      })
                    : null}
                </div>
              )
            }
            return <></>
          })}
      </>
    )
  }
  const renderCharacteristics = () => {
    const list = data?.[historyFields.fCharacteristics]
    if (list && list?.length > 0) {
      return (
        <DotList className="dot-wrap">
          {list.map((v, i) => {
            return (
              <div key={i} className={classNames("dot_list")}>
                <div
                  className="d-flex align-items-center"
                  // style={{ color: "#000" }}
                >
                  <div className="dot-icon" />
                  {v?.Text}
                </div>
              </div>
            )
          })}
        </DotList>
      )
    }
    return <></>
  }
  return (
    <Wrap className="vehicle-info">
      <div className="subtitle">Thông tin đặt xe</div>
      <div className="mt-15px d-flex wrap-car-info">
        <div className="name-plate">
          <div className="label mb-10px">{name}</div>
          <div className="custom-text">{plate}</div>
          {renderCharacteristics()}
        </div>
        <img src={img} alt="" />
      </div>
      <div className="devitem-line">
        <div className="label mb-10px fw-600">Mã xác nhận</div>
        <div className="custom-text">{orderNumber}</div>
      </div>
      {/* <div className="devitem-line">
        <div className="label mb-10px">Chính sách hủy chuyến</div>
      </div> */}
      {renderPart()}
      <div className="devitem-line final"></div>
    </Wrap>
  )
}
const Wrap = styled.div`
  & .label {
    color: var(--mauden);
  }
  & .custom-text {
    color: #353434;
  }
  & .wrap-car-info {
    justify-content: space-between;
    img {
      width: 100px;
      max-height: 100px;
      object-fit: cover;
      border-radius: 10px;
      box-shadow: 0px 4px 15px 0px #0000001a;
    }
    & .name-plate {
      width: calc(100% - 100px);
      padding-right: 15px;
      overflow: hidden;
    }
  }
  & .devitem-line {
    &.final {
      &:before {
        margin-bottom: 0;
      }
    }
  }
`
const DotList = styled.div`
  &.dot-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-top: 10px;
    margin-left: -18px;
  }
  & .dot_list {
    width: unset;
    max-width: unset;
    padding-left: 0;
    padding-right: 16px;
    & .dot-icon {
      height: 5px;
      width: 5px;
      margin-right: 16px;
      border-radius: 5px;
      background: #000;
    }
  }
`
export default VehicleInfo
