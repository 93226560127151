import MyUIOne from "./MyUI"

interface IModuleCorePage extends IModuleOne {
  runAfterInit: () => void
}
const ModuleCorePage: IModuleCorePage = {
  runAfterInit: () => {
    MyUIOne.init()
  },
}

export default ModuleCorePage
