import React from "react"
import { MyTableCellHelpers, CSuppense } from "@macashipo/mlib"

const V1_mtc_readonly = React.lazy(() => import("./Types/v1_mtc_readonly"))
const V1_mtc_text = React.lazy(() => import("./Types/v1_mtc_text"))
const V1_mtc_default_actions = React.lazy(
  () => import("./Types/v1_mtc_default_actions")
)
const V1_mtc_date = React.lazy(() => import("./Types/v1_mtc_date"))
const V1_mtc_files = React.lazy(() => import("./Types/v1_mtc_files"))
// const V1_mtc_jsonview = React.lazy(() => import("./Types/v1_mtc_jsonview"))
const V1_mtc_select = React.lazy(() => import("./Types/v1_mtc_select"))
const V1_mtc_api = React.lazy(() => import("./Types/v1_mtc_api"))
const V1_mtc_form = React.lazy(() => import("./Types/v1_mtc_form"))
const V1_mtc_with_myui = React.lazy(() => import("./Types/v1_mtc_with_myui"))
const V1_mtc_action = React.lazy(() => import("./Types/v1_mtc_action"))
const V1_mtc_html = React.lazy(() => import("./Types/v1_mtc_html"))

const List = {
  v1_mtc_readonly: V1_mtc_readonly,
  v1_mtc_text: V1_mtc_text,
  v1_mtc_default_actions: V1_mtc_default_actions,
  v1_mtc_date: V1_mtc_date,
  v1_mtc_files: V1_mtc_files,
  // v1_mtc_jsonview: V1_mtc_jsonview,
  v1_mtc_select: V1_mtc_select,
  v1_mtc_api: V1_mtc_api,
  v1_mtc_form: V1_mtc_form,
  v1_mtc_with_myui: V1_mtc_with_myui,
  v1_mtc_action: V1_mtc_action,
  v1_mtc_html: V1_mtc_html,
}
const ListCustom = {
  v1_mtc_readonly_date: {
    component: V1_mtc_date,
    moreProps: { readonly: true },
  },
  v1_mtc_readonly_html: {
    component: V1_mtc_html,
    moreProps: { readonly: true },
  },
}
const ListMapV1 = {
  v1_mtc_api: "api",
  v1_mtc_form: "form",
  v1_mtc_action: "action",
  v1_mtc_html: "html",
  v1_mtc_readonly_html: "readonly_html",
}

const MyTableCellOne = {
  init() {
    // MyTableCellHelpers.Types["_empty"] = props => {
    //   return CSuppense(props, V1_mtc_readonly)
    // }

    for (let k of Object.keys(List)) {
      MyTableCellHelpers.addTypes(k, props => {
        return CSuppense(props, List[k])
      })
    }
    for (let k of Object.keys(ListCustom)) {
      MyTableCellHelpers.addTypes(k, props => {
        return CSuppense(
          { ...props, ...ListCustom[k].moreProps },
          ListCustom[k].component
        )
      })
    }

    //map type with solidv1
    MyTableCellOne.mapWithV1()
  },
  mapWithV1() {
    MyTableCellHelpers.Types["text"] = MyTableCellHelpers.Types["v1_mtc_text"]
    MyTableCellHelpers.Types["textarea"] =
      MyTableCellHelpers.Types["v1_mtc_text"]
    MyTableCellHelpers.Types["files"] = MyTableCellHelpers.Types["v1_mtc_files"]
    MyTableCellHelpers.Types["date"] = MyTableCellHelpers.Types["v1_mtc_date"]
    MyTableCellHelpers.Types["datetime"] =
      MyTableCellHelpers.Types["v1_mtc_date"]
    MyTableCellHelpers.Types["select"] =
      MyTableCellHelpers.Types["v1_mtc_select"]
    MyTableCellHelpers.Types["json_editor"] =
      MyTableCellHelpers.Types["v1_mtc_jsonview"]
    for (let k of Object.keys(ListMapV1)) {
      MyTableCellHelpers.Types[ListMapV1[k]] = MyTableCellHelpers.Types[k]
    }
  },
}

export default MyTableCellOne
