export const SGPaths = {
  home: "/",
  searchxe: "/searchxe",
  chitietxe: "/:vehicle/:code",
  chuyendicuatoi: "/tai-khoan/chuyendi",
  lichsuchuyendi: "/tai-khoan/chuyendi/",
  cauhoithuonggap: "/cau-hoi-thuong-gap",
  huongdandangkyxe: "/huong-dan-dang-ky-xe",
  huongdandatxe: "/huong-dan-dat-xe",
  xecuatoi: "/tai-khoan/doixe",
  themxe: "/tai-khoan/add",
  chinhsuaxe: "/tai-khoan/edit/:id",
  taikhoancuatoi: "/tai-khoan/info",
  xacthuctaikhoan: "/tai-khoan/xac-thuc",
  thongtintaikhoan: "/thong-tin/:id",
  tichluy: "/tai-khoan/sigo-xu",
  slug: "/:slug",
  thongbao: "/tai-khoan/thong-bao",
  thanhtoan: "/tai-khoan/thanh-toan",
  dev_test: "/dev_test",
  donhanghientai: "/tai-khoan/don-hang-hien-tai",
  lichhoatdong: "/tai-khoan/lich-hoat-dong",
  danhmucxe: "/tai-khoan/danh-muc-xe",
  taikhoan: "/account",
}
export const SGNewPaths = {
  searchxe: "/search",
  chuyendicuatoi: "/account/my-trips",
  lichsuchuyendi: "/account/my-trips",
  xecuatoi: "/account/my-cars",
  themxe: "/account/my-cars/add",
  chinhsuaxe: "/account/my-cars/edit/:id",
  taikhoancuatoi: "/account/personal-info",
  xacthuctaikhoan: "/account/verify",
  thongtintaikhoan: "/profile/:id",
  tichluy: "/account/savings",
  thongbao: "/account/notifications",
  thanhtoan: "/account/payments",
  donhanghientai: "/account/my-orders",
  lichhoatdong: "/account/activity-schedule",
  danhmucxe: "/account/my-cars",
}
