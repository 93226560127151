import React from "react"
import { ImSpinner8 } from "react-icons/im"
import styled, { keyframes } from "styled-components"

const WrapLoading = styled.div``

export default function MyLoadingPage() {
  return (
    <WrapLoading className="wploading">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </WrapLoading>
  )
}
