import classNames from "classnames"
import classnames from "classnames"
import React, { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import OwlCarousel from "react-owl-carousel2"
import { MyModal, MyUI } from "src/core/components"
import { detailFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const MyUICarImageSlide = props => {
  const {
    allData,
    className,
    config,
    configFromFirstSetting,
    loading,
    mobileWidth,
  } = props
  const [isMobileView, setIsMobileView] = useState(false)
  const [itemCount, setItemCount] = useState(4)
  const { data } = allData || {}
  const { imageSlides, shareSettings, fancyboxSettings } =
    configFromFirstSetting || {}

  useEffect(() => {
    const ro = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        const { left, top, width, height } = entry.contentRect
        if (entry.target == document.body) {
          if (width <= mobileWidth) {
            if (isMobileView === false) {
              setIsMobileView(true)
              setItemCount(2)
            }
          } else {
            setIsMobileView(false)
          }
          if (width <= 767) {
            setItemCount(1)
          }
        }
      }
    })
    ro.observe(document.body)
    // eslint-disable-next-line
  }, [])

  /** Get */
  const getNewArray = item => {
    const arr = Array.from({ length: item }, (_, index) => index + 1)
    return arr
  }
  const getCanShowBtnPreviewImages = () => {
    if (config?.showBtnPreview && data?.length >= itemCount) {
      return true
    }
    return false
  }
  /** */
  const onHanleShare = () => {
    MyModal.showFromMyUI(
      {
        type: "sg_share",
        data: allData,
        shareSettings: shareSettings,
      },
      {
        size: "top",
        showHeader: false,
        showHeaderClose: false,
        modalClassName: "modal-share",
      }
    )
  }
  /** Render */
  const renderLoading = () => {
    let emptyImgs: any = getNewArray(3)
    if (isMobileView) {
      emptyImgs = getNewArray(1)
    }
    return (
      <WrapLoading className="" mobileWidth={mobileWidth}>
        <div className="first-image">
          <Skeleton />
        </div>
        <div className="others-image">
          {emptyImgs &&
            emptyImgs?.length > 0 &&
            emptyImgs.map((v, i) => {
              return (
                <div className="one-other-image" key={`empty-${i}`}>
                  <Skeleton />
                </div>
              )
            })}
        </div>
      </WrapLoading>
    )
  }
  const renderImage = (fancyboxName?: any) => {
    let otherImgs: any = []
    let firstImg = ""
    let emptyImgs: any = []
    let hiddenImgs: any = []
    let item = 3
    if (data) {
      if (data?.length > 0) {
        firstImg = data[0]
      }
      if (data?.length > 1 && item) {
        otherImgs = data?.slice(1, item + 1)
      }
      if (data?.length > 4) {
        hiddenImgs = data?.slice(4)
      }
      if (otherImgs && otherImgs?.length < item) {
        emptyImgs = getNewArray(config?.item - otherImgs?.length)
      }
    }
    return (
      <WrapImage className="only-desktop" mobileWidth={mobileWidth}>
        <div className="first-image">
          <a href={firstImg} data-fancybox={fancyboxName}>
            <img className="lazy" src={firstImg} alt="car_image" />
          </a>
        </div>
        <div className="others-image">
          {otherImgs &&
            otherImgs?.length > 0 &&
            otherImgs?.map((v, i) => {
              return (
                <a
                  className={classNames(
                    "one-other-image",
                    isMobileView && i > 0 && "hidden"
                  )}
                  key={i}
                  href={v}
                  data-fancybox={fancyboxName}
                >
                  <img className="lazy" src={v} alt="car_mage" />
                </a>
              )
            })}
          {imageSlides?.showEmptyImg &&
            emptyImgs &&
            emptyImgs?.length > 0 &&
            emptyImgs.map((v, i) => {
              return (
                <div className="one-other-image empty" key={`empty-${i}`}></div>
              )
            })}
          {hiddenImgs &&
            hiddenImgs?.length > 0 &&
            hiddenImgs.map((v, i) => {
              return (
                <a
                  className="one-other-image hidden"
                  key={i}
                  href={v}
                  data-fancybox={fancyboxName}
                >
                  <img className="lazy" src={v} alt="car_mage" />
                </a>
              )
            })}
        </div>
      </WrapImage>
    )
  }
  if (data?.length > 0) {
    const fancyboxName = "images"
    const canShowPreview = getCanShowBtnPreviewImages()
    const { infinite = false } = fancyboxSettings || {}
    return (
      <Wrap className="container">
        <MyUI
          type="fancybox"
          data={data}
          options={{
            Carousel: {
              infinite: infinite,
            },
            Thumbs: { type: "classic", showOnStart: false },
            Toolbar: {
              items: {
                share: {
                  tpl: `<button class="f-button" title="Share"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512.001" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M361.824 344.395c-24.531 0-46.633 10.593-61.972 27.445l-137.973-85.453A83.321 83.321 0 0 0 167.605 256a83.29 83.29 0 0 0-5.726-30.387l137.973-85.457c15.34 16.852 37.441 27.45 61.972 27.45 46.211 0 83.805-37.594 83.805-83.805C445.629 37.59 408.035 0 361.824 0c-46.21 0-83.804 37.594-83.804 83.805a83.403 83.403 0 0 0 5.726 30.386l-137.969 85.454c-15.34-16.852-37.441-27.45-61.972-27.45C37.594 172.195 0 209.793 0 256c0 46.21 37.594 83.805 83.805 83.805 24.53 0 46.633-10.594 61.972-27.45l137.97 85.454a83.408 83.408 0 0 0-5.727 30.39c0 46.207 37.593 83.801 83.804 83.801s83.805-37.594 83.805-83.8c0-46.212-37.594-83.805-83.805-83.805zm-53.246-260.59c0-29.36 23.887-53.246 53.246-53.246s53.246 23.886 53.246 53.246c0 29.36-23.886 53.246-53.246 53.246s-53.246-23.887-53.246-53.246zM83.805 309.246c-29.364 0-53.25-23.887-53.25-53.246s23.886-53.246 53.25-53.246c29.36 0 53.242 23.887 53.242 53.246s-23.883 53.246-53.242 53.246zm224.773 118.95c0-29.36 23.887-53.247 53.246-53.247s53.246 23.887 53.246 53.246c0 29.36-23.886 53.246-53.246 53.246s-53.246-23.886-53.246-53.246zm0 0" fill="#dbdbdb" opacity="1" data-original="#000000" class=""></path></g></svg></button>`,
                  click: e => {
                    onHanleShare()
                  },
                },
              },
              display: {
                left: [],
                middle: [],
                right: ["slideshow", "fullscreen", "thumbs", "share", "close"],
              },
            },
          }}
        >
          {({ show }) => {
            return (
              <>
                {renderImage(fancyboxName)}
                {canShowPreview && (
                  <button
                    className={classnames("btn", config?.classNameBtnPreview)}
                    onClick={e => {
                      show && show()
                    }}
                  >
                    <i className="flaticon-option" />
                    {imageSlides?.textBtnPreview}
                  </button>
                )}
              </>
            )
          }}
        </MyUI>
      </Wrap>
    )
  } else if (loading) {
    return <Wrap className="container">{renderLoading()}</Wrap>
  }
  return <></>
}
const WrapLoading: any = styled.div`
  display: flex;
  & .first-image {
    width: calc(66.6667% - 8px);
    margin-right: 8px;
    height: 570px;
    & > span {
      display: block;
      height: 100%;
      & > span {
        height: 100%;
      }
    }
  }
  & .others-image {
    width: 33.3333%;
    & .one-other-image {
      margin-bottom: 8px;
      display: block;
      height: 185px;
      & > span {
        display: block;
        height: 100%;
        & > span {
          height: 100%;
        }
      }
    }
  }
  @media (max-width: ${(props: any) => props.mobileWidth}px) {
    & .first-image {
      width: calc(50% - 4px);
      margin-right: 8px;
      height: 300px;
    }
    & .others-image {
      width: calc(50% - 4px);
      & .one-other-image {
        height: 300px;
      }
    }
  }
  @media (max-width: 767px) {
    & .first-image {
      width: 100%;
      margin-right: 0;
      height: 300px;
    }
    & .others-image {
      display: none;
    }
  }
`
const WrapImage: any = styled.div`
  display: flex;
  & .first-image {
    width: calc(66.6667% - 8px);
    margin-right: 8px;
    img {
      height: 570px;
      width: 100%;
      object-fit: cover;
      border-radius: 15px 0 0 15px;
    }
  }
  & .others-image {
    width: 33.3333%;
    img {
      max-height: 185px;
      width: 100%;
      object-fit: cover;
      border-radius: 0 15px 15px 0;
    }
    & .one-other-image {
      margin-bottom: 8px;
      display: block;
      &.empty {
        background-color: #e3e1e1;
        width: 100%;
        height: 185px;
        border-radius: 0 15px 15px 0;
      }
      &.hidden {
        display: none;
      }
    }
  }
  @media (min-width: 992px) {
    // & .first-image {
    //   height: 570px;
    //   background-color: #ededed;
    //   border-radius: 15px 0 0 15px;
    //   img {
    //     object-fit: contain;
    //   }
    // }
  }
  @media (max-width: 992px) {
    & .first-image {
      width: calc(50% - 4px);
      margin-right: 8px;
      img {
        max-height: 300px;
        width: 100%;
      }
    }
    & .others-image {
      width: calc(50% - 4px);
      img {
        max-height: 300px;
        height: 300px;
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    & .first-image {
      width: 100%;
      margin-right: 0;
      img {
        max-height: 300px;
        width: 100%;
        border-radius: 15px;
      }
    }
    & .others-image {
      display: none;
    }
  }
`
const Wrap = styled.div`
  position: relative;
  .owl-carousel,
  .owl-carousel .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative;
  }

  .owl-carousel {
    display: none;
    width: 100%;
    z-index: 1;
  }

  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;
  }

  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .owl-carousel .owl-item,
  .owl-carousel .owl-wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }

  .owl-carousel .owl-item {
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
  }

  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .owl-carousel .owl-dots.disabled,
  .owl-carousel .owl-nav.disabled {
    display: none;
  }

  .no-js .owl-carousel,
  .owl-carousel.owl-loaded {
    display: block;
  }

  .owl-carousel .owl-dot,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-carousel.owl-loading {
    opacity: 0;
    display: block;
  }

  .owl-carousel.owl-hidden {
    opacity: 0;
  }

  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden;
  }

  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
  }

  .owl-carousel.owl-rtl {
    direction: rtl;
  }

  .owl-carousel.owl-rtl .owl-item {
    float: right;
  }

  .owl-carousel .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  .owl-carousel .owl-animated-in {
    z-index: 0;
  }

  .owl-carousel .owl-animated-out {
    z-index: 1;
  }

  .owl-carousel .fadeOut {
    animation-name: fadeOut;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .owl-height {
    transition: height 0.5s ease-in-out;
  }

  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d;
  }

  .owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }

  .owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url(owl.video.play.png) no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 0.1s ease;
  }

  .owl-carousel .owl-video-play-icon:hover {
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }

  .owl-carousel .owl-video-playing .owl-video-play-icon,
  .owl-carousel .owl-video-playing .owl-video-tn {
    display: none;
  }

  .owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 0.4s ease;
  }

  .owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
  & .show-images,
  & .show-images:hover,
  & .show-images:focus,
  & .show-images:active {
    background-color: var(--color-text-fff);
    border: 1px solid var(--color-border-dfdfdf);
    color: var(--color-text-000);
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1;
    i {
      margin-right: 5px;
      font-size: 16px;
      display: flex;
      width: 16px;
      height: 16px;
      align-items: center;
      justify-content: center;
      color: #6c6c6c;
    }
  }
  @media (max-width: 1199px) {
    .owl-carousel .owl-item img {
      height: 300px;
    }
  }
`
const WrapShare = styled.div`
  border-radius: 3px;
  & .fancybox-share_button {
    display: inline-block;
    text-decoration: none;
    margin: 0 10px 10px 0;
    padding: 10px 20px;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    background: #fff;
    white-space: nowrap;
    font-size: 16px;
    line-height: 23px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 140px;
    color: #707070;
    transition: all 0.2s;
    & svg {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      vertical-align: text-bottom;
    }
  }
  & input {
    box-sizing: border-box;
    width: 100%;
    margin: 5px 0 0;
    padding: 10px 15px;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    background: #ebebeb;
    color: #5d5b5b;
    font-size: 14px;
    outline: none;
  }
`
export default MyUICarImageSlide
