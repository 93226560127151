import { HLink, HUtils, MyIcon } from "@macashipo/mlib"
import classnames from "classnames"
import classNames from "classnames"
import { Button } from "reactstrap"
import { squareBracketReplace, isHTMLString } from "src/core/helpers/utils"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const ColumnContentFields = {
  fIcon: "Icon",
  fUrl: "url",
}
const ColumnContentCard = props => {
  const { data, configMoreInfo } = props
  const img = data?.[homeSlidesFields.fOneSlideImageUrl]
  const title = data?.[homeSlidesFields.fOneSlideTitle]
  const description = data?.[homeSlidesFields.fOneSlideDescription]
  const icon = data?.[ColumnContentFields.fIcon]
  const moreInfo = data?.[homeSlidesFields.fOneSlideMoreInfo] || {}
  const btnConfig =
    HUtils.get(configMoreInfo, "websiteSetting.configBtn4EachCard") || {}
  const itemConfig = HUtils.get(configMoreInfo, "websiteSetting.itemConfig")
  const url =
    moreInfo?.[ColumnContentFields.fUrl] ||
    HUtils.get(moreInfo, "websiteSetting.url")
  const onHandleBtn = () => {
    if (url) {
      HLink.openUrl(squareBracketReplace(url, data), "_blank")
    }
  }
  return (
    <Wrap className={itemConfig?.className} style={itemConfig?.wrapStyle}>
      {icon &&
        (isHTMLString(icon) ? (
          <div
            className="html-icon"
            dangerouslySetInnerHTML={{ __html: icon }}
          ></div>
        ) : (
          <MyIcon data={`type:${icon}`} />
        ))}
      {img && !icon && (
        <div className="image">
          <img src={img} alt="" style={itemConfig?.imgStyle} />
        </div>
      )}
      {title && (
        <div className="title" style={itemConfig?.titleStyle}>
          {title}
        </div>
      )}
      {description && (
        <div
          className={classnames("des")}
          dangerouslySetInnerHTML={{ __html: description }}
          style={itemConfig?.descriptionStyle}
        ></div>
      )}
      {url && btnConfig?.canShow && (
        <Button
          color={btnConfig?.color}
          className={classNames(btnConfig?.className)}
          onClick={onHandleBtn}
          disabled={!url ? true : false}
        >
          {btnConfig?.title}
        </Button>
      )}
    </Wrap>
  )
}
const Wrap: any = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  & svg {
    height: 113px;
  }
  &.mr-3 {
    margin-right: 1rem;
  }
  &.pr-3 {
    padding-right: 1rem;
  }
  & .title {
    color: var(--color-text-000);
    font-size: 20px;
    font-weight: 700;
    padding-top: 15px;
  }
  & .des {
    color: #656565;
    font-size: 16px;
    padding-top: 0.5rem;
  }
  & button {
    margin-top: 20px;
    padding: 7px 20px;
    font-size: 14px !important;
  }
  & .image {
    overflow: hidden;
    & img {
      object-fit: scale-down;
      height: 90px;
    }
  }
  @media (max-width: 767px) {
    &.mobile-center {
      & .image {
        display: flex;
        justify-content: center;
        width: 100%;
        & img {
          height: 150px;
        }
      }
      & .title {
        text-align: center !important;
        width: 100%;
      }
      & .description {
        text-align: center !important;
      }
    }
  }
`
export default ColumnContentCard
