import React from "react"
import { Input } from "reactstrap"
class InputRadio extends React.Component {
  render() {
    return <Input {...this.props} type="radio" />
  }
}

//value: string
//defaultValue: string
//onChange: value = string

export default InputRadio
