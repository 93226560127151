import classNames from "classnames"
import Skeleton from "react-loading-skeleton"
import styled from "styled-components"
import { MyUI, MyIcon, HUtils, HCF } from "@macashipo/mlib"
import { IoSearchOutline } from "react-icons/io5"
import imgGGPlayStore from "src/modules/app_sigo/assets/imgs/gplay.jpg"
import imgAppleStore from "src/modules/app_sigo/assets/imgs/ios.jpg"
import imgBgDownloadApp from "src/modules/app_sigo/assets/imgs/download-app-background.png"
import imgQRCode from "src/modules/app_sigo/assets/imgs/qr-download-app.png"
import { useEffect, useState } from "react"

const MyLoadingContent = props => {
  const { onlyRenderBlog = false } = props
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    const ro = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        const { left, top, width, height } = entry.contentRect
        if (entry.target == document.body) {
          setScreenWidth(width)
        }
      }
    })
    ro.observe(document.body)
    // eslint-disable-next-line
  }, [])
  const getItemList4CarSuggest = defaultConfig => {
    const responsive = defaultConfig || {}
    const keys = Object.keys(responsive)
    let config: any = {}
    if (keys?.length > 0) {
      keys.every((k, i) => {
        let configScreen = Number(k)
        let nextConfigScreen = -1
        if (i + 1 < keys?.length) {
          nextConfigScreen = Number(keys[i + 1])
        }
        if (
          screenWidth >= configScreen &&
          ((nextConfigScreen > -1 && screenWidth < nextConfigScreen) ||
            nextConfigScreen === -1)
        ) {
          config = responsive[k]
          return false
        }
        return true
      })
    }
    return config
  }
  const getItemList4Render = defaultConfig => {
    const responsive = defaultConfig || {}
    const keys = Object.keys(responsive)
    let config: any = {}
    let item = 1
    if (keys?.length > 0) {
      keys.every((k, i) => {
        let configScreen = Number(k)
        let nextConfigScreen = -1
        if (i + 1 < keys?.length) {
          nextConfigScreen = Number(keys[i + 1])
        }
        if (
          screenWidth >= configScreen &&
          ((nextConfigScreen > -1 && screenWidth < nextConfigScreen) ||
            nextConfigScreen === -1)
        ) {
          config = responsive[k]
          return false
        }
        return true
      })
      if (config?.items) {
        item = config?.items
      }
    }
    const arr = Array.from({ length: item }, (_, index) => index + 1)
    return arr
  }
  const renderLandingPage = () => {
    const defaultConfig = {}
    const itemList = getItemList4Render(defaultConfig)
    if (itemList && itemList?.length > 0) {
      return (
        <WrapLandingPage className="mb-25px row wrap-home-slides landing-page">
          <div className="custom-wrap-slide container">
            {itemList?.map((v, i) => {
              return (
                <div
                  className="home-slide wrap-home-slide"
                  key={`landing-${i}`}
                >
                  <Skeleton />
                </div>
              )
            })}
          </div>
        </WrapLandingPage>
      )
    }
    return <></>
  }
  const renderService = () => {
    // Cần thay đổi khi điều chỉnh responsive trên config home slide
    const defaultConfig = {
      "0": {
        items: 1,
        nav: true,
        loop: true,
      },
      "768": {
        items: 3,
        nav: false,
      },
    }
    const itemList = getItemList4Render(defaultConfig)
    if (itemList && itemList?.length > 0) {
      return (
        <WrapHomeService
          className="mb-25px row wrap-home-slides home_service_loading"
          items={itemList?.length}
        >
          <div className="custom-wrap-slide container">
            <div className="wrap-title-home">
              <div className="col-sm-12 title-home">
                <Skeleton />
              </div>
            </div>
            <div className="home_service ">
              {itemList?.map((v, i) => {
                return (
                  <div className="one_service_item" key={`service_item-${i}`}>
                    <div className="icon">
                      <Skeleton />
                    </div>
                    <div className="title_item">
                      <Skeleton />
                    </div>
                    <div className="description_item">
                      <Skeleton />
                    </div>
                    <div className="btn_item">
                      <Skeleton />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </WrapHomeService>
      )
    }
    return <></>
  }
  const renderCategory = () => {
    // Cần thay đổi khi điều chỉnh responsive trên config home slide
    const defaultConfig = {
      "0": {
        items: 1,
        nav: false,
      },
      "580": {
        items: 3,
        nav: true,
      },
      "1016": {
        items: 4,
        nav: true,
      },
      "1500": {
        items: 6,
        nav: true,
      },
    }
    const itemList = getItemList4Render(defaultConfig)
    if (itemList && itemList?.length > 0) {
      return (
        <WrapHomeCategory
          className="mb-25px row wrap-home-slides home_category_loading"
          items={itemList?.length}
        >
          <div className="custom-wrap-slide container">
            <div className="wrap-title-home">
              <div className="col-sm-12 title-home">
                <Skeleton />
              </div>
            </div>
            <div className="home_category">
              {itemList?.map((v, i) => {
                return (
                  <div className="one_category_item" key={`category-${i}`}>
                    <div className="image">
                      <Skeleton />
                    </div>
                    <div className="wrap-content">
                      <div className="title_item">
                        <Skeleton />
                      </div>
                      <div className="description_item">
                        <Skeleton />
                      </div>
                      <div className="sub_text">
                        <Skeleton />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </WrapHomeCategory>
      )
    }
    return <></>
  }
  const renderPromotion = () => {
    // Cần thay đổi khi điều chỉnh responsive trên config home slide
    const defaultConfig = {
      "0": {
        items: 1,
        nav: false,
      },
      "580": {
        items: 1,
        nav: false,
      },
      "1000": {
        items: 3,
        nav: false,
      },
    }
    const itemList = getItemList4Render(defaultConfig)
    if (itemList && itemList?.length > 0) {
      return (
        <WrapHomePromotion
          className="mb-25px row wrap-home-slides home_promotion_loading"
          items={itemList?.length}
        >
          <div className="custom-wrap-slide container">
            <div className="col-sm-12 wrap-title-home">
              <div className="title-home">
                <Skeleton />
              </div>
              <div className="description-title">
                <Skeleton />
              </div>
            </div>
            <div className="home_promotion">
              {itemList?.map((v, i) => {
                return (
                  <div className="one_promotion_item" key={`promotion-${i}`}>
                    <div className="image">
                      <Skeleton />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </WrapHomePromotion>
      )
    }
    return <></>
  }
  const renderCarSuggest = () => {
    // Cần thay đổi khi điều chỉnh responsive trên config home slide
    const defaultConfig = {
      "0": {
        items: 2,
        itemPerRow: 1,
      },
      "768": {
        items: 4,
        itemPerRow: 2,
      },
      "1000": {
        items: 6,
        itemPerRow: 3,
      },
      "1200": { items: 8, itemPerRow: 4 },
      "1561": { items: 12, itemPerRow: 6 },
    }
    const config = getItemList4CarSuggest(defaultConfig)
    const { items, itemPerRow } = config || {}
    if (items && items > 0) {
      const itemList = Array.from({ length: items }, (_, index) => index + 1)
      if (itemList && itemList?.length > 0) {
        return (
          <WrapHomeCar
            className="mb-25px row wrap-home-slides home_car_loading"
            itemPerRow={itemPerRow}
          >
            <div className="custom-wrap-slide container">
              <div className="col-sm-12 wrap-title-home">
                <div className="title-home">
                  <Skeleton />
                </div>
                <div className="description-title">
                  <Skeleton />
                </div>
              </div>
              <div className="home_car">
                {itemList?.map((v, i) => {
                  return (
                    <div className="one_car_item" key={`car-${i}`}>
                      <div className="image">
                        <Skeleton />
                      </div>
                      <div className="wrap-name">
                        <div className="name">
                          <Skeleton />
                        </div>
                        <div className="rate">
                          <Skeleton />
                        </div>
                      </div>
                      <div className="sub-content">
                        <Skeleton />
                      </div>
                      <div className="sub-content">
                        <Skeleton />
                      </div>
                      <div className="sub-content">
                        <Skeleton />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </WrapHomeCar>
        )
      }
    }

    return <></>
  }
  const renderHelps = () => {
    // Cần thay đổi khi điều chỉnh responsive trên config home slide
    const defaultConfig = {
      "0": {
        items: 1,
        nav: true,
      },
      "580": {
        items: 1,
        nav: true,
        margin: 5,
      },
      "820": {
        items: 2,
        nav: true,
      },
      "1561": {
        items: 3,
        nav: true,
      },
    }
    const itemList = getItemList4Render(defaultConfig)
    if (itemList && itemList?.length > 0) {
      return (
        <WrapHomeHelps
          className="mb-25px row wrap-home-slides home_helps_loading"
          items={itemList?.length}
        >
          <div className="custom-wrap-slide container">
            <div className="col-sm-12 wrap-title-home">
              <div className="title-home">
                <Skeleton />
              </div>
              {/* <div className="description-title">
                <Skeleton />
              </div> */}
            </div>
            <div className="home_helps">
              {itemList?.map((v, i) => {
                return (
                  <div className="one_helps_item" key={`help-${i}`}>
                    <div className="image">
                      <Skeleton />
                    </div>
                    <div className="title">
                      <Skeleton />
                    </div>
                    {/* <div className="description"><Skeleton /></div> */}
                    <div className="button_item">
                      <Skeleton />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </WrapHomeHelps>
      )
    }
    return <></>
  }
  const renderAdvantage = () => {
    // Cần thay đổi khi điều chỉnh responsive trên config home slide
    const defaultConfig = {
      "0": {
        items: 1,
        nav: false,
      },
      "768": {
        items: 2,
      },
      "1200": {
        items: 3,
      },
      "1561": {
        items: 4,
      },
    }
    const itemList = getItemList4Render(defaultConfig)
    if (itemList && itemList?.length > 0) {
      return (
        <WrapHomeAdvantage
          className="mb-25px row wrap-home-slides home_advantage_loading"
          items={itemList?.length}
        >
          <div className="custom-wrap-slide container">
            <div className="col-sm-12 wrap-title-home">
              <div className="title-home">
                <Skeleton />
              </div>
              <div className="description-title">
                <Skeleton />
              </div>
            </div>
            <div className="home_advantage">
              {itemList?.map((v, i) => {
                return (
                  <div className="one_advantage_item" key={`advantage-${i}`}>
                    <div className="wrap-img">
                      <div className="bg-img"></div>
                      <div className="image">
                        <Skeleton />
                      </div>
                    </div>
                    <div className="title">
                      <Skeleton />
                    </div>
                    <div className="description">
                      <Skeleton />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </WrapHomeAdvantage>
      )
    }
    return <></>
  }
  const renderBlog = () => {
    // Cần thay đổi khi điều chỉnh responsive trên config home slide
    const defaultConfig = {
      "0": {
        items: 1,
      },
      "580": {
        items: 2,
      },
      "1200": {
        items: 3,
      },
      "1500": {
        items: 4,
      },
      "1800": {
        items: 5,
      },
    }
    const itemList = getItemList4Render(defaultConfig)
    if (itemList && itemList?.length > 0) {
      return (
        <WrapHomeBlog
          className="mb-25px row wrap-home-slides home_blog_loading"
          items={itemList?.length}
        >
          <div className="custom-wrap-slide container">
            <div className="col-sm-12 wrap-title-home">
              <div className="title-home">
                <Skeleton />
              </div>
              <div className="description-title">
                <Skeleton />
              </div>
            </div>
            <div className="home_blog">
              {itemList?.map((v, i) => {
                return (
                  <div className="one_blog_item" key={`blog-${i}`}>
                    <div className="image">
                      <Skeleton />
                    </div>
                    <div className="wrap_content">
                      <div className="title">
                        <Skeleton />
                      </div>
                      <div className="description">
                        <Skeleton />
                      </div>
                      <div className="date">
                        <Skeleton />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </WrapHomeBlog>
      )
    }
    return <></>
  }
  if (!onlyRenderBlog) {
    return (
      <>
        {renderLandingPage()}
        {renderService()}
        {renderCategory()}
        {renderPromotion()}
        {renderCarSuggest()}
        {renderHelps()}
        {renderAdvantage()}
      </>
    )
  }
  return <>{renderBlog()}</>
}
export const getLoadingHome = props => {
  const { tabList, logo } = props
  const renderApp = () => {
    const dfConfigDownloadApp =
      HUtils.get(HCF.getCF("websiteSetting"), "download_app_info") || {}
    const isCustom = dfConfigDownloadApp?.custom
    const qrImg = dfConfigDownloadApp?.qr_code_img_url || imgQRCode
    const linkGgPlayStore = dfConfigDownloadApp?.android_url
    const linkAppStore = dfConfigDownloadApp?.app_store_url
    const description = dfConfigDownloadApp?.description || ""
    return (
      <WrapDownloadApp className="row mb-25px app container">
        <div className="title">
          Tải ứng dụng SIGO <br /> để tối ưu trải nghiệm của bạn
        </div>
        <div className="title mobile">
          Tải ứng dụng SIGO để tối ưu trải nghiệm của bạn
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12 wrap-content">
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          <div className="wrap-bottom-content">
            <div className="d-flex wrap-img-and-qr">
              <div className="d-flex wrap-download-img">
                {linkAppStore && (
                  <div className="app-img">
                    <a href={linkAppStore} target="_blank" rel="noreferrer">
                      <img
                        className="lazy"
                        src={imgAppleStore}
                        data-original={imgAppleStore}
                        alt="appstore"
                      />
                    </a>
                  </div>
                )}
                {linkGgPlayStore && (
                  <div className="app-img">
                    <a href={linkGgPlayStore} target="_blank" rel="noreferrer">
                      <img
                        className="lazy"
                        src={imgGGPlayStore}
                        data-original={imgGGPlayStore}
                        alt="chplay"
                      />
                    </a>
                  </div>
                )}
              </div>
              {isCustom ? (
                <img
                  className="lazy qrcode"
                  src={qrImg}
                  data-original={qrImg}
                  alt="qrcode"
                />
              ) : (
                <MyIcon
                  data="type:ic_qrdownload"
                  width={120}
                  height={120}
                  style={{ marginTop: "-3px" }}
                  className="ic-qr"
                />
              )}
            </div>
            <MyIcon data="type:ic_taxi_download" className="ic-taxi" />
          </div>
        </div>
        <img
          src={imgBgDownloadApp}
          alt="download-app-bg"
          className="img-download-app"
        />
      </WrapDownloadApp>
    )
  }
  return (
    <WrapLoadingSkeleton className="page-home-skeleton">
      <div className="navbar">
        <WrapTopHeader className="container wrap-header">
          <div className="navbar-header">
            <a className="navbar-brand" href="/">
              <img className="logo" src={logo} alt="logo" />
            </a>
          </div>
          <div className="mid-menu">
            <div className="first-menu">
              {tabList &&
                tabList?.length > 0 &&
                tabList?.map(v => {
                  return (
                    <a
                      className={classNames("menu-item")}
                      href={v?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Skeleton />
                    </a>
                  )
                })}
            </div>
          </div>
          <div className="form-search">
            <div className="mb-3 col-md-4 col-sm-12 form-group">
              <div className="mc_btn_address_v2">
                <div className="btn-input-address custom-text">
                  <div className="value merge-mode">
                    <MyIcon data="type:ic_location_pin" />
                    <span className="wrap-skeleton">
                      <Skeleton />
                    </span>
                  </div>
                </div>
                <div className="btn-input-address">
                  <div className="title">{<Skeleton />}</div>
                  <div className="value">
                    <Skeleton />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3 datefromto form-group">
              <div className="mc-input-date-from-to">
                <div className="btn-time-select custom-text">
                  <div className="divider-line custom"></div>
                  <span className="text-merge">
                    <Skeleton />
                  </span>
                </div>
                <div className="btn-time-select">
                  <div className="divider-line"></div>
                  <div className="start item">
                    <div className="title">
                      <Skeleton />
                    </div>
                    <div className="time">
                      <Skeleton />
                    </div>
                  </div>
                  <div className="divider-line"></div>
                  <div className="end item">
                    <div className="title">
                      <Skeleton />
                    </div>
                    <Skeleton />
                  </div>
                </div>
              </div>
            </div>
            <button className="btn btn-search">
              <IoSearchOutline />
            </button>
          </div>
          <RightMenu
            className="collapse navbar-collapse collapseRight right-menu"
            data-show="menumember"
            // id="collapseRightDesktop"
          >
            <ul className="nav navbar-nav navbar-right avatar-menu">
              <MyUI type="sg_avatar" />
            </ul>
          </RightMenu>
        </WrapTopHeader>
      </div>
      <WrapContent>
        <MyLoadingContent />
        {renderApp()}
        <MyLoadingContent onlyRenderBlog={true} />
      </WrapContent>
    </WrapLoadingSkeleton>
  )
}

const WrapDownloadApp: any = styled.div`
  position: relative;
  margin-top: 25px;
  & .title {
    top: 0;
    left: 0;
    font-size: 36px;
    font-weight: 700;
    color: var(--color-text-000);
    line-height: 50px;
    &:not(.mobile) {
      position: absolute;
    }
  }
  & .wrap-content {
    position: absolute;
    top: calc(50% + 25px);
    transform: translateY(-50%);
    left: 30px;
    & .description {
      margin-bottom: 30px;
    }
    & .app-img {
      img {
        width: 170px;
        object-fit: cover;
        margin-bottom: 5px;
      }
    }
    & svg.ic-taxi {
      margin-top: 20px;
    }
  }
  & .wrap-img-and-qr {
    & .qrcode {
      width: 120px;
      height: 120px;
      margin-top: -3px;
      object-fit: cover;
    }
    & .wrap-download-img {
      flex-direction: column;
      margin-right: 10px;
    }
  }
  & .mobile {
    display: none;
  }
  & .img-download-app {
    align-self: flex-start;
  }
  @media (max-width: 1199px) {
    & .title {
      left: 40px;
      font-size: 28px;
      line-height: 40px;
    }
    & .wrap-content {
      left: 60px;
      & .description {
        margin-bottom: 10px;
      }
      & svg.ic-taxi {
        margin-top: 10px;
        width: 60px;
      }
      & .app-img {
        img {
          width: 150px;
          object-fit: cover;
        }
      }
    }
    & .wrap-img-and-qr {
      & .qrcode {
        width: 113px;
        height: 113px;
      }

      & > svg.ic-qr {
        width: 110px;
        height: 110px;
      }
    }
    &.container {
      width: 100%;
      max-width: 100%;
      padding: 0 !important;
    }
  }
  @media (max-width: 1160px) {
    & .wrap-content {
      top: calc(50% + 35px);
      & svg.ic-taxi {
        width: 80px;
        margin-top: -13px;
      }
    }
    & .wrap-bottom-content {
      // display: flex;
    }
    &.container {
      width: 100%;
      max-width: 100%;
      padding: 0 !important;
    }
  }
  @media (max-width: 1023px) {
    & .title {
      display: none;
      margin-bottom: 1rem;
    }
    & .wrap-content {
      position: unset;
      width: 100%;
      transform: unset;
    }
    & .mobile {
      display: unset;
    }
    & img.img-download-app {
      display: none;
    }
    // & .wrap-download-img {
    //   flex-direction: row;
    //   & .app-img:first-child {
    //     margin-right: 10px;
    //   }
    // }
    & .wrap-content {
      & svg.ic-taxi {
        width: 80x;
        margin-top: 10px;
      }
    }
    & .wrap-bottom-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    & .wrap-img-and-qr {
      justify-content: center;
    }
  }
`
const WrapContent = styled.div`
  &.page-home {
    & .navbar {
      padding-bottom: 5px;
    }
  }
  & .wrap-home-slides {
    padding: 25px 0px;
    margin-left: 0px;
    margin-right: 0px;

    &.landing-page {
      margin-top: 25px;
      margin-bottom: 15px;
      position: relative;
      z-index: 0;
      height: 500px;
    }
  }
  & .wrap-home-slide {
    height: 100%;
    & > span {
      height: 100%;
      display: block;
      & > span {
        height: 100%;
        border-radius: 15px;
      }
    }
  }
  & .wrap-title-home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    & .title-home {
      width: 500px;
      height: 49px;
      & > span {
        height: 100%;
        display: block;
        width: 100%;
        & > span {
          height: 100%;
        }
      }
    }
    & .description-title {
      padding-top: 15px;
      width: 700px;
      height: 40px;
      & > span {
        height: 100%;
        display: block;
        width: 100%;
        & > span {
          height: 100%;
        }
      }
    }
  }
  @media (max-width: 767px) {
    & .wrap-title-home {
      & .title-home {
        width: 100%;
        height: 39px;
      }
      & .description-title {
        width: 100%;
      }
    }
  }
`
const WrapLandingPage = styled.div``
const WrapHomeService: any = styled.div`
  &.home_service_loading {
    padding-top: 0px;
    max-width: 1200px;
    margin: 0px auto 25px;
  }
  & .home_service {
    display: flex;
    & .one_service_item {
      width: ${(props: any) =>
        props?.items ? `calc(100% / ${props?.items})` : "100%"};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      & .icon {
        width: 113px;
        height: 113px;
        & > span {
          height: 100%;
          display: block;
          & > span {
            height: 100%;
          }
        }
      }
      & .title_item {
        padding-top: 15px;
        height: 40px;
        width: 130px;
        & > span {
          height: 100%;
          display: block;
          & > span {
            height: 100%;
          }
        }
      }
      & .description_item {
        padding-top: 15px;
        height: 65px;
        width: 220px;
        & > span {
          height: 100%;
          display: block;
          & > span {
            height: 100%;
          }
        }
      }
      & .btn_item {
        margin-top: 20px;
        height: 35px;
        width: 100px;
        & > span {
          height: 100%;
          display: block;
          & > span {
            height: 100%;
            border-radius: 8px;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`
const WrapHomeCategory: any = styled.div`
  &.home_category_loading {
    background-color: rgb(242, 242, 242);
    margin-bottom: 0px;
    padding-top: 30px;
    padding-bottom: 50px;
  }
  & .home_category {
    display: flex;
    & .one_category_item {
      width: ${(props: any) =>
        props?.items ? `calc(100% / ${props?.items})` : "100%"};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 15px;
      & .image {
        width: 100%;
        & > span {
          height: 100%;
          display: block;
          width: 100%;
          height: 300px;
          & > span {
            height: 100%;
          }
        }
      }
      & .wrap-content {
        height: 110px;
        width: 100%;
        background-color: var(--color-text-fff);
        border-radius: 0px 0px 15px 15px;
        padding: 15px;
      }
      & .sub_text {
        padding-top: 5px;
        & > span {
          height: 100%;
          display: block;
          width: 80%;
          & > span {
            height: 100%;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`
const WrapHomePromotion: any = styled.div`
  & .home_promotion {
    display: flex;
    & .one_promotion_item {
      width: ${(props: any) =>
        props?.items ? `calc(100% / ${props?.items})` : "100%"};
      margin-right: 15px;
      & .image {
        width: 100%;
        height: 219px;
        & > span {
          height: 100%;
          display: block;
          width: 100%;
          & > span {
            height: 100%;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`
const WrapHomeCar: any = styled.div`
  & .home_car {
    display: grid;
    grid-template-columns: repeat(
      ${(props: any) => (props?.itemPerRow ? props?.itemPerRow : 2)},
      minmax(0px, 1fr)
    );
    gap: 25px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
    & .one_car_item {
      & .image {
        width: 100%;
        height: 219px;
        & > span {
          height: 100%;
          display: block;
          width: 100%;
          & > span {
            height: 100%;
          }
        }
      }
      & .wrap-name {
        margin: 10px 0;
        height: 30px;
        display: flex;
        justify-content: space-between;
        & .name {
          width: 70%;
          & > span {
            height: 100%;
            display: block;
            width: 100%;
            & > span {
              height: 100%;
            }
          }
        }
        & .rate {
          width: 20%;
          & > span {
            height: 100%;
            display: block;
            width: 100%;
            & > span {
              height: 100%;
            }
          }
        }
      }
    }
  }
`
const WrapHomeAdvantage: any = styled.div`
  & .home_advantage {
    display: flex;
    & .one_advantage_item {
      width: ${(props: any) =>
        props?.items ? `calc(100% / ${props?.items})` : "100%"};
      margin-right: 35px;
      & .wrap-img {
        width: 100%;
        height: 475px;
        position: relative;
        & .bg-img {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: #e8e8e8;
          height: 50%;
          width: 50%;
          border-radius: 15px;
        }
      }
      & .image {
        object-fit: cover;
        height: 455px;
        position: absolute;
        width: calc(100% - 20px) !important;
        & > span {
          height: 100%;
          display: block;
          width: 100%;
          & > span {
            height: 100%;
            border-radius: 15px;
            border: 5px solid var(--color-text-fff);
          }
        }
      }
      & .title {
        margin-top: 15px;
      }
      & .description {
        height: 90px;
        margin-top: 15px;
        & > span {
          height: 100%;
          display: block;
          width: 100%;
          & > span {
            height: 100%;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`
const WrapHomeBlog: any = styled.div`
  &.home_blog_loading {
    padding-top: 30px;
    padding-bottom: 50px;
    background-color: rgb(242, 242, 242);
    margin-bottom: 0px;
  }
  & .home_blog {
    display: flex;
    & .one_blog_item {
      width: ${(props: any) =>
        props?.items ? `calc(100% / ${props?.items})` : "100%"};
      margin-right: 15px;
      & .image {
        height: 325px;
        width: 100%;
        & > span {
          height: 100%;
          display: block;
          width: 100%;
          & > span {
            height: 100%;
            border-radius: 15px 15px 0 0;
          }
        }
      }
      & .wrap_content {
        padding: 15px;
        border-radius: 0px 0px 15px 15px;
        background-color: var(--color-text-fff);
      }
      & .title {
        margin-top: 15px;
      }
      & .description {
        margin-top: 15px;
        height: 90px;
        & > span {
          height: 100%;
          display: block;
          width: 100%;
          & > span {
            height: 100%;
          }
        }
      }
      & .date {
        margin-top: 15px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`
const WrapHomeHelps: any = styled.div`
  &.home_helps_loading {
    padding-top: 30px;
    padding-bottom: 50px;
    background-color: rgb(242, 247, 255);
  }
  & .home_helps {
    display: flex;
    & .one_helps_item {
      width: ${(props: any) =>
        props?.items ? `calc(100% / ${props?.items})` : "100%"};
      margin-right: 15px;
      & .image {
        width: 100%;
        height: 400px;
        & > span {
          height: 100%;
          display: block;
          width: 100%;
          & > span {
            height: 100%;
          }
        }
      }
      & .title {
        margin-top: 15px;
      }
      & .button_item {
        margin-top: 20px;
        width: 100px;
        height: 36px;
        & > span {
          height: 100%;
          display: block;
          width: 100%;
          & > span {
            height: 100%;
            border-radius: 10px;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`
const WrapTopHeader = styled.div`
  &.wrap-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 135px;
    transition: background 0.25s;
    width: 100%;
    position: relative;
    z-index: 334;
    padding: 0px;
  }
  & .navbar-header {
    transition: all 0.25s;
    & .navbar-brand {
      padding: 0 !important;
    }
    & .btn-filter {
      display: none;
    }
  }
  & .logo {
    filter: unset;
  }

  & .first-menu {
    display: flex;
    gap: 50px;
    & .menu-item {
      margin-right: 50px;
      color: #5b5959;
      font-weight: 500;
      font-size: 16px;
      min-width: 60px;
      &:last-child {
        margin-right: 0;
      }
    }
    & .menu-item.active {
      color: var(--color-text-000);
      font-weight: 700;
    }
  }
  & .form-search {
    // max-width: 75%;
    height: 5em;
    top: 50px;
    transform: translate(-50%, 0);
    border-radius: 37px;
    width: 1000px;
    position: absolute;
    align-items: center;
    background: #e9e9e9;
    box-shadow: 0px 2px 10px 0px #0000001a;
    border: 1px solid #e3e1e1;
    cursor: pointer;
    display: flex;
    padding: 0 0 0 0.5em;
    transition: all 0.25s;
    left: 50%;
    justify-content: flex-end;
    & .mb-3 {
      margin-bottom: 0 !important;
    }
    & .datefromto-dropdown-menu {
      transform: translate(-29%, 2%) !important;
    }
    & .select-address-dropdown-menu {
      transform: translate(-29%, 2%) !important;
      left: 100% !important;
      top: 5px !important;
      backface-visibility: hidden;
    }
  }
  & .form-search {
    & .form-group {
      width: 30%;
    }
    & .form-group.datefromto {
      width: calc(70% - 70px - 18px);
    }
  }
  & .mid-menu {
    left: 50%;
    position: absolute;
    top: 20px;
    visibility: visible;
    transform: translateX(-50%) translateY(-50%) scale(1);
    transition: all 0.25s;
  }
  & .btn-search {
    width: 56px;
    height: 56px;
    box-shadow: none;
    border: none;
    border-radius: 50%;
    font-size: 25px;
    background-color: var(--mau1);
    color: var(--color-text-fff);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-right: 10px;
  }
  & .btn-search:hover {
    box-shadow: none;
    border: none;
  }
  & .btn-time-select {
    padding: 0;
    color: var(--color-text-search-bar);
    box-shadow: none;
    border: none;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    & .item {
      padding: 10px;
      padding-left: 20px;
      text-align: left;
      width: 48%;
      position: relative;
      z-index: 1;

      & .title {
        font-weight: 700;
        color: var(--color-text-000);
        font-size: 14px;
      }
      & .time {
        padding-top: 5px;
        font-size: 16px;
        color: var(--color-text-search-bar);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & .item.active::after {
      content: "";
      top: -2px;
      left: 0;
      right: 0;
      z-index: -1;
      margin: 0 2px;
      width: 100%;
      height: calc(100% + 2px);
      position: absolute;
      border: 1px solid transparent;
      background-color: var(--color-text-fff);
      box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
      border-radius: 37px;
    }
    & .divider-line {
      border-left: 1px solid #d8d8d8;
      width: 1px;
      height: 30px;
    }
    & .divider-line.custom {
      margin-left: -5px;
    }
    & .text-merge {
      font-size: 14px;
      color: var(--color-text-search-bar);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  & .btn-time-select.custom-text {
    display: none;
  }
  & .btn-input-address {
    color: var(--color-text-000);
    text-align: left;
    border: none;
    box-shadow: none;
    width: 100%;
    padding: 6px;
    &.custom-text {
      display: none;
    }
    & .title {
      font-weight: 700;
      font-size: 14px;
      // min-width: 100%;
    }
    & .value {
      padding-top: 5px;
      font-size: 16px;
      color: var(--color-text-search-bar);
      &:not(.merge-mode) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.merge-mode {
        padding-top: 0px;
        font-size: 14px;
        display: flex;
        align-items: center;
        width: 100%;
        & svg {
          margin-right: 5px;
        }
        span {
          width: calc(100% - 20px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &.active {
      position: relative;
    }
    &.active::before {
      content: "";
      top: -6px;
      left: -20px;
      right: 0;
      z-index: -1;
      margin: 0 2px;
      width: calc(100% + 20px);
      height: calc(100% + 10px);
      position: absolute;
      border: 1px solid transparent;
      background-color: var(--color-text-fff);
      box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
      border-radius: 37px;
    }
  }
  @media (max-width: 1009px) {
    & .form-search {
      max-width: 100%;
    }
    &.wrap-header {
      height: 53px;
    }
    & .navbar-header {
      & .btn-filter {
        display: inline-block;
        margin: 5px 15px 0px;
        color: rgb(34, 34, 34);
        min-width: 81px;
        height: 39px;
        padding: 0;
        border-color: transparent;
        & > span {
          height: 100%;
          & > span {
            height: 100%;
            display: block;
            border-radius: 20px;
          }
        }
      }
    }
    & .form-search,
    & .mid-menu {
      display: none;
    }
  }
  @media (max-width: 767px) {
    box-shadow: rgb(0 0 0 / 16%) 0 0 6px;
    padding-bottom: 5px;
    &.wrap-header.container {
      padding: 0px !important;
      & .navbar-header {
        margin: 0 !important;
      }
    }
    & .mobile-menu-dropdown {
      margin: 0;
    }
    & .navavatar {
      & .wp-icobar {
        // padding-top: 0 !important;
        margin-right: 5px;
      }
    }
    & .navbar-header {
      & .navbar-brand {
        padding: 5px 0 5px 10px !important;
      }
    }
  }
`
const RightMenu = styled.div`
  &.right-menu {
    &.collapse.navbar-collapse {
      display: flex !important;
      align-items: center;
      gap: 20px;
      flex: 1 0 140px;
      justify-content: flex-end;
      padding-right: 0;
    }
    & .navbar-right.avatar-menu {
      float: unset;
      margin-top: 0 !important;
    }
    & .custom-link {
      color: var(--color-text-000);
      font-size: 14px;
      font-weight: 500;
    }
  }
  @media (max-width: 767px) {
    &.right-menu {
      margin: 0 !important;
      flex: unset;
      &.collapse.navbar-collapse {
        gap: unset;
      }
      & ul.dropdown-menu {
        display: none !important;
      }
      & .hidden-mobile {
        display: unset !important;
      }
      & .wp-icobar {
        padding-top: 14px;
        margin-right: 0;
      }
      & .arvartimg {
        max-height: unset;
      }
      & .navavatar {
        margin-right: 25px;
      }
    }
  }
`
const WrapLoadingSkeleton = styled.div`
  & .navbar {
    background-color: var(--color-text-fff) !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 6px;
    padding-bottom: 5px;
  }
  @media (max-width: 767px) {
    & .navbar {
      padding-bottom: 0 !important;
    }
  }
`
