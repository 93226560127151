import { HLocalStorage, HUtils, HApi } from "src/core/helpers"
import { MyModal } from "src/core/components"
import MyUIOne from "./MyUI"
import "./scss/index.scss"

interface IModuleDevSimulator extends IModuleOne {
  usingLocalBaseUrlKey?: any
  localBaseUrlKey?: any
  usingLocalBaseUrl?: boolean
  runAfterInit: () => void
  setLocalBaseUrl: (newBaseUrl: any) => void
  getIsUsingLocalBaseUrl: () => boolean
  getLocalBaseUrl: () => string
  showUIDevSimulator: () => void
  setIsUsingLocalBaseUrl: (value: any) => void
}
const ModuleDevSimulator: IModuleDevSimulator = {
  usingLocalBaseUrlKey: "_usingLocalBaseUrl",
  localBaseUrlKey: "_localBaseUrl",
  usingLocalBaseUrl: false,
  runAfterInit: () => {
    MyUIOne.init()
    const localBaseUrl = ModuleDevSimulator.getLocalBaseUrl()
    const isUsingLocalBaseUrl = ModuleDevSimulator.getIsUsingLocalBaseUrl()
    if (isUsingLocalBaseUrl === true) {
      ModuleDevSimulator.usingLocalBaseUrl = true
      if (localBaseUrl) {
        HApi.initApi({ baseUrl: localBaseUrl })
      }
    }
    if (HUtils.isLocalDev() === true || isUsingLocalBaseUrl === true) {
      ModuleDevSimulator.showUIDevSimulator()
    } else {
      console.log = () => {}
      console.warn = () => {}
    }
    if (!localBaseUrl) {
      ModuleDevSimulator.setLocalBaseUrl("http://localhost:12391")
    }
  },
  getIsUsingLocalBaseUrl: () => {
    if (
      HLocalStorage.getObj(ModuleDevSimulator.usingLocalBaseUrlKey, {}) === true
    ) {
      return true
    }
    return false
  },
  getLocalBaseUrl: () => {
    return HLocalStorage.getString(ModuleDevSimulator.localBaseUrlKey, "")
  },
  setIsUsingLocalBaseUrl: value => {
    ModuleDevSimulator.usingLocalBaseUrl = value
    HLocalStorage.saveString(ModuleDevSimulator.usingLocalBaseUrlKey, value)
  },
  setLocalBaseUrl: newBaseUrl => {
    HLocalStorage.saveString(ModuleDevSimulator.localBaseUrlKey, newBaseUrl)
  },
  showUIDevSimulator: () => {
    if (!document.getElementById("dev_simulator")) {
      const _localBaseUrl = ModuleDevSimulator.getLocalBaseUrl()
      let button = document.createElement("button")
      button.id = "dev_simulator"
      button.type = "button"
      button.innerHTML = `<i class='fa fa-bug'/> ${
        ModuleDevSimulator.usingLocalBaseUrl && _localBaseUrl
          ? _localBaseUrl
          : ""
      }`
      button.className = "btn btn-danger btn-dev-sml"
      button.onclick = function () {
        MyModal.showFromMyUI(
          {
            type: "dev_simulator",
          },
          {
            title: "Config BaseUrl",
          }
        )
      }
      document.body.appendChild(button)
      document.body.style.position = "relative"
    }
  },
}
export default ModuleDevSimulator
