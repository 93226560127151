import classNames from "classnames"
import styled from "styled-components"

const RentalPriceDetails = props => {
  const { data, title } = props
  return (
    <Wrap>
      {data &&
        data?.length > 0 &&
        data.map((v, i) => {
          let isTotal = false
          if (v?.Title?.startsWith("Tổng")) {
            isTotal = true
          }
          let information = ""
          const detail = v?.Detail || {}
          if (detail?.IsShowTitleOnly && detail?.Title) {
            information = detail?.Title
          } else if (v?.Information) {
            information = v?.Information
          }
          return (
            <div
              className={classNames("one-item mauden", isTotal && "total")}
              key={i}
            >
              <div className="content-price">
                <div className="title-item">{v?.Title}</div>
                <div className="price-text fw-600">{v?.PriceText}</div>
              </div>
              {information && (
                <div className="description-price">{information}</div>
              )}
            </div>
          )
        })}
    </Wrap>
  )
}
const Wrap = styled.div`
  & .one-item {
    padding-bottom: 16px;
    & .content-price {
      display: flex;
      justify-content: space-between;
    }
    &.total {
      font-weight: 600;
      border-top: 1px solid var(--color-divider);
      padding-top: 20px;
      margin-top: 10px;
    }
    & .description-price {
      color: #7b7b7b;
      font-size: 14px;
      width: 100%;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
`
export default RentalPriceDetails
