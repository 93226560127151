import React, { useEffect, useRef, useState } from "react"
import { HFormat, HLink, HUtils } from "src/core/helpers"
import { MyForm, MyModal, MyUI } from "src/core/components"
import { isNotEmpty } from "src/core/helpers/utils"
import styled from "styled-components"
import { showAlert } from "src/modules/app_sigo/helpers/ui"
import { mobileWidth } from "src/modules/app_sigo/constants"
import { headerTabList } from "src/modules/app_sigo/constants"
import classNames from "classnames"
import { Button, Collapse } from "reactstrap"
import EnterAddress from "./EnterAddress"
import { connect, ConnectedProps } from "react-redux"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import EnterDate from "./EnterDate"
import EnterTime from "./EnterTime"

const mapStateToProps = state => ({
  dataFirstSetting: state.app.dataFirstSetting,
})
const mapDispatchToProps = {}

const getHourStringFromTime = time => {
  if (!time) {
    return "00:00"
  }
  const date = new Date(time)
  const hoursDate = date.getHours().toString().padStart(2, "0")
  const minutesDate = date.getMinutes().toString().padStart(2, "0")
  return hoursDate + ":" + minutesDate
}

const connector = connect(mapStateToProps, mapDispatchToProps)
interface Props extends ConnectedProps<typeof connector> {
  tabList?: any
  moreConfig?: any
  fnList?: any
  dataFilter?: any
  goBackHome?: any
}
const OneCollapse = props => {
  const {
    children,
    label,
    placeholder,
    value,
    defaultStatus,
    classNameCollapse,
    open,
    onChangeCollapseActive,
    moreConfig,
  } = props
  const [isShow, setIsShow] = useState(open)

  let _value = placeholder
  if (value) {
    _value = value
  }
  return (
    <WrapOneCollapse
      className={classNames(
        "one-collapse",
        open && "is-open",
        classNameCollapse
      )}
      onClick={() => {
        if (!open) {
          onChangeCollapseActive()
        }
      }}
    >
      {!open ? (
        <div className="d-flex justify-between">
          <div className="label">{label}</div>
          <div className="value">{_value}</div>
        </div>
      ) : (
        children
      )}
    </WrapOneCollapse>
  )
}

const Search = props => {
  const { fnList, dataFilter = {} } = props
  const [myFilter, setMyFilter] = useState({
    [homeSlidesFields.fAddress]: null as any,
    [homeSlidesFields.fFromDate]: new Date().setHours(21, 0, 0, 0),
    [homeSlidesFields.fToDate]: new Date(
      new Date().setHours(24, 0, 0, 0)
    ).setHours(20, 0, 0, 0),
    [homeSlidesFields.fTimeStart]: "21:00",
    [homeSlidesFields.fTimeEnd]: "20:00",
    ...dataFilter,
  })

  const [curCollapseOpen, setCurCollapseOpen] = useState("address")
  const [focusingAddress, setFocusingAddress] = useState(false)

  /** Get */
  const getDisabledSearch = () => {
    let isDisabled = false
    const requiredFields = {
      [homeSlidesFields.fAddress]: homeSlidesFields.fAddress,
      [homeSlidesFields.fFromDate]: homeSlidesFields.fFromDate,
      [homeSlidesFields.fToDate]: homeSlidesFields.fToDate,
    }
    Object.keys(requiredFields).every(f => {
      if (!isNotEmpty(myFilter[f])) {
        isDisabled = true
        return false
      }
      return true
    })
    if (
      myFilter?.[homeSlidesFields.fFromDate] >=
      myFilter?.[homeSlidesFields.fToDate]
    ) {
      isDisabled = true
    }
    return isDisabled
  }
  const convertHourStringToMilliseconds = timeString => {
    if (!timeString) {
      return 0
    }
    // Tách chuỗi thành giờ và phút
    const [hours, minutes] = timeString.split(":").map(Number)

    // Tính tổng số mili giây
    const totalMilliseconds = hours * 3600000 + minutes * 60000

    return totalMilliseconds
  }

  const getFnList = () => {
    return {
      afterUpdateMultiValues: obj => {
        const { values, cbSuccess } = obj
        let objUpdate: any = {}
        objUpdate = { ...values }
        if (values?.[`${homeSlidesFields.fAddress}_MoreInfo`]) {
          const location = HUtils.get(
            values,
            `${homeSlidesFields.fAddress}_MoreInfo[0].geometry.location`
          )
          if (location?.lat) {
            objUpdate[homeSlidesFields.fLatitude] = location.lat()
          }
          if (location?.lng) {
            objUpdate[homeSlidesFields.fLongitude] = location.lng()
          }
          delete objUpdate[`${homeSlidesFields.fAddress}_MoreInfo`]
          cbSuccess && cbSuccess()
          setMyFilter({
            ...myFilter,
            ...objUpdate,
          })
          updateDataOnSearchBar({ ...myFilter, ...objUpdate })
          onChangeCollapseActive("date")
          onHandleHideFooter(false)
        } else {
        }
      },
      onCloseCollapse: () => {
        onChangeCollapseActive(null)
      },
      onUpdateDate: values => {
        let newValuesUpdate: any = {}
        let timeStart = myFilter?.[homeSlidesFields.fTimeStart]
        let timeEnd = myFilter?.[homeSlidesFields.fTimeEnd]
        newValuesUpdate[homeSlidesFields.fFromDate] =
          values[homeSlidesFields.fFromDate]
        newValuesUpdate[homeSlidesFields.fToDate] =
          values[homeSlidesFields.fToDate]

        // Nếu chọn giờ trước thì cần apply giờ vào ngày
        if (timeStart && values[homeSlidesFields.fFromDate]) {
          const resetTimeOfValue = new Date(
            values?.[homeSlidesFields.fFromDate]
          ).setHours(0, 0, 0, 0)
          newValuesUpdate[homeSlidesFields.fFromDate] = new Date(
            resetTimeOfValue
          ).setMilliseconds(convertHourStringToMilliseconds(timeStart))
        }
        if (timeEnd && values[homeSlidesFields.fToDate]) {
          const resetTimeOfValue = new Date(
            values?.[homeSlidesFields.fToDate]
          ).setHours(0, 0, 0, 0)
          newValuesUpdate[homeSlidesFields.fToDate] = new Date(
            resetTimeOfValue
          ).setMilliseconds(convertHourStringToMilliseconds(timeEnd))
        }
        // Cần cộng thêm 1 ngày nếu ngày thuê >= ngày trả
        if (
          newValuesUpdate[homeSlidesFields.fFromDate] &&
          newValuesUpdate[homeSlidesFields.fToDate] &&
          newValuesUpdate[homeSlidesFields.fFromDate] >=
            newValuesUpdate[homeSlidesFields.fToDate]
        ) {
          let _newToDate =
            newValuesUpdate[homeSlidesFields.fFromDate] + 86400000
          let endHours = new Date(
            newValuesUpdate[homeSlidesFields.fToDate]
          ).getHours()
          let endMinute = new Date(
            newValuesUpdate[homeSlidesFields.fToDate]
          ).getMinutes()
          newValuesUpdate[homeSlidesFields.fToDate] = new Date(
            new Date(_newToDate).setHours(endHours, endMinute, 0, 0)
          ).getTime()
        }
        setMyFilter({
          ...myFilter,
          ...newValuesUpdate,
        })
        updateDataOnSearchBar({ ...myFilter, ...values })
        onChangeCollapseActive("time")
      },
      onUpdateTime: values => {
        let newValuesUpdate: any = {}
        let timeStart = values[homeSlidesFields.fTimeStart]
        let timeEnd = values[homeSlidesFields.fTimeEnd]

        if (timeStart) {
          newValuesUpdate[homeSlidesFields.fTimeStart] = timeStart
          if (myFilter?.[homeSlidesFields.fFromDate]) {
            const resetTimeOfValue = new Date(
              myFilter?.[homeSlidesFields.fFromDate]
            ).setHours(0, 0, 0, 0)
            newValuesUpdate[homeSlidesFields.fFromDate] = new Date(
              resetTimeOfValue
            ).setMilliseconds(convertHourStringToMilliseconds(timeStart))
          }
        }
        if (timeEnd) {
          newValuesUpdate[homeSlidesFields.fTimeEnd] = timeEnd
          if (myFilter?.[homeSlidesFields.fToDate]) {
            const resetTimeOfValue = new Date(
              myFilter?.[homeSlidesFields.fToDate]
            ).setHours(0, 0, 0, 0)
            newValuesUpdate[homeSlidesFields.fToDate] = new Date(
              resetTimeOfValue
            ).setMilliseconds(convertHourStringToMilliseconds(timeEnd))
          }
        }
        // Cần cộng thêm 1 ngày nếu ngày thuê >= ngày trả
        if (
          newValuesUpdate[homeSlidesFields.fFromDate] &&
          newValuesUpdate[homeSlidesFields.fToDate] &&
          newValuesUpdate[homeSlidesFields.fFromDate] >=
            newValuesUpdate[homeSlidesFields.fToDate]
        ) {
          let _newToDate =
            newValuesUpdate[homeSlidesFields.fFromDate] + 86400000
          let endHours = new Date(
            newValuesUpdate[homeSlidesFields.fToDate]
          ).getHours()
          let endMinute = new Date(
            newValuesUpdate[homeSlidesFields.fToDate]
          ).getMinutes()
          newValuesUpdate[homeSlidesFields.fToDate] = new Date(
            new Date(_newToDate).setHours(endHours, endMinute, 0, 0)
          ).getTime()
        }
        //Nếu có thời gian nhưng không có ngày
        setMyFilter({
          ...myFilter,
          ...newValuesUpdate,
        })
        updateDataOnSearchBar({ ...myFilter, ...values })
      },
      updateAddress: (
        obj,
        { afterUpdate, updateWithTime = false }: any = {}
      ) => {
        if (updateWithTime) {
          const { FromDate, ToDate, ...other } = obj
          let newData: any = {
            ...myFilter,
            ...other,
          }
          let isFormatted = false
          if (FromDate && ToDate) {
            if (
              FromDate <= new Date().getTime() ||
              ToDate <= new Date().getTime()
            ) {
              // showAlert({
              //   component: (
              //     <MyUI
              //       type="alert"
              //       alertType="warning"
              //       title="Thông báo"
              //       msg="Thời gian thuê xe không hợp lệ, bạn hãy chọn lại nhé."
              //     />
              //   ),
              // })
            } else if (FromDate >= ToDate) {
              // Trường hợp ngày nhận >= ngày trả
              let _newToDate = FromDate + 86400000
              let endHours = new Date(ToDate).getHours()
              let endMinute = new Date(ToDate).getMinutes()
              newData[homeSlidesFields.fToDate] = new Date(
                new Date(_newToDate).setHours(endHours, endMinute, 0, 0)
              ).getTime()
              newData[homeSlidesFields.fFromDate] = FromDate
              isFormatted = true
            } else {
              newData[homeSlidesFields.fToDate] = ToDate
              newData[homeSlidesFields.fFromDate] = FromDate
            }
          }
          if (newData[homeSlidesFields.fFromDate]) {
            newData[homeSlidesFields.fTimeStart] = getHourStringFromTime(
              newData[homeSlidesFields.fFromDate]
            )
          }
          if (newData[homeSlidesFields.fToDate]) {
            newData[homeSlidesFields.fTimeEnd] = getHourStringFromTime(
              newData[homeSlidesFields.fToDate]
            )
          }

          if (
            (FromDate === newData[homeSlidesFields.fFromDate] &&
              ToDate === newData[homeSlidesFields.fToDate]) ||
            isFormatted
          ) {
            setMyFilter(newData)
            onChangeCollapseActive("date")
            updateDataOnSearchBar({ ...myFilter, ...other })
            fnList?.onFilter && fnList?.onFilter({ ...myFilter, ...other })
            MyModal.hideModalComponent()
            // onHandleHideFooter(false)
          } else {
            setMyFilter(newData)
            updateDataOnSearchBar(newData)
            // Xử lý trường hợp recently address chưa có thời gian hoặc thời gian đã cũ
            onChangeCollapseActive("date")
            onHandleHideFooter(false)
          }
        } else {
          const { Name, ...other } = obj
          let newData = {
            ...myFilter,
            ...other,
            [`${homeSlidesFields.fAddress}_MoreInfo`]: [],
          }
          setMyFilter(newData)
          onChangeCollapseActive("date")
          onHandleHideFooter(false)
        }
      },
      setFocusingAddress: setFocusingAddress,
      onHandleHideFooter: onHandleHideFooter,
    }
  }

  const getDateToShow = () => {
    const start = myFilter?.[homeSlidesFields.fFromDate]
    const end = myFilter?.[homeSlidesFields.fToDate]
    let dateStart: any = ""
    let dateEnd: any = ""
    if (!start || !end) {
      return
    }

    if (start) {
      const _dateStart = new Date(start)
      const y = _dateStart?.getFullYear()
      const m = _dateStart?.getMonth() + 1
      const d = _dateStart?.getDate()
      let formattedDate = `${d < 10 ? `${d}` : d} tháng ${m}, ${y}`

      dateStart = formattedDate
    }
    if (end) {
      const _dateEnd = new Date(end)
      const y = _dateEnd?.getFullYear()
      const m = _dateEnd?.getMonth() + 1
      const d = _dateEnd?.getDate()
      let formattedDate = `${d < 10 ? `${d}` : d} tháng ${m}, ${y}`

      dateEnd = formattedDate
    }
    // if (dateStart) {
    //   let commaIndex = dateStart.indexOf(",")
    //   if (commaIndex !== -1) {
    //     dateStart = dateStart.slice(0, commaIndex)
    //   }
    // }
    return dateStart + "-" + dateEnd
  }

  const getTimeToShow = () => {
    const _timeStart = myFilter?.[homeSlidesFields.fTimeStart]
    const _timeEnd = myFilter?.[homeSlidesFields.fTimeEnd]
    if (!_timeStart || !_timeEnd) {
      return false
    }
    return _timeStart + "-" + _timeEnd
  }

  const getConfigCollapseList = () => {
    const moreCfAddress =
      (fnList?.getConfigFromFirstSettingByKey &&
        fnList?.getConfigFromFirstSettingByKey("address")) ||
      {}
    return [
      {
        key: "address",
        label: "Địa điểm",
        placeholder: "Chọn địa điểm",
        value: myFilter?.[homeSlidesFields.fAddress],
        content: (
          <EnterAddress
            moreConfig={{
              ...moreCfAddress,
              fieldAddress: homeSlidesFields.fAddress,
            }}
            fnList={{ ...fnList, ...getFnList() }}
            address={myFilter?.[homeSlidesFields.fAddress]}
          />
        ),
      },
      {
        key: "date",
        label: "Thời gian",
        value: getDateToShow(),
        placeholder: "Chọn ngày",
        classNameCollapse: "wrap-part-date",
        content: (
          <EnterDate
            moreConfig={{
              ...moreCfAddress,
              fieldAddress: homeSlidesFields.fAddress,
            }}
            fnList={{
              ...fnList,
              ...getFnList(),
            }}
            data={myFilter}
          />
        ),
      },
      {
        key: "time",
        label: "Nhận/Trả xe",
        placeholder: "Chọn giờ",
        value: getTimeToShow(),
        content: (
          <EnterTime
            moreConfig={{
              ...moreCfAddress,
              fieldAddress: homeSlidesFields.fAddress,
            }}
            fnList={{
              ...fnList,
              ...getFnList(),
            }}
            tabActive={curCollapseOpen}
            data={myFilter}
          />
        ),
      },
    ]
  }
  const updateDataOnSearchBar = newValue => {
    fnList?.setCurData && fnList?.setCurData(newValue)
  }
  const onChangeCollapseActive = key => {
    setCurCollapseOpen(key)
    const btnHideModal = document.getElementById("btn-close-modal")
    if (btnHideModal) {
      if (key === "address" && !btnHideModal?.classList.contains("hide")) {
        btnHideModal?.classList.add("hide")
      } else if (
        key !== "address" &&
        btnHideModal?.classList.contains("hide")
      ) {
        btnHideModal?.classList.remove("hide")
      }
    }
  }
  const onSearch = () => {
    if (myFilter) {
      fnList?.onFilter && fnList?.onFilter(myFilter)
      MyModal.hideModalComponent()
    }
  }
  const onHandleClear = () => {
    setMyFilter({})
    // setMyFilter({
    //   [homeSlidesFields.fAddress]: null as any,
    //   [homeSlidesFields.fFromDate]: new Date().setHours(21, 0, 0, 0),
    //   [homeSlidesFields.fToDate]: new Date(
    //     new Date().setHours(24, 0, 0, 0)
    //   ).setHours(20, 0, 0, 0),
    //   [homeSlidesFields.fTimeStart]: "21:00",
    //   [homeSlidesFields.fTimeEnd]: "20:00",
    // })
    setCurCollapseOpen("address")
  }
  const onHandleHideFooter = value => {
    const footer = document.getElementById("search-mobile-footer")
    if (footer) {
      if (value && !footer?.classList?.contains("hide")) {
        footer.classList?.add("hide")
      } else if (!value && footer?.classList?.contains("hide")) {
        footer.classList.remove("hide")
      }
    }
  }
  // const renderForm = () => {
  //   return (
  //     <div className="col-sm-12 item">
  //       <div className="wpsearchsl">
  //         <MyForm
  //           className="wpsearch filter-top"
  //           ref={refTopForm}
  //           configForm={configFormFilter}
  //           fnList={
  //             {
  //               afterUpdateValueControl: opts => {
  //                 const { fieldName, newValue } = opts
  //                 let newData: any = JSON.parse(JSON.stringify(myFilter))
  //                 newData[fieldName] = newValue
  //                 if (
  //                   fieldName === "datetimefrom" &&
  //                   newData.datetimefrom >= newData.datetimeto
  //                 ) {
  //                   const _newDateTimeTo = newValue + 86400000
  //                   newData.datetimeto = _newDateTimeTo
  //                   if (
  //                     refTopForm?.current?.fnListFromExtControl?.["datetimeto"]
  //                   ) {
  //                     refTopForm.current.fnListFromExtControl[
  //                       "datetimeto"
  //                     ].updateValue(_newDateTimeTo)
  //                   }
  //                   setMyFilter(newData)
  //                 } else if (
  //                   fieldName === "datetimeto" &&
  //                   newData.datetimeto <= newData.datetimefrom
  //                 ) {
  //                   if (
  //                     refTopForm?.current?.fnListFromExtControl?.[fieldName]
  //                   ) {
  //                     refTopForm.current.fnListFromExtControl[
  //                       fieldName
  //                     ].updateValue(myFilter[fieldName])
  //                   }
  //                   showAlert({
  //                     component: (
  //                       <MyUI
  //                         type="alert"
  //                         alertType="warning"
  //                         title="Thông báo"
  //                         msg="Thời gian thuê xe không hợp lệ, bạn hãy chọn lại nhé."
  //                       />
  //                     ),
  //                   })
  //                 } else {
  //                   setMyFilter(newData)
  //                 }
  //               },
  //               afterUpdateMultiValues: obj => {
  //                 const { values, cbSuccess } = obj
  //                 let objUpdate: any = {}
  //                 objUpdate = { ...values }
  //                 if (values?.[`from_MoreInfo`]) {
  //                   const location = HUtils.get(
  //                     values,
  //                     `from_MoreInfo[0].geometry.location`
  //                   )
  //                   if (location?.lat) {
  //                     objUpdate.lat = location.lat()
  //                   }
  //                   if (location?.lng) {
  //                     objUpdate.long = location.lng()
  //                   }
  //                   delete objUpdate["from_MoreInfo"]
  //                   cbSuccess && cbSuccess()
  //                   setMyFilter({
  //                     ...myFilter,
  //                     ...objUpdate,
  //                   })
  //                 }
  //               },
  //             } as any
  //           }
  //           renderFooter={() => {
  //             return (
  //               <div className="form-group" style={{ flex: 1 }}>
  //                 <button
  //                   name="btnsearch"
  //                   className="btn btn-info btnsearch"
  //                   disabled={getDisabledSearch()}
  //                   onClick={() => {
  //                     HLink.openUrl(getLinkSearch(), "_self")
  //                     MyModal.hideModalComponent()
  //                   }}
  //                 >
  //                   <i className="flaticon-search"></i>
  //                 </button>
  //               </div>
  //             )
  //           }}
  //         />
  //       </div>
  //     </div>
  //   )
  // }
  const renderContent = () => {
    const collapseList = getConfigCollapseList() || []
    if (collapseList && collapseList?.length > 0) {
      return (
        <div className="w-content">
          {collapseList?.map((v, i) => {
            let active = false
            if (v?.key === curCollapseOpen) {
              active = true
            }
            return (
              <OneCollapse
                placeholder={v?.placeholder}
                classNameCollapse={v?.classNameCollapse}
                label={v?.label}
                value={v?.value}
                open={active}
                onChangeCollapseActive={() => {
                  onChangeCollapseActive(v?.key)
                }}
                key={i}
              >
                {v?.content}
              </OneCollapse>
            )
          })}
        </div>
      )
    }
    return <></>
  }
  const renderFooter = () => {
    return (
      <Footer id="search-mobile-footer">
        <Button
          color="underline-black"
          className="fw-600"
          onClick={onHandleClear}
        >
          Xóa tất cả
        </Button>
        <Button
          color="primary-v2"
          className=""
          onClick={onSearch}
          disabled={getDisabledSearch()}
        >
          Tìm kiếm
        </Button>
      </Footer>
    )
  }
  return (
    <Wrap className="search-popup">
      {renderContent()}
      {renderFooter()}
    </Wrap>
  )
}
const MUISearchbar = (props: Props) => {
  const {
    tabList,
    dataFirstSetting,
    fnList,
    dataFilter = {},
    goBackHome,
  } = props
  const fromDate = dataFilter?.[homeSlidesFields.fFromDate]
  const toDate = dataFilter?.[homeSlidesFields.fToDate]
  const [curTab, setCurTab] = useState("xe-tu-lai")
  const [configFirstSetting, setConfigFirstSetting] = useState(
    HUtils.get(dataFirstSetting, "DefaultValues.websiteSetting") || {}
  )
  useEffect(() => {
    setConfigFirstSetting(
      HUtils.get(dataFirstSetting, "DefaultValues.websiteSetting")
    )
    // eslint-disable-next-line
  }, [dataFirstSetting])
  const getConfigFromFirstSettingByKey = key => {
    if (configFirstSetting) {
      const { config_search_in_header } = configFirstSetting || {}
      return HUtils.get(config_search_in_header, key)
    }
    return
  }

  const getTimeText = () => {
    if (fromDate && toDate) {
      const dateStart = new Date(fromDate)
      const dateEnd = new Date(toDate)
      return `${HFormat.format(dateStart, {
        type: "date",
        format: "HH:mm DD/MM/YYYY",
      })}-${HFormat.format(dateEnd, {
        type: "date",
        format: "HH:mm DD/MM/YYYY",
      })}`
    }
    return ""
  }

  const getDataFilter = () => {
    let _dataFilter = dataFilter || {}
    if (_dataFilter?.[homeSlidesFields.fFromDate]) {
      _dataFilter[homeSlidesFields.fTimeStart] = getHourStringFromTime(
        _dataFilter?.[homeSlidesFields.fFromDate]
      )
    }
    if (_dataFilter?.[homeSlidesFields.fToDate]) {
      _dataFilter[homeSlidesFields.fTimeEnd] = getHourStringFromTime(
        _dataFilter?.[homeSlidesFields.fToDate]
      )
    }
    return _dataFilter
  }
  return (
    <Wrap className="container wrap-search-bar">
      <div
        className="search-bar"
        onClick={() => {
          MyModal.showFromComponent(
            <Search
              tabList={tabList}
              dataFilter={getDataFilter()}
              fnList={{
                ...fnList,
                getConfigFromFirstSettingByKey: getConfigFromFirstSettingByKey,
              }}
            />,
            {
              title: (
                <Header>
                  <div
                    className="btn-close-modal hide"
                    id="btn-close-modal"
                    onClick={() => {
                      MyModal.hideModalComponent()
                    }}
                  >
                    <i className="flaticon-close" />
                  </div>
                  <div className="first-menu">
                    {headerTabList &&
                      headerTabList?.length > 0 &&
                      headerTabList?.map((v, i) => {
                        return (
                          <a
                            className={classNames(
                              "menu-item mauden ",
                              curTab === v?.key ? "active" : ""
                            )}
                            href={v?.link}
                            target="_blank"
                            rel="noreferrer"
                            key={i}
                          >
                            {v?.title}
                          </a>
                        )
                      })}
                  </div>
                </Header>
              ) as any,
              showHeader: true,
              backdrop: "static",
              modalClassName:
                "default footer-space-between modal-filter modal-full modal-search-mobile ",
              showFooter: false,
            }
          )
        }}
      >
        {goBackHome ? (
          <a className="btn-back" href="/">
            <i className="fa fa-long-arrow-left"></i>
          </a>
        ) : (
          <i className="flaticon-search"></i>
        )}
        <div className="intro-text">
          <div className="main">
            {dataFilter?.[homeSlidesFields.fAddress]
              ? dataFilter?.[homeSlidesFields.fAddress]
              : "Bạn muốn thuê xe ở đâu?"}
          </div>
          <div className="des">
            {fromDate && toDate ? (
              getTimeText()
            ) : (
              <>
                {!dataFilter?.[homeSlidesFields.fAddress] && (
                  <>
                    Địa điểm <div className="dot-icon" />
                  </>
                )}
                Ngày nhận
                <div className="dot-icon" /> Ngày trả
              </>
            )}
          </div>
        </div>
      </div>
    </Wrap>
  )
}
const Header = styled.div`
  & .btn-close-modal {
    position: absolute;
    font-size: 20px;
    top: 18px;
    left: 15px;
    & i {
      display: flex;
    }
    &.hide {
      display: none;
    }
  }
  & .first-menu {
    display: flex;
    width: calc(100% - 36px);
    justify-content: center;
    margin-left: 36px;
    & .menu-item {
      margin-right: 16px;
      color: #5b5959;
      font-weight: 500;
      font-size: 14px;
      min-width: 60px;
      &:last-child {
        margin-right: 0;
      }
    }
    & .menu-item.active {
      color: var(--color-text-000);
      font-weight: 700;
      user-select: none;
      pointer-events: none;
      &:after {
        height: 2px;
        content: "";
        background: #444444;
        width: 100%;
        display: block;
      }
    }
  }
`
const Wrap = styled.div`
  &.search-popup {
    display: flex;
    height: 100%;
    flex-direction: column;
    & .w-content {
      flex: 1 1 auto;
      padding-left: 16px;
      padding-right: 16px;
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    & .btnsearch {
      margin-bottom: 0px !important;
    }
    & .wpsearchsl {
      margin-bottom: 0px !important;
      padding: 0px;
    }
  }
  & .search-bar {
    cursor: pointer;
    margin-top: 0.5rem;
    box-shadow: 0 3px 10px rgba(0 0 0 / 0.1);
    border: 0.5px solid rgba(0 0 0 / 0.08);
    min-height: 56px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    & i {
      flex-shrink: 1;
      margin-top: 0.25rem;
      font-size: 25px;
      color: var(--color-text-363636);
    }
    & .btn-back {
      width: 30px;
      height: 40px;
      & i {
        font-size: 16px;
        color: var(--den);
      }
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      cursor: pointer;
    }

    & .intro-text {
      flex: 1;
      min-width: 0;
      margin-left: 0.5rem;
      & .main {
        font-weight: 600;
        color: var(--color-text-222222);
        font-size: 16px;
        line-height: 1.5rem;
        letter-spacing: normal;
      }
      & .des {
        font-size: 12px;
        line-height: 1rem;
        color: #939393;
        letter-spacing: normal;
        display: flex;
        align-items: center;
        & .dot-icon {
          height: 3px;
          width: 3px;
          margin-right: 8px;
          margin-left: 8px;
          border-radius: 3px;
          background: #000;
        }
      }
    }
  }
  & .label-text {
    color: #555;
    font-size: 16px;
    cursor: default;
  }
  & .filter-top {
    display: flex;
    align-items: center;
    & > .form-group {
      height: 48px;
      margin: 0px !important;
      & label {
        line-height: 30px;
        margin: 0 !important;
        font-weight: 700;
        color: #555;
        line-height: 25px;
      }
      & > div:nth-child(2) {
        flex: 1;
      }
      & .react-datepicker-wrapper {
        width: 90px !important;
      }
    }
    & .mc-input-datetime {
      justify-content: space-between;
    }
    & .nice-select .current {
      line-height: 1;
    }
    & input {
      padding-left: 0 !important;
      padding-bottom: 0 !important;
      height: 22px;
    }
    & .mc_input_address {
      .clear > button {
        height: 23px;
      }
    }
  }
  & .btnsearch {
    &:disabled {
      color: #fff;
      background-color: var(--mau1);
      border-color: var(--mau1);
    }
  }
  & .w-content {
  }
  @media (min-width: 768px) and (max-width: ${mobileWidth}px) {
    .wpinput.mid {
      border-left: none;
      border-right: none;
    }
  }
  @media (max-width: ${mobileWidth}px) {
    & .wpsearchsl {
      max-width: 100%;
      height: auto;
      padding: 10px;
      margin-bottom: 15px;
    }
    & .filter-top {
      flex-direction: column;
      & .form-group {
        margin-bottom: 20px !important;
        padding: 0 10px;
        width: 100%;
      }
      & .btnsearch {
        width: 100%;
        margin-top: 5px;
        border-radius: 20px;
      }
    }
    & .wpsearchsl {
      padding-bottom: 0;
    }
  }
`
const Footer = styled.div`
  // position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  background: #fff;
  width: 100%;
  margin: 0;
  left: 0;
  padding: 16px;
  align-items: center;
  & .btn-primary-v2 {
    min-width: 150px;
    font-size: 16px;
    min-height: 48px;
  }
  &.hide {
    display: none;
  }
`
export const WrapOneCollapse = styled.div`
  border: 1px solid #e9e9e9;
  box-shadow: 0px 4px 10px 0px #00000026;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 16px;
  background: var(--mautrang);

  &.wrap-part-date {
    &.is-open {
      position: absolute;
      width: calc(100% - 32px);
      padding: 0;
      top: 75px;
      bottom: 0;
      z-index: 10;
      margin-bottom: 0;
    }
    & .value {
      width: calc(100% - 70px);
    }
  }
  & .label {
    color: #7b7878;
    font-weight: 600;
    font-size: 14px;
    max-width: 90px;
    padding-right: 8px;
  }
  & .value {
    color: #585858;
    font-weight: 600;
    font-size: 14px;
    width: calc(100% - 90px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
  }
`
export default connector(MUISearchbar)
