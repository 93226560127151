import { toast } from "react-toastify"
import { HCF } from "@macashipo/mlib"
import { MyDialog, MyModal } from "src/core/components"

const configToast = {
  hideProgressBar: true,
  pauseOnHover: true,
  autoClose: 2000,
}

const ToastType = {
  success: "success",
  warn: "warn",
  warning: "warning",
  info: "info",
  error: "error",
}

export const showToast = (msg, options) => {
  let _options = { ...configToast, ...options }
  let _type = options.type || ToastType.success
  switch (_type) {
    case ToastType.success:
      toast.success(msg, _options)
      break
    case ToastType.warn:
      toast.warn(msg, _options)
      break
    case ToastType.warning:
      toast.warn(msg, _options)
      break
    case ToastType.info:
      toast.info(msg, _options)
      break
    case ToastType.error:
      toast.error(msg, _options)
      break
  }
}

export const showToastWithCheckMsg = (
  msg,
  defaultType = ToastType.success,
  opts: any = null
) => {
  if (msg) {
    if (msg.startsWith("[")) {
      if (msg.startsWith("[Warning]")) {
        if (opts?.uiConfig?.msgWarningType === "alert") {
          showAlert({
            component: <div>{msg.replace("[Warning]", "")}</div>,
            className: "modal-dialog-alert",
            opts: {
              showFooter: true,
              titleBtnClose: "Đã hiểu",
              showFooterClose: true,
              modalCentered: true,
            },
          })
        } else {
          showToast(msg.replace("[Warning]", ""), {
            type: ToastType.warning,
          })
        }
      } else if (msg.startsWith("[Error]")) {
        showToast(msg.replace("[Error]", ""), {
          type: ToastType.error,
        })
      } else if (msg.startsWith("[Info]")) {
        showToast(msg.replace("[Info]", ""), {
          type: ToastType.info,
        })
      } else if (msg.startsWith("[Success]")) {
        showToast(msg.replace("[Success]", ""), {
          type: ToastType.success,
        })
      } else if (msg.startsWith("[Alert]")) {
        showDialog({
          title: HCF.getCF("title") || "Notice",
          msg: msg.replace("[Alert]", ""),
        })
      } else if (msg.startsWith("[System]") && window.alert) {
        window.alert(msg.replace("[System]", ""))
      } else {
        showToast(msg, {
          type: defaultType,
        })
      }
    } else {
      showToast(msg, {
        type: defaultType,
      })
    }
  }
}

export const showToastSuccess = (msg, opts: any = null) => {
  showToastWithCheckMsg(msg, ToastType.success, opts)
}

export const showToastWarning = msg => {
  showToastWithCheckMsg(msg, ToastType.warning)
}

export const showToastError = msg => {
  showToastWithCheckMsg(msg, ToastType.error)
}

export const showToastLikeToast = msg => {
  if (msg) {
    showToast(msg, {
      type: ToastType.success,
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        background: "black",
        color: "white",
      },
    })
  }
}

export const showApiMsg = (msg, opts: any = null) => {
  if (msg) {
    showToastSuccess(msg, opts)
  }
}

export const hideInMyModalFromProps = props => {
  if (props && props.inMyModal && props.inMyModal.hide) {
    props.inMyModal.hide()
  }
}

export const showDialog = (configDialog: any) => {
  console.warn("showDialog", configDialog)
  MyDialog.show({
    ...configDialog,
  })
}
export const showAlert = ({ component, className = "", opts = {} }) => {
  MyModal.showMyDialog({
    title: "",
    component: component,
    size: "md",
    showFooter: false,
    showHeader: false,
    modalClassName: className,
    ...opts,
  })
}
