export const LOGOUT = "app/LOGOUT"
export const FETCH_FS_PENDING = "FETCH_FS_PENDING"
export const FETCH_FS_SUCCESS = "FETCH_FS_SUCCESS"
export const FETCH_FS_ERROR = "FETCH_FS_ERROR"
export const INIT_FS_DATA = "INIT_FS_DATA"

export const SIDE_NAV_CLOSE = "app/SIDE_NAV_CLOSE"
export const SIDE_NAV_UPDATE = "app/SIDE_NAV_UPDATE"
export const SIDE_NAV_REPLACE = "app/SIDE_NAV_REPLACE"

export const HEADER_TITLE_UPDATE = "app/HEADER_TITLE_UPDATE"
export const HEADER_QUICKOVERVIEW_UPDATE = "app/HEADER_QUICKOVERVIEW_UPDATE"
export const NAVBAR_UITYPE_UPDATE = "app/NAVBAR_UITYPE_UPDATE"
