import classNames from "classnames"
import { Button } from "reactstrap"
import { MyModal, MyUI } from "src/core/components"
import styled from "styled-components"

export const UITypes = {
  part_with_icon: (data, config) => {
    const {
      icon,
      fieldData,
      title,
      fnOnClick,
      moreStyleIcon = {},
    } = config || {}

    return (
      <WrapPartWithIcon
        onClick={() => {
          fnOnClick && fnOnClick()
        }}
        className={classNames(fnOnClick ? "cursor-pointer" : "")}
      >
        <div className="d-flex wrap-title">
          {icon && <i className={icon} style={moreStyleIcon} />}
          <div className="label">{title}</div>
        </div>
        <i className="flaticon-right-arrow mauden" />
      </WrapPartWithIcon>
    )
  },
  call_with_icon: (data, config) => {
    const { icon, fieldData, title, fnOnClick, mobileNumber } = config || {}
    const number = data?.[fieldData] || mobileNumber || ""
    return (
      <CallWithIcon
        onClick={() => {
          fnOnClick && fnOnClick()
        }}
        className={classNames(fnOnClick ? "cursor-pointer" : "")}
        href={`tel:${number}`}
      >
        <div className="d-flex wrap-title">
          {icon && <i className={icon} />}
          <div className="label-title">{title}</div>
        </div>
        <i className="flaticon-right-arrow" />
      </CallWithIcon>
    )
  },
  popup_html: (data, config) => {
    const { icon, title, description, fieldData } = config || {}
    const _data = data?.[fieldData] || {}
    const fullDescription = _data?.Content || ""
    const onHandleLearnMore = () => {
      MyModal.showFromComponent(
        <div
          dangerouslySetInnerHTML={{
            __html: fullDescription,
          }}
        ></div>,
        {
          title: _data?.Title,
          modalClassName: "default modal-filter modal-scrollable-inside",
          onScrollContent: true,
          modalScrollInside: true,
        }
      )
    }
    return (
      <WrapPartWithModal>
        {_data?.Title && (
          <div className="label mb-10px fw-600">{_data?.Title}</div>
        )}
        {_data?.Description && (
          <div
            className={classNames(
              "description",
              fullDescription ? "mb-10px" : ""
            )}
            dangerouslySetInnerHTML={{ __html: _data?.Description }}
          ></div>
        )}
        {fullDescription && (
          <>
            {config?.showFull ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: fullDescription,
                }}
              ></div>
            ) : (
              <Button
                color="underline-black"
                className="btn-more"
                onClick={onHandleLearnMore}
              >
                Tìm hiểu thêm
              </Button>
            )}
          </>
        )}
      </WrapPartWithModal>
    )
  },
  cancel_order: (data, config, { fnList, buttonCode }) => {
    const { icon, fieldData, title } = config || {}
    let btnConfig = fnList?.getButtonConfigByCode(buttonCode?.Cancel)
    btnConfig = { ...btnConfig, Name: title, icon: icon, Code: "NewCancel" }
    const buttonList = [{ ...btnConfig }]
    return (
      <MyUI
        type="tripdetail_action"
        data={data}
        fnList={fnList}
        buttonList={buttonList}
      />
    )
  },
}
export const WrapPartWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .wrap-title {
    align-items: center;
    width: calc(100% - 20px);
    padding-right: 10px;
    color: var(--mauden);
    i {
      display: flex;
      font-size: 20px;
      margin-right: 10px;
      height: 25px;
      align-items: center;
    }
  }
  & i.flaticon-right-arrow {
    font-size: 14px;
    display: flex;
  }
  @media (max-width: 996px) {
    & .wrap-title {
      i {
        margin-right: 16px;
      }
    }
  }
`
const CallWithIcon = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--mauden);
  & .wrap-title {
    align-items: center;
    width: calc(100% - 20px);
    padding-right: 10px;
    i {
      display: flex;
      font-size: 20px;
      color: #535151;
      margin-right: 10px;
    }
  }
  & i.flaticon-right-arrow {
    font-size: 14px;
    display: flex;
  }
  @media (max-width: 996px) {
    & .wrap-title {
      i {
        margin-right: 16px;
      }
    }
  }
`
const WrapPartWithModal = styled.div`
  & .btn-underline-black {
    font-weight: 600;
    font-size: 16px;
  }
  & .btn-underline-black,
  & .btn-underline-black:hover,
  & .btn-underline-black:active,
  & .btn-underline-black:focus {
    font-weight: 600;
    font-size: 16px;
  }
`
