import React from "react"
import { Input } from "reactstrap"
class InputCheckbox extends React.Component {
  render() {
    return (
      <div className="form-switch">
        <Input {...this.props} type="checkbox" />
      </div>
    )
  }
}

export default InputCheckbox
