import classNames from "classnames"
import { Button } from "reactstrap"
import { MyModal } from "src/core/components"
import { isNotEmpty } from "src/core/helpers/utils"
import { historyFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"
import RentalPriceDetails from "./RetalPriceDetails"
import SurchargeDetails from "./SurchargeDetails"
import { UITypes } from "./UITypes"

const PaymentInfo = props => {
  const { data, fnList } = props
  const amountRemain = data?.[historyFields.fAmountRemain]
  const deposit = data?.[historyFields.fDepositAmount]
  const total = data?.[historyFields.fTotalPrice]

  const getPartsConfig = () => {
    return [
      {
        type: "part_with_icon",
        canShow: true,
        classWrap: "devitem-line",
        title: "Chi phí có thể phát sinh bạn cần biết",
        dataField: "",
        icon: "flaticon-addition",
        fnOnClick: () => {
          handleOpenExtraSurcharges()
        },
        moreStyleIcon: {
          color: "#535151",
        },
      },
      {
        type: "part_with_icon",
        classWrap: "devitem-line",
        canShow: false,
        title: "Nhận phiếu thu",
        dataField: "",
        icon: "flaticon-addition",
      },
    ]
  }
  const handleOpenPriceDetail = () => {
    const rentalPrice = data?.[historyFields.fRentalPriceDetails]
    if (rentalPrice && rentalPrice?.length > 0) {
      const component = (
        <RentalPriceDetails data={rentalPrice} title="Chi tiết giá" />
      )
      return MyModal.showFromComponent(component, {
        size: "default",
        title: "Chi tiết giá",
        modalClassName: "default modal-filter",
        onScrollContent: true,
        // showHeader: false,
      })
    }
  }
  const handleOpenExtraSurcharges = () => {
    const extraSurcharges = data?.[historyFields.fExtraSurcharges]
    if (extraSurcharges && extraSurcharges?.length > 0) {
      const component = <SurchargeDetails data={extraSurcharges} />
      return MyModal.showFromComponent(component, {
        title: "Phụ phí có thể phát sinh",
        size: "default",
        modalClassName: "default modal-filter",
        onScrollContent: true,
        // showHeader: false,
      })
    }
  }
  const renderPart = () => {
    const parts = getPartsConfig()
    return (
      <>
        {parts &&
          parts?.length > 0 &&
          parts.map((v, i) => {
            if (!isNotEmpty(v?.canShow) || v?.canShow) {
              return (
                <div
                  className={classNames(
                    "mb-15px",
                    v?.classWrap ? v?.classWrap : ""
                  )}
                  key={`part-${i}`}
                >
                  {UITypes?.[v.type]
                    ? UITypes[v.type](data, v, {
                        fnList: {
                          ...fnList,
                        },
                      })
                    : null}
                </div>
              )
            }
            return <></>
          })}
      </>
    )
  }
  return (
    <Wrap className="payment-info">
      <div className="subtitle mb-16px">Thông tin thanh toán</div>
      <div className="wrap-price mauden">
        <div className="one-item">
          <div className="title-item">Thanh toán khi nhận xe</div>
          <div className="price-text">{amountRemain}</div>
        </div>
        <div className="one-item">
          <div className="title-item">Tiền cọc cần thanh toán</div>
          <div className="price-text bold">{deposit}</div>
        </div>
        <div className="wrap-total">
          <div className="one-item total">
            <div className="title-item">Tổng</div>
            <div className="price-text bold">{total}</div>
          </div>
          <div className="wrap-button">
            <Button color="underline-black" onClick={handleOpenPriceDetail}>
              Hiển thị chi tiết giá
            </Button>
          </div>
        </div>
      </div>
      {renderPart()}
    </Wrap>
  )
}
const Wrap = styled.div`
  & .wrap-price {
    padding: 0 10px;
  }
  & .one-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    &.total {
      font-weight: 600;
    }
    & .title-item {
      padding-right: 10px;
    }
  }
  & .wrap-total {
    border-top: 1px solid var(--color-divider);
    padding-top: 20px;
    margin-top: 10px;
    & .one-item {
      padding: 5px 0;
    }
    & .wrap-button {
      text-align: right;
    }
  }
  & .btn-underline-black {
    font-weight: 600;
  }
  & .btn-underline-black,
  & .btn-underline-black:hover,
  & .btn-underline-black:active,
  & .btn-underline-black:focus {
    font-weight: 600;
  }
`
export default PaymentInfo
