import { HCF, HLocalStorage, HUtils } from "@macashipo/mlib"
import { HApi } from "../helpers"
import { kFirstSettingData } from "src/modules/app_sigo/constants"
interface IModuleFirstSetting extends ICore.IOneModule {
  requestFirstSetting: () => void
  parseFirstSetting: (data: object) => void
}
const ModuleFirstSetting: IModuleFirstSetting = {
  _key: "first_setting",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "first_setting"
  },
  runAfterInit: (HConfig: any) => {
    ModuleFirstSetting.requestFirstSetting()
  },

  requestFirstSetting: () => {
    let _uiAppConfig =
      HUtils.runFuntion(HCF.CF, "fnGetRequestUIAppConfigFirstSetting") || {}
    HApi.apiPost({
      path: "GlobalAppSetting",
      name: "FisrtSetting",
      data: {
        UIAppConfig: _uiAppConfig,
      },
    })
      .then(response => {
        if (response.Data) {
          ModuleFirstSetting.parseFirstSetting(response.Data)
        }
      })
      .catch(error => {})
  },
  parseFirstSetting: data => {
    if (data) {
      HCF.setCFMultiObj(data)
      if (data["DefaultValues"]) {
        HCF.setCFMultiObj(data["DefaultValues"])
        if (HCF?.CF?.["afterCallFirstSetting"]) {
          HCF?.CF?.["afterCallFirstSetting"]({ ...data })
        }
      }
      if (data["UIAppConfig"]) {
        HCF.setCFMultiObj(data["UIAppConfig"])
      }

      // let _fnWaitingFooter = HCF.getCF("waitingFirstSettingForFooter")
      // if (_fnWaitingFooter) {
      //   _fnWaitingFooter()
      // }
      // let _fnWaitingHeader = HCF.getCF("waitingFirstSettingForHeader")
      // if (_fnWaitingHeader) {
      //   _fnWaitingHeader()
      // }
      // let _fnWaitingDownloadInfo = HCF.getCF(
      //   "waitingFirstSettingForDownloadInfo"
      // )
      // if (_fnWaitingDownloadInfo) {
      //   _fnWaitingDownloadInfo()
      // }
    }
  },
}

export default ModuleFirstSetting
