import React from "react"
import MyFormControlHelpers from "src/core/components/MyForm/MyFormControlHelpers"

const MFCOnlyLabel = React.lazy(() => import("./Types/only_label"))

const List = {
  only_label: MFCOnlyLabel,
}
const MyControlForm = {
  init() {
    MyFormControlHelpers.addTypesFromListLazy(List)
  },
}

export default MyControlForm
