import React, { useRef, useState } from "react"
import { MyForm, MyModal } from "src/core/components"
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"
import styled from "styled-components"
import { isArray } from "src/core/helpers/utils"
import Skeleton from "react-loading-skeleton"

interface IProps {
  title: string
  icon?: any
  actionType: string
  configForm: any
  fnList: any
  more?: any
  configModal?: any
  classNameForm?: string
  classNameWrapForm?: string
  isShowSkeleton?: any
}
const ModalForm = props => {
  const {
    refMyForm,
    classNameWrapForm,
    classNameForm,
    configForm,
    config,
    fnList,
  } = props
  return (
    <WrapModalForm className={classNameWrapForm}>
      <MyForm
        className={classNameForm}
        ref={refMyForm}
        configForm={configForm}
        fnList={fnList}
        renderFooter={() => {
          if (config?.showFooter) {
            return (
              <div className="d-flex justify-space-between">
                <button
                  className="btn btn-primary mb-0"
                  onClick={() => {
                    if (fnList?.onSubmitForm) {
                      fnList.onSubmitForm()
                    }
                  }}
                >
                  Áp dụng
                </button>
              </div>
            )
          }
          return <></>
        }}
      />
    </WrapModalForm>
  )
}
const MUIButtonForm = (props: IProps) => {
  const {
    title,
    icon,
    classNameWrapForm,
    classNameForm,
    actionType,
    configForm,
    fnList,
    configModal,
  } = props

  const getActiveButton = () => {
    const { defaultValues, controls } = configForm || {}
    const result = controls?.findIndex(v => {
      if (
        isArray(defaultValues[v.fieldName]) &&
        !defaultValues[v.fieldName]?.length
      ) {
        return false
      }
      return defaultValues[v.fieldName]
    })
    if (result >= 0) {
      return true
    }
    return false
  }

  const [open, setOpen] = useState(false)
  const refMyForm = useRef<MyForm>(null)
  const isActive = getActiveButton()
  if (actionType === "dropdown") {
    const toggle = () => {
      setOpen(prevState => !prevState)
    }
    return (
      <Wrap>
        <Dropdown
          isOpen={open}
          toggle={toggle}
          className=" mr-10px item cursor-context"
        >
          <DropdownToggle
            color="default"
            className="dropdown-toggle btn-radius"
          >
            {title} <span className="caret"></span>
          </DropdownToggle>
          <DropdownMenu>
            <MyForm
              ref={refMyForm}
              configForm={configForm}
              fnList={fnList}
              renderFooter={() => {
                return (
                  <div className="d-flex justify-space-between">
                    <button
                      className="btn btn-primary mb-0"
                      onClick={() => {
                        if (fnList?.onFilter && refMyForm?.current) {
                          fnList.onFilter({
                            ...refMyForm.current.getCurrentValues(),
                          })
                          toggle()
                        }
                      }}
                    >
                      Áp dụng
                    </button>
                  </div>
                )
              }}
            />
          </DropdownMenu>
        </Dropdown>
      </Wrap>
    )
  }
  return (
    <Wrap>
      <button
        className={`btn btn-default mr-10px item btn-radius modal-toggle ${
          isActive ? "btn-active" : ""
        }`}
        onClick={() => {
          MyModal.showFromComponent(
            <ModalForm
              refMyForm={refMyForm}
              configForm={configForm}
              classNameWrapForm={classNameWrapForm}
              classNameForm={classNameForm}
              fnList={{
                ...fnList,
                onSubmitForm: () => {
                  if (fnList?.onFilter && refMyForm?.current) {
                    fnList.onFilter({
                      ...refMyForm.current.getCurrentValues(),
                    })
                    MyModal.hideModalComponent()
                  }
                },
              }}
              config={{
                showFooter: !configModal?.showFooter,
              }}
            />,
            {
              size: " ",
              modalClassName: "default",
              onClickClose: () => {
                if (refMyForm?.current) {
                  const controls = configForm?.controls
                  if (controls?.length > 0) {
                    controls.forEach(v => {
                      if (
                        v?.fieldName &&
                        refMyForm.current?.fnListFromExtControl
                      ) {
                        refMyForm.current.fnListFromExtControl[
                          v.fieldName
                        ].updateValue(null)
                      }
                    })
                    refMyForm.current.forceUpdate()
                  }
                }
              },
              onClickOK: () => {
                if (fnList?.onFilter && refMyForm?.current) {
                  fnList.onFilter({
                    ...refMyForm.current.getCurrentValues(),
                  })
                  MyModal.hideModalComponent()
                }
              },
              ...configModal,
            }
          )
        }}
      >
        {icon}
        {title}
      </button>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: fit-content;
  & .dropdown-toggle,
  & .modal-toggle {
    &:focus,
    &:active {
      box-shadow: none !important;
    }
    &:after {
      display: none;
    }
  }
  & .dropdown-menu {
    &.show {
      top: unset !important;
      transform: none !important;
      width: max-content;
    }
  }
  & .btn-default > span:first-child {
    display: none;
  }
`
const WrapModalForm = styled.div`
  & label {
    font-weight: 700;
  }
`
export default MUIButtonForm
