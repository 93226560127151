import React from "react"
import { CSuppense } from "@macashipo/mlib"
import MyFormControlHelpers from "src/core/components/MyForm/MyFormControlHelpers"

const V1_mfc_mycontrol = React.lazy(() => import("./Types/v1_mfc_mycontrol"))
const V1_mfc_group = React.lazy(() => import("./Types/v1_mfc_group"))
const V1_mfc_group_row = React.lazy(() => import("./Types/v1_mfc_group_row"))
const V1_mfc_lable_control_inline = React.lazy(
  () => import("./Types/v1_mfc_lable_control_inline")
)
const MyFormOne = {
  init() {
    MyFormControlHelpers.addTypes("v1_mfc_text", props => {
      return CSuppense({ ...props, typeControl: "text" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_password", props => {
      return CSuppense({ ...props, typeControl: "password" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_textarea", props => {
      return CSuppense({ ...props, typeControl: "textarea" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_select", props => {
      return CSuppense({ ...props, typeControl: "select" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_checkbox", props => {
      return CSuppense({ ...props, typeControl: "checkbox" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_switch", props => {
      return CSuppense({ ...props, typeControl: "switch" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_radio", props => {
      return CSuppense({ ...props, typeControl: "radio" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_range", props => {
      return CSuppense({ ...props, typeControl: "range" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_color", props => {
      return CSuppense({ ...props, typeControl: "color" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_date", props => {
      return CSuppense({ ...props, typeControl: "date" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_time", props => {
      return CSuppense({ ...props, typeControl: "time" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_select_checkbox", props => {
      return CSuppense(
        { ...props, typeControl: "select_checkbox" },
        V1_mfc_mycontrol
      )
    })
    MyFormControlHelpers.addTypes("v1_mfc_select_radio", props => {
      return CSuppense(
        { ...props, typeControl: "select_radio" },
        V1_mfc_mycontrol
      )
    })
    MyFormControlHelpers.addTypes("v1_mfc_select2", props => {
      return CSuppense({ ...props, typeControl: "select2" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_select2_customfilter", props => {
      return CSuppense(
        { ...props, typeControl: "select2_customfilter" },
        V1_mfc_mycontrol
      )
    })
    MyFormControlHelpers.addTypes("v1_mfc_date_flatpickr", props => {
      return CSuppense(
        { ...props, typeControl: "date_flatpickr" },
        V1_mfc_mycontrol
      )
    })
    MyFormControlHelpers.addTypes("v1_mfc_group", props => {
      return CSuppense(props, V1_mfc_group)
    })
    MyFormControlHelpers.addTypes("v1_mfc_group_row", props => {
      return CSuppense(props, V1_mfc_group_row)
    })
    MyFormControlHelpers.addTypes("v1_mfc_component", props => {
      return CSuppense({ ...props, typeControl: "component" }, V1_mfc_mycontrol)
    })
    MyFormControlHelpers.addTypes("v1_mfc_lable_control_inline", props => {
      return CSuppense(props, V1_mfc_lable_control_inline)
    })
    //
    MyFormOne.mapWithV1()

    //check
    // console.warn("MyFormControlHelpers:", MyFormControlHelpers)
  },
  mapWithV1() {
    MyFormControlHelpers.Types["text"] =
      MyFormControlHelpers.Types["v1_mfc_text"]
    MyFormControlHelpers.Types["password"] =
      MyFormControlHelpers.Types["v1_mfc_password"]
    MyFormControlHelpers.Types["textarea"] =
      MyFormControlHelpers.Types["v1_mfc_textarea"]
    MyFormControlHelpers.Types["select"] =
      MyFormControlHelpers.Types["v1_mfc_select"]
    MyFormControlHelpers.Types["checkbox"] =
      MyFormControlHelpers.Types["v1_mfc_checkbox"]
    MyFormControlHelpers.Types["radio"] =
      MyFormControlHelpers.Types["v1_mfc_radio"]
    MyFormControlHelpers.Types["color"] =
      MyFormControlHelpers.Types["v1_mfc_color"]
    MyFormControlHelpers.Types["range"] =
      MyFormControlHelpers.Types["v1_mfc_range"]
    MyFormControlHelpers.Types["date"] =
      MyFormControlHelpers.Types["v1_mfc_date"]
    MyFormControlHelpers.Types["time"] =
      MyFormControlHelpers.Types["v1_mfc_time"]
  },
}

export default MyFormOne
