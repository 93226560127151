import React from "react"
import { Input } from "reactstrap"
class InputDate extends React.Component {
  render() {
    return <Input {...this.props} type="date" />
  }
}

//value: string
//defaultValue: string
//onChange: value = string

export default InputDate
