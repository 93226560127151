import React, { useEffect } from "react"
import {
  Route,
  RouteProps,
  Redirect,
  RouteComponentProps,
} from "react-router-dom"
import { connect } from "react-redux"
import { HAuth, HLocalStorage } from "src/core/helpers"
import ModuleCheckIdle from "src/modules/check_idle"
interface ReduxProps {
  isAuthenticated: boolean
}
interface Props extends ReduxProps, RouteProps {
  component: React.ComponentType<RouteComponentProps>
  redirectTo?: any
}

function AuthenticatedGuard(props: Props) {
  const { isAuthenticated, component: Component, redirectTo, ...rest } = props
  useEffect(() => {
    ModuleCheckIdle._fnList.checkIdleAfterInit()
  })
  if (redirectTo) {
    const searchParams = window.location.search
    let newUrl = redirectTo
    if (searchParams) {
      newUrl += searchParams
    }
    return <Redirect to={newUrl} />
  }
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated && !HAuth.authIsLogged()) {
          // const currentSolution = HLocalStorage.getString("apply_solution", "")
          // if (currentSolution) {
          //   return <Redirect to="/register" />
          // }
          return <Redirect to="/" />
        }
        return <Component {...props} />
      }}
    />
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.app.isAuthenticated,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedGuard)
