import React from "react"
import MainLayout from "src/layouts/MainLayout"
import { ConfigPage, MyPageLayoutHelpers } from "@macashipo/mlib"

MyPageLayoutHelpers.setDefaultMyPageLayout((props: any) => {
  return <MainLayout>{props.children}</MainLayout>
})
MyPageLayoutHelpers.addTypes("blank", (props: any) => {
  return <div>{props.children}</div>
})
function PageGeneric(props) {
  const configPage = ConfigPage.createFromConfigPageServer(props.configPage)
  const CMyPage = configPage.getMyPage(props)
  const CLayout = configPage.getWrapMyPageLayout({
    ...props,
    children: CMyPage,
  })
  return <MainLayout {...props?.configMainLayout}>{CMyPage}</MainLayout>
}

export default PageGeneric
