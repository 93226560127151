import { HUtils, HCF } from "@macashipo/mlib"
import LodashGet from "lodash/get"
import LodashDebounce from "lodash/debounce"
import { logOne } from "./log"
import K from "src/helpers/K"
import HConstant from "./constant"

export const isFunction = (v: any) => {
  return typeof v === "function"
}
export const isArray = (v: any) => {
  return Array.isArray(v)
}
export const isString = (v: any) => {
  return typeof v === "string"
}
export const isDateValue = (v: any) => {
  return new Date(v).getTime() > 0
}
export const isNotEmpty = (v: any) => {
  return v !== null && v !== undefined && v !== "" ? true : false
}
export const isNotEmptyIncludeZero = (v: any) => {
  return isNotEmpty(v) && v !== 0 ? true : false
}
export const mergeObjIfExist = (srcObj, mergeObj) => {
  if (srcObj && mergeObj) {
    let _keys = Object.keys(mergeObj)
    if (_keys && _keys.length > 0) {
      for (let k of _keys) {
        srcObj[k] = mergeObj[k]
      }
    }
  }
}
export const runFuntion = (fnList: any, name, args: any[] = []) => {
  if (fnList && isFunction(fnList[name])) {
    fnList[name].apply(null, args)
  } else {
    logOne("runFuntion failed:", fnList, name)
  }
}
export const safeInvoke = (fn, args: any[] = []) => {
  if (fn) {
    return fn.apply(null, args)
  }
}
export const initMCore = (key, obj) => {
  if (window) {
    if (window["MCore"] == null) {
      window["MCore"] = {}
    }
    window["MCore"][key] = obj
  }
}
export const addArray2Array = (arr, moreArr) => {
  if (arr && moreArr) {
    // arr = arr.concat(moreArr);//ko dung concat duoc, chua  biet tai sao
    arr.push(...moreArr)
  }
}
export const objHasKey = (obj, key) => {
  if (obj && key && obj.hasOwnProperty) {
    return obj.hasOwnProperty(key)
  }
}
// export const combineArray = (array1, array2) => {
//   return [...array1, ...array2]
// }

// export const combineArray = (array1, array2) => {
//   return [].concat(array1, array2)
// }
export const isLocalDev = () => {
  if (window.location.href.indexOf("localhost:") > -1) {
    return true
  }
  if (localStorage.getItem(HConstant.Dev.Key._DEBUG_) === "true") {
    return true
  }
  return false
}
export const onKeyDownEnter = (ev, fn) => {
  if (ev.key === "Enter") {
    fn()
  }
}
export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
export const debounce = (fn, time, opts) => {
  return LodashDebounce(fn, time, opts)
}
export const get = LodashGet
export const getTextWithData = HUtils.getTextWithData
export const checkConditionShow = (value: any, conditionValue) => {
  // console.warn("checkConditionShow:", value, conditionValue)
  let _canShow = true
  if (isArray(conditionValue)) {
    _canShow = false
    for (let i = 0; i < conditionValue.length; i++) {
      let _check = checkConditionShow(value, conditionValue[i])
      if (_check === true) {
        //chi can 1 cai true thi se true
        return true
      }
    }
  } else {
    if (isArray(value)) {
      if (value.indexOf(conditionValue) === -1) {
        return false
      }
    } else if (value !== conditionValue) {
      return false
    }
  }
  return _canShow
}
export const checkConditionShowWithOperator = (
  value: any,
  conditionValue,
  operator,
  format: any
) => {
  // console.warn("checkConditionShow:", value, conditionValue)
  let _valueNeedCmp = value
  let _canShow = false
  if (operator === "!=" && _valueNeedCmp !== undefined) {
    if (_valueNeedCmp !== conditionValue) {
      _canShow = true
    }
  }
  if (_valueNeedCmp !== null && _valueNeedCmp !== undefined) {
    if (format?.type) {
      switch (format.type) {
        case "year":
          if (
            format?.cmpWith === "now" &&
            new Date().getFullYear() >= new Date(_valueNeedCmp).getFullYear()
          ) {
            _valueNeedCmp =
              new Date().getFullYear() - new Date(_valueNeedCmp).getFullYear()
          }
          break

        default:
          break
      }
    }
    if (typeof operator === "function") {
      return operator(_valueNeedCmp)
    }
    switch (operator) {
      case ">=":
        if (_valueNeedCmp >= conditionValue) {
          _canShow = true
        }
        break
      case ">":
        if (_valueNeedCmp > conditionValue) {
          _canShow = true
        }
        break
      case "!=":
        if (_valueNeedCmp !== conditionValue) {
          _canShow = true
        }
        break
      case "=":
        if (_valueNeedCmp === conditionValue) {
          _canShow = true
        }
        break
      case "<":
        if (_valueNeedCmp < conditionValue) {
          _canShow = true
        }
        break
      case "<=":
        if (_valueNeedCmp <= conditionValue) {
          _canShow = true
        }
        break
      default:
        break
    }
  }
  return _canShow
}
export const isValidEmail = str => {
  const regexCheck =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
  if (str) {
    return regexCheck.test(str)
  }
}
export const isValid_AUS_PhoneNumber = str => {
  let _pattern = HCF.getCF(K.mobileNumberValidRegexPattern)
  if (_pattern) {
    let _regex = new RegExp(_pattern, "")
    if (str) {
      return _regex.test(str)
    }
  }
  return true
  // const regexCheck = /^(0[4|5])([0-9]{8})$/
  // if (str) {
  //   return regexCheck.test(str)
  // }
}
export const isValidUsername = str => {
  const regexCheck = /^[\w]+$/
  if (str) {
    return regexCheck.test(str)
  }
}
export const xorCrypt = function (str: string = "", key: number = 7) {
  var output = ""
  if (!key) {
    key = 6
  }
  for (var i = 0; i < str.length; ++i) {
    output += String.fromCharCode(key ^ str.charCodeAt(i))
  }
  return output
}
export const compareValueEqual = (v1, v2) => {
  if (isNotEmpty(v1) && isNotEmpty(v2)) {
    if (typeof v1 === "string" && typeof v2 === "string") {
      let v1Cmp = v1.trim().toLowerCase()
      let v2Cmp = v2.trim().toLowerCase()
      if (v1Cmp === v2Cmp) {
        return true
      }
    } else if (v1 === v2) {
      return true
    }
  }
  return false
}
export const convertArrayString2String = (arr: any) => {
  let string = ""
  if (Array.isArray(arr) && arr?.length > 0) {
    arr.forEach((v, i) => {
      if (typeof v === "string") {
        string += v
        if (i < arr.length - 1) {
          string += ","
        }
      }
    })
  }
  return string
}
export const convertString2ArrayString = (str: any) => {
  let arr: any = []
  if (typeof str === "string" && isNotEmpty(str)) {
    const strSplit = str.split(",")
    if (strSplit?.length > 0) {
      strSplit.forEach(v => {
        if (typeof v === "string") {
          arr.push(v.trim())
        }
      })
    }
  }
  return arr
}
export const isOnScreen = function (element) {
  if (element && element.offsetTop != null) {
    var curTop = element.offsetTop
    var heightOfWindow = window.innerHeight
    var offsetYOfWindow = window.scrollY
    if (curTop > offsetYOfWindow && curTop < heightOfWindow + offsetYOfWindow) {
      return true
    }
  }
  return false
}
export const squareBracketReplace = function (s, obj) {
  const regex = /\[(.[^\]]*)\]/gm
  const extract = s => {
    var _result: any = []
    if (s != null) {
      var _m
      while ((_m = regex.exec(s)) !== null) {
        if (_m.length > 1) {
          _result.push(_m[1])
        }
      }
    }
    return _result
  }
  let ext = extract(s)
  let newS = s
  if (ext != null) {
    for (var _item of ext) {
      if (obj[_item] != null) {
        var rg = new RegExp(`\\[${_item}\\]`, "g")
        newS = newS.replace(rg, obj[_item])
      }
    }
  }
  return newS
}
export const isHTMLString = s => {
  const pattern = "<([a-z]+)(?![^>]*/>)[^>]*>"
  const regex = new RegExp(pattern, "")
  if (s) {
    return regex.test(s)
  }
  return false
}
window["_xorCrypt"] = xorCrypt
