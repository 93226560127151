import { MyLibHelpers } from "@macashipo/mlib"
import React from "react"

const MyLib_react_google_places_autocomplete = React.lazy(() =>
  import("./Types/react_google_places_autocomplete")
)
const MyLib_react_google_places_autocomplete_custom = React.lazy(() =>
  import("./Types/react_google_places_autocomplete_custom")
)
const MyLib_react_google_places_autocomplete_custom_dropdown = React.lazy(() =>
  import("./Types/react_google_places_autocomplete_custom_v2")
)
const MyLib_react_google_places_autocomplete_sg_mobile = React.lazy(() =>
  import("./Types/search_address_mobile")
)
const List = {
  react_google_places_autocomplete: MyLib_react_google_places_autocomplete,
  react_google_places_autocomplete_custom:
    MyLib_react_google_places_autocomplete_custom,
  react_google_places_autocomplete_custom_dropdown: MyLib_react_google_places_autocomplete_custom_dropdown,
  react_google_places_autocomplete_sg_mobile:MyLib_react_google_places_autocomplete_sg_mobile
}
const MyLibOne = {
  init() {
    MyLibHelpers.addTypesFromListLazy(List)
  },
}

export default MyLibOne
