// import LodashDebounce from "lodash/debounce"
import LodashThrottle from "lodash/throttle"
import { MyModal } from "src/core/components"
import { HCF, HLocalStorage, HUtils, HModules, MyUI } from "@macashipo/mlib"
import {
  authLogoutWithNothing,
  authIsLogged,
  registerAfterLogout,
} from "src/core/helpers/auth"
import { HConstant } from "src/core/helpers"
import MyUIOne from "./MyUI"
const KCheckIdle = {
  // kCheckIdleLocal: "kCheckIdleLocal",
  checkIdleLocalTimeIdle: "checkIdleLocalTimeIdle",
  hasCheckIdle: "hasCheckIdle",
  checkIdleSecond: "checkIdleSecond",
  checkIdleInterval: "checkIdleInterval",
  checkIdleCountdown: "checkIdleCountdown",
  _noCheckIdle: "_noCheckIdle",
}
const ModuleCheckIdle = {
  _key: "check_idle",
  _fnList: {
    resetTimerDebounce: null,
    onIdle: () => {
      console.log("on Idle")
      clearInterval(ModuleCheckIdle._config.timer)
      document.removeEventListener(
        "mousemove",
        ModuleCheckIdle._fnList.resetTimerDebounce
      )
      document.removeEventListener(
        "touchmove",
        ModuleCheckIdle._fnList.resetTimerDebounce
      )
      document.removeEventListener(
        "keydown",
        ModuleCheckIdle._fnList.resetTimerDebounce
      )
      MyModal.showFromComponent(
        props => {
          return (
            <MyUI
              {...props}
              type="checkidle_countdown"
              countSeconds={ModuleCheckIdle._config.countdown}
            />
          )
        },
        {
          size: "xs",
          showHeader: false,
          modalCentered: true,
          backdrop: "static",
          fnList: {
            onTimeout: () => {
              console.log("timeout")
              HLocalStorage.removeItem(KCheckIdle.checkIdleLocalTimeIdle)
              authLogoutWithNothing()
            },
            onSignOut: () => {
              console.log("onSignOut")
              HLocalStorage.removeItem(KCheckIdle.checkIdleLocalTimeIdle)
              authLogoutWithNothing()
              window.location.reload()
            },
            onKeepSignIn: () => {
              console.log("onKeepSignIn")
              ModuleCheckIdle._fnList.start()
            },
            onBackToSignIn: () => {
              console.log("onBackToSignInonBackToSignIn")
              window.location.reload()
            },
          },
        }
      )
      // MyModal.showMyDialog({
      //   title: "Session Expire Warning",
      //   msg: `You're being timed out due to inactivity. \nYou will logged off automatically`,
      //   size: "xs",
      //   modalCentered: true,
      //   backdrop: "static",
      //   showHeader: false,
      //   showFooter: true,
      //   showFooterOK: true,
      //   classNameBody: "text-pre-line",
      //   onClickOK: () => {
      //     console.log("ok")
      //     window.location.reload()
      //   },
      // })
    },
    updateTimeIdle: () => {
      let _current = new Date().getTime()
      _current +=
        (HCF.getCF(KCheckIdle.checkIdleSecond) ||
          ModuleCheckIdle._config.idleAfterSecond) * 1000
      HLocalStorage.saveString(KCheckIdle.checkIdleLocalTimeIdle, _current)
    },
    start: () => {
      // ModuleCheckIdle._config.currentIdle = 0
      ModuleCheckIdle._fnList.updateTimeIdle()
      if (ModuleCheckIdle._config.timer) {
        clearInterval(ModuleCheckIdle._config.timer)
      }
      ModuleCheckIdle._config.timer = setInterval(
        ModuleCheckIdle._fnList.timerIncrement,
        ModuleCheckIdle._config.checkAfterSecond * 1000
      )
      document.addEventListener(
        "mousemove",
        ModuleCheckIdle._fnList.resetTimerDebounce
      )
      document.addEventListener(
        "touchmove",
        ModuleCheckIdle._fnList.resetTimerDebounce
      )
      document.addEventListener(
        "keydown",
        ModuleCheckIdle._fnList.resetTimerDebounce
      )
    },
    resetTimer: () => {
      // console.log("resetTimer")
      // ModuleCheckIdle._config.currentIdle = 0
      // HLocalStorage.removeItem(KCheckIdle.kCheckIdleLocal)
      ModuleCheckIdle._fnList.updateTimeIdle()
      //
    },
    timerIncrement: () => {
      // ModuleCheckIdle._config.currentIdle +=
      let _currentTime = new Date().getTime()
      let _currentTimeIdle = Number(
        HLocalStorage.getString(KCheckIdle.checkIdleLocalTimeIdle)
      )
      if (_currentTime >= _currentTimeIdle) {
        ModuleCheckIdle._fnList.onIdle()
      }
      console.log(
        "timer:",
        _currentTime,
        _currentTimeIdle,
        _currentTimeIdle - _currentTime,
        new Date(_currentTimeIdle)
      )
    },
    checkIdleAfterInit: () => {
      let _hasCheckIdle = HCF.getCF(KCheckIdle.hasCheckIdle)
      let _currentTime = new Date().getTime()
      let _previousIdleLocalTime = ModuleCheckIdle._config.previousIdleLocalTime
      console.log("previousIdleLocalTime:", _previousIdleLocalTime)
      if (_hasCheckIdle) {
        if (_previousIdleLocalTime && _currentTime >= _previousIdleLocalTime) {
          clearInterval(ModuleCheckIdle._config.timer)
          document.removeEventListener(
            "mousemove",
            ModuleCheckIdle._fnList.resetTimerDebounce
          )
          document.removeEventListener(
            "touchmove",
            ModuleCheckIdle._fnList.resetTimerDebounce
          )
          document.removeEventListener(
            "keydown",
            ModuleCheckIdle._fnList.resetTimerDebounce
          )
          HLocalStorage.removeItem(KCheckIdle.checkIdleLocalTimeIdle)
          MyModal.showFromComponent(
            props => {
              return (
                <MyUI
                  {...props}
                  type="checkidle_countdown"
                  onlyShowMsg={true}
                  countSeconds={1}
                />
              )
            },
            {
              size: "xs",
              showHeader: false,
              modalCentered: true,
              backdrop: "static",
              fnList: {
                onSignOut: () => {
                  console.log("onSignOut")
                  authLogoutWithNothing()
                  ModuleCheckIdle._config.previousIdleLocalTime = null
                  // window.location.reload()
                },
                onCloseAlert: () => {
                  MyModal.hideModalComponent()
                  // window.location.reload()
                },
              },
            }
          )
        } else {
          ModuleCheckIdle._config.previousIdleLocalTime = null
        }
      } else {
        HLocalStorage.removeItem(KCheckIdle.checkIdleLocalTimeIdle)
      }
    },
    checkIdleAfterLogin: () => {
      let _hasCheckIdle = HCF.getCF(KCheckIdle.hasCheckIdle)
      if (_hasCheckIdle === true) {
        console.log("check_idle run start after login")
        if (authIsLogged()) {
          let _noCheck = HLocalStorage.getString(KCheckIdle._noCheckIdle)
          if (_noCheck === "1") {
            return
          }
          ModuleCheckIdle._fnList.start()
        }
      }
    },
  },
  _config: {
    // currentIdle: 0,
    timer: null,
    countdown: 60,
    idleAfterSecond: 60 * 10, //10p
    checkAfterSecond: 5, //5s
    previousIdleLocalTime: HLocalStorage.getString(
      KCheckIdle.checkIdleLocalTimeIdle
    )
      ? Number(HLocalStorage.getString(KCheckIdle.checkIdleLocalTimeIdle))
      : null,
  },
  getMyKey: () => {
    return "check_idle"
  },
  runAfterInit: HConfig => {
    HModules.initDefault(ModuleCheckIdle, { MyUIOne })
    console.log("run check_idle")
    //
    // ModuleCheckIdle._fnList.resetTimerDebounce = LodashDebounce(
    //   ModuleCheckIdle._fnList.resetTimer,
    //   500
    // )
    ModuleCheckIdle._fnList.resetTimerDebounce = LodashThrottle(
      ModuleCheckIdle._fnList.resetTimer,
      500
    )
    //
    let _hasCheckIdle = HCF.getCF(KCheckIdle.hasCheckIdle)
    let _checkSecond = HCF.getCF(KCheckIdle.checkIdleSecond)
    let _checkCountdown = HCF.getCF(KCheckIdle.checkIdleCountdown)
    let _checkIdleInterval = HCF.getCF(KCheckIdle.checkIdleInterval)
    if (_checkSecond > 0) {
      ModuleCheckIdle._config.idleAfterSecond = _checkSecond
    }
    if (_checkIdleInterval > 0) {
      ModuleCheckIdle._config.checkAfterSecond = _checkIdleInterval
    }
    if (_checkCountdown > 0) {
      ModuleCheckIdle._config.countdown = _checkCountdown
    }
    if (_hasCheckIdle === true) {
      console.log("check_idle run start")
      if (authIsLogged()) {
        let _noCheck = HLocalStorage.getString(KCheckIdle._noCheckIdle)
        if (_noCheck === "1") {
          return
        }
        ModuleCheckIdle._fnList.start()
      } else {
        //waiting login
        HUtils.runFuntion(
          HConfig,
          HConstant.HConfig.FnList.registerAfterLogin,
          [
            () => {
              console.log("check idle: call request when after login ")
              ModuleCheckIdle._fnList.start()
            },
          ]
        )
      }
    }
    //after logout
    registerAfterLogout("afterlogout_checkidle", () => {
      let _currentTimeIdle = HLocalStorage.getString(
        KCheckIdle.checkIdleLocalTimeIdle
      )
      if (_currentTimeIdle != null) {
        HLocalStorage.removeItem(KCheckIdle.checkIdleLocalTimeIdle)
        window.location.reload()
      }
    })
  },
}
export default ModuleCheckIdle
