import classNames from "classnames"
import { MdOutlineClose } from "react-icons/md"
import { Button } from "reactstrap"
import { MyModal } from "src/core/components"
import styled from "styled-components"

const SurchargesDetails = props => {
  const { data, title } = props
  return (
    <Wrap className="surcharges">
      <div className="body">
        {data?.length > 0 &&
          data.map((v, i) => {
            return (
              <div className="one-surcharge" key={i}>
                <div className={classNames("one-item mauden")}>
                  <b className="title-item">{v?.TitleLeft}</b>
                  <b className="price-text">{v?.TitleRight}</b>
                </div>
                <div className="wrap-content">{v?.Content}</div>
              </div>
            )
          })}
      </div>
    </Wrap>
  )
}
const Wrap = styled.div`
  & .one-surcharge {
    border-bottom: 1px solid var(--color-border-dfdfdf);
    padding-bottom: 12px;
    margin-bottom: 15px;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
    & .wrap-content {
      font-size: 14px;
      line-height: 17px;
      padding-top: 7px;
    }
  }
  & .one-item {
    display: flex;
    justify-content: space-between;
  }
`
export default SurchargesDetails
