import React from "react"
import classnames from "classnames"
import { FormGroup, Label } from "reactstrap"
import InputRadio from "./InputRadio"
import Ext_Select from "./Ext_Select"
class SelectCheckbox extends Ext_Select {
  getMulti = () => {
    return false
  }
  render() {
    const sourceData = this.getSourceData()
    return (
      <div>
        {sourceData &&
          sourceData.length > 0 &&
          sourceData.map((v, i) => {
            return (
              <FormGroup
                className={classnames(
                  "mb-1 ",
                  this.getInLine() ? "form-check-inline" : "d-flex"
                )}
              >
                <InputRadio
                  style={{ minWidth: "1em" }}
                  name={this.getFieldName()}
                  checked={this.isChecked(v)}
                  onChange={() => {
                    this.onCheckOption(v)
                  }}
                />
                <Label
                  className="ms-2"
                  onClick={() => {
                    this.onCheckOption(v)
                  }}
                >
                  {v.Text}
                </Label>
              </FormGroup>
            )
          })}
      </div>
    )
  }
}

//value: string
//defaultValue: string
//onChange: value = string

export default SelectCheckbox
