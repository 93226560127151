import classNames from "classnames"
import { HLink } from "@macashipo/mlib"
import styled from "styled-components"
import { isNotEmpty } from "src/core/helpers/utils"
import { UITypes } from "./UITypes"

const SupportInfo = props => {
  const { data, fnList } = props
  const getDataConfig = () => {
    if (
      fnList?.getConfigFromFirstSettingByKey &&
      fnList?.getConfigFromFirstSettingByKey("supportInfo")
    ) {
      return fnList?.getConfigFromFirstSettingByKey("supportInfo")
    }
    return {}
  }
  const myData = getDataConfig()
  const getPartsConfig = () => {
    return [
      {
        type: "call_with_icon",
        canShow: true,
        classWrap: "devitem-line",
        title: "Gọi trợ giúp từ hotline của SIGO",
        dataField: "",
        icon: "flaticon-telephone",
        mobileNumber: myData?.callCenter,
      },
      {
        type: "part_with_icon",
        canShow: myData?.callCenter ? true : false,
        classWrap: "devitem-line",
        title: "Các câu hỏi thường gặp",
        dataField: "",
        icon: "flaticon-information-pamphlet",
        mobileNumber: myData?.callCenter,
        fnOnClick: () => {
          const link = myData?.linkCommonQuestion
          if (link) {
            HLink.openUrl(link, "_blank")
          }
        },
      },
    ]
  }
  const renderParts = () => {
    const parts = getPartsConfig()
    return (
      <>
        {parts &&
          parts?.length > 0 &&
          parts.map((v, i) => {
            if (!isNotEmpty(v?.canShow) || v?.canShow) {
              return (
                <div
                  className={classNames(
                    "mb-15px",
                    v?.classWrap ? v?.classWrap : ""
                  )}
                  key={`part-${i}`}
                >
                  {UITypes?.[v.type]
                    ? UITypes[v.type](data, v, {
                        fnList: {
                          ...fnList,
                        },
                      })
                    : null}
                </div>
              )
            }
            return <></>
          })}
      </>
    )
  }
  return (
    <Wrap className="support-info">
      <div className="subtitle mb-15px">{myData?.title}</div>
      <div className="description">{myData?.description}</div>
      {renderParts()}
    </Wrap>
  )
}
const Wrap = styled.div``
export default SupportInfo
