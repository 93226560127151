import React from "react"
const MC_ic_has_driver = props => {
  const { fill, style } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="102"
      height="104"
      viewBox="0 0 102 104"
      fill="none"
    >
      <path
        d="M3.1875 35.75H12.75V47.125H3.1875C2.34212 47.125 1.53137 46.7826 0.933597 46.1731C0.335825 45.5636 0 44.737 0 43.875V39C0 38.138 0.335825 37.3114 0.933597 36.7019C1.53137 36.0924 2.34212 35.75 3.1875 35.75Z"
        fill="#2F79F0"
      />
      <path
        d="M98.8125 47.125H89.25V35.75H98.8125C99.6579 35.75 100.469 36.0924 101.066 36.7019C101.664 37.3114 102 38.138 102 39V43.875C102 44.737 101.664 45.5636 101.066 46.1731C100.469 46.7826 99.6579 47.125 98.8125 47.125Z"
        fill="#2F79F0"
      />
      <path
        d="M92.4375 58.5H9.5625L14.8856 15.0475C15.1734 12.6864 16.298 10.5142 18.0476 8.9398C19.7971 7.36545 22.0509 6.49771 24.3844 6.50001H77.6156C79.9601 6.48177 82.2294 7.34233 83.9923 8.91816C85.7553 10.494 86.889 12.6753 87.1781 15.0475L92.4375 58.5Z"
        fill="#FFFEFF"
      />
      <path
        d="M84.0066 43.875L79.2253 35.75C78.9486 35.2612 78.5517 34.8542 78.0738 34.5693C77.596 34.2844 77.0536 34.1312 76.5 34.125H60.5625C60.009 34.1312 59.4665 34.2844 58.9887 34.5693C58.5108 34.8542 58.114 35.2612 57.8372 35.75L53.0559 43.875C52.7776 44.3664 52.6304 44.9236 52.6288 45.4911C52.6272 46.0586 52.7714 46.6166 53.0471 47.1096C53.3227 47.6026 53.7201 48.0135 54.1998 48.3013C54.6795 48.5891 55.2247 48.7438 55.7813 48.75H57.6938L57.375 50.375H79.6875L79.3688 48.75H81.2813C81.8378 48.7438 82.383 48.5891 82.8627 48.3013C83.3424 48.0135 83.7398 47.6026 84.0154 47.1096C84.2911 46.6166 84.4353 46.0586 84.4337 45.4911C84.4322 44.9236 84.2849 44.3664 84.0066 43.875Z"
        fill="#88B8F9"
      />
      <path
        d="M68.5312 30.875C72.9323 30.875 76.5 27.2373 76.5 22.75C76.5 18.2627 72.9323 14.625 68.5312 14.625C64.1302 14.625 60.5625 18.2627 60.5625 22.75C60.5625 27.2373 64.1302 30.875 68.5312 30.875Z"
        fill="#88B8F9"
      />
      <path
        d="M68.5312 58.5C73.8125 58.5 78.0938 54.1348 78.0938 48.75C78.0938 43.3652 73.8125 39 68.5312 39C63.25 39 58.9688 43.3652 58.9688 48.75C58.9688 54.1348 63.25 58.5 68.5312 58.5Z"
        fill="#FFFEFF"
      />
      <path
        d="M68.5312 37.375C66.3248 37.375 64.1678 38.0421 62.3332 39.292C60.4985 40.5419 59.0686 42.3185 58.2242 44.397C57.3798 46.4755 57.1589 48.7626 57.5894 50.9692C58.0198 53.1757 59.0824 55.2025 60.6426 56.7933C62.2028 58.3842 64.1907 59.4675 66.3548 59.9064C68.5189 60.3453 70.762 60.1201 72.8006 59.2591C74.8391 58.3982 76.5815 56.9402 77.8073 55.0696C79.0332 53.199 79.6875 50.9998 79.6875 48.75C79.6875 45.7332 78.5121 42.8399 76.4199 40.7067C74.3277 38.5734 71.4901 37.375 68.5312 37.375ZM60.5625 48.75C60.5621 46.8769 61.1965 45.0613 62.3582 43.6103C63.5199 42.1593 65.1376 41.1621 66.9375 40.7875V56.7125C65.1376 56.3379 63.5199 55.3407 62.3582 53.8897C61.1965 52.4387 60.5621 50.6231 60.5625 48.75ZM70.125 56.7125V40.7875C71.9264 41.1605 73.5459 42.1569 74.7092 43.6081C75.8725 45.0592 76.508 46.8758 76.508 48.75C76.508 50.6242 75.8725 52.4408 74.7092 53.8919C73.5459 55.3431 71.9264 56.3395 70.125 56.7125Z"
        fill="#0C3798"
      />
      <path
        d="M95.9437 53.2838C95.8681 51.9373 95.3835 50.6481 94.5572 49.595L90.27 14.625C89.8817 11.4827 88.3817 8.59299 86.0517 6.49852C83.7217 4.40406 80.7219 3.24891 77.6156 3.25H24.3844C21.2781 3.24891 18.2783 4.40406 15.9483 6.49852C13.6183 8.59299 12.1183 11.4827 11.73 14.625L7.44281 49.595C6.61649 50.6481 6.13191 51.9373 6.05625 53.2838L4.78125 78H97.2188L95.9437 53.2838ZM18.0572 15.4375C18.2523 13.858 19.0092 12.4064 20.1842 11.3581C21.3593 10.3097 22.8708 9.73752 24.4322 9.75H77.6156C79.177 9.73752 80.6885 10.3097 81.8636 11.3581C83.0386 12.4064 83.7955 13.858 83.9906 15.4375L87.8316 47.125H14.1684L18.0572 15.4375Z"
        fill="#88B8F9"
      />
      <path
        d="M7.96875 87.75H27.0938V97.5C27.0938 98.362 26.7579 99.1886 26.1602 99.7981C25.5624 100.408 24.7516 100.75 23.9062 100.75H11.1562C10.3109 100.75 9.50012 100.408 8.90235 99.7981C8.30457 99.1886 7.96875 98.362 7.96875 97.5V87.75Z"
        fill="#0C3798"
      />
      <path
        d="M19.125 71.5C21.7656 71.5 23.9062 69.3174 23.9062 66.625C23.9062 63.9326 21.7656 61.75 19.125 61.75C16.4844 61.75 14.3438 63.9326 14.3438 66.625C14.3438 69.3174 16.4844 71.5 19.125 71.5Z"
        fill="#F8CF6D"
      />
      <path
        d="M19.125 73.125C17.8641 73.125 16.6316 72.7438 15.5832 72.0296C14.5349 71.3153 13.7178 70.3002 13.2353 69.1124C12.7528 67.9247 12.6265 66.6178 12.8725 65.3569C13.1185 64.096 13.7256 62.9379 14.6172 62.0288C15.5088 61.1198 16.6447 60.5007 17.8813 60.2499C19.1179 59.9991 20.3997 60.1278 21.5646 60.6198C22.7295 61.1118 23.7251 61.9449 24.4256 63.0138C25.1261 64.0827 25.5 65.3394 25.5 66.625C25.5 68.3489 24.8284 70.0022 23.6328 71.2212C22.4373 72.4402 20.8158 73.125 19.125 73.125ZM19.125 63.375C18.4946 63.375 17.8783 63.5656 17.3541 63.9227C16.8299 64.2798 16.4214 64.7874 16.1801 65.3813C15.9389 65.9751 15.8758 66.6286 15.9987 67.2591C16.1217 67.8895 16.4253 68.4686 16.8711 68.9231C17.3169 69.3776 17.8848 69.6872 18.5031 69.8126C19.1215 69.938 19.7624 69.8736 20.3448 69.6276C20.9272 69.3816 21.4251 68.9651 21.7753 68.4306C22.1256 67.8961 22.3125 67.2678 22.3125 66.625C22.3125 65.7631 21.9767 64.9364 21.3789 64.3269C20.7811 63.7174 19.9704 63.375 19.125 63.375Z"
        fill="#FFFEFF"
      />
      <path
        d="M7.96875 63.375H3.1875C2.3073 63.375 1.59375 64.1025 1.59375 65V68.25C1.59375 69.1475 2.3073 69.875 3.1875 69.875H7.96875C8.84895 69.875 9.5625 69.1475 9.5625 68.25V65C9.5625 64.1025 8.84895 63.375 7.96875 63.375Z"
        fill="#EE7231"
      />
      <path
        d="M4.78125 78C3.93587 78 3.12512 78.3424 2.52735 78.9519C1.92957 79.5614 1.59375 80.388 1.59375 81.25V84.5C1.59375 85.362 1.92957 86.1886 2.52735 86.7981C3.12512 87.4076 3.93587 87.75 4.78125 87.75H97.2188C98.0641 87.75 98.8749 87.4076 99.4727 86.7981C100.07 86.1886 100.406 85.362 100.406 84.5V81.25C100.406 80.388 100.07 79.5614 99.4727 78.9519C98.8749 78.3424 98.0641 78 97.2188 78H4.78125Z"
        fill="#2F79F0"
      />
      <path
        d="M90.8438 100.75H78.0938C77.2484 100.75 76.4376 100.408 75.8398 99.7981C75.2421 99.1886 74.9062 98.362 74.9062 97.5V87.75H94.0312V97.5C94.0312 98.362 93.6954 99.1886 93.0977 99.7981C92.4999 100.408 91.6891 100.75 90.8438 100.75Z"
        fill="#0C3798"
      />
      <path
        d="M78.0938 47.125L72.6431 56.875C72.3623 57.371 71.9579 57.7825 71.4709 58.0678C70.984 58.3532 70.4317 58.5023 69.87 58.5H32.13C31.5683 58.5023 31.016 58.3532 30.5291 58.0678C30.0421 57.7825 29.6377 57.371 29.3569 56.875L23.9062 47.125H78.0938Z"
        fill="#2F79F0"
      />
      <path
        d="M82.875 71.5C85.5156 71.5 87.6562 69.3174 87.6562 66.625C87.6562 63.9326 85.5156 61.75 82.875 61.75C80.2344 61.75 78.0938 63.9326 78.0938 66.625C78.0938 69.3174 80.2344 71.5 82.875 71.5Z"
        fill="#F8CF6D"
      />
      <path
        d="M82.875 73.125C81.6141 73.125 80.3816 72.7438 79.3332 72.0296C78.2849 71.3153 77.4678 70.3002 76.9853 69.1124C76.5028 67.9247 76.3765 66.6178 76.6225 65.3569C76.8685 64.096 77.4756 62.9379 78.3672 62.0288C79.2588 61.1198 80.3947 60.5007 81.6313 60.2499C82.8679 59.9991 84.1497 60.1278 85.3146 60.6198C86.4795 61.1118 87.4751 61.9449 88.1756 63.0138C88.8761 64.0827 89.25 65.3394 89.25 66.625C89.25 68.3489 88.5783 70.0022 87.3828 71.2212C86.1873 72.4402 84.5658 73.125 82.875 73.125ZM82.875 63.375C82.2446 63.375 81.6283 63.5656 81.1041 63.9227C80.5799 64.2798 80.1714 64.7874 79.9301 65.3813C79.6889 65.9751 79.6258 66.6286 79.7487 67.2591C79.8717 67.8895 80.1753 68.4686 80.6211 68.9231C81.0669 69.3776 81.6348 69.6872 82.2531 69.8126C82.8715 69.938 83.5124 69.8736 84.0948 69.6276C84.6772 69.3816 85.1751 68.9651 85.5253 68.4306C85.8756 67.8961 86.0625 67.2678 86.0625 66.625C86.0625 65.7631 85.7267 64.9364 85.1289 64.3269C84.5311 63.7174 83.7204 63.375 82.875 63.375Z"
        fill="#FFFEFF"
      />
      <path d="M30.2812 65H71.7188V68.25H30.2812V65Z" fill="#B7DBF9" />
      <path
        d="M94.0312 69.875H98.8125C99.6927 69.875 100.406 69.1475 100.406 68.25V65C100.406 64.1025 99.6927 63.375 98.8125 63.375H94.0312C93.151 63.375 92.4375 64.1025 92.4375 65V68.25C92.4375 69.1475 93.151 69.875 94.0312 69.875Z"
        fill="#EE7231"
      />
      <path
        d="M65.3438 76.375H36.6562C34.8958 76.375 33.4688 77.8301 33.4688 79.625V86.125C33.4688 87.9199 34.8958 89.375 36.6562 89.375H65.3438C67.1042 89.375 68.5312 87.9199 68.5312 86.125V79.625C68.5312 77.8301 67.1042 76.375 65.3438 76.375Z"
        fill="#B7DBF9"
      />
    </svg>
  )
}
export default MC_ic_has_driver
