import React, { Component, ReactNode } from "react"
import { MdOutlineError } from "react-icons/md"
import { logException } from "src/helpers/Log"
import styled from "styled-components"

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error) {
    logException({ Error: error })
    this.setState(
      {
        hasError: true,
      },
      () => {
        this.forceUpdate()
      }
    )
  }

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorComponent>
          <MdOutlineError style={{ marginRight: "0.2rem" }} />
          <span>Sorry, there was an error.</span>
        </ErrorComponent>
      )
    }
    return this.props.children
  }
}
const ErrorComponent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    fill: var(--color-secondary);
  }
`
export default ErrorBoundary
