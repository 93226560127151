import React from "react"
import { MyButtonHelpers } from "@macashipo/mlib"
import { CSuppense } from "src/core/components"

const MB_filter_table = React.lazy(() => import("./Types/filter_table"))

const List = {
  filter_table: MB_filter_table,
}
const MyUIOne = {
  init() {
    MyButtonHelpers.addTypesFromListLazy(List)
  },
}

export default MyUIOne
