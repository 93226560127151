import React from "react"
import { Input } from "reactstrap"
import classnames from "classnames"
class InputRange extends React.Component {
  render() {
    return (
      <Input
        {...this.props}
        className={classnames("form-range", this.props.className)}
        type="range"
      />
    )
  }
}

//value: string
//defaultValue: string
//onChange: value = string

export default InputRange
