import React from "react"
import { MyFormControlHelpers, CSuppense } from "@macashipo/mlib"

const V1_mfc_mycontrol = React.lazy(
  () => import("src/core/modules/v1page/MyForm/Types/v1_mfc_mycontrol")
)

const ListControl = {
  input_address: "input_address",
  list_radio: "list_radio",
  list_checkbox: "list_checkbox",
  input_range: "input_range",
  input_select: "input_select",
  input_datetime: "input_datetime",
  nice_select: "nice_select",
  input_rate: "input_rate",
  button_modal_form: "button_modal_form",
  select_custom: "select_custom",
  input_text: "input_text",
  input_password: "input_password",
  input_date: "input_date",
  input_date_string: "input_date_string",
  input_currency: "input_currency",
  input_phone_number: "input_phone_number",
  input_password_v2: "input_password_v2",
  input_date_from_to: "input_date_from_to",
  input_date_from_to_v2: "input_date_from_to_v2",
  button_select_address: "button_select_address",
  check_with_icon: "check_with_icon",
  input_dual_range: "input_dual_range",
  select2_custom_filter: "select2_custom_filter",
  input_datetime_v2: "input_datetime_v2",
  input_address_v2: "input_address_v2",
  input_date_from_to_detail: "input_date_from_to_detail",
  input_date_from_to_search: "input_date_from_to_search",
  input_time_from_to_search: "input_time_from_to_search",
}
const MyFormControlOne = {
  init() {
    for (let k of Object.keys(ListControl)) {
      MyFormControlHelpers.addTypes(k, props => {
        return CSuppense({ ...props, typeControl: k }, V1_mfc_mycontrol)
      })
    }
  },
}

export default MyFormControlOne
