import React from "react"
import { HUtils, HIs } from "@macashipo/mlib"
const OptionsInfo = {
  fValue: "Value",
  fText: "Text",
}
export const getListSelectTagBySourceData = (
  sourceData?: any[],
  opts: any = {}
) => {
  if (sourceData && sourceData.length > 0) {
    let _ui: any = []
    if (opts) {
      if (opts.noAddAutoEmpty !== true) {
        _ui.push(
          <option key={"_empty_"} value={""}>
            {""}
          </option>
        )
      }
    }
    for (let i = 0; i < sourceData.length; i++) {
      let _text = sourceData[i].Text || sourceData[i].label || ""
      let _value = sourceData[i].Value || sourceData[i].value || ""
      _ui.push(
        <option key={i} value={_value}>
          {_text}
        </option>
      )
    }
    return _ui
  }
}

export const convertSourceDataToString = (sourceData?: any[]) => {
  if (sourceData) {
    return sourceData.join(",")
  }
  return ""
}

export const getOptionByValue = (sourceList: any = [], value) => {
  if (sourceList && sourceList.length > 0 && value != null) {
    for (let i = 0; i < sourceList.length; i++) {
      if (sourceList[i].Value == value) {
        return sourceList[i]
      }
    }
  }
}

export const getTextByValue = (sourceList = [], value) => {
  let _text = value
  let _option = getOptionByValue(sourceList, value)
  // console.warn("getTextByValue", sourceList, value)
  if (_option && _option.Text) {
    _text = _option.Text
  }
  return _text
}

export const convertSourceDataToArrayValue = (sourceData?: any[]) => {
  if (sourceData) {
    return sourceData.map((v: any, i) => {
      return v[OptionsInfo.fValue]
    })
  }
  return []
}

export const getSourceDataFromSourceList = (sourceList: any, key: string) => {
  if (sourceList && sourceList[key]) {
    return sourceList[key]
  }
  return []
}

export const getSourceListFromFnList = (fnList: any) => {
  if (fnList && fnList.getSourceList) {
    return fnList.getSourceList()
  }
  return {}
}

export const isHasLevelOptionKey = optionKey => {
  if (optionKey) {
    if (optionKey.indexOf(".") > -1) {
      return true
    }
  }
  return false
}

export const convertOptionKeyLevelMissingField = (
  optionKey = "",
  fieldNames = []
) => {
  let _optionKey = optionKey
  //truong hop a.b cua cell, ko day du a.[field].b
  if (
    _optionKey.indexOf(".") > -1 &&
    _optionKey.indexOf("[") === -1 &&
    fieldNames.length > 0
  ) {
    let _countDot = 0
    for (let i = 0; i < _optionKey.length; i++) {
      if (_optionKey[i] === ".") {
        _countDot++
        if (fieldNames.length > 0) {
          if (HIs.isString(fieldNames)) {
            let _add = `[${fieldNames}].`
            _optionKey =
              _optionKey.slice(0, i + 1) +
              _add +
              _optionKey.slice(i + 1, _optionKey.length)
            i += _add.length
          } else if (fieldNames[_countDot - 1]) {
            //is array
            let _add = `[${fieldNames[_countDot - 1]}].`
            _optionKey =
              _optionKey.slice(0, i + 1) +
              _add +
              _optionKey.slice(i + 1, _optionKey.length)
            i += _add.length
          }
        }
      }
    }
  }
  return _optionKey
}
export const getOptionsByLevel = (
  sourceList = {},
  optionKey = "",
  data = {},
  fieldNames = []
) => {
  optionKey = convertOptionKeyLevelMissingField(optionKey, fieldNames)
  // console.warn("optionKey", optionKey, fieldNames, data)
  if (sourceList[optionKey]) {
    //if exist
    return sourceList[optionKey]
  } else {
    // a.[field].b = a.value of field.b
    let _newOptionKey = HUtils.getTextWithData(optionKey, data)
    let _options: any = null
    let _split = _newOptionKey.split(".")
    let _prefixK = "" //bo sung prefixK cho truong hop Value co dau .
    for (let i = 0; i < _split.length; i++) {
      let k = _split[i]
      let _k = k
      if (_prefixK) {
        _k = `${_prefixK}.${k}`
      }
      if (_options === null && sourceList[_k]) {
        //truong hop co trong sourceList thi lay _options = sourceList[_k]
        _options = sourceList[_k]
      } else if (_options && HIs.isArray(_options) && _options.length > 0) {
        //truong hop co _option tu truoc-> can di kiem tra value
        let _hasItem = false
        for (let i = 0; i < _options.length; i++) {
          if (_options[i].Value == _k) {
            _options = _options[i]
            _hasItem = true
            _prefixK = "" //reset prefix neu co
            break
          }
        }
        if (_hasItem === false) {
          //phai co item neu ko thi la rong
          if (i < _split.length - 1) {
            //neu chua phai cuoi cung, co the la value chua dau .
            _prefixK = _k
            continue
          } else {
            _options = []
          }
        }
      } else if (_options && _options.Value && _options[k]) {
        //thuong thi k o buoc nay ko co dau .
        _options = _options[k]
      } else {
        _options = []
      }
    }
    sourceList[_newOptionKey] = _options
    // console.warn("options:", _options, sourceList)
    return _options || []
  }
}
// ;(window as any)["test"] = convertOptionKeyLevelMissingField

export const isExistValueInSourceList = (sourceList = [], value) => {
  let _isExist = false
  if (sourceList.length > 0) {
    for (let i = 0; i < sourceList.length; i++) {
      let _item: any = sourceList[i]
      if (_item.Value == value) {
        return true
      }
    }
  }
  return _isExist
}
