import { HUtils } from "@macashipo/mlib"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"

const HomeUsageCard = props => {
  const { data } = props

  const _url = HUtils.get(data, `${homeSlidesFields.fOneSlideMoreInfo}.url`)
  const img = data?.[homeSlidesFields.fOneSlideImageUrl]
  const title = data?.[homeSlidesFields.fOneSlideTitle]
  return (
    <a href={_url} className="itemhd">
      <img className="lazy" src={img} data-original={img} alt={title} />
      <h3 className="title">{title}</h3>
    </a>
  )
}

export default HomeUsageCard
