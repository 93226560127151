import React from "react"
import { MyUIHelpers } from "@macashipo/mlib"

const MUI_v1_page_header = React.lazy(() => import("./Types/v1_page_header"))
const MUI_v1_page_header_buttons = React.lazy(
  () => import("./Types/v1_page_header_buttons")
)
const MUI_v1_page_filter = React.lazy(() => import("./Types/v1_page_filter"))
const MUI_v1_page_table = React.lazy(() => import("./Types/v1_page_table"))
const MUI_v1_page_table_expand = React.lazy(
  () => import("./Types/v1_page_table_expand")
)
const MUI_v1_page_msginui = React.lazy(() => import("./Types/v1_page_msginui"))

const List = {
  v1_page_header: MUI_v1_page_header,
  v1_page_header_buttons: MUI_v1_page_header_buttons,
  v1_page_filter: MUI_v1_page_filter,
  v1_page_table: MUI_v1_page_table,
  v1_page_table_expand: MUI_v1_page_table_expand,
  v1_page_msginui: MUI_v1_page_msginui,
}
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List)
  },
}

export default MyUIOne
