import { HLog } from "src/core/helpers"
import { HLocalStorage } from "@macashipo/mlib"
import ModuleFirstSetting from "src/core/modules/firstsetting"
// import ModuleCategoryScreen from "src/core/modules/categoryscreen"
import ModuleV1Page from "src/core/modules/v1page"
import ModuleDev from "src/core/modules/dev"
import ModuleIconHiOutline from "src/core/modules/iconHiOutline"
import ModuleSetting from "src/core/modules/setting"
import ModuleSetting2FA from "src/core/modules/setting2fa"
import ModuleRedirectToken from "src/core/modules/redirectToken"
import ModuleDevSimulator from "src/core/modules/dev_simulator"
import ModuleCore from "src/core/modules/core"
import ModuleReactGA from "src/modules/reactga"
import ModuleCheckIdle from "src/modules/check_idle"
import ModuleAdminTable from "src/modules/admintable"
import ModuleAppSigoGeneric from "src/modules/app_sigo_generic"

import Lang_en from "src/assets/i18n/en.json"
import Lang_vi from "src/assets/i18n/vi.json"

import { store } from "src/store/store"
import K from "src/helpers/K"
import {
  fetchFirstSettingSuccess,
  logout,
  updateHeaderTitle,
} from "src/App/App.actions"
import "src/assets/scss/theme-layout.css"
const kFirstSettingData = "FIRST_SETTING_DATA"
const AppConfig: IConfigApp = {
  appInfo: {
    title: "SIGO® | Ứng dụng cho thuê xe tự lái 4 - 7 chỗ",
    appName: "sigoweb",
    home: "/",
    logoUrl: "/",
    targetLogoUrl: "_self",
    acceptFiles:
      ".pdf, .doc, .docx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    GAId: "G-RSHGJ465P9",
    GAIdDev: "UA-119777650-2",
    GooglePlaceApiKey: "AIzaSyBzpjMv2KczEub9Od-uYrB6Skvqslkf3Fg",
    hasCheckIdle: false,
    checkIdleSecond: 600,
    checkIdleInterval: 5,
    checkIdleCountdown: 60,
    configLogin: {
      showRegister: false,
      textWelcome: "MEZY CUSTOMER PORTAL",
    },
    facebookAppId: "543044071219951",
    facebookAdmins: "100002470701612",
    dfMetaConfig: {
      MetaUrl: "https://sigo.vn/",
      MetaTitle:
        "SIGO® | Ứng dụng cho thuê xe tự lái 4 - 7 chỗ | 1900 99 99 19 | 02888889399",
      MetaKeyWords:
        "Sigo, cho thuê xe tự lái, 4 chỗ, 7 chỗ, 1900 99 99 19, 02888889399",
      MetaDescription:
        "Sigo là một platform (nền tảng), là một ứng dụng đặt xe online, là nền tảng kết nối giữa chủ xe và người thuê xe (car-sharing). Qua Sigo, khách hàng có nhu cầu thuê xe tự lái sẽ liên hệ với những chủ xe tại địa phương. Mọi thắc mắc xin liên hệ tổng đài 1900 99 99 19 và vui lòng nhấc máy khi nhận được cuộc gọi từ số 02888889399 nhé!",
    },
  },
  configApi: {
    baseUrl: "https://sigo-api-dev2.allianceitsc.com", //"https://sigo-api-dev.allianceitsc.com",
    fnLogout: opts => {
      // console.warn("logout")
      store.dispatch(logout(opts))
    },
  },
  configI18n: {
    lng: "en",
    defaultNS: "common",
    resources: {
      en: {
        common: Lang_en,
      },
      vi: {
        common: Lang_vi,
      },
    },
  },
  configAuth: {
    afterLogin: () => {
      console.log("afterLogin")
      ModuleCheckIdle._fnList.checkIdleAfterLogin()
    },
  },
  fnList: {
    dispatchFromStore(action) {
      store.dispatch(action)
    },
    updateHeaderTitle: title => {
      store.dispatch(updateHeaderTitle(title))
    },
    onAfterInit: function (HConfig) {
      HLog.logW("After init", HConfig)
    },
    fnGetRequestUIAppConfigFirstSetting: () => {
      let _keys = [
        K.mobileNumberInvalidMessage,
        K.mobileNumberValidRegexPattern,
        K.acceptedTypeIDUpload,
        K.acceptedTypeIDUploadName,
      ]
      let _obj = {}
      for (let k of _keys) {
        if (AppConfig.UIAppConfig[k] != null) {
          _obj[k] = AppConfig.UIAppConfig[k]
        }
      }
      return _obj
    },
    afterCallFirstSetting: config => {
      HLocalStorage.saveObj(kFirstSettingData, config)
      store.dispatch(fetchFirstSettingSuccess(config))
    },
  },
  modules: [
    ModuleCore,
    ModuleDev,
    ModuleDevSimulator,
    ModuleFirstSetting,
    // ModuleCategoryScreen,
    ModuleV1Page,
    ModuleIconHiOutline,
    ModuleSetting,
    ModuleSetting2FA,
    ModuleRedirectToken,
    ModuleReactGA,
    ModuleCheckIdle,
    ModuleAdminTable,
    ModuleAppSigoGeneric,
  ],
  UIAppConfig: {
    numeralFormat: "0,0.[0]",
    numeralFormatMoney: "0,0.[00]",
    timeFormat: "HH:mm",
    dateFormat: "DD-MMMYY", //MomentJS
    datetimeFormat: "DD-MMMYY HH:mm", //MomentJS
    widthMobile: 1000,
    symbolDecimal: ",",
    symbolThousand: ".",
    mobileNumberValidRegexPattern: "^(0[4|5])([0-9]{8})$",
    mobileNumberInvalidMessage:
      "Please enter a valid Australian mobile number (04xxxxxxxx or 05xxxxxxxx).",
    acceptedTypeIDUpload: ".jpg,.jpeg,.png,.pdf",
    acceptedTypeIDUploadName: ".jpg .jpeg .png .pdf",
  },
}

export default AppConfig
