import React from "react"

import iconInsurance from "./ic_insurance.json"
import iconCar from "./car.json"
import iconPriceTag from "./price_tag.json"
import iconMinicar from "./minicar.json"

const List = {
  aic_insurance: iconInsurance,
  aic_car: iconCar,
  aic_price_tag: iconPriceTag,
  aic_minicar: iconMinicar,
}
export const getIconPath = iconName => {
  return List[iconName] ?? ""
}
