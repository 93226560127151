import styled from "styled-components"
import { MyIcon, Tooltip } from "src/core/components"
import { HCF, HUtils } from "@macashipo/mlib"
import { isArray, isNotEmpty } from "src/core/helpers/utils"
import Skeleton from "react-loading-skeleton"
const MUIButtonRefresh = props => {
  const {
    fnList,
    configFilter = {},
    valuesFilter = {},
    defaultActive = false,
  } = props

  const checkNotempty = fieldName => {
    if (isArray(valuesFilter[fieldName]) && !valuesFilter[fieldName]?.length) {
      return false
    }
    return isNotEmpty(valuesFilter[fieldName])
  }

  const getActiveFilter = () => {
    const { listField = [], listOption = [] } = configFilter
    if (defaultActive) {
      return true
    }
    const rsField = listField?.findIndex(field => {
      return checkNotempty(field.fieldName)
    })
    if (rsField >= 0) {
      return true
    }
    for (var option of listOption) {
      const listFieldOption = getSourceListFilter(option.optionKey)
      const rsOption = listFieldOption?.findIndex(option => {
        return checkNotempty(option.FieldName)
      })
      if (rsOption >= 0) {
        return true
      }
    }

    return false
  }

  const getSourceListFilter = optionKey => {
    let options = {}
    if (!optionKey) {
      return
    }
    if (fnList && fnList.getSourceList) {
      options = fnList.getSourceList()
    }

    return options[optionKey] || []
  }

  const isActiveFilter = getActiveFilter()

  return (
    <Tooltip
      placement={"top"}
      overlay={
        <div>
          {HUtils.get(
            HCF.getCF("websiteSetting"),
            "category_screen.resetFilterTooltip"
          )}
        </div>
      }
    >
      <WrapButton
        className={`btn btn-default btn-radius btn-refresh ${
          isActiveFilter ? "btn-active-filter" : ""
        }`}
        onClick={() => {
          fnList.onClearFilter && fnList.onClearFilter()
        }}
      >
        <MyIcon
          data="type:ic_refresh"
          fill={isActiveFilter ? "#fff" : "#000000"}
        />
      </WrapButton>
    </Tooltip>
  )
}
const WrapButton = styled.button`
  &.btn-default.btn-refresh {
    padding: 6px 15px;
    margin-right: 8px;
    position: relative;
    transition: all 0.5s, background-color 0s;
    &:hover,
    &:focus {
      border-color: #ccc !important;
      background: transparent !important;
    }
    &.btn-active-filter {
      background: var(--mau1) !important;
      border-color: var(--color-border-blue-light);
      &:after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        position: absolute;
        right: 10px;
        top: 6px;
        background: red;
      }
    }
  }
`
export default MUIButtonRefresh
