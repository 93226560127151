import React, { useEffect, useState } from "react"
import { HAuth } from "src/core/helpers"
import { MyModal, MyUI } from "src/core/components"
import { SGNewPaths, SGPaths } from "src/modules/app_sigo/constants/paths"
import dfAvatar from "src/modules/app_sigo/images/user.png"
import dfLogo from "src/modules/app_sigo/images/logo-sigo.png"
import styled from "styled-components"
import { logout } from "src/App/App.actions"
import { ConnectedProps, connect } from "react-redux"
import classNames from "classnames"
import { HCF, HEE, HUtils } from "@macashipo/mlib"
import { KAuth } from "src/helpers/Flows/Auth"
import { mobileWidth } from "src/modules/app_sigo/constants"

const configKeyHeader = {
  menu_left: "menu_left",
  item_link: "item_link",
  item_title: "item_title",
  sub_items: "sub_items",
  sub_items_url: "url",
  sub_items_name: "name",
  // menu right
  menu_right: "menu_right",
  classname_icon: "classname_icon",
  classname_link: "classname_link",
  item_classname_wrap: "item_classname_wrap",
  right_item_link: "item_link",
  right_item_title: "item_title",
  right_sub_menu: "sub_menu",
  right_sub_url: "url",
  right_sub_name: "name",
  // menu mobile
  abouts: "abouts",
  rental_types: "rental_types",
  url: "url",
  name: "name",
}
const mapStateToProps = state => ({
  isAuthenticated: state.app.isAuthenticated,
  dataFirstSetting: state.app.dataFirstSetting,
})

const mapDispatchToProps = {
  logout,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
interface Props extends ConnectedProps<typeof connector> {
  fnOpenFilter?: any
  moreConfig?: any
}

const MyUIHeader = (props: Props) => {
  const {
    isAuthenticated,
    logout,
    dataFirstSetting,
    fnOpenFilter,
    moreConfig,
  } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)
  const logo = HAuth.getUserLogoApp() || dfLogo //"/config/logo.png"
  const [avatar, setAvatar] = useState(HAuth.getUserAvatar() || dfAvatar)
  const [userName, setUsername] = useState(HAuth.getUserName())
  const [isMobileView, setIsMobileView] = useState(false)
  const [data, setData] = useState(
    HUtils.get(dataFirstSetting, "DefaultValues.websiteSetting.header") || {}
  )
  const [configFirstSetting, setConfigFirstSetting] = useState(
    HUtils.get(dataFirstSetting, "DefaultValues.websiteSetting") || {}
  )
  useEffect(() => {
    setData(HUtils.get(dataFirstSetting, "DefaultValues.websiteSetting.header"))
    setConfigFirstSetting(
      HUtils.get(dataFirstSetting, "DefaultValues.websiteSetting")
    )
    // eslint-disable-next-line
  }, [dataFirstSetting])
  const _updateAuthInfo = () => {
    setUsername(HAuth.getUserName())
    setAvatar(HAuth.getUserAvatar())
  }
  useEffect(() => {
    HEE.on(KAuth.eeUpdateAuthInfo, _updateAuthInfo)
    return () => {
      HEE.off(KAuth.eeUpdateAuthInfo, _updateAuthInfo)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    const ro = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        const { left, top, width, height } = entry.contentRect
        if (entry.target == document.body) {
          if (width <= mobileWidth) {
            if (isMobileView === false) {
              console.log(
                `RO Mobile Element's size: ${width}px x ${height}px`,
                isMobileView
              )
            }
            setIsMobileView(true)
          } else {
            setIsMobileView(false)
          }
        }
      }
    })
    ro.observe(document.body)
  }, [])
  const getConfigFromFirstSettingByKey = key => {
    if (configFirstSetting) {
      const { config_search_in_header } = configFirstSetting || {}
      return HUtils.get(config_search_in_header, key)
    }
    return
  }

  const onScroll = () => {
    const navbar = document.getElementById("navbar")
    const sticky: any = navbar?.offsetTop
    if (navbar) {
      if (window.pageYOffset >= sticky && window.pageYOffset > 0) {
        navbar.classList.add("truot")
      } else {
        navbar.classList.remove("truot")
      }
    }
  }
  const closeDropdownMobile = e => {
    if (
      !document
        .getElementsByClassName("mobile-menu-dropdown")[0]
        ?.contains(e.target) &&
      !document
        .getElementsByClassName("dropdown-mobile-btn")[0]
        ?.contains(e.target)
    ) {
      setDropdownOpen(false)
    }
  }
  useEffect(() => {
    onScroll()
    window.addEventListener("scroll", onScroll)
    window.addEventListener("click", closeDropdownMobile)
    return () => {
      window.removeEventListener("scroll", onScroll)
      window.removeEventListener("click", closeDropdownMobile)
    }
  }, [])

  const renderMobile = () => {
    const abouts = data?.[configKeyHeader.abouts] || []
    const rentalTypes = data?.[configKeyHeader.rental_types] || []
    return (
      <div
        className={classNames(
          "collapse navbar-collapse hidden-desktop collapseRight mobile-menu-dropdown",
          dropdownOpen && "open"
        )}
        data-show="menumember"
        id="collapseRightMobile"
      >
        {isAuthenticated ? (
          <ul className="nav navbar-nav navbar-right">
            <li className="active">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                }}
              >
                {userName}
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGPaths.taikhoancuatoi}>
                Tài khoản
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGPaths.thongbao}>
                Thông báo <span className="text-danger">*</span>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGPaths.chuyendicuatoi}>
                Chuyến đi của tôi
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGPaths.tichluy}>
                Tích lũy
              </a>
            </li>
            <li className="divider"></li>
            <li>
              <a className="dropdown-item" href={SGPaths.donhanghientai}>
                Quản lý xe cho thuê <span className="text-danger">*</span>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href={SGPaths.thanhtoan}>
                Thanh toán
              </a>
            </li>
            {/* <li>
              <a
                className="dropdown-item"
                href="/tai-khoan/hieu-suat"
              >
                Hiệu suất
              </a>
            </li> */}
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="dropdown-item signout bgxam"
                href="#"
                onClick={e => {
                  e.preventDefault()
                  logout({})
                  window.location.reload()
                }}
              >
                Đăng xuất
              </a>
            </li>
          </ul>
        ) : (
          <ul className="nav navbar-nav navbar-right">
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                data-toggle="modal"
                data-target="#signin"
                onClick={e => {
                  e.preventDefault()
                  MyModal.showFromMyUI(
                    {
                      type: "sg_login",
                      afterLogin: () => {
                        MyModal.hideModalMyUI()
                        window.location.reload()
                      },
                      actionType: "register",
                      // field: "PhoneNumber",
                      field: "MobilePhone",
                    },
                    {
                      size: "top",
                      showHeader: false,
                      showHeaderClose: false,
                      backdrop: "static",
                      modalClassName: "modal-login",
                    }
                  )
                }}
              >
                Đăng ký
              </a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                data-toggle="modal"
                data-target="#signin"
                onClick={e => {
                  e.preventDefault()
                  MyModal.showFromMyUI(
                    {
                      type: "sg_login",
                      afterLogin: () => {
                        MyModal.hideModalMyUI()
                        window.location.reload()
                      },
                      actionType: "login",
                      field: "MobilePhone",
                    },
                    {
                      size: "top",
                      showHeader: false,
                      showHeaderClose: false,
                      backdrop: "static",
                      modalClassName: "modal-login",
                    }
                  )
                }}
              >
                Đăng nhập
              </a>
            </li>
            <li className="divider"></li>
            {abouts &&
              abouts?.length > 0 &&
              abouts.map((v, i) => {
                const url = v?.[configKeyHeader.url] || "#"
                const name = v?.[configKeyHeader.name] || ""
                return (
                  <li key={`about-${i}`}>
                    <a className="dropdown-item" href={url}>
                      {name}
                    </a>
                  </li>
                )
              })}
            {rentalTypes &&
              rentalTypes?.length > 0 &&
              rentalTypes.map((v, i) => {
                const url = v?.[configKeyHeader.url] || "#"
                const name = v?.[configKeyHeader.name] || ""
                return (
                  <li key={`rent-${i}`}>
                    <a className="dropdown-item hidden-desktop" href={url}>
                      {name}
                    </a>
                  </li>
                )
              })}
          </ul>
        )}
      </div>
    )
  }
  const renderMenuLeft = () => {
    const menuLeft = data?.[configKeyHeader.menu_left] || []
    if (menuLeft && menuLeft?.length > 0) {
      return (
        <div
          className="collapse navbar-collapse pull-sm-left hidden-mobile"
          id="collapseLeft"
        >
          <ul className="nav navbar-nav">
            {menuLeft.map((v, i) => {
              const title = v?.[configKeyHeader.item_title] || ""
              const link = v?.[configKeyHeader.item_link] || "#"
              const subMenu = v?.[configKeyHeader.sub_items] || ""
              const isDropdown = subMenu && subMenu !== ""
              return (
                <li
                  className={isDropdown ? "nav-item dropdown menumember" : ""}
                  key={`leftmenu-${i + 1}`}
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  {isDropdown ? (
                    <a
                      href={link}
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      {title}
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {subMenu.map((item, j) => {
                          const url =
                            item?.[configKeyHeader.sub_items_url] || "#"
                          const name = item?.[configKeyHeader.sub_items_name]
                          return (
                            <li key={`about-${j}`}>
                              <a className="dropdown-item" href={url}>
                                {name}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </a>
                  ) : (
                    <a href={link}>{title}</a>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
    return <></>
  }
  const renderMenuRight = () => {
    // const menuRight = data?.[configKeyHeader.menu_right] || []
    const menuRight = getConfigFromFirstSettingByKey("menu_item.right") || []
    if (menuRight && menuRight?.length > 0) {
      return (
        <RightMenu
          className="collapse navbar-collapse collapseRight right-menu"
          data-show="menumember"
          id="collapseRightDesktop"
        >
          {menuRight &&
            menuRight?.length > 0 &&
            menuRight.map((v, i) => {
              return (
                <a href={v?.link} className="custom-link right-item" key={i}>
                  {v?.name}
                </a>
              )
            })}
          <ul className="nav navbar-nav navbar-right navbar_right-v2 avatar-menu right-item">
            <MyUI type="sg_avatar" />
          </ul>
        </RightMenu>
      )
    }
    return (
      <div
        className="collapse navbar-collapse collapseRight "
        data-show="menumember"
        id="collapseRightDesktop"
      >
        <ul className="nav navbar-nav navbar-right">
          <MyUI type="sg_avatar" />
        </ul>
      </div>
    )
  }
  if (
    (window.location.pathname === SGPaths.searchxe ||
      window.location.pathname === SGNewPaths.searchxe) &&
    !isMobileView
  ) {
    return <></>
  }
  if (!data || Object.keys(data)?.length === 0) {
    HCF.setCF("waitingFirstSettingForHeader", () => {
      setData(HUtils.get(HCF.getCF("websiteSetting"), "header") || {})
    })
  }
  return (
    <Wrap id="navbar" className="navbar">
      <div className="container wrap-header">
        <div className="navbar-header">
          {!moreConfig?.onlyShowLogo && (
            <>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="navavatar hidden-desktop dropdown-mobile-btn"
                data-toggle="dropdown"
                onClick={e => {
                  // e.preventDefault()
                  toggle()
                }}
              >
                <span className="wp-icobar">
                  <span className="icon-bar"></span>
                  <span className="icon-bar mid"></span>
                  <span className="icon-bar"></span>
                </span>
                <span className="numalert">0</span>
                <img src={avatar} className="arvartimg2" alt="" />
              </a>
            </>
          )}
          {fnOpenFilter && (
            <button
              className="nav-link btn-filter btn btn-default mr-10px item btn-radius"
              onClick={fnOpenFilter}
            >
              <i
                className="flaticon-filter"
                style={{ marginRight: "0.2rem" }}
              ></i>
              Bộ lọc
            </button>
          )}
          <a className="navbar-brand" href="/">
            <img className="logo" src={logo} alt="logo" />
          </a>
        </div>
        {!moreConfig?.onlyShowLogo && (
          <>
            {/* {!moreConfig?.hideMenuLeft && renderMenuLeft()} */}
            {renderMenuRight()}
            {renderMobile()}
          </>
        )}
        <div className="line hidden-mobile"></div>
      </div>
    </Wrap>
  )
}
const RightMenu = styled.div`
  &.right-menu {
    &.collapse.navbar-collapse {
      display: flex !important;
      align-items: center;
      // gap: 20px;
      flex: 1 0 140px;
      justify-content: flex-end;
      padding-right: 0;
      & .right-item {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    & .navbar-right.avatar-menu {
      float: unset;
      margin-top: 0 !important;
    }
    & .custom-link {
      color: var(--color-text-000);
      font-size: 14px;
      font-weight: 500;
    }
    & .navbar_right-v2 {
      & ul.dropdown-menu {
        padding-top: 0;
      }
    }
  }
`
const Wrap = styled.nav`
  background-color: #fff;
  background-color: var(--color-text-fff) !important;
  // border-bottom: 1px solid var(--color-border-e4);
  box-shadow: rgb(0 0 0 / 16%) 0 0 6px;
  transition: none;
  padding-bottom: 0px;
  & .wrap-header {
    height: 50px;
    &.container {
      padding: 0;
    }
  }
  & .navbar-brand {
    padding: 0;
  }
  & .navavatar {
    // padding: 5px 15px;
    // padding-right: 5px !important;
    display: flex;
    align-items: center;
    & .arvartimg2 {
      border-radius: 50%;
      max-width: 30px;
      width: 30px;
      height: 30px;
      max-height: 30px;
      object-fit: cover;
    }
    & .wp-icobar {
      padding-top: 2px !important;
      // margin-right: 5px;
    }
  }
  & .btn-filter {
    float: right;
    margin: 5px 15px 0;
    color: #222;
    cursor: pointer;
  }
  @media (max-width: 992px) {
    & .line.hidden-mobile {
      display: none !important;
    }
  }
  @media (min-width: 768px) and (max-width: ${mobileWidth}px) {
    box-shadow: rgb(0 0 0 / 16%) 0 0 6px;
    padding-bottom: 5px;
    .navbar-header {
      color: white;
      background-color: white;
      float: unset;
    }
    &.navbar {
      padding-top: 0px;
      padding-bottom: 0px;
      & .container.wrap-header {
        padding: 0;
      }
    }
    & .hidden-mobile {
      display: none !important;
    }
    #collapseRightDesktop {
      display: none !important;
    }
    .navbar-collapse.collapse {
      display: none !important;
    }
    & .navavatar.hidden-desktop {
      display: flex !important;
    }
    .navbar-brand {
      padding: 5px 0px 5px 10px;
    }
    .navavatar {
      float: right;
      margin-top: 5px;
      margin-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 15px;
      padding-right: 5px !important;
      & .wp-icobar {
        padding-top: 0 !important;
        margin-right: 5px;
      }
    }
    .navbar-brand img {
      max-height: 30px;
      margin-top: 5px;
    }
    .logo {
      filter: none;
    }
    & .hidden-desktop {
      &.navbar-collapse.open {
        display: block !important;
        height: unset !important;
      }
    }
    .navbar-nav {
      position: relative;
      background: #fff;
      overflow-y: auto;
      margin-top: 0px;
      max-height: 400px;
      z-index: 999;
    }
    .navbar-nav > li > a {
      color: #444 !important;
      background-color: white;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
    .navbar-nav > li.active > a {
      background-color: var(--mau1);
      color: white !important;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    & .mobile-menu-dropdown {
      padding: 0;
      & .navbar-nav.navbar-right {
        margin-top: 0 !important;
        width: 100%;
        margin-right: 0;
        & > li {
          height: unset;
          float: unset;
        }
      }
    }
  }
  @media (max-width: 767px) {
    box-shadow: rgb(0 0 0 / 16%) 0 0 6px;
    padding-bottom: 5px;
    & .wrap-header {
      height: unset;
    }
    & .mobile-menu-dropdown {
      margin: 0;
    }
    & .navbar-header {
      color: white;
      background-color: white;
      float: unset;
      margin: 0;
    }
    .navavatar {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 15px;
      padding-right: 5px !important;
      & .wp-icobar {
        padding-top: 0 !important;
        margin-right: 5px;
      }
    }
    .navbar-brand {
      padding: 5px 0 5px 10px;
    }
  }
`
export default connector(MyUIHeader)
