import MyPageOne from "./MyPage"
import MyUIOne from "./MyUI"
import MyTableCellOne from "./MyTableCell"
import MyTableFilterControlOne from "./MyTableFilterControl"
import MyFormOne from "./MyForm"
import MyButtonOne from "./MyButton"
import MyLoadingOne from "./MyLoading/index"
import MyControlOne from "./MyControl"
import "./assets/scss/theme-v1.scss"

interface IModuleV1Page extends IModuleOne {
  runAfterInit: () => void
}
const ModuleV1Page: IModuleV1Page = {
  runAfterInit: () => {
    MyControlOne.init()
    MyPageOne.init()
    MyUIOne.init()
    MyTableCellOne.init()
    MyFormOne.init()
    MyButtonOne.init()
    MyLoadingOne.init()
    MyTableFilterControlOne.init()
    window.document.body.classList.add("theme-v1")
  },
}

export default ModuleV1Page
