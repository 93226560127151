const kObjCategoryLocal = "category"
const kFirstSettingData = "FIRST_SETTING_DATA"
const kRoleUserActive = "roleUserActive"
const myuiOrderNav = "sg_nav_order"
const myuiDfNav = "sg_nav_default"

const rentalTypes = [
  {
    url: "/thue-xe-4-cho",
    name: "Thuê xe 4 chỗ",
  },
  {
    url: "/thue-xe-7-cho",
    name: "Thuê xe 7 chỗ",
  },
]
const timeRange = [
  {
    Text: "",
    Value: "",
  },
]
const mobileWidth = 820
const pageKey = {
  category: "category",
  home: "home",
  onlyHeader: "onlyHeader",
}
const headerTabList = [
  {
    key: "xe-tu-lai",
    title: "Xe tự lái",
    link: "/",
  },
  {
    key: "xe-co-tai",
    title: "Xe có tài",
    link: "/xe-co-tai",
  },
  {
    key: "thue-dai-han",
    title: "Thuê dài hạn",
    link: "/thue-dai-han",
  },
]
export {
  kObjCategoryLocal,
  rentalTypes,
  timeRange,
  kFirstSettingData,
  kRoleUserActive,
  myuiDfNav,
  myuiOrderNav,
  mobileWidth,
  pageKey,
  headerTabList,
}
