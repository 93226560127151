import * as types from "./App.constants"

export const logout = opts => ({
  type: types.LOGOUT,
  opts,
})

export const toggleSideNav = () => ({
  type: types.SIDE_NAV_CLOSE,
})

export const sideNavUpdate = data => ({
  type: types.SIDE_NAV_UPDATE,
  items: data,
})

export const fetchFirstSettingPending = () => {
  return {
    type: types.FETCH_FS_PENDING,
  }
}

export const fetchFirstSettingSuccess = data => {
  return {
    type: types.FETCH_FS_SUCCESS,
    data: data,
  }
}
export const initFirstSettingData = data => {
  return {
    type: types.INIT_FS_DATA,
    data: data,
  }
}

export const fetchFirstSettingError = error => {
  return {
    type: types.FETCH_FS_ERROR,
    error: error,
  }
}

export const updateHeaderTitle = title => ({
  type: types.HEADER_TITLE_UPDATE,
  title: title,
})

export const updateQuickOverviewHeader = data => ({
  type: types.HEADER_QUICKOVERVIEW_UPDATE,
  data: data,
})
export const changeUITypeNavbar = data => ({
  type: types.NAVBAR_UITYPE_UPDATE,
  data: data,
})
