import { HCF, HUtils } from "@macashipo/mlib"
import {
  KConfigApp,
  KConfigPageTableDefault,
  KConfigPageFilter,
  KConfigPageTable,
  KConfigPagePage,
  KConfigPageHeader,
  KUI,
} from "./K"
export const kfnUpdatePageTitle = "fnUpdatePageTitle"
export const kfnGetApiPath = "fnGetApiPath"
export const kfnGetScreenCode = "fnGetScreenCode"
export const kgetSourceList = "getSourceList"
export const kfnHandleSearchText = "fnHandleSearchText"
export const kfnLoadLocalFilter = "fnLoadLocalFilter"
export const kfnGetConfigPageScreenConfig = "fnGetConfigPageScreenConfig"
export const kfnGetConfigPageHeader = "fnGetConfigPageHeader"
export const kfnGetConfigPageTable = "fnGetConfigPageTable"
export const kfnGetConfigPageDetail = "fnGetConfigPageDetail"
export const kfnGetConfigPageFilter = "fnGetConfigPageFilter"
export const kfnGetConfigPagePage = "fnGetConfigPagePage"
export const kfnGetConfigChart = "kfnGetConfigChart"
export const kgetCurrentValues = "getCurrentValues"
export const kfnGetSourceData = "fnGetSourceData"
export const kfnRenderBadge = "fnRenderBadge"
export const kgetTabsIndexSelected = "getTabsIndexSelected"
export const kgetTabLevel = "getTabLevel"
export const konChangeTab = "onChangeTab"
export const kgetDefaultValues = "getDefaultValues"
export const kgetIsMobileByWidth = "getIsMobileByWidth"
export const kgetDefaultTypeHubFilter = "getDefaultTypeHubFilter"
export const kfnForceUpdateTable = "fnForceUpdateTable"
export const kgetDataList = "getDataList"
export const kfnGetComponentTable = "fnGetComponentTable"
export const kfnGetOnePage = "fnGetOnePage"
export const kfnGetOptions = "fnGetOptions"
export const kfnGetExtraDataTable = "fnGetExtraDataTable"
export const kfnGetDataTableFilterd = "fnGetDataTableFilterd"
export const kfnGetDataTable = "fnGetDataTable"
export const kaddFnListFromControl = "addFnListFromControl"
export const kresetFormControl = "resetFormControl"
export const kfnReloadList = "fnReloadList"
export const kfnReloadOptionsAndList = "fnReloadOptionsAndList"
export const kfnGetHeaderTitle = "fnGetHeaderTitle"
export const kfnGetConfigInMore = "fnGetConfigInMore"
export const kfnGetIsInTableExpand = "fnGetIsInTableExpand"
export const kfnGetIsInModal = "fnGetIsInModal"
export const kfnGetColumnSelect = "fnGetColumnSelect"
export const kfnGetConfigModal = "fnGetConfigModal"
export const kfnGetTypeWrapControl = "fnGetTypeWrapControl"
export const kfnGetIsHide = "fnGetIsHide"
export const kfnChangeSwitchView = "fnChangeSwitchView"
export const kfnGetCurrentSwitchView = "fnGetCurrentSwitchView"
export const kfnGetButtonList = "fnGetButtonList"
export const kfnRequestUpdateFromExpand = "fnRequestUpdateFromExpand"
const rf = (fnList, name, args) => {
  return HUtils.runFuntion(fnList, name, args)
}

const fnListModal = {
  showLoadingModal(fnList = {}, args = []) {
    HUtils.runFuntion(fnList, "showLoadingModal", args)
  },
  hideLoadingModal(fnList = {}, args = []) {
    HUtils.runFuntion(fnList, "hideLoadingModal", args)
  },
  hideModal(fnList = {}, args = []) {
    HUtils.runFuntion(fnList, "hideModal", args)
  },
}

const fnListPopover = {
  hidePopover(fnList = {}, args = []) {
    HUtils.runFuntion(fnList, "hidePopover", args)
  },
}
const fnListFilesManager = {
  onAfterAddFolderGGDrive(fnList = {}, args = []) {
    HUtils.runFuntion(fnList, "onAfterAddFolderGGDrive", args)
  },
}

/** MyForm */
const listFnMyForm = []

const kfnRenderFormControl = "fnRenderFormControl"
const fnListMyForm = {
  getSourceList(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kgetSourceList, args)
  },
  getCurrentValues(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kgetCurrentValues, args)
  },
  getDefaultValues(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kgetDefaultValues, args)
  },
  addFnListFromControl(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kaddFnListFromControl, args)
  },
  [kresetFormControl](fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kresetFormControl, args)
  },
  fnGetTypeWrapControl(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetTypeWrapControl, args)
  },
  fnRenderFormControl(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnRenderFormControl, args)
  },
}
if (listFnMyForm && listFnMyForm.length > 0) {
  for (let k of listFnMyForm) {
    fnListMyForm[k] = (fnList = {}, args = []) => {
      return HUtils.runFuntion(fnList, k, args)
    }
  }
}

// const fnListMyFormKeys = [kgetSourceList]
// for(let k of fnListMyFormKeys){
//   fnListMyForm[k] = (fnList, args)=>{
//     return rf(fnList,k,args)
//   }
// }

const fnListMyFormControl = {
  getSourceData(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, "getSourceData", args)
  },
  fnGetTypeWrapControl(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetTypeWrapControl, args)
  },
}

const fnListMyTableCell = {
  fnGetSourceData(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetSourceData, args)
  },
  fnRenderBadge(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnRenderBadge, args)
  },
  fnGetHeaderTitle(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetHeaderTitle, args)
  },
  fnGetConfigInMore(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetConfigInMore, args)
  },
  fnGetConfigModal(fnList = {}, args = []) {
    return rf(fnList, kfnGetConfigModal, args)
  },
  fnGetIsHide(fnList = {}, args = []) {
    return rf(fnList, kfnGetIsHide, args)
  },
}

export const kfnGetTitleControl = "fnGetTitleControl"
const fnListMyTableFilterControl = {
  fnGetTitleControl(fnList = {}, args = []) {
    return rf(fnList, kfnGetTitleControl, args)
  },
}

export const kfnGetSharedRequestData = "fnGetSharedRequestData"
export const kfnGetDataTotal = "fnGetDataTotal"
export const kfnGetTableCols = "fnGetTableCols"
const fnListMyPage = {
  fnGetSharedRequestData(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetSharedRequestData, args)
  },
  fnReloadList(fnList = {}, args = []) {
    console.warn("fnReloadList")
    //for old
    if (fnList["reloadTable"]) {
      return HUtils.runFuntion(fnList, "reloadTable", args)
    } else if (fnList["reloadList"]) {
      return HUtils.runFuntion(fnList, "reloadList", args)
    }
    return HUtils.runFuntion(fnList, kfnReloadList, args)
  },
  fnReloadOptionsAndList(fnList = {}, args = []) {
    //for old
    if (fnList["reloadOptionsAndList"]) {
      return HUtils.runFuntion(fnList, "reloadOptionsAndList", args)
    }
    return HUtils.runFuntion(fnList, kfnReloadOptionsAndList, args)
  },
  fnReloadPage(fnList = {}, args = []) {
    window.location.reload()
  },
  fnUpdatePageTitle(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnUpdatePageTitle, args)
  },
  fnGetApiPath(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetApiPath, args)
  },
  fnGetScreenCode(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetScreenCode, args)
  },
  getSourceList(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kgetSourceList, args)
  },
  fnHandleSearchText(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnHandleSearchText, args)
  },
  fnLoadLocalFilter(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnLoadLocalFilter, args)
  },
  fnGetConfigPageScreenConfig(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetConfigPageScreenConfig, args)
  },
  fnGetConfigPageHeader(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetConfigPageHeader, args)
  },
  fnGetConfigPageTable(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetConfigPageTable, args)
  },
  fnGetConfigPageDetail(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetConfigPageDetail, args)
  },
  fnGetConfigPageFilter(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetConfigPageFilter, args)
  },
  fnGetConfigPagePage(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetConfigPagePage, args)
  },
  fnGetConfigChart(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetConfigChart, args)
  },
  getTabsIndexSelected(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kgetTabsIndexSelected, args)
  },
  onChangeTab(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, konChangeTab, args)
  },
  getTabLevel(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kgetTabLevel, args)
  },
  fnForceUpdateTable(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnForceUpdateTable, args)
  },
  getDataList(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kgetDataList, args)
  },
  fnGetOnePage(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetOnePage, args)
  },
  fnGetOptions(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetOptions, args)
  },
  fnGetExtraDataTable(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetExtraDataTable, args)
  },
  fnGetDataTableFilterd(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetDataTableFilterd, args)
  },
  fnGetDataTable(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetDataTable, args)
  },
  fnGetComponentTable(fnList = {}, args = []) {
    return HUtils.runFuntion(fnList, kfnGetComponentTable, args)
  },
  fnGetDefaultScreenConfig(fnList = {}, args = []) {
    return rf(fnList, "fnGetDefaultScreenConfig", args)
  },
  fnGetIsInTableExpand(fnList = {}, args = []) {
    return rf(fnList, kfnGetIsInTableExpand, args)
  },
  fnGetIsInModal(fnList = {}, args = []) {
    return rf(fnList, kfnGetIsInModal, args)
  },
  fnChangeSwitchView(fnList = {}, args = []) {
    return rf(fnList, kfnChangeSwitchView, args)
  },
  fnGetCurrentSwitchView(fnList = {}, args = []) {
    return rf(fnList, kfnGetCurrentSwitchView, args)
  },
  fnGetButtonList(fnList = {}, args = []) {
    return rf(fnList, kfnGetButtonList, args)
  },
  fnGetDataTotal(fnList = {}, args = []) {
    return rf(fnList, kfnGetDataTotal, args)
  },
  fnGetTableCols(fnList = {}, args = []) {
    return rf(fnList, kfnGetTableCols, args)
  },
  fnRequestUpdateFromExpand(fnList = {}, args = []) {
    return rf(fnList, kfnRequestUpdateFromExpand, args)
  },
}

export const kgetHasLoginSocial = "getHasLoginSocial"
const fnListConfigApp = {
  getHasLoginSocial() {
    return (
      fnListConfigApp.getHasLoginSocialGoogle() ||
      fnListConfigApp.getHasLoginSocialFacebook()
    )
  },
  getHasLoginSocialGoogle() {
    return HCF.getCF(KConfigApp.hasLoginSocialGoogle) === true
  },
  getHasLoginSocialFacebook() {
    return HCF.getCF(KConfigApp.hasLoginSocialFacebook) === true
  },
  getHasForgotPassword() {
    return HCF.getCF(KConfigApp.hasForgotPassword) === true
  },
  getHasRegisterAccount() {
    return HCF.getCF(KConfigApp.hasRegisterAccount) === true
  },
  getGoogleClientID() {
    return HCF.getCF(KConfigApp.GoogleClientId)
  },
  getScreenWidth() {
    return window.document.body.offsetWidth
  },
  getIsMobileByWidth() {
    let _widthMobile = HCF.getCF(KConfigApp.widthMobile) || 567
    if (window.document.body.offsetWidth < _widthMobile) {
      return true
    }
    return false
  },
  getDefaultTypeHubFilter() {
    return HCF.getCF(KConfigApp.defaultTypeHubFilter)
  },
  getDefaultPositionFilter() {
    return HCF.getCF(KConfigApp.defaultPositionFilter)
  },
  getIsDevMode() {
    return HCF.getCF(KConfigApp.isDevMode)
  },
  getHomeUrl() {
    //home_url tu configApp, configAppExt, authInfo.configs. DefaultHomeURL tu FirstSetting
    return (
      HCF.getCF(KConfigApp.home_url) ||
      HCF.getCF(KConfigApp.DefaultHomeURL) ||
      "/"
    )
  },
  getAppLogo() {
    return HCF.getCF(KConfigApp.appLogo_url) || "/config/logo.png"
  },
  getAuthInfo() {
    return HCF.getCF(KConfigApp.AuthInfo)
  },
  getAppTitle() {
    return HCF.getCF(KConfigApp.title)
  },
  getVerAdmin() {
    return HCF.getCF(KConfigApp.verAdmin)
  },
}

export const fnGetConfigTable = (fnList, key) => {
  if (key) {
    let _configAll = fnListMyPage.fnGetConfigPageTable(fnList, [])
    if (_configAll && _configAll.hasOwnProperty(key)) {
      return _configAll[key]
    }
    if (KConfigPageTableDefault.hasOwnProperty(key)) {
      return KConfigPageTableDefault[key]
    }
  }
}

const fnListUI = {
  fnShowToast(msg, options, opts) {
    rf(HCF.CF, KUI.fnShowToast, [msg, options, opts])
  },
  fnshowDialog(configDialog, opts) {
    rf(HCF.CF, KUI.fnshowDialog, [configDialog, opts])
  },
  fnshowConfirm(configDialog, opts) {
    rf(HCF.CF, KUI.fnshowConfirm, [configDialog, opts])
  },
  fnShowLoadingScreen(opts) {
    rf(HCF.CF, KUI.fnShowLoadingScreen, [opts])
  },
  fnForceUpdateRoutes(opts) {
    rf(HCF.CF, KUI.fnForceUpdateRoutes, [opts])
  },
}

export {
  fnListModal,
  fnListPopover,
  fnListUI,
  fnListFilesManager,
  fnListMyForm,
  fnListMyFormControl,
  fnListMyPage,
  fnListMyTableCell,
  fnListConfigApp,
  fnListMyTableFilterControl,
  KConfigPageFilter,
  KConfigPageTable,
  KConfigPagePage,
  KConfigPageHeader,
}

window._fnListUI = fnListUI
