import React, { useRef, useEffect } from "react"
import { Fancybox } from "@fancyapps/ui"
// import "@fancyapps/ui/dist/fancybox/fancybox.css"
import "src/modules/app_sigo/assets/guest/css/fancybox.css"
import styled from "styled-components"

const MyUIFancybox = props => {
  const containerRef = useRef(null)
  useEffect(() => {
    const container = containerRef.current

    const delegate = props.delegate || "[data-fancybox]"
    const options = props.options || {}

    Fancybox.bind(container, delegate, options)

    return () => {
      Fancybox.unbind(container)
      Fancybox.close()
    }
  })
  return (
    <Wrap ref={containerRef}>
      {props.children({
        show: () => {
          if (props?.data?.length > 0) {
            Fancybox.show(
              props.data.map(v => {
                return {
                  src: v,
                }
              }),
              props?.options
            )
          }
        },
      })}
    </Wrap>
  )
}
const Wrap = styled.div``
export default MyUIFancybox
