import React from "react"
import { connect, ConnectedProps } from "react-redux"
import classnames from "classnames"
import { NavLink } from "react-router-dom"
import { PATH } from "src/helpers/paths"
import { isLocalDev } from "src/core/helpers/utils"
import { Menu, Nav, Sidebar, CloseSidebar } from "./SideNav.styles"
// import { HLog } from "src/core/helpers"
import SideNavHeader from "./SideNavHeader"
import { MyIcon } from "src/core/components"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { toggleSideNav } from "src/App/App.actions"

const mapStateToProps = state => ({
  closeSideNav: state.app.closeSideNav,
  sideNavItems: state.app.sideNavItems,
})

const mapDispatchToProps = {
  toggleSideNav,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface Props extends ConnectedProps<typeof connector> {}

function SideNav(props: Props) {
  const { closeSideNav, sideNavItems, toggleSideNav } = props
  if (closeSideNav === true) {
    window.document.body.classList.add("sidebar-close")
    window.document.body.classList.remove("sidebar-open")
  } else {
    window.document.body.classList.add("sidebar-open")
    window.document.body.classList.remove("sidebar-close")
  }
  return (
    <Sidebar>
      <Nav className={classnames("c-sidebar", closeSideNav ? "close" : "")}>
        {/* <h1>App</h1> */}
        <CloseSidebar>
          <button className="btn btn-primary" onClick={toggleSideNav}>
            <MdKeyboardArrowLeft size={26} />
          </button>
        </CloseSidebar>
        <SideNavHeader />
        <Menu className="list-unstyled mb-5">
          <li>
            <NavLink exact to={PATH.HOME}>
              <MyIcon
                configIcon="type:HiOutlineHome"
                style={{ marginRight: "5px" }}
              />
              <span>Home</span>
            </NavLink>
          </li>

          {sideNavItems &&
            sideNavItems.length > 0 &&
            sideNavItems.map((v, i) => {
              if (v.isDev !== true || isLocalDev()) {
                return (
                  <li key={i}>
                    <NavLink exact to={v.url}>
                      <MyIcon
                        configIcon="type:HiOutlineViewGrid"
                        style={{ marginRight: "5px" }}
                      />
                      <span>{v.name}</span>
                    </NavLink>
                  </li>
                )
              }
              return null
            })}
        </Menu>
        {/* <Footer>
        <p>Copyright ©{new Date().getFullYear()} All rights reserved</p>
      </Footer> */}
      </Nav>
      <div
        className={`overlay ${closeSideNav ? "close" : "open"}`}
        onClick={() => {
          toggleSideNav()
        }}
      ></div>
    </Sidebar>
  )
}

export default connector(SideNav)
