import React from "react"
import MyPageHelpers from "src/core/components/MyPage/MyPageHelpers"
import { CSuppense } from "src/core/components"

const PTable = React.lazy(() => import("./Types/Table"))
const PTab = React.lazy(() => import("./Types/Tab"))
const PPanel = React.lazy(() => import("./Types/Panel"))

const PTable_in_card = React.lazy(() => import("./Types/Table_in_card"))
const PTable_no_action = React.lazy(() => import("./Types/Table_no_action"))
const MyPageOne = {
  init() {
    MyPageHelpers.addTypes("table", props => {
      return CSuppense(props, PTable)
    })
    MyPageHelpers.addTypes("tab", props => {
      return CSuppense(props, PTab)
    })
    MyPageHelpers.addTypes("panel", props => {
      return CSuppense(props, PPanel)
    })

    //
    MyPageHelpers.addTypes("table_in_card", props => {
      return CSuppense(props, PTable_in_card)
    })
    MyPageHelpers.addTypes("table_no_action", props => {
      return CSuppense(props, PTable_no_action)
    })
  },
}

export default MyPageOne
