import { HUtils } from "@macashipo/mlib"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const MyUIHomeRentalCar = props => {
  const { data } = props
  const slideImages = data?.[homeSlidesFields.fSlideImages] || []
  let imgUrl = ""
  if (slideImages && slideImages?.length > 0) {
    imgUrl = slideImages[0]?.[homeSlidesFields.fOneSlideImageUrl]
  }
  const title = data?.[homeSlidesFields.fTitle]
  const description = data?.[homeSlidesFields.fDescription]
  const url = HUtils.get(data, "MoreInfo.url")
  return (
    <>
      {imgUrl && (
        <Wrap className="mui-home-rental-car" bgImage={imgUrl}>
          <div className="col-sm-12">
            <div className="bgchothue" style={{}}>
              <div className="row">
                <div className="col-lg-5">
                  <div className="title-home">
                    {title && <h2 className="maintxt">{title}</h2>}
                    {description && <p>{description}</p>}
                  </div>
                  <a
                    href={url}
                    className="btn btn-md btn-primary radius20px uppeercase btn-rental"
                  >
                    <h3>Đăng ký xe cho thuê</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Wrap>
      )}
    </>
  )
}
const Wrap: any = styled.div`
  padding: 0;
  & .bgchothue {
    background-image: url("${(props: any) => props.bgImage}");
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: cover;
    & .row {
      width: 100%;
    }
  }
  & .title-home {
    margin-bottom: 40px;
  }
  & .maintxt {
    color: #fff;
    margin-bottom: 30px;
  }
  & .btn-rental {
    h3 {
      font-size: 15px;
      color: white;
      line-height: 25px;
      margin-bottom: 0px;
    }
  }
  & .btn-rental:hover {
    border-color: transparent;
  }
`
export default MyUIHomeRentalCar
