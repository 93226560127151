import styled from "styled-components"
import { BREAKPOINT } from "src/helpers/styles"

export const Footer = styled.div`
  padding: 0 30px;
`

export const Menu = styled.ul`
  padding: 0;
  transition: 0.3s;
  li {
    font-size: 16px;
    > a {
      padding: 10px 30px;
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.6);
      // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      text-decoration: none;
      img {
        width: 20px;
        height: auto;
        display: inline-block;
        margin-right: 10px;
      }
      &:hover {
        background: #3699ff;
        color: #ffffff;
      }
      &.active {
        background: rgba(255, 255, 255, 0.05);
        color: #8abef5;
        font-weight: bold;
        position: relative;
      }
      &.active::after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        width: 4px;
        background-color: currentColor;
      }
    }
  }
`

export const Nav = styled.nav`
  min-width: 256px;
  max-width: 256px;
  background: #303c54;
  color: #fff;
  transition: all 0.3s;
  &.close {
    min-width: 0px;
    max-width: 0px;
    text-align: center;
    ${Menu} {
      li > a span {
        display: none;
      }
    }
    ${Footer} {
      display: none;
    }
  }
  @media (max-width: ${BREAKPOINT.MD - 1}px) {
    min-width: 0px;
    max-width: 0px;
    text-align: center;
    ${Menu} {
      li > a span {
        display: none;
      }
    }
    ${Footer} {
      display: none;
    }
    &.close {
      margin-left: -80px;
    }
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #303c54;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #242e40;
  }
`

export const Logo = styled.a`
  display: block;
  color: #fff;
  font-weight: 900;
  padding: 10px 30px;
  transition: 0.3s;
`
export const Sidebar = styled.div`
  position: relative;
  display: flex;
  & .overlay {
    display: none;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    top: 0;
    position: absolute;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 768px) {
    & .overlay.open {
      display: block;
    }
    & .c-sidebar {
      max-width: 256px;
      min-width: 256px;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 100;
      min-height: calc(100vh - 73px);
      height: 100%;
      overflow: auto;
    }
    & .c-sidebar.close {
      margin-left: -256px;
    }
    & .c-sidebar > ul > li > a span {
      display: block;
    }
  }
`
export const CloseSidebar = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0.5rem;
  & > button {
    background-color: #242e40;
    border-color: #242e40;
  }
  @media (max-width: 768px) {
    display: flex;
  }
`
