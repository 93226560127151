import React, { useEffect } from "react"
import { ConnectedProps, connect } from "react-redux"
import { Button, Input } from "reactstrap"
import { HApi } from "src/core/helpers"
import * as actions from "src/pages/Login/Login.actions"
import styled from "styled-components"
import { MyForm, MyModal, MyUI } from "src/core/components"
import { HCF, HUtils } from "@macashipo/mlib"
import {
  MdArrowBackIos,
  MdOutlineClose,
  MdOutlineRemoveRedEye,
} from "react-icons/md"
import ICGoogle from "src/modules/app_sigo/assets/imgs/ic-google.png"
import ICFacebook from "src/modules/app_sigo/assets/imgs/ic-facebook.png"
import { ImEyeBlocked } from "react-icons/im"
import { BiInfoCircle } from "react-icons/bi"
import { useForceUpdate } from "src/modules/app_sigo/hooks/useForceUpdate"
import { isNotEmpty } from "src/core/helpers/utils"
import classNames from "classnames"

const TabCode = {
  enter_phone: "enter_phone",
  enter_password: "enter_password",
  enter_otp: "enter_otp",
  user_register: "user_register",
  forgot_password: "forgot_password",
  enter_new_password: "enter_new_password",
}
export const loginSuccessWithResponse = (response: any) => dispatch => {
  dispatch(actions.fetchLoginSuccess(response))
}
const mapStateToProps = state => ({
  isAuthenticated: state.app.isAuthenticated,
})

const mapDispatchToProps = {
  loginSuccessWithResponse,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface Props extends ConnectedProps<typeof connector> {
  afterLogin?: any
  field?: any
  actionType?: any
  moreConfig?: any
}

const MyUILogin = (props: Props) => {
  const {
    field,
    actionType,
    afterLogin,
    loginSuccessWithResponse,
    moreConfig,
  } = props
  const [tabActive, setTabActive] = React.useState(TabCode.enter_phone)
  const [mobileNumber, setMobileNumber] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [showPwd, setShowPwd] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [msg, setMsg] = React.useState("")
  const [pwdMsg, setPwsMsg] = React.useState("")
  const [verifyToken, setVerifyToken] = React.useState("")
  const [dfResendTime, setDfResendTime] = React.useState(20)
  const [isForgotPwd, setIsForgotPwd] = React.useState(false)
  const dfMsgInfo = HUtils.get(HCF.getCF("websiteSetting"), "auth") || {}
  const [keyPwd, setKeyPwd] = useForceUpdate()
  const [invalidMobileMsg, setInvalidMobileMsg] = React.useState({} as any)

  useEffect(() => {
    // Clear mọi thứ và password khi back về nhập số điện thoại
    setPassword("")
    setPwsMsg("")
    setKeyPwd()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabActive])
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => window.removeEventListener("beforeunload", handleBeforeUnload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileNumber])

  // const checkValidMobile = value => {
  //   let regexPhoneNumber =
  //     /^(0?)(3[2-9]|5[6|8|9|2]|7[0|6-9]|8[1-9]|9[0-4|6-9])[0-9]{7}$/
  //   if (dfMsgInfo?.regex_check_phone_number) {
  //     regexPhoneNumber = new RegExp(dfMsgInfo?.regex_check_phone_number)
  //   }
  //   return value.match(regexPhoneNumber) ? true : false
  // }
  // const handleMobileNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const newValue: any = event.target.value.replace(/\s/g, "")
  //   if (!newValue) {
  //     setMsg("Bạn chưa điền số điện thoại!")
  //   } else if (checkValidMobile(newValue) === false) {
  //     setMsg("Số điện thoại của bạn không đúng định dạng!")
  //   } else {
  //     setMsg("")
  //   }
  //   setMobileNumber(newValue)
  // }
  /** Request */
  const requestGetOTP = ({ cusActionType }: any = {}) => {
    let _actionType = actionType
    if (cusActionType) {
      _actionType = cusActionType
    }
    if (field && _actionType && mobileNumber) {
      setLoading(true)
      HApi.apiPost({
        path: "Account",
        name: "GetOTP",
        data: {
          [field]: mobileNumber,
          ActionType: _actionType,
        },
      })
        .then(response => {
          const data = response?.Data
          setLoading(false)
          if (data) {
            const nextScreen = data?.NextScreen
            const _verifyToken = data?.VerifyToken
            const _dfResendTime = data?.PinResendInSecond
            if (_verifyToken) {
              setVerifyToken(_verifyToken)
            }
            if (nextScreen) {
              setTabActive(nextScreen)
            }
            if (isNotEmpty(_dfResendTime)) {
              setDfResendTime(_dfResendTime)
            }
            // else {
            //   setDfResendTime(20)
            // }
          }
        })
        .catch(error => {
          // phone number is in blacklist
          // const _msg = error?.msg
          // if (_msg) {
          //   setMsg(_msg)
          // }
          setLoading(false)
        })
    }
  }
  const requestVerifyOTP = (otp, { cbError, cbSuccess }: any = {}) => {
    let _actionType = actionType
    let _verifyToken = verifyToken
    // Trường hợp verify OTP của Forgot password
    if (isForgotPwd) {
      _actionType = "forgot_password"
    }
    if (_verifyToken && otp && _actionType) {
      HApi.apiPost({
        path: "Account",
        name: "VerifyOTP",
        data: {
          VerifyToken: _verifyToken,
          ActionType: _actionType,
          OTP: otp,
        },
      })
        .then(response => {
          const data = response?.Data
          const nextScreen = data?.NextScreen
          if (nextScreen) {
            // if (onSetTabActive) {
            //   onSetTabActive(nextScreen)
            // } else {
            //   setTabActive(nextScreen)
            // }
            setTabActive(nextScreen)
          }
          cbSuccess && cbSuccess()
        })
        .catch(error => {
          const rs = error?.response
          const data = rs?.data?.Data || {}
          const _dfResendTime = data?.PinResendInSecond
          if (isNotEmpty(_dfResendTime)) {
            setDfResendTime(_dfResendTime)
          }
          const _msg = error?.msg
          cbError && cbError(_msg)
        })
    } else {
      cbError && cbError()
    }
  }
  const requestResendOTP = ({
    cbError,
    cbSuccess,
    onSetVerifyToken,
    cusMobileNumber,
    cusActionType,
  }: any = {}) => {
    let _mobileNumber = mobileNumber
    let _actionType = actionType
    if (isForgotPwd) {
      _actionType = "forgot_password"
    }
    if (_mobileNumber && _actionType) {
      setLoading(true)
      HApi.apiPost({
        path: "Account",
        name: "GetOTP",
        data: {
          ActionType: _actionType,
          MobilePhone: _mobileNumber,
        },
      })
        .then(response => {
          const data = response?.Data
          setLoading(false)
          const _verifyToken = data?.VerifyToken
          const _dfResendTime = data?.PinResendInSecond
          if (_verifyToken) {
            if (onSetVerifyToken) {
              onSetVerifyToken(_verifyToken)
            } else {
              setVerifyToken(_verifyToken)
            }
          }
          if (isNotEmpty(_dfResendTime)) {
            setDfResendTime(_dfResendTime)
          }
          cbSuccess && cbSuccess()
        })
        .catch(error => {
          cbError && cbError()
        })
    } else {
      cbError && cbError()
    }
  }
  const requestCreateAccount = (data, { cbError, cbSuccess }: any = {}) => {
    if (mobileNumber && data) {
      HApi.apiPost({
        path: "Account",
        name: "Register",
        data: {
          PhoneNumber: mobileNumber,
          ...data,
        },
      })
        .then(response => {
          loginSuccessWithResponse(response.Data)
          afterLogin && afterLogin()
          cbSuccess && cbSuccess()
        })
        .catch(error => {
          const _msg = error?.msg
          cbError && cbError(_msg)
        })
    } else {
      cbError && cbError()
    }
  }
  const requestForgotPassword = (
    mobileNumber,
    { cbError, cbSuccess }: any = {}
  ) => {
    if (mobileNumber) {
      HApi.apiPost({
        path: "Account",
        name: "GetOTP",
        data: {
          MobilePhone: mobileNumber,
          ActionType: "forgot_password",
        },
      })
        .then(response => {
          const data = response?.Data
          const nextScreen = data?.NextScreen
          const verifyToken = data?.VerifyToken
          const dfResendTime = data?.PinResendInSecond
          cbSuccess && cbSuccess({ nextScreen, verifyToken, dfResendTime })
        })
        .catch(error => {
          const _msg = error?.msg
          cbError && cbError(_msg)
        })
    }
  }
  const requestSetNewPwd = (data, { cbSuccess, cbError }: any = {}) => {
    if (data) {
      HApi.apiPost({
        path: "Account",
        name: "ResetPassword",
        data: {
          ...data,
        },
      })
        .then(response => {
          const data = response?.Data
          if (data) {
            setTabActive(TabCode.enter_phone)
          }
          loginSuccessWithResponse(response.Data)
          afterLogin && afterLogin()
          cbSuccess && cbSuccess()
        })
        .catch(error => {
          const _msg = error?.msg
          cbError && cbError(_msg)
        })
    } else {
      cbError && cbError()
    }
  }
  const requestLogin = () => {
    if (!getDisabledLoginBtn()) {
      setLoading(true)
      HApi.apiPost({
        path: "Account",
        name: "Login",
        data: {
          MobilePhone: mobileNumber,
          Password: password,
        },
      })
        .then(response => {
          setLoading(false)
          loginSuccessWithResponse(response.Data)
          afterLogin && afterLogin()
        })
        .catch(error => {
          setLoading(false)
          const _msg = error?.msg
          if (_msg) {
            setPwsMsg(_msg)
          }
        })
    }
  } /** Get */
  const getDisabledLoginBtn = () => {
    if (loading || !password || msg) {
      return true
    }
    return false
  }
  const getConfigFormEnterPhone = () => {
    let configInputPhoneNumber: any = {}
    if (dfMsgInfo?.config_input_phone_number) {
      configInputPhoneNumber = {
        ...dfMsgInfo?.config_input_phone_number,
      }
    }
    if (moreConfig?.notFocus) {
      configInputPhoneNumber = {
        ...configInputPhoneNumber,
        autoFocus: false,
      }
    }
    return {
      defaultValues: {
        [field]: mobileNumber,
      },
      controls: [
        {
          type: "input_phone_number",
          fieldName: field,
          more: {
            needUpdateImmediately: true,
            ui: {
              showPrefix: true,
              iconPrefix: "+84",
              ...invalidMobileMsg,
            },
            handleKeyDown: e => {
              if (!getDisabledContinueBtn()) {
                handlePressEnter(e, handleChkValidMobieNumber)
              }
            },
            disabled: loading,
            ...configInputPhoneNumber,
          },
        },
      ],
    }
  }
  const getRegexPhoneNumber = () => {
    let regexPhoneNumber =
      /^(0|\+?84)?(3[2-9]|5[6|8|9|2]|7[0|6-9]|8[1-9]|9[0-4|6-9])[0-9]{7}$/
    if (dfMsgInfo?.regex_check_phone_number) {
      regexPhoneNumber = new RegExp(dfMsgInfo?.regex_check_phone_number)
    }
    return regexPhoneNumber
  }
  const getDisabledContinueBtn = () => {
    if (loading || msg || invalidMobileMsg?.showError) {
      return true
    }
    return false
  }
  const getConfigFromWebsiteSettings = key => {
    return HUtils.get(dfMsgInfo, key)
  }
  const getDescriptionWithField = (key, field, data) => {
    let msg = getConfigFromWebsiteSettings(key)
    if (msg) {
      return msg?.replace(`[${field}]`, data)
    }
    return ""
  }
  /** Other function */
  const onHandleClose = () => {
    MyModal.hideModalMyUI()
  }
  const onFormatMobileNumber4UI = mobile => {
    let cleaned = ("" + mobile).replace(/\\D/g, "")
    let regex = /(\d{4})(\d{3})(\d{3})/
    let breakCharacter = " "
    // Lấy config từ website setting
    const config = dfMsgInfo?.config_format_phonenumber_ui || {}
    if (config?.regex) {
      regex = new RegExp(config?.regex)
    }
    if (config?.break_character) {
      breakCharacter = config?.break_character
    }
    let match = cleaned.match(regex)
    if (match) {
      return match[1] + breakCharacter + match[2] + breakCharacter + match[3]
    }
    return mobile
  }
  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPwsMsg("")
    setPassword(event.target.value)
  }
  const handleForgetPassword = () => {
    requestGetOTP({ cusActionType: "forgot_password" })
    setIsForgotPwd(true)
  }
  const handlePressEnter = (e, fnClick) => {
    const dfConfig = dfMsgInfo?.go_next_after_press_enter
    if (e.key === "Enter" && dfConfig) {
      fnClick && fnClick()
    }
  }
  const handleBeforeUnload = e => {
    if (mobileNumber) {
      e.preventDefault()
      e.returnValue = true
    }
  }
  const handleChkValidMobieNumber = () => {
    const regex = getRegexPhoneNumber()
    const configMsgError = getConfigFromWebsiteSettings(
      "config_msg_input_mobile_phone"
    )
    let isValid = true
    let msg = {
      showError: false,
      textError: "",
    }
    if (!mobileNumber) {
      msg.showError = true
      msg.textError = configMsgError?.whenNull || ""
      isValid = false
    } else if (!regex?.test(mobileNumber)) {
      isValid = false
      msg.showError = true
      msg.textError = configMsgError?.whenInvalid || ""
    } else {
      isValid = true
    }
    if (isValid) {
      requestGetOTP()
    } else {
      setInvalidMobileMsg(msg)
    }
  }
  /** Render */

  const renderTabEnterPhone = () => {
    const isActive = tabActive === TabCode.enter_phone
    const description = dfMsgInfo?.enter_phone_number_des || ""
    const showOtherMethod = dfMsgInfo?.show_other_login_method
    const { configHeaderTitle, classNameHeader } = moreConfig || {}
    let title = "Đăng nhập hoặc đăng ký"
    if (configHeaderTitle) {
      title = configHeaderTitle
    }
    return (
      <MyUI
        type="sg_loading_overlay"
        // isLoading={loading}
        showLoadingIcon={false}
      >
        <WrapEnterPhone
          className="tabdk active"
          id="tabdk1"
          style={{ display: isActive ? "block" : "none" }}
        >
          <div
            className={classNames("header", classNameHeader && classNameHeader)}
          >
            {!configHeaderTitle && (
              <Button color="hide-modal" onClick={onHandleClose}>
                <MdOutlineClose />
              </Button>
            )}
            <div className="title">{title}</div>
          </div>
          <div className="body">
            <div className="welcome-text">Chào mừng bạn đến với SIGO!</div>
            <MyForm
              configForm={getConfigFormEnterPhone() as any}
              fnList={
                {
                  afterUpdateValueControl: (opts: any) => {
                    const { newValue } = opts
                    setMobileNumber(newValue)
                    if (newValue !== mobileNumber) {
                      setInvalidMobileMsg({})
                    }
                  },
                } as any
              }
              renderFooter={() => {
                return <></>
              }}
            />
            <div
              className="description mb-25px"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
            <MyUI
              type="sg_button"
              onClick={handleChkValidMobieNumber}
              color="primary-v2"
              className="btn-lg btn-block getotp"
              disabled={getDisabledContinueBtn()}
              loading={loading}
              btnName="Tiếp tục"
              loadingColor={"#fff"}
            />
            {showOtherMethod && (
              <div className="other-method ">
                <div className="line-divider mt-25px mb-25px">hoặc</div>
                <Button color="outline" className="btn-block mb-15px">
                  <img src={ICGoogle} className="ic-btn" alt="ic-google" />
                  Đăng nhập bằng Google
                </Button>
                <Button color="outline" className="btn-block">
                  <img src={ICFacebook} className="ic-btn" alt="ic-facebook" />
                  Đăng nhập bằng Facebook
                </Button>
              </div>
            )}
          </div>
        </WrapEnterPhone>
      </MyUI>
    )
  }
  const renderTabEnterPwd = () => {
    const isActive = tabActive === TabCode.enter_password
    const des = getDescriptionWithField(
      "msg_des_enter_pwd",
      "MobileNumber",
      onFormatMobileNumber4UI(mobileNumber)
    )
    return (
      <MyUI
        type="sg_loading_overlay"
        // isLoading={loading}
        showLoadingIcon={false}
      >
        <WrapEnterPassword
          className="tabdk "
          id="tabdklogin"
          style={{ display: isActive ? "block" : "none" }}
        >
          <div className="header">
            <Button
              color="hide-modal"
              onClick={() => {
                setTabActive(TabCode.enter_phone)
              }}
            >
              <MdArrowBackIos />
            </Button>
            <div className="title">Nhập mật khẩu của bạn</div>
          </div>
          <div className="body">
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: des }}
            ></div>
          </div>
          <div className="form-pwd mb-25px">
            <div className="form-group relative">
              <div
                className="btn-link hienpass"
                onClick={() => {
                  setShowPwd(!showPwd)
                }}
              >
                {showPwd ? <MdOutlineRemoveRedEye /> : <ImEyeBlocked />}
              </div>
              <Input
                key={keyPwd}
                type={showPwd ? "text" : "password"}
                placeholder="Mật khẩu"
                autoComplete="off"
                autoFocus
                value={password}
                name="pass"
                data-divmessage="#messtaotk"
                className="line-bottom inputpwd"
                onChange={handlePassword}
                disabled={loading}
                onKeyDown={e => {
                  handlePressEnter(e, requestLogin)
                }}
              />
            </div>
            {pwdMsg && (
              <Error className="__msgerr">
                <BiInfoCircle />
                <MyUI type="html" html={pwdMsg} style={{ flex: 1 }} />
              </Error>
            )}
          </div>
          <Button
            color="underline-black"
            className=""
            onClick={handleForgetPassword}
          >
            Quên mật khẩu
          </Button>
          <hr className="divider-line solid mb-25px"></hr>
          <MyUI
            type="sg_button"
            onClick={requestLogin}
            color="primary-v2"
            className="btn-lg btn-block btnlogin"
            disabled={getDisabledLoginBtn()}
            loading={loading}
            btnName="Đăng nhập"
            loadingColor={"#fff"}
          />
        </WrapEnterPassword>
      </MyUI>
    )
  }
  const renderTabEnterOtp = () => {
    const isActive = tabActive === TabCode.enter_otp
    return (
      <MyUI
        type="sg_enter_otp"
        isActive={isActive}
        mobileNumber={mobileNumber}
        verifyToken={verifyToken}
        dfResendTime={dfResendTime}
        fnList={{
          onVerifyOTP: requestVerifyOTP,
          onGoBack: () => {
            setTabActive(TabCode.enter_phone)
            setIsForgotPwd(false)
          },
          onResendOTP: requestResendOTP,
          onFormatMobileNumber4UI: onFormatMobileNumber4UI,
          handlePressEnter: (e, func) => {
            handlePressEnter(e, func)
          },
          onGetDescription: () => {
            return getDescriptionWithField(
              "msg_enter_otp",
              "MobileNumber",
              onFormatMobileNumber4UI(mobileNumber)
            )
          },
        }}
      />
    )
  }
  const renderTabUserRegister = () => {
    const isActive = tabActive === TabCode.user_register
    return (
      <MyUI
        type="sg_user_register"
        isActive={isActive}
        fnList={{
          onCreateAccount: requestCreateAccount,
          onGoBack: () => {
            setTabActive(TabCode.enter_phone)
          },
          handlePressEnter: (e, func) => {
            handlePressEnter(e, func)
          },
        }}
      />
    )
  }
  // const renderTabForgotPwd = () => {
  //   const isActive = tabActive === TabCode.forgot_password
  //   return (
  //     <MyUI
  //       type="sg_forgot_pwd"
  //       isActive={isActive}
  //       fnList={{
  //         onGoBack: () => {
  //           setTabActive(TabCode.enter_phone)
  //         },
  //         checkValidMobile: checkValidMobile,
  //         requestForgotPassword: requestForgotPassword,
  //         requestVerifyOTP: requestVerifyOTP,
  //         requestResendOTP: requestResendOTP,
  //         requestSetNewPwd: requestSetNewPwd,
  //       }}
  //     />
  //   )
  // }
  const renderTabEnterNewPwd = () => {
    const _isActive = tabActive === TabCode.enter_new_password
    return (
      <MyUI
        type="sg_enter_new_pwd"
        isActive={_isActive}
        fnList={{
          onGoBack: () => {
            setTabActive(TabCode.enter_phone)
            setIsForgotPwd(false)
          },
          requestSetNewPwd: (data, { cbError, cbSuccess }) => {
            let _data = { ...data, MobilePhone: mobileNumber }
            requestSetNewPwd(_data, {
              cbError,
              cbSuccess,
            })
          },
          handlePressEnter: (e, func) => {
            handlePressEnter(e, func)
          },
          onGetDescription: () => {
            return getDescriptionWithField(
              "msg_description_change_pwd",
              "MobileNumber",
              onFormatMobileNumber4UI(mobileNumber)
            )
          },
        }}
      />
    )
  }
  return (
    <Wrap className="sg_login">
      {renderTabEnterPhone()}
      {renderTabEnterOtp()}
      {renderTabEnterPwd()}
      {renderTabUserRegister()}
      {/* {renderTabForgotPwd()} */}
      {renderTabEnterNewPwd()}
    </Wrap>
  )
}
const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-danger);
  font-size: 13px;
  margin: 5px 0;
  & > svg {
    margin-right: 0.25rem;
    width: 15px;
    height: 15px;
  }
`
const WrapEnterPhone = styled.div`
  & .welcome-text {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-text-000);
  }
  & .my-form {
    margin-top: 20px;
  }
  & .description {
    font-size: 14px;
    color: var(--color-text-000);
    a {
      font-weight: 700;
      text-decoration: underline;
      color: var(--color-text-000);
    }
    a:hover {
      color: var(--mau2);
    }
  }
  & .getotp {
    color: var(--color-text-fff);
    font-weight: 700;
    font-size: 16px;
  }
  & .line-divider {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }
  .line-divider::before,
  .line-divider::after {
    flex: 1;
    content: "";
    padding: 1px 0 0;
    background-color: var(--color-divider);
  }
  .line-divider::before {
    margin-right: 25px;
  }
  .line-divider::after {
    margin-left: 25px;
  }
  & .other-method {
    button {
      position: relative;
    }
    & .ic-btn {
      width: 25px;
      position: absolute;
      left: 20px;
    }
  }
  @media (max-width: 767px) {
    & .my-form {
      margin-top: 15px;
    }
    .mb-25px {
      margin-bottom: 15px;
    }
    & .line-divider {
      margin-top: 15px;
    }
  }
`
const WrapEnterPassword = styled.div`
  & .description {
    color: var(--color-text-000);
    // span {
    //   font-weight: 600;
    // }
  }
  & .inputpwd {
    border: 1px solid #959292;
    border-radius: 8px;
    min-height: 46px;
    padding: 8px 12px;
  }
  // & .inputpwd:focus {
  //   border: 1px solid #959292;
  // }
  & .hienpass {
    bottom: 11px;
    color: var(--color-ic-show-pwd);
  }
`
const Wrap = styled.div`
  & .myui-wrap-loading-overlay {
    border-radius: 20px;
    & .a-overlay {
      border-radius: 20px;
    }
  }
  & .btn-sg {
    min-height: 43px;
  }
  & .form-control {
    background-color: transparent;
  }
  & .form-group {
  }
  & .btnchangetab {
    &.sm {
      font-size: 17px;
      line-height: 25px;
    }
  }
  & .info-msg {
    margin-top: -30px;
    font-style: italic;
    font-size: 13px;
    padding-bottom: 25px;
    display: block;
  }

  & .header {
    position: relative;
    margin: -25px -25px 0;
    padding: 25px;
    border-bottom: 1px solid var(--color-divider);
    & .title {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      color: var(--color-text-000);
    }
    & .btn-hide-modal {
      position: absolute;
      background-color: transparent;
      color: var(--color-text-000);
      box-shadow: none;
      font-size: 20px;
      padding: 0;
      top: 21px;
      border: none;
    }
    & .btn-hide-modal:hover {
      color: var(--color-text-000);
      box-shadow: none;
      background-color: transparent;
    }
  }
  & .body {
    padding: 25px 0;
  }
  & .btn-primary-v2 {
    color: var(--color-text-fff);
    font-weight: 700;
    font-size: 16px;
  }
  @media (max-width: 767px) {
    & .header {
      margin: -15px -15px 0;
      padding: 15px;
      & .btn-hide-modal {
        position: absolute;
        background-color: transparent;
        color: var(--color-text-000);
        box-shadow: none;
        font-size: 20px;
        padding: 0;
        top: 11px;
      }
    }
    & .body {
      padding: 15px 0;
    }
  }
`
export default connector(MyUILogin)
