import classNames from "classnames"
import styled from "styled-components"

const MyUIContentOverlay = props => {
  const { canShow, children, id, classNameWrap, renderMore } = props
  if (children && id) {
    return (
      <Wrap
        className={classNames(
          "wrap-content-overlay overlay",
          canShow ? "show" : "",
          classNameWrap ? classNameWrap : ""
        )}
        id={id}
      >
        {renderMore && renderMore()}
        {children}
      </Wrap>
    )
  }
  return <></>
}
const Wrap = styled.div`
  &.overlay {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 65px;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.7s;
    padding: 0;
    opacity: 1;
    left: -100%;
    width: 100%;
    &.header-no-search {
      top: 60px;
    }
    &.wrap-order-detail {
      height: auto;
      bottom: 0;
      top: 60px;
      padding-top: 0;
      background: #f8f8f8;
      padding-bottom: 0;
    }
  }
  &.show {
    width: 100%;
    padding-top: 30px;
    opacity: 1;
    left: 0;
    padding-bottom: 70px;
  }
  & .hide {
    display: none;
  }
  @media (max-width: 1010px) {
    &.overlay {
      &.wrap-order-detail {
        top: 0;
        bottom: 0;
      }
    }
  }
  @media (max-width: 820px) {
    &.overlay {
      top: 53px;
      &.header-no-search {
        top: 49px;
      }
    }
    &.show {
      padding-top: 15px;
    }
  }
  @media (max-width: 767px) {
    &.overlay {
      &.header-no-search {
        top: 53px;
      }
    }
  }
`
export default MyUIContentOverlay
