import React from "react"
import styled from "styled-components"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import { HLink, HUtils, MyCard } from "@macashipo/mlib"
import classnames from "classnames"

const ImageRowContentCard = props => {
  const { data, configMoreInfo, config } = props
  const imageUrl = data?.[homeSlidesFields.fOneSlideImageUrl]
  const itemConfig = HUtils.get(configMoreInfo, "websiteSetting.itemConfig")
  const dataConfig = HUtils.get(data, "MoreInfo.websiteSetting")
  const contentData = data?.Data ?? []

  const renderContent = () => {
    if (contentData?.length > 0) {
      return (
        <WrapContent
          className="wrap-content"
          numberOfItems={contentData?.length}
        >
          {contentData.map((v, i) => {
            return (
              <div className="item" key={i}>
                <MyCard type={dataConfig?.cardType} data={v} />
              </div>
            )
          })}
        </WrapContent>
      )
    }
    return <></>
  }
  return (
    <Wrap
      className={classnames(
        "image-row-content-card",
        itemConfig?.className,
        config?.className
      )}
      style={itemConfig?.wrapStyle}
    >
      {imageUrl && (
        <div className="wrap-img">
          <img src={imageUrl} alt="iphone" />
        </div>
      )}
      {renderContent()}
    </Wrap>
  )
}
const WrapContent: any = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(
    ${(props: any) => (props?.numberOfItems < 3 ? props?.numberOfItems : 3)},
    minmax(0px, 1fr)
  );
  gap: 1rem;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
  margin-top: 1rem;
  @media (max-width: 996px) {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
`
const Wrap = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & .description {
    padding-left: 1rem;
    color: var(--color-text-000);
  }
  @media (max-width: 1023px) {
  }
  @media (max-width: 576px) {
  }
`
export default ImageRowContentCard
