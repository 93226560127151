import HConstant from "./constant"
const C: {
  HConfig: IHConfig | null
  initC: (HConfig: IHConfig) => void
  getAuthUserName: () => any
  getAuthUserId: () => any
  getConfig: (key: string) => any
  getDefaultFormatDate: () => string
} = {
  HConfig: null,
  initC(HConfig: IHConfig) {
    C.HConfig = HConfig
  },
  getAuthUserName() {
    return C.HConfig?.getAuthUserName()
  },
  getAuthUserId() {
    return C.HConfig?.getAuthUserId()
  },
  getConfig(key: string) {
    return C.HConfig?.getConfig(key)
  },
  getDefaultFormatDate() {
    return C.HConfig?.getConfig(HConstant.ConfigAppExt.dateFormat) || "DD-MMMYY"
  },
}

export default C
