import { MyModal } from "src/core/components"

export const showAlert = ({ component, className = "", opts = {} }) => {
  MyModal.showMyDialog({
    title: "",
    component: component,
    size: "md",
    showFooter: false,
    showHeader: false,
    modalClassName: className,
    ...opts,
  })
}
