import React from "react"
import styled from "styled-components"
const MyUIBanner = props => {
  const { bgImg, children } = props
  return (
    <Wrap
      id="slider"
      style={
        bgImg
          ? {
              background: `url('${bgImg}') no-repeat top center`,
              backgroundSize: "auto 100%",
            }
          : {}
      }
    >
      {children}
    </Wrap>
  )
}
const Wrap = styled.div``
export default MyUIBanner
