import React from "react"
import { Input } from "reactstrap"
import Ext_Input from "./Ext_Input"
class InputText extends Ext_Input {
  render() {
    const propsInput = this.getPropsInput()
    if (propsInput?.disabled) {
      return <input type="text" className="form-control" {...propsInput} />
    }
    return <Input {...propsInput} type="text" />
  }
}
export default InputText
