import React from "react"
import { connect, ConnectedProps } from "react-redux"
import IMGlogo from "src/assets/images/logo.png"
// import { HLog } from "src/core/helpers"
const mapStateToProps = state => ({
  closeSideNav: state.app.closeSideNav,
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface Props extends ConnectedProps<typeof connector> {}

function SideNavHeader(props: Props) {
  // const { closeSideNav } = props
  return (
    <div className="p-3">
      <img
        src={IMGlogo}
        alt="logo"
        style={{ width: "100%", maxHeight: "40px", objectFit: "contain" }}
      />
    </div>
  )
}

export default connector(SideNavHeader)
