import React from "react"
const MC_ic_long_term_rent = props => {
  const { fill, style } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="113"
      height="88"
      viewBox="0 0 113 88"
      fill="none"
    >
      <g clip-path="url(#clip0_664_162)">
        <path
          d="M11.1941 20.2417H14.9328V52.7553H11.1941V20.2417Z"
          fill="#616484"
        />
        <path
          d="M98.0672 20.2417H101.806V52.7553H98.0672V20.2417Z"
          fill="#424566"
        />
        <path
          d="M104.044 20.2417H8.95614C7.08237 20.2417 5.56172 19.0592 5.56172 17.5983V2.65891C5.56172 1.19969 7.08016 0.0154724 8.95614 0.0154724H104.044C105.918 0.0154724 107.438 1.19797 107.438 2.65891V17.5983C107.438 19.0592 105.918 20.2417 104.044 20.2417Z"
          fill="#E6EAFF"
        />
        <path
          d="M104.044 0.0171814H56.5V20.2417H104.044C105.918 20.2417 107.436 19.0592 107.436 17.6V2.66062C107.436 1.19968 105.918 0.0171814 104.044 0.0171814Z"
          fill="#CAD4FF"
        />
        <path
          d="M46.5684 6.09811V9.27093H50.1592C50.9515 9.27093 51.5938 9.77108 51.5938 10.3881C51.5938 11.0051 50.9515 11.5053 50.1592 11.5053H46.5684V14.6781H53.0813C53.8736 14.6781 54.5159 15.1783 54.5159 15.7953C54.5159 16.4123 53.8736 16.9125 53.0813 16.9125H45.1338C44.3415 16.9125 43.6992 16.4123 43.6992 15.7953V4.98093C43.6992 4.3639 44.3415 3.86374 45.1338 3.86374H53.0813C53.8736 3.86374 54.5159 4.3639 54.5159 4.98093C54.5159 5.59796 53.8736 6.09811 53.0813 6.09811H46.5684Z"
          fill="#616484"
        />
        <path
          d="M69.1617 3.86374C68.3694 3.86374 67.7272 4.3639 67.7272 4.98093V11.6566L62.6245 4.82968C62.2979 4.39311 61.6358 4.18171 61.0112 4.31405C60.3866 4.4464 59.9496 4.88811 59.9496 5.39343V15.7953C59.9496 16.4123 60.5918 16.9125 61.3842 16.9125C62.1765 16.9125 62.8187 16.4123 62.8187 15.7953V9.53046L67.9236 16.3573C68.1862 16.708 68.6629 16.9125 69.1639 16.9125C69.2875 16.9125 69.4133 16.9005 69.5369 16.873C70.1637 16.7406 70.5985 16.2989 70.5985 15.7936V4.98093C70.5963 4.3639 69.9541 3.86374 69.1617 3.86374Z"
          fill="#424566"
        />
        <path
          d="M32.9179 11.5071H33.3593C36.0629 11.5071 38.2633 9.79182 38.2633 7.68635C38.2633 5.57916 36.0629 3.86557 33.3593 3.86557H27.237C26.4446 3.86557 25.8024 4.36573 25.8024 4.98276V15.7971C25.8024 16.4142 26.4446 16.9143 27.237 16.9143C28.0293 16.9143 28.6715 16.4142 28.6715 15.7971V11.5071H28.9849L35.0013 16.5053C35.2816 16.7407 35.6656 16.8593 36.0474 16.8593C36.3984 16.8593 36.7515 16.7596 37.0274 16.5568C37.6056 16.1357 37.6365 15.4276 37.0936 14.9773L32.9179 11.5071ZM28.6715 9.27276V6.09995H33.3593C34.4804 6.09995 35.3942 6.81151 35.3942 7.68635C35.3942 8.5612 34.4804 9.27276 33.3593 9.27276H28.6715Z"
          fill="#616484"
        />
        <path
          d="M85.7608 3.86374H77.4646C76.6723 3.86374 76.03 4.3639 76.03 4.98093C76.03 5.59796 76.6723 6.09811 77.4646 6.09811H80.1793V15.7953C80.1793 16.4123 80.8215 16.9125 81.6138 16.9125C82.4061 16.9125 83.0484 16.4123 83.0484 15.7953V6.09811H85.763C86.5554 6.09811 87.1976 5.59796 87.1976 4.98093C87.1976 4.3639 86.5532 3.86374 85.7608 3.86374Z"
          fill="#424566"
        />
        <path
          d="M106.913 87.9828H100.034C98.3585 87.9828 97.0012 86.9258 97.0012 85.6212V69.0405C97.0012 67.7359 98.3585 66.6789 100.034 66.6789H106.913C108.588 66.6789 109.945 67.7359 109.945 69.0405V85.6212C109.945 86.9258 108.588 87.9828 106.913 87.9828Z"
          fill="#333756"
        />
        <path
          d="M12.9663 87.9828H6.08919C4.41406 87.9828 3.05673 86.9258 3.05673 85.6212V69.0405C3.05673 67.7359 4.41406 66.6789 6.08919 66.6789H12.9685C14.6436 66.6789 16.001 67.7359 16.001 69.0405V85.6212C16.001 86.9258 14.6414 87.9828 12.9663 87.9828Z"
          fill="#424566"
        />
        <path
          d="M3.43634 69.5372C3.62615 70.1783 4.40744 75.472 4.95478 78.6723C5.16665 79.9064 6.51736 80.8276 8.11745 80.8276H104.883C106.483 80.8276 107.833 79.9064 108.045 78.6723C108.593 75.472 109.374 70.18 109.564 69.5372C110.16 67.5125 111.115 65.5514 111.42 63.4786C111.804 60.8523 111.382 58.1848 110.1 55.7339C107.99 51.7017 104.315 48.2986 102.227 44.2561C100.047 40.0314 97.5309 35.9184 95.1672 31.7556C91.8566 25.9256 84.4476 22.1633 76.2618 22.1633H36.7382C28.5524 22.1633 21.1456 25.9273 17.8328 31.7556C15.4691 35.9184 12.9531 40.0297 10.7725 44.2561C8.68687 48.3003 5.01216 51.7017 2.90003 55.7339C1.61775 58.1848 1.1962 60.854 1.58023 63.4786C1.8848 65.5531 2.84044 67.5125 3.43634 69.5372Z"
          fill="#47B5FF"
        />
        <path
          d="M110.102 55.7339C107.99 51.7017 104.315 48.3003 102.23 44.2561C100.049 40.0314 97.5331 35.9184 95.1694 31.7556C91.8566 25.9273 84.4498 22.1633 76.264 22.1633H56.5V80.8276H104.885C106.485 80.8276 107.836 79.9081 108.047 78.6723C108.595 75.472 109.376 70.18 109.566 69.5389C110.162 67.5142 111.117 65.5531 111.422 63.4803C111.806 60.854 111.384 58.1848 110.102 55.7339Z"
          fill="#2192FF"
        />
        <path
          d="M94.5117 36.6386C93.7723 35.3684 93.0065 34.057 92.2671 32.7542C89.462 27.8214 83.183 24.6314 76.264 24.6314H36.7382C29.817 24.6314 23.5358 27.8197 20.7306 32.7542C19.9913 34.0553 19.2299 35.3684 18.4905 36.6369C17.089 39.0483 15.6478 41.5284 14.3258 44.0086C27.6938 44.9058 41.8497 45.3853 56.5 45.3853C71.1503 45.3853 85.3062 44.9058 98.6719 44.0086C97.3543 41.5302 95.9154 39.05 94.5117 36.6386Z"
          fill="#616484"
        />
        <path
          d="M94.5139 36.6403C93.7723 35.3702 93.0087 34.0588 92.2694 32.7542C89.4642 27.8231 83.183 24.6314 76.2662 24.6314H56.5V45.3853H56.5022C71.1547 45.3853 85.3084 44.9058 98.6742 44.0086C97.3566 41.5319 95.9154 39.0517 94.5139 36.6403Z"
          fill="#424566"
        />
        <path
          d="M88.9632 68.3479L91.8566 63.886C92.8432 62.3632 94.2998 61.0449 96.1317 60.1117C98.1974 59.0598 101.225 57.977 105.072 57.8498C105.072 57.8498 107.118 57.977 107.118 60.6548V63.5939C107.118 64.534 106.434 65.3934 105.348 65.8059C103.026 66.6876 98.1489 68.0385 89.1795 68.5937C89.0096 68.6057 88.886 68.4665 88.9632 68.3479Z"
          fill="#CAD4FF"
        />
        <path
          d="M101.943 59.993C100.009 59.993 98.438 61.2133 98.438 62.7223C98.438 64.2314 100.009 65.4517 101.943 65.4517C103.881 65.4517 105.448 64.2314 105.448 62.7223C105.448 61.2133 103.881 59.993 101.943 59.993ZM101.943 64.6508C100.577 64.6508 99.4665 63.7862 99.4665 62.7223C99.4665 61.6584 100.577 60.7939 101.943 60.7939C103.309 60.7939 104.419 61.6584 104.419 62.7223C104.419 63.7862 103.309 64.6508 101.943 64.6508Z"
          fill="#E6EAFF"
        />
        <path
          d="M101.943 61.3491C100.969 61.3491 100.179 61.9644 100.179 62.7223C100.179 63.4803 100.969 64.0956 101.943 64.0956C102.916 64.0956 103.706 63.4803 103.706 62.7223C103.706 61.9644 102.916 61.3491 101.943 61.3491ZM101.943 63.2208C101.59 63.2208 101.305 62.9973 101.305 62.7223C101.305 62.4473 101.59 62.2256 101.943 62.2256C102.296 62.2256 102.581 62.4473 102.581 62.7223C102.581 62.9973 102.296 63.2208 101.943 63.2208Z"
          fill="#E6EAFF"
        />
        <path
          d="M95.123 64.0028C94.0195 64.0028 93.1235 64.6989 93.1235 65.56C93.1235 66.4211 94.0195 67.1172 95.123 67.1172C96.2288 67.1172 97.1226 66.4211 97.1226 65.56C97.1226 64.6989 96.2288 64.0028 95.123 64.0028ZM95.123 66.66C94.344 66.66 93.7105 66.1667 93.7105 65.56C93.7105 64.9533 94.344 64.46 95.123 64.46C95.9021 64.46 96.5355 64.9533 96.5355 65.56C96.5355 66.1667 95.9021 66.66 95.123 66.66Z"
          fill="#E6EAFF"
        />
        <path
          d="M95.1253 64.9687C94.7059 64.9687 94.3638 65.2334 94.3638 65.56C94.3638 65.8865 94.7059 66.1512 95.1253 66.1512C95.5446 66.1512 95.8845 65.8865 95.8845 65.56C95.8845 65.2334 95.5446 64.9687 95.1253 64.9687ZM95.1253 65.7748C94.973 65.7748 94.8494 65.6786 94.8494 65.56C94.8494 65.4414 94.973 65.3451 95.1253 65.3451C95.2753 65.3451 95.3989 65.4414 95.3989 65.56C95.3989 65.6786 95.2753 65.7748 95.1253 65.7748Z"
          fill="#E6EAFF"
        />
        <path
          d="M105.075 57.848C101.228 57.9734 98.1975 59.058 96.134 60.1116C94.2977 61.0448 92.8433 62.3631 91.8567 63.8842L88.9655 68.3478C88.8883 68.4664 89.0119 68.6056 89.1796 68.5953C89.2856 68.5901 89.3915 68.5816 89.4974 68.573L92.3092 64.2383C93.2957 62.7155 94.7502 61.3955 96.5864 60.4639C98.5816 59.4447 101.482 58.398 105.15 58.2158C105.582 58.1934 106.021 58.2828 106.37 58.4839C106.375 58.4873 106.379 58.4891 106.383 58.4925C105.796 57.8926 105.075 57.848 105.075 57.848Z"
          fill="#FFC738"
        />
        <path
          d="M24.0368 68.3479L21.1434 63.886C20.1568 62.3632 18.7002 61.0449 16.8683 60.1117C14.8026 59.0598 11.7745 57.977 7.92766 57.8498C7.92766 57.8498 5.88174 57.977 5.88174 60.6548V63.5939C5.88174 64.534 6.56592 65.3934 7.65178 65.8059C9.97358 66.6876 14.8511 68.0385 23.8205 68.5937C23.9926 68.6057 24.114 68.4665 24.0368 68.3479Z"
          fill="#E6EAFF"
        />
        <path
          d="M7.55467 62.7223C7.55467 64.2314 9.12166 65.4517 11.0594 65.4517C12.9928 65.4517 14.5642 64.2314 14.5642 62.7223C14.5642 61.2133 12.9928 59.993 11.0594 59.993C9.12166 59.993 7.55467 61.2133 7.55467 62.7223ZM8.58315 62.7223C8.58315 61.6584 9.69329 60.7939 11.0594 60.7939C12.4256 60.7939 13.5357 61.6584 13.5357 62.7223C13.5357 63.7862 12.4256 64.6508 11.0594 64.6508C9.69329 64.6508 8.58315 63.7862 8.58315 62.7223Z"
          fill="white"
        />
        <path
          d="M11.0594 61.3491C10.0861 61.3491 9.29602 61.9644 9.29602 62.7223C9.29602 63.4803 10.0861 64.0956 11.0594 64.0956C12.0327 64.0956 12.8229 63.4803 12.8229 62.7223C12.8229 61.9644 12.0327 61.3491 11.0594 61.3491ZM11.0594 63.2208C10.7063 63.2208 10.4216 62.9973 10.4216 62.7223C10.4216 62.4473 10.7063 62.2256 11.0594 62.2256C11.4126 62.2256 11.6973 62.4473 11.6973 62.7223C11.6973 62.9973 11.4126 63.2208 11.0594 63.2208Z"
          fill="white"
        />
        <path
          d="M15.8774 65.56C15.8774 66.4211 16.7712 67.1172 17.877 67.1172C18.9805 67.1172 19.8765 66.4211 19.8765 65.56C19.8765 64.6989 18.9805 64.0028 17.877 64.0028C16.7712 64.0028 15.8774 64.6989 15.8774 65.56ZM16.4644 65.56C16.4644 64.9533 17.0979 64.46 17.877 64.46C18.656 64.46 19.2895 64.9533 19.2895 65.56C19.2895 66.1667 18.656 66.66 17.877 66.66C17.0979 66.66 16.4644 66.1667 16.4644 65.56Z"
          fill="white"
        />
        <path
          d="M17.877 64.9687C17.4598 64.9687 17.1177 65.2334 17.1177 65.56C17.1177 65.8865 17.4598 66.1512 17.877 66.1512C18.2963 66.1512 18.6384 65.8865 18.6384 65.56C18.6384 65.2334 18.2963 64.9687 17.877 64.9687ZM17.877 65.7748C17.7269 65.7748 17.6033 65.6786 17.6033 65.56C17.6033 65.4414 17.7269 65.3451 17.877 65.3451C18.0292 65.3451 18.1528 65.4414 18.1528 65.56C18.1528 65.6786 18.0292 65.7748 17.877 65.7748Z"
          fill="white"
        />
        <path
          d="M7.92767 57.848C11.7745 57.9734 14.8048 59.058 16.8684 60.1116C18.7046 61.0448 20.159 62.3631 21.1456 63.8842L24.0368 68.3478C24.114 68.4664 23.9904 68.6056 23.8227 68.5953C23.7168 68.5901 23.6108 68.5816 23.5049 68.573L20.6931 64.2383C19.7066 62.7155 18.2522 61.3955 16.4159 60.4639C14.4208 59.4447 11.5207 58.398 7.85263 58.2158C7.42005 58.1934 6.98085 58.2828 6.63214 58.4839C6.62772 58.4873 6.62331 58.4891 6.6189 58.4925C7.20597 57.8926 7.92767 57.848 7.92767 57.848Z"
          fill="#FFF259"
        />
        <path
          d="M85.1252 73.2703C84.9111 73.2944 84.7809 73.4731 84.8692 73.6261C86.0588 75.6955 88.2989 78.0536 91.4549 78.0536C93.172 78.0536 98.5792 77.6566 100.29 77.5173C101.649 77.4073 103.618 77.3352 104.748 76.658C105.353 76.2953 105.43 75.6319 105.445 75.0836C105.476 73.9595 105.622 72.2855 104.269 71.5258C103.549 71.1219 102.792 71.3178 101.989 71.4089C97.5949 71.9073 89.515 72.7667 85.1252 73.2703Z"
          fill="#0085F2"
        />
        <path
          d="M90.2477 73.193C90.0623 73.2136 89.9497 73.3683 90.027 73.5006C91.0555 75.2916 92.9954 77.3317 95.7255 77.3317C97.2131 77.3317 98.7028 77.318 100.182 77.196C101.358 77.0997 103.062 77.0378 104.039 76.4517C104.562 76.1389 104.629 75.5649 104.642 75.0888C104.668 74.116 104.794 72.667 103.625 72.0105C103.002 71.6616 102.347 71.83 101.651 71.9091C97.8509 72.3405 94.0482 72.7547 90.2477 73.193Z"
          fill="#424566"
        />
        <path
          d="M27.877 73.2703C28.0911 73.2944 28.2213 73.4731 28.133 73.6261C26.9434 75.6955 24.7033 78.0536 21.5472 78.0536C19.8302 78.0536 14.4229 77.6566 12.7125 77.5173C11.353 77.4073 9.38429 77.3352 8.25429 76.658C7.64956 76.2953 7.57232 75.6319 7.55687 75.0836C7.52597 73.9595 7.38031 72.2855 8.73322 71.5258C9.45271 71.1219 10.2097 71.3178 11.0131 71.4089C15.4051 71.9073 23.485 72.7667 27.877 73.2703Z"
          fill="#2192FF"
        />
        <path
          d="M22.7523 73.193C22.9377 73.2136 23.0502 73.3683 22.973 73.5006C21.9445 75.2916 20.0045 77.3317 17.2744 77.3317C15.7869 77.3317 14.2971 77.318 12.8184 77.196C11.6421 77.0997 9.93825 77.0378 8.96054 76.4517C8.43747 76.1389 8.37126 75.5649 8.35802 75.0888C8.33153 74.116 8.20573 72.667 9.37546 72.0105C9.99784 71.6616 10.6533 71.83 11.3485 71.9091C15.1491 72.3405 18.954 72.7547 22.7523 73.193Z"
          fill="#616484"
        />
        <path
          d="M111.682 38.6994C110.961 38.2937 110.076 38.1012 109.184 38.1012H106.553C105.684 38.1012 105.028 38.3126 104.534 38.5979C103.695 39.0844 103.241 39.8767 103.241 40.6914V42.3861H112.258C112.956 41.549 112.998 40.6226 112.998 40.6226C113 39.6928 112.367 39.0826 111.682 38.6994Z"
          fill="#2192FF"
        />
        <path
          d="M101.237 42.3895C101.477 42.8261 101.709 43.2644 101.941 43.7044H108.86C110.667 43.7044 111.685 43.077 112.258 42.3878H101.568L101.237 42.3895Z"
          fill="#424566"
        />
        <path
          d="M9.7617 40.6914C9.7617 39.8767 9.30705 39.0844 8.46617 38.5979C7.97621 38.3126 7.31852 38.1012 6.44674 38.1012H3.81816C2.92652 38.1012 2.04371 38.2937 1.3198 38.6994C0.633418 39.0826 0 39.6928 0 40.6226C0 40.6226 0.0419336 41.5422 0.732734 42.3758H9.7617V40.6914Z"
          fill="#47B5FF"
        />
        <path
          d="M9.76169 42.3758H0.732727C1.30435 43.0684 2.324 43.7044 4.14038 43.7044H11.0572C11.2867 43.2661 11.5229 42.8261 11.7591 42.3895L9.76169 42.3758Z"
          fill="#616484"
        />
        <path
          d="M107.807 79.3426C107.306 80.2261 106.176 80.8276 104.883 80.8276H8.11746C6.82414 80.8276 5.69414 80.2261 5.19756 79.3426H27.9785C30.1591 79.3426 32.1675 78.4162 33.2092 76.9226L34.1604 75.5665C35.4295 73.7498 37.8727 72.6206 40.5299 72.6206H72.5319C75.1891 72.6206 77.6323 73.7515 78.9014 75.5665L79.8526 76.9226C80.8943 78.4145 82.9027 79.3426 85.0833 79.3426H107.807Z"
          fill="#616484"
        />
        <path
          d="M85.0833 79.3426C82.9027 79.3426 80.8943 78.4162 79.8504 76.9243L78.9014 75.5683C77.6323 73.7515 75.1869 72.6223 72.5319 72.6223H56.5V80.8276H104.885C106.178 80.8276 107.308 80.2261 107.809 79.3426H85.0833Z"
          fill="#424566"
        />
        <path
          d="M98.6587 43.9776L97.1226 44.1065L96.9703 44.1203H96.924C84.0725 44.9453 70.5147 45.3853 56.5 45.3853C42.4854 45.3853 28.9232 44.947 16.0716 44.1203H16.0253L15.873 44.1065L14.3369 43.9776L11.0528 43.7026C11.0528 43.7026 17.2987 59.7867 29.2343 59.7867H83.759C95.6947 59.7867 101.936 43.7026 101.936 43.7026L98.6587 43.9776Z"
          fill="#5CC5FF"
        />
        <path
          d="M98.6587 43.9776L97.1226 44.1065L96.9725 44.1203H96.9262C84.0747 44.947 70.5169 45.3853 56.5022 45.3853H56.5V59.7884H83.7657C95.624 59.7884 101.861 43.914 101.943 43.7061C101.943 43.7043 101.943 43.7043 101.943 43.7043L98.6587 43.9776Z"
          fill="#47B5FF"
        />
        <path
          d="M87.0718 59.3622C86.0212 59.6372 84.9199 59.7867 83.7635 59.7867H29.2387C28.08 59.7867 26.9765 59.6355 25.9282 59.3622C25.6854 60.3831 26.2239 61.4659 26.7205 62.4181C27.5901 64.0991 29.0026 65.6167 30.5453 66.9728C31.3354 67.6689 32.5471 68.1519 33.463 68.1777C41.1501 68.3856 48.8217 68.4905 56.5022 68.4905C64.1827 68.4905 71.8543 68.3856 79.537 68.1777C80.4573 68.1536 81.6646 67.6706 82.4569 66.9728C83.9974 65.6167 85.4121 64.1008 86.2817 62.4181C86.7805 61.4677 87.3124 60.3866 87.0718 59.3622Z"
          fill="#616484"
        />
        <path
          d="M87.074 59.3639C86.0235 59.6372 84.9199 59.7884 83.7657 59.7884H56.5V68.4904H56.5022C64.1827 68.4904 71.8543 68.3856 79.537 68.1776C80.4573 68.1536 81.6646 67.6706 82.4591 66.9728C83.9974 65.6184 85.4143 64.1008 86.2839 62.4198C86.7805 61.4676 87.3124 60.3865 87.074 59.3639Z"
          fill="#424566"
        />
        <path
          d="M32.4742 59.7884V68.0075C32.077 67.8923 31.6731 67.7205 31.3001 67.5073V59.7884H32.4742Z"
          fill="#72769C"
        />
        <path
          d="M36.9501 59.7884V68.2653C36.5595 68.2567 36.1666 68.2464 35.776 68.2378V59.7884H36.9501Z"
          fill="#72769C"
        />
        <path
          d="M41.4238 59.7884V68.3547C41.0331 68.3495 40.6403 68.3426 40.2496 68.3323V59.7884H41.4238Z"
          fill="#72769C"
        />
        <path
          d="M45.8996 59.7884V68.4217C45.5068 68.4183 45.1161 68.4131 44.7255 68.408V59.7884H45.8996Z"
          fill="#72769C"
        />
        <path
          d="M50.3733 59.7884V68.4681C49.9826 68.4647 49.592 68.463 49.2013 68.4578V59.7884H50.3733Z"
          fill="#72769C"
        />
        <path
          d="M54.8491 59.7884V68.487C54.4585 68.4887 54.0656 68.487 53.675 68.4853V59.7884H54.8491Z"
          fill="#72769C"
        />
        <path
          d="M59.325 59.7884V68.4853C58.9343 68.487 58.5415 68.4887 58.1508 68.487V59.7884H59.325Z"
          fill="#616484"
        />
        <path
          d="M63.7987 59.7884V68.4578C63.408 68.463 63.0174 68.4647 62.6245 68.4681V59.7884H63.7987Z"
          fill="#616484"
        />
        <path
          d="M68.2745 59.7884V68.408C67.8839 68.4131 67.491 68.42 67.1004 68.4217V59.7884H68.2745Z"
          fill="#616484"
        />
        <path
          d="M72.7481 59.7884V68.3323C72.3553 68.3426 71.9646 68.3495 71.574 68.3547V59.7884H72.7481Z"
          fill="#616484"
        />
        <path
          d="M77.224 59.7884V68.2378C76.8334 68.2464 76.4405 68.2567 76.0499 68.2653V59.7884H77.224Z"
          fill="#616484"
        />
        <path
          d="M81.6999 59.7884V67.5073C81.3269 67.7222 80.923 67.894 80.5257 68.0092V59.7884H81.6999Z"
          fill="#616484"
        />
        <path
          d="M56.5 67.7549C59.106 67.7549 61.2186 66.1097 61.2186 64.0803C61.2186 62.0508 59.106 60.4056 56.5 60.4056C53.894 60.4056 51.7814 62.0508 51.7814 64.0803C51.7814 66.1097 53.894 67.7549 56.5 67.7549Z"
          fill="#E6EAFF"
        />
        <path
          d="M56.5 60.4037V67.7548C59.1065 67.7548 61.2208 66.11 61.2208 64.0802C61.2208 62.0503 59.1065 60.4037 56.5 60.4037Z"
          fill="#CAD4FF"
        />
        <path
          d="M56.5 66.9264C58.5185 66.9264 60.1548 65.6521 60.1548 64.0801C60.1548 62.5082 58.5185 61.2339 56.5 61.2339C54.4815 61.2339 52.8452 62.5082 52.8452 64.0801C52.8452 65.6521 54.4815 66.9264 56.5 66.9264Z"
          fill="#616484"
        />
        <path
          d="M56.5 61.2339V66.9264C58.5194 66.9264 60.1548 65.6511 60.1548 64.0801C60.1548 62.5075 58.5194 61.2339 56.5 61.2339Z"
          fill="#424566"
        />
        <path
          d="M56.5 61.0054L53.1718 65.6203H59.826L56.5 61.0054Z"
          fill="#E6EAFF"
        />
        <path d="M56.5 61.0036V65.6202H59.8282L56.5 61.0036Z" fill="#CAD4FF" />
        <path
          d="M55.0566 64.7608L56.5 62.7602L57.9412 64.7608H55.0566Z"
          fill="#616484"
        />
        <path d="M56.5 62.7602V64.7608H57.9434L56.5 62.7602Z" fill="#424566" />
      </g>
      <defs>
        <clipPath id="clip0_664_162">
          <rect width="113" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default MC_ic_long_term_rent
